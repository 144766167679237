import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';


import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js';


import { Context } from '../context/Context';
import axios from 'axios';
import api_base from '../api/api_base_url';
import api from '../api/api';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Merri Weather',
      fontSize: '17px',
      fontWeight: 'Bold',
      color: '#525D7D',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#979dac',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const AddPaymentMethodForm = () => {
  const {
    user,
    setUser,
    setPaymentMethods,
    showPaymentMethodModal,
    handleClosePaymentMethodModal,
    addPaymentMethodLoading,
    setAddPaymentMethodLoading,
    token
  } = useContext(Context);

  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [postal, setPostal] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    setAddPaymentMethodLoading(true)
    createPaymentMethod()
  }

  const createPaymentMethod = async event => {
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    
    // if(data.students.length > 0) {
      // for (var i = 0 ; i < data.students.length ; i++ ) {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            email: user.email,
            // name: user.first_name + ' ' + user.last_name,
            name,
            phone: (user.cell_phone) ? user.cell_phone : "+335555555555",
            address: {
              postal_code: postal,
            },
          },
        });
        stripePaymentMethodHandler(result);
      // }
    // }
  }

  const stripePaymentMethodHandler = async (result)=> {
    if (result.error) {
      // Show error in payment form
      alert(result.error.message)
    } else {
      const stripe_api_url = api_base + '/api/stripe/add-payment-method';
      const stripe_headers = {
        headers: { 
          'Content-Type': 'application/json',
        Authorization: "Bearer " + token, 
      },
      };

      const customer_data = {
        email: result.paymentMethod.billing_details.email,
        phone: result.paymentMethod.billing_details.phone,
        name: result.paymentMethod.billing_details.name,
        payment_method: result.paymentMethod.id,
        customer_id: user.customer_id,
      };

      await axios
        .post(stripe_api_url, customer_data, stripe_headers)
        .then(res => {
          if(res.data.status === 'success') {
              api.getPaymentMethods(token, user.customer_id, setPaymentMethods).then(() => {
                setAddPaymentMethodLoading(false)
                handleClosePaymentMethodModal()
              })
          } else {
            alert("There's an error saving your data.")
            setAddPaymentMethodLoading(false)
            handleClosePaymentMethodModal()
          }
        })
        .catch(e => {
          alert(`[Error ${e.response.status}] ${e.response.data.message}`)
        });
    }
  };

  return (
  <div className="payment-holder">
  <div className="form-group required">
    <div className="card-wrapper">
    <form onSubmit={handleSubmit}>
        <div className="card-holder">
        <label htmlFor="name">
            <span>Name on Card</span>
            <input
            id="name"
            className="text-holder t-h1"
            name="card"
            required
            placeholder="Jenny Rosen"
            value={name}
            onChange={e => {
                setName(e.target.value);
            }}
            />
        </label>
        </div>
        <div className="card-holder">
        <div className="info-holder">
            <label>Card Information</label>
            <CardNumberElement
            id="cardNumber"
            className="text-holder t-h1"
            options={ELEMENT_OPTIONS}
            />
            <CardExpiryElement
            id="expiry"
            className="text-holder t-h1"
            options={ELEMENT_OPTIONS}
            />
            <CardCvcElement
            id="cvc"
            className="text-holder t-h1"
            options={ELEMENT_OPTIONS}
            />
            <input
            id="postal"
            className="text-holder t-h1"
            required
            placeholder="Zipcode"
            value={postal}
            onChange={e => {
                setPostal(e.target.value);
            }}
            />
            {/* {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
        </div>
        <div className="button-holder">
            <button className="btn-submit d-flex" disabled={!stripe && !addPaymentMethodLoading}>
            { addPaymentMethodLoading && <Spinner
                className="mr-2"
                animation="border"
                role="status"
                aria-hidden="true"
            /> }
            { addPaymentMethodLoading && <span>Please wait...</span> }
            { !addPaymentMethodLoading && <span>{'Add Payment Method'}</span> }
            </button>
        </div>
        </div>
    </form>
    </div>
    </div>
    </div>
  )
}

export default AddPaymentMethodForm
