import React, { useRef } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'

import { ContextProvider } from './context/Context'
import { isLogin, isAdmin } from './components/util'

import Globals from './containers/Globals'
import Header from './components/Header'
import DashHeader from './components/DashHeader'
import RightSideBar from './components/RightSideBar'
import LeftSideBar from './components/LeftSideBar'
import DashboardHome from './components/DashboardHome'
import DashboardCardsSent from './components/DashboardCardsSent'
import DashboardStudentInfo from './components/DashboardStudentInfo'
import DashboardSubscription from './components/DashboardSubscription'
import DashboardUserInfo from './components/DashboardUserInfo'
import Footer from './components/Footer'
// import HomePage from './containers/HomePage'
import About from './containers/About'
import Resources from './containers/Resources'
import Faqs from './containers/Faq'
import Register from './containers/Register'
// import Dashboard from './containers/Dashboard';
import Landing from './containers/Landing'
import PolicyAndTerms from './containers/PolicyAndTerms'
import NoRouteMatch from './containers/404'
import FormModal from './components/ModalContainer'
import ForgotPassword from './containers/ForgotPassword'
import ChangeSubscriptionModal from './components/ChangeSubscriptionModal'
import ChangeStudentAddressModal from './components/ChangeStudentAddressModal'
import ChangePasswordModal from './components/ChangePasswordModal'
import EditMessageModal from './components/EditMessageModal'
import EditDefaultMessagesModal from './components/EditDefaultMessagesModal'

import AdminHeader from './components/AdminHeader'
import AdminHome from './components/AdminHome'
import AdminLeftSideBar from './components/AdminLeftSidebar'
import AdminRightSideBar from './components/AdminRightSidebar'
import AdminLanding from './containers/AdminLanding'
import AdminManageColleges from './components/AdminManageColleges'
import AdminManageTemplates from './components/AdminManageTemplates'
import AdminManageSubscribers from './components/AdminManageSubscribers'
import AdminManageTwilio from './components/AdminManageTwilio'
import AdminManageAffilliates from './components/AdminManageAffiliates'

const PublicRoute = ({ component: Component, restricted, reference, ...rest }) => {
  return (
    <>
      {/* <Header /> */}
      <Route
        {...rest}
        render={props =>
          isLogin() && restricted ? <Redirect to="/dashboard" /> : <Component {...props} reference={reference} />
        }
      />
      {/* <Footer /> */}
      <FormModal />
      <ChangePasswordModal />
    </>
  )
}

const AuthRoute = ({ component: Component, restricted, reference, ...rest }) => {
  return (
    <>
      <Route
        {...rest}
        render={props => {
          let route = '/dashboard'
          if (isAdmin()) {
            route = '/admin-dashboard'
          }
          return (isLogin() || isAdmin()) && restricted ? (
            <Redirect to={route} />
          ) : (
            <Component {...props} reference={reference} />
          )
        }}
      />
    </>
  )
}

const DashboardRoute = ({ component: Component, restricted, reference, ...rest }) => {
  return (
    <>
      <DashHeader />
      <Route
        {...rest}
        render={props =>
          isLogin() === false && restricted ? <Redirect to="/login" /> : <Component {...props} reference={reference} />
        }
      />
      <LeftSideBar />
      <RightSideBar />
      <ChangeSubscriptionModal />
      <ChangeStudentAddressModal />
      <ChangePasswordModal />
      <EditMessageModal />
      <EditDefaultMessagesModal />
      <FormModal />
    </>
  )
}

const AdminRoute = ({ component: Component, restricted, reference, ...rest }) => {
  return (
    <>
      <AdminHeader />
      <Route
        {...rest}
        render={props =>
          isAdmin() === false && restricted ? <Redirect to="/" /> : <Component {...props} reference={reference} />
        }
      />
      <AdminLeftSideBar />
      <AdminRightSideBar />
      {/* <AdminRightSideBar />
      <ChangePasswordModal /> */}
    </>
  )
}

const initializeReactGA = () => {
  ReactGA.initialize('UA-163393661-1')
  ReactGA.pageview('/')
  // ReactGA.pageview('/home')
  ReactGA.pageview('/sign-up')
  ReactGA.pageview('/login')
  ReactGA.pageview('/forgot-password')
  // ReactGA.pageview('/globals')
  // ReactGA.pageview('/about')
  // ReactGA.pageview('/resources')
  // ReactGA.pageview('/faq')
  // ReactGA.pageview('/policy-and-terms')
}

function App() {
  console.log('02/23/2021 Updates')
  const appRef = useRef(null)
  initializeReactGA()
  // useEffect(() => loadReCaptcha(reCAPTCHA_key), []);

  return (
    <HashRouter basename="/">
      <ContextProvider>
        <div className="App">
          <Switch>
            <AuthRoute restricted={true} component={Landing} path={['/', '/login']} reference={appRef} exact />
            <AuthRoute restricted={true} component={ForgotPassword} path="/forgot-password" reference={appRef} exact />
            {/* <Route
              restricted={true}
              component={ChangePassword}
              path="/change-password"
              reference={appRef}
              exact /> */}
            <DashboardRoute restricted={true} component={DashboardHome} path="/dashboard" exact />
            <DashboardRoute restricted={true} component={DashboardCardsSent} path="/cards-sent" exact />
            <DashboardRoute restricted={true} component={DashboardStudentInfo} path="/student-info" exact />
            {/* <DashboardRoute
              restricted={true}
              component={DashboardSubscription}
              path="/subscription-details"
              exact
            /> */}
            <DashboardRoute restricted={true} component={DashboardUserInfo} path="/profile" exact />
            {/* <PublicRoute
              restricted={false}
              component={HomePage}
              path={['/', '/home']}
              reference={appRef}
              exact
            /> */}
            {/* <AuthRoute
              restricted={false}
              component={Landing}
              path={['/', '/login']}
              reference={appRef}
              exact
            /> */}
            {/* <PublicRoute
              restricted={false}
              component={Register}
              path="/sign-up"
              exact
            /> */}
            <AuthRoute restricted={true} component={Register} path="/sign-up" reference={appRef} exact />
            {/* <PublicRoute
              restricted={false}
              component={Globals}
              path="/globals"
              reference={appRef}
              exact
            /> */}
            {/* <PublicRoute
              restricted={false}
              component={About}
              path="/about"
              reference={appRef}
              exact
            />
            <PublicRoute
              restricted={false}
              component={Resources}
              path="/resources"
              reference={appRef}
              exact
            />
            <PublicRoute
              restricted={false}
              component={Faqs}
              path="/faq"
              reference={appRef}
              exact
            />
            <PublicRoute
              restricted={false}
              component={PolicyAndTerms}
              path="/policy-and-terms"
              reference={appRef}
              exact
            /> */}
            <AuthRoute restricted={true} component={AdminLanding} path="/c2c-admin" reference={appRef} exact />
            <AdminRoute restricted={true} component={AdminHome} path="/admin-dashboard" exact />
            <AdminRoute restricted={true} component={AdminManageColleges} path="/manage-colleges" exact />
            <AdminRoute restricted={true} component={AdminManageTemplates} path="/manage-templates" exact />
            <AdminRoute restricted={true} component={AdminManageSubscribers} path="/manage-subscribers" exact />
            <AdminRoute restricted={true} component={AdminManageTwilio} path="/manage-twilio" exact />
            <AdminRoute restricted={true} component={AdminManageAffilliates} path="/manage-affiliates" exact />
            <Route component={NoRouteMatch} />
          </Switch>
        </div>
      </ContextProvider>
    </HashRouter>
  )
}

export default App
