import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Page404 = () => {
  return (
    <main className="page-404" id="page404">
      <div className="page-404-wrapper">
        <div className="img-holder">
          <img src={require('../assets/images/404.png')} alt="404-Page" />
        </div>
        <div className="text-holder">
          <div className="text-wrapper">
            <h1>Uh-Oh, page not found…</h1>
            <Link smooth to="/" className="btn-teal c-white t-uppercase">
              Go back home
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Page404;
