import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";

import { Context } from "../context/Context";
import api from "../api/api";
import { validateWhiteSpace, validateEmailAddress } from "./util";

const EditStudentInfoModal = () => {
  const {
    showEditStudentInfoModal,
    handleCloseEditStudentInfoModal,
    updateStudentInfoLoading,
    selectedStudent,
    setSelectedStudent,
    onChangeStudentData,
    setUpdateStudentInfoLoading,
    token,
    schools,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
  } = useContext(Context);

  const [selectedSemesterDate, setSelectedSemesterDate] = useState(null);

  const [isAddingNewSchool, setIsAddingNewSchool] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState("default");

  const schoolOptions =
    schools &&
    schools.map((school, index) => {
      return (
        <option key={index} value={school.college_name}>
          {school.college_name}
        </option>
      );
    });

  const [data, setData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    school: "",
    first_day_of_semester: "",
    street: "",
    unit: "",
    city: "",
    state: "",
    zipcode: "",
    company: "",
  });

  useEffect(() => {
    setData({
      id: selectedStudent.id,
      first_name: selectedStudent.first_name,
      last_name: selectedStudent.last_name,
      email: selectedStudent.email,
      school: selectedStudent.school,
      first_day_of_semester: selectedStudent.first_day_of_semester,
      street: selectedStudent.street === null ? "" : selectedStudent.street,
      unit: selectedStudent.unit === null ? "" : selectedStudent.unit,
      city: selectedStudent.city === null ? "" : selectedStudent.city,
      state: selectedStudent.state === null ? "" : selectedStudent.state,
      zipcode: selectedStudent.zipcode === null ? "" : selectedStudent.zipcode,
      company: selectedStudent.company === null ? "" : selectedStudent.company,
    });

    const selectedDate = selectedStudent.first_day_of_semester;
    const selectedSchool = selectedStudent.school;

    setSelectedSemesterDate(new Date(selectedDate ? selectedDate : null));

    const schoolExists = schools.some(
      (school) => school.college_name == selectedSchool
    );

    if (schoolExists) {
      setIsAddingNewSchool(false);
      setSelectedSchool(selectedSchool);
    } else {
      setIsAddingNewSchool(true);
      setSelectedSchool("add-new-school");
    }
  }, [showEditStudentInfoModal, selectedStudent]);

  const updateData = (e) => {
    if (e === null) return;
    let target = null;
    let value = null;

    if (e.target !== undefined) {
      target = e.target.name;
      value = e.target.value;
    }

    if (e.target === undefined) {
      let selectedDate = e;
      let formatedDate =
        selectedDate.getMonth() +
        1 +
        "/" +
        selectedDate.getDate() +
        "/" +
        selectedDate.getFullYear();
      setSelectedSemesterDate(e);
      setData({
        ...data,
        ["first_day_of_semester"]: formatedDate,
      });
    } else {
      setData({
        ...data,
        [target]: value,
      });
    }
  };

  const handleOnChangeSchool = (e) => {
    let value = e.target.value;
    setData({
      ...data,
      ["school"]: "",
      ["semester"]: "",
    });
    if (value === "default") {
      setIsAddingNewSchool(false);
      setSelectedSchool("default");
    } else if (value === "add-new-school") {
      setSelectedSchool("add-new-school");
      setIsAddingNewSchool(true);
    } else {
      setData({
        ...data,
        ["school"]: value,
        ["semester"]: "0",
      });
      setSelectedSchool(value);
      setIsAddingNewSchool(false);
    }
  };

  const editStudentInfo = () => {
    setUpdateStudentInfoLoading(true);
    api
      .handleUpdateStudent(
        token,
        data,
        setSelectedStudent,
        newRecipientData,
        setNewRecipientData,
        getSubscriptionType
      )
      .then(() => {
        setUpdateStudentInfoLoading(false);
        handleCloseEditStudentInfoModal();
      });
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(data.first_name) === -1 || data.first_name === "") {
      alert("First name is required");
    } else if (
      validateWhiteSpace(data.last_name) === -1 ||
      data.last_name === ""
    ) {
      alert("Last name is required");
    } else if (
      validateEmailAddress(data.email) === -1 &&
      data.email !== "" &&
      data.email !== null &&
      selectedStudent.subscription_type != "theme"
    ) {
      alert("Invalid email address");
    } else if (
      (validateWhiteSpace(data.school) === -1 || data.school === "") &&
      selectedStudent.subscription_type != "theme"
    ) {
      alert("School is required");
    } else if (
      (validateWhiteSpace(data.first_day_of_semester) === -1 ||
        data.first_day_of_semester === "") &&
      selectedStudent.subscription_type != "theme"
    ) {
      alert("First day of current semester is required");
    } else if (validateWhiteSpace(data.street) === -1 || data.street === "") {
      alert("Street is required");
    } else if (validateWhiteSpace(data.city) === -1 || data.city === "") {
      alert("City is required");
    } else if (validateWhiteSpace(data.state) === -1 || data.state === "") {
      alert("State is required");
    } else if (validateWhiteSpace(data.zipcode) === -1 || data.zipcode === "") {
      alert("Zipcode is required");
    } else {
      editStudentInfo();
    }
  };

  const isOnlyMonday = (date) => {
    const day = date.getDay();
    return day === 1;
  };

  const getSchoolsByType = (subscriptionType) => {
    return schools.reduce((result, school) => {
      if (subscriptionType == school.qtr_or_sem) {
        result.push(school);
      }
      return result;
    }, []);
  };

  const getSchoolOptions = () => {
    let schoolTypeAbbrv = "S";
    selectedStudent.subscription_type === "quarter" && (schoolTypeAbbrv = "Q");

    const filteredSchools = getSchoolsByType(schoolTypeAbbrv);

    return filteredSchools.length ? (
      filteredSchools.map((school, index) => {
        return (
          <option key={index} value={school.college_name}>
            {school.college_name}
          </option>
        );
      })
    ) : (
      <option value="default">No Schools Available</option>
    );
  };

  return (
    <Modal
      className="c2c-modal edit-student-modal"
      show={showEditStudentInfoModal}
      onHide={handleCloseEditStudentInfoModal}
      centered
      backdrop={"static"}
    >
      <div
        className="btn-close-modal"
        onClick={handleCloseEditStudentInfoModal}
      ></div>
      <div className="step-two">
        <h2 className="title t-h1 c-grayish-navy">
          Update {selectedStudent.first_name}'s Info
        </h2>
        <div className="student-details-holder">
          <div className="form-group required">
            <div className="title-holder">
              <h3 className="info-title t-h1 c-grayish-navy">
                {selectedStudent.subscription_type != "theme"
                  ? "Student Details"
                  : "Details"}
              </h3>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="first_name"
                placeholder="First Name"
                value={data.first_name || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={data.last_name || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>
            {selectedStudent.subscription_type != "theme" && (
              <React.Fragment>
                <div className="input-wrapper">
                  {schools && (
                    <select
                      name="school"
                      className="text-holder t-h1"
                      value={selectedSchool}
                      onChange={handleOnChangeSchool}
                    >
                      <option value="default">Choose a school</option>
                      <optgroup label="Available schools">
                        {getSchoolOptions()}
                      </optgroup>
                      <optgroup label="Didn't find your school?">
                        <option value="add-new-school">
                          Add another school
                        </option>
                      </optgroup>
                      {/* {schools ? schoolOptions : <option value="default">No Schools Available</option>} */}
                      {/* <optgroup label="Didn't find your school?">
                        <option value="add-new-school">Add another school</option>
                      </optgroup> */}
                    </select>
                  )}
                  <div className="input-subtext"></div>
                </div>
                {isAddingNewSchool && (
                  <div className="add-school-header label c-grayish-navy">
                    Enter school name and start of classes date below:
                  </div>
                )}
                {isAddingNewSchool && (
                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="school"
                      placeholder="School Name"
                      value={data.school || ""}
                      onChange={updateData}
                    />
                    <div className="input-subtext"></div>
                  </div>
                )}
                {isAddingNewSchool && (
                  <div className="input-wrapper">
                    <div className="input-label-wrapper">
                      <DatePicker
                        className="text-holder t-h1"
                        name="first_day_of_semester"
                        onChange={updateData}
                        selected={selectedSemesterDate || ""}
                        filterDate={isOnlyMonday}
                        placeholderText="Click to select a date"
                      />
                      <span>
                        First Day of Current{" "}
                        {selectedStudent.subscription_type == "semester"
                          ? "Semester"
                          : "Quarter"}
                      </span>
                    </div>
                    <div className="input-subtext"></div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {/* {selectedStudent.subscription_type != "theme" && (
          <div className="email-holder">
            <div className="form-group">
              <div className="input-wrapper">
                <input
                  className="text-holder t-h1"
                  type="text"
                  name="email"
                  placeholder="Student Email Address"
                  value={data.email || ""}
                  onChange={updateData}
                ></input>
                <div className="input-subtext"></div>
              </div>
              <span className="label c-grayish-navy">
                For Student E-Gift Card
              </span>
            </div>
          </div>
        )} */}
        <div className="address-holder">
          <div className="form-group">
            <span className="title-holder">
              <h3 className="info-title t-h1 c-grayish-navy">
                {selectedStudent.subscription_type != "theme"
                  ? "Student Address"
                  : "Address"}
              </h3>
              {/* <span className="label c-grayish-navy">
                Don't have their address yet? No problem. You can add it later.
              </span> */}
            </span>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="street"
                placeholder="Address 1"
                value={data.street || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="unit"
                placeholder="Address 2"
                value={data.unit || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="city"
                placeholder="City"
                value={data.city || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="state"
                placeholder="State"
                value={data.state || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="zipcode"
                placeholder="Zipcode"
                value={data.zipcode || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>
          </div>

          <div className="form-group">
            <span className="title-holder">
              <h3 className="info-title t-h1 c-grayish-navy">
                College Name
              </h3>
            </span>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="company"
                placeholder="Only put if necessary for mailing address"
                value={data.company || ""}
                onChange={updateData}
              />
              <div className="input-subtext"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={updateStudentInfoLoading}
        >
          {updateStudentInfoLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {updateStudentInfoLoading && <span>Please wait</span>}
          {!updateStudentInfoLoading && <span>Save</span>}
        </button>
      </div>
    </Modal>
  );
};

export default EditStudentInfoModal;
