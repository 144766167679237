import React from 'react';

import C2CModal from "./C2CModal";
import { Context } from "../../context/Context";
import GiftCardOptions from "./GiftCardOptions";
import Spinner from 'react-bootstrap/Spinner';
import api from '../../api/api'

const UpdateGiftCardModal = () => {
  const {
    selectedStudent,
    onChangeStudentData,
    updateGiftCardModalState,
    handleCloseUpdateGiftCardModal,
    giftCardProcess,
    setGiftCardProcess,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
    token,
    setSelectedStudent,
  } = React.useContext(Context);

  const [data, setData] = React.useState({gift_card: "", id: ""})

  React.useEffect(() => {
    setData({
      ...data,
      gift_card: selectedStudent.gift_card,
      id: selectedStudent.id
    })
  }, [selectedStudent])

  // const buildGiftCardTexts = (giftCardsList) => {
  //   return giftCardsList ? giftCardsList.join(", ") : ""
  // }

  const updateGiftCard = () => {
    if (!data.gift_card ) {
      alert("Gift Card is required")
    } else {
      setGiftCardProcess(true)
      api.handleUpdateStudent(
        token,
        data,
        setSelectedStudent,
        newRecipientData,
        setNewRecipientData,
        getSubscriptionType).then(() => {
        setGiftCardProcess(false)
        handleCloseUpdateGiftCardModal()
      })
    }
  }

  return (
    <C2CModal
      show={updateGiftCardModalState}
      onHide={handleCloseUpdateGiftCardModal}
      // title={`Update Gift Card`}
      customClass={"confirm-modal"}
    >
      <GiftCardOptions data={selectedStudent} handleOnChange={onChangeStudentData} title={`Update Gift Card for ${selectedStudent.nick_name}`} />

      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={giftCardProcess ? null : updateGiftCard}
          disabled={giftCardProcess}
        >
          { giftCardProcess && <Spinner
            className="mr-2"
            animation="border"
            role="status"
            aria-hidden="true"
          /> }
          { giftCardProcess && <span>Please wait</span> }
          { !giftCardProcess && <span>Update</span> }
        </button>
      </div>
    </C2CModal>
  )
}

export default UpdateGiftCardModal