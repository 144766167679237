import React from 'react';
import { Redirect } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import Contact from '../components/ContactUs';
import Newsletter from '../components/Newsletter';

import MetaTags from 'react-meta-tags';

const memberList = [
  {
    memberPhoto: require('../assets/images/member-2.jpeg'),
    memberName: 'Stacy Dennig',
    memberPosition: 'Co-Founder / CEO',
    memberInfo:
      "Stacy is a wife & mother who knows the importance of the mind, body, & spirit connection. In 2015, Stacy took over her grandmother's legacy of writing weekly letters to her cousins away at college. Now Stacy wants to share her passionate gift to inpire & motivate other college students across our country as they navigate their way through college - away from home, on their own, but never alone.",
  },

  {
    memberPhoto: require('../assets/images/member-1.jpg'),
    memberName: 'Melissa Dunderdale',
    memberPosition: 'Co-Founder / COO',
    memberInfo:
      "Melissa is a wife and mother to 6, who knows how hard it can be to find time to connect with kids away at college. It's a critical time for the young adults of today who desperately need support to face the many challenges that college life brings. Now, Melissa hopes to create an easy way to consistently stay connected with kids away at college - no longer at home yet always in our thoughts.",
  },
];

const legacy = [
  {
    legacyTitle: 'It All Started When...',
    legacyInfoFirst:
      'M. Charlotte & Michael Mike Sr. are an amazing couple we get the honor to call our grandparents! Our grandfather, who started his own business in 1947, always believed in higher education & put many family members through college!',
    legacyInfoSecond:
      "Over thirty years ago, our grandmother started writing weekly letters to her grandchildren away at college to keep them 'in the know' with family happenings & motivated to 'stay the college course' as she knew how challenging being away from home was for them. We looked forward to our weekly letter in our college campus mailboxes. Our grandfather passed away in 1992 & is to this day, the wind beneath our wings. Our grandmother wrote weekly letters for over 15 years and on November 3rd of 2019, she turned 92 years old. Although her mind hasn't kept pace with her body, she has her two granddaughters to carry-on her weekly letter-writing legacy.",
    legacyInfoThird:
      "In August 2019, Cards2College was a long-awaited idea that finally came to fruition. The goal is to bring the same weekly encouragement we received to college students across the country. We're excited to share our motivational messages to help inspire your student!",
  },
];

const legacyImages = [
  {
    legacyImage: require('../assets/images/legacy-image-1.png'),
    legacyImageCaption:
      'Pictured Above: Michael A Mike Sr. & M. Charlotte Mike',
  },
  {
    legacyImage: require('../assets/images/legacy-image-2.png'),
    legacyImageCaption: 'Pictured Above: Letters from M. Charlotte Mike 1990',
  },
];

const contactInfo = {
  title: 'Contact Us',
  heading: "We're just a message away...",
  subheading:
    "Reach out, or fill out the form and we'll get in touch with you!",
  phone: '1-702-551-CARD (2273)',
  email: 'support@cards2college.com',
};

const AboutPage = () => {

  return (
    <main className="about">
      <MetaTags>
        <title>Letters from home sent weekly for consistent encouragement.</title>
        <meta name="keywords" content="Cards, Cards to college, College Student, Mental Health, Inspirational Quotes, Motivational Messages, Weekly Cards, Staying Connected, Personalized greeting card, University, Support, Letters from Home, Encouragement, Homesick" />
        <meta name="description" content="An opportunity to create cherished letters from home to support your student away at college.  Students need encouragement now more than ever!" />
        <meta property="og:title" content="Letters from home sent weekly for consistent encouragement." />
      </MetaTags>
      <div className="section-banner">
        <div className="container custom-container">
          <div className="banner-wrapper">
            <div className="first-holder">
              <h1 className="headline t-h1 c-purple">
                We're Excited to Launch
              </h1>
              <p className="headline-subheading banner-headlinesub">
                Fall Semester 2020
              </p>
              <div className="button-holder">
                <Link
                  smooth
                  to="/#how-it-works"
                  className="btn btn-white-purple c-purple"
                >
                  Learn More
                </Link>
              </div>
              {/* <Button content={ bannerButton } />
               */}
            </div>
            <div className="design">
              <p className="t-h3 line">Coming Soon!</p>
            </div>
            <div className="second-holder">
              <div className="image-holder">
                <img
                  src={require('../assets/images/about-hero-image.png')}
                  className="image"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-legacy" id="legacy">
        <div className="container custom-container">
          <h2 className="t-h1 title c-purple line">Our Legacy</h2>
          <div className="legacy-content-holder">
            <div className="legacy-list">
              {legacy.map((item, i) => {
                return (
                  <div className="legacy-content" key={i}>
                    <h2 className="t-h1 title c-purple">{item.legacyTitle}</h2>
                    <p className="legacy-info">{item.legacyInfoFirst}</p>
                    <p className="legacy-info">{item.legacyInfoSecond}</p>
                    <p className="legacy-info">{item.legacyInfoThird}</p>
                  </div>
                );
              })}
            </div>
            <div className="legacy-list">
              <div className="quote-holder">
                <p className="quote">
                  “The greatest legacy one can pass on to one's children and
                  grandchildren is not money or other material things
                  accumulated in one's life, but rather a legacy of character
                  and faith.”
                </p>
                <p className="author">-Billy Graham</p>
              </div>
              {legacyImages.map((item, i) => {
                return (
                  <div className="legacy-content" key={i}>
                    <div className="image-holder">
                      <img src={item.legacyImage} className="image" alt="" />
                      <p className="image-caption">{item.legacyImageCaption}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="section-meet-team" id="team">
        <div className="container custom-container">
          <h2 className="t-h1 title c-purple line">Meet Our Team</h2>
          <div className="meet-team-holder">
            {memberList.map((item, i) => {
              return (
                <div className="member-list" key={i}>
                  <div className="image-holder">
                    <img src={item.memberPhoto} className="image" alt="" />
                  </div>
                  <div className="info">
                    <p className="member-name t-h1">{item.memberName}</p>
                    <p className="member-position">{item.memberPosition}</p>
                    <p className="member-info">{item.memberInfo}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="quote-holder">
            <p className="quote t-h1">
              "Every great dream begins with a dreamer. Always remember, you
              have within you the strength, the patience, and the passion to
              reach for the stars to change the world."
            </p>
            <p className="author t-h4">-Harriet Tubman</p>
          </div>
        </div>
      </div>
      <div className="section-cta border-purple">
        <h2 className="t-h1 title c-purple">
          Ready to Encourage Your Student?
        </h2>
        <p className="t-h4 desc">Start Today!</p>
        <Link smooth to="/#plan" className="btn-teal c-white">
          Sign Up
        </Link>
      </div>
      <Contact content={contactInfo} />
      <Newsletter />
    </main>
  );
};

export default AboutPage;
