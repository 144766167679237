import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import { reCAPTCHA_key_v2 } from '../helpers/constants'
import SweetAlert from 'sweetalert2-react'
import api_base from '../api/api_base_url'

const Newsletter = () => {
  const [name, setName] = useState({
    fist_name: '',
    last_name: '',
  })
  const [email, setEmail] = useState('')
  const [reCaptcha, setReCaptcha] = useState(false)
  const [showSwal, setShowSwal] = useState(false)
  const [swalContent, setSwalContent] = useState({
    title: '',
    text: '',
  })
  const recaptchaRef = React.createRef()

  const signUp = (e) => {
    sendData()
      .then((res) => {
        // window.alert('Success! You are now signed up!');
        setName({
          fist_name: '',
          last_name: '',
        })
        setEmail('')
        setSwalContent({
          title: 'Success!',
          text: 'You are now Signed Up',
        })
        setReCaptcha(false)
        setShowSwal(true)
      })
      .catch((e) => {
        // alert(`[Error ${e.response.status}] ${e.response.data.message}`)
        setSwalContent({
          title: 'Oops!',
          text: e.response.data.errors_all,
        })
        setShowSwal(true)
        setReCaptcha(false)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (name === '' || email === '') {
      setSwalContent({
        title: 'Oops!',
        text: 'Please complete the form',
      })
      setShowSwal(true)
    } else setReCaptcha(true)
  }

  const handleChange = (e) => {
    const value = e.target.value
    const target = e.target.name
    setName({
      ...name,
      [target]: value,
    })
  }
  const handleChangeEmail = (e) => {
    const value = e.target.value
    setEmail(value)
  }

  async function sendData() {
    /*
     * Added https://cors-anywhere.herokuapp.com/ to fix cors issue
     */
    const api_url = api_base + '/api/mailchimp/subscribe'
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    }

    const data = {
      email: email,
      first_name: name.first_name,
      last_name: name.last_name,
    }
    await axios.post(api_url, data, headers)
    // return sendSingUp;
  }

  return (
    <div className="section-newsletter bg-purple">
      <div className="container custom-container">
        <p className="t-h1 title c-white line">Cards2College Newsletter</p>
        <p className="t-h4 desc c-white">Sign Up Today!</p>

        {!reCaptcha && (
          <Form className="form-wrapper" noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="formGroupName">
              <Form.Control
                className="c-white"
                placeholder="First Name"
                name="first_name"
                value={name.first_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formGroupName">
              <Form.Control
                className="c-white"
                placeholder="Last Name"
                name="last_name"
                value={name.last_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Control
                className="c-white"
                type="email"
                placeholder="Email"
                name="Email"
                value={email}
                onChange={handleChangeEmail}
              />
            </Form.Group>

            <div className="button-holder">
              <Button className="btn-white c-grayish-navy" type="submit">
                Sign Up
              </Button>
            </div>
          </Form>
        )}
        {reCaptcha && (
          <div className="newsletter-captcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCAPTCHA_key_v2}
              onChange={(value) => {
                signUp()
              }}
            />
          </div>
        )}
      </div>
      {showSwal && (
        <SweetAlert
          show="true"
          title={swalContent.title}
          text={swalContent.text}
          onConfirm={() => setShowSwal(false)}
        />
      )}
    </div>
  )
}

export default Newsletter
