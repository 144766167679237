import React, { useContext, useState, useEffect } from 'react';

import { Context } from '../context/Context';

import ConfirmModal from '../components/ConfirmModal';
import CardImagesForm from '../components/CardImagesForm';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api/api';
import { validateWhiteSpace } from './util';
import api_base from '../api/api_base_url';

const UpdateTemplateModal = ({ template }) => {
  const {
    tokenAdmin,
    setUpdateTemplateLoading,
    updateTemplateLoading,
    showUpdateTemplateModal,
    handleCloseUpdateTemplateModal,
    setTemplates,
  } = useContext(Context);

  const [data, setData] = useState({
    id: '',
    template_id: '',
    template_name: '',
    message_1: '',
    order: '',
    cover_image: '',
    message_image: '',
    cover_image_src: '',
    message_image_src: '',
    quarter_order: '',
  });

  useEffect(() => {
    setData({
      id: template.id,
      template_id: template.template_id,
      template_name: template.template_name,
      message_1: template.message_1,
      order: template.order,
      cover_image: '',
      message_image: '',
      cover_image_src: `${api_base}/storage/templates/${template.template_id}.png?${new Date().getTime()}`,
      message_image_src: `${api_base}/storage/messages/${template.message_1}.png?${new Date().getTime()}`,
      quarter_order: template.quarter_order,
    });
  }, [showUpdateTemplateModal]);

  const handleOnChange = function (e) {
    if (e.target == undefined) return;
    const inputType = e.target.type;

    if (inputType === 'text') {
      const target = e.target.name;
      const value = e.target.value;
      setData({
        ...data,
        [target]: value,
      });
      return;
    } else if (inputType === 'file') {
      const imageObject = e.target.files[0] || e.dataTransfer.files[0];
      const fileName = imageObject.name;
      const fileType = imageObject.type;
      const inputName = e.target.name;

      if (fileType !== 'image/png') {
        alert('Image file type is not supported. Please choose PNG image.');
        return;
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setData({
            ...data,
            [inputName]: e.target.result,
            [`${inputName}_src`]: e.target.result,
          });
        };
        reader.readAsDataURL(imageObject);
        return;
      }
    }
  };

  const updateTemplate = () => {
    setUpdateTemplateLoading(true);
    // console.log(data)
    api.handleManageTemplateData(tokenAdmin, data, 'update').then(() => {
      api.handleGetTemplateData(tokenAdmin, setTemplates).then(() => {
        setUpdateTemplateLoading(false);
        handleCloseUpdateTemplateModal();
      });
    });
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(data.order) === -1 || data.order === '') {
      alert('Order is required');
    } else if (validateWhiteSpace(data.template_id) === -1 || data.template_id === '') {
      alert('Template id is required');
    } else if (validateWhiteSpace(data.template_name) === -1 || data.template_name === '') {
      alert('Template name is required');
    } else if (
      validateWhiteSpace(data.quarter_order) === -1 ||
      data.quarter_order === '' ||
      data.quarter_order === undefined
    ) {
      alert('Quarter order is required');
    } else if (data.quarter_order?.length > 3) {
      alert('Quarter order max character length is 3');
    } else {
      updateTemplate();
    }
  };

  return (
    <Modal
      className='c2c-modal'
      show={showUpdateTemplateModal}
      onHide={handleCloseUpdateTemplateModal}
      centered
      backdrop={'static'}
    >
      <div className='btn-close-modal' onClick={handleCloseUpdateTemplateModal}></div>

      <CardImagesForm data={data} handleOnChange={handleOnChange} title={data.template_name} />

      <div className='btn-holder'>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={() => handleCloseUpdateTemplateModal()}
          disabled={updateTemplateLoading}
        >
          <span>Cancel</span>
        </button>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={() => handleOnSave()}
          disabled={updateTemplateLoading}
        >
          {updateTemplateLoading && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
          {updateTemplateLoading && <span>Please wait ...</span>}
          {!updateTemplateLoading && <span>Update</span>}
        </button>
      </div>
    </Modal>
  );
};

export default UpdateTemplateModal;
