import React, { useContext, useState } from 'react';

import { Context } from '../context/Context';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api/api';
import UpdateGiftCardModal from './NewComponents/UpdateGiftCardModal';

const DashboardSubscription = () => {
  const {
    selectedStudent,
    handleShowPlanModal,
    defaultPlans,
    subscriptions,
    invoice,
    upcomingInvoice,
    handleShowUpdateGiftCardModal,
  } = useContext(Context);

  // useState(() => {
  //   console.log(invoice)
  // }, [invoice])

  let selectedPlanPrice = 'no plan selected';

  defaultPlans.map((plan, i) => {
    if (selectedStudent.plan_selected === plan.id) {
      selectedPlanPrice = plan.metadata.text_price;
    }
  });

  const buildGiftCardTexts = (giftCardsList) => {
    return Array.isArray(giftCardsList) && giftCardsList.length ? giftCardsList.join(', ') : giftCardsList ? giftCardsList : '';
  };

  return (
    <>
      {/* <div className="dashboard-body"> */}
      {/* <div className="container dash-container"> */}
      <div id={selectedStudent.student_id} className='student-info-wrapper'>
        <div className='section-heading-holder'>
          <div className='c-teal section-title'>
            {selectedStudent.first_name + ' ' + selectedStudent.last_name}'S Subscription Details
          </div>
          {/* {selectedStudent.plan_selected !== "Cancelled" &&
                selectedStudent.subscription_type !== "theme" && (
                  <button className="action-btn" onClick={handleShowPlanModal}>
                    Update Subscription
                  </button>
                )} */}
        </div>
        <div className='two-column-data'>
          <div className='data-title'>Current Plan</div>
          <div className='data-value'>{selectedStudent.plan_selected}</div>
        </div>
        {selectedStudent.gift_card &&
        selectedStudent.subscription_type != 'theme' &&
        selectedStudent.plan_selected.includes('Weekly Gift Card') ? (
          <div className='two-column-data'>
            <div className='data-title'>Gift Card</div>
            <div className='data-value data-value--with-action'>
              <span>{buildGiftCardTexts(selectedStudent.gift_card)}</span>
              <span onClick={handleShowUpdateGiftCardModal} className='fas fa-edit data-action'></span>
            </div>
          </div>
        ) : null}
        {/* {selectedStudent.plan_selected !== "Cancelled" && (
              <div>
                {subscriptions !== null ? (
                  <div>
                    <div className="two-column-data">
                      <div className="data-title">Current Plan Price</div>
                      <div className="data-value">
                        {"$"}
                        {subscriptions.amount}
                      </div>
                    </div>
                    {selectedStudent.subscription_type != "theme" && (
                      <React.Fragment>
                        <div className="two-column-data">
                          <div className="data-title">
                            Upcoming Invoice Date
                          </div>
                          <div className="data-value">
                            {subscriptions.current_billing_date}
                          </div>
                        </div>
                        <div className="two-column-data">
                          <div className="data-title">Next Billing Date</div>
                          <div className="data-value">
                            {subscriptions.next_semester_billing_date}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <div className="d-flex p-2 justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            )} */}
      </div>

      {/* { selectedStudent.plan_selected !== 'Cancelled' && selectedStudent.subscription_type !== "theme" && */}
      {/* Below is an invoice section. As of Nov. 15, 2022, it was requested to be removed */}
      {/* {selectedStudent.plan_selected !== "Cancelled" && (
            <React.Fragment>
              <div className="section-invoice-wrapper">
                <div className="section-heading-holder">
                  <div className="c-teal section-title">Current Invoice</div>
                </div>
                {invoice !== null ? (
                  <div>
                    {selectedStudent.subscription_type != "theme" && (
                      <div className="two-column-data">
                        <div className="data-title">Due Date</div>
                        <div className="data-value">{invoice.invoice_date}</div>
                      </div>
                    )}
                    <div className="two-column-data">
                      <div className="data-title">Subtotal</div>
                      <div className="data-value">
                        {"$"}
                        {invoice.subtotal}
                      </div>
                    </div>
                    <div className="two-column-data">
                      <div className="data-title">
                        Discount (
                        {invoice.discount_type === "amount" ? "$ " : ""}
                        {invoice.discount_amount}
                        {invoice.discount_type === "percent" ? "%" : ""})
                      </div>
                      <div className="data-value">
                        -{"$"}
                        {invoice.discount}
                      </div>
                    </div>
                    <div className="two-column-data">
                      <div className="data-title">Tax</div>
                      <div className="data-value">
                        {"$"}
                        {invoice.tax}
                      </div>
                    </div>
                    <div className="two-column-data">
                      <div className="data-title">Total</div>
                      <div className="data-value">
                        {"$"}
                        {invoice.total}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex p-2 justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>

              {upcomingInvoice && (
                <div className="section-invoice-wrapper">
                  <div
                    className="section-heading-holder"
                    style={{ paddingTop: "30px" }}
                  >
                    <div className="c-teal section-title">Upcoming Invoice</div>
                  </div>
                  <div style={{ marginBottom: "30px" }}>
                    {selectedStudent.subscription_type != "theme" && (
                      <div className="two-column-data">
                        <div className="data-title">Due Date</div>
                        <div className="data-value">
                          {upcomingInvoice.invoice_date}
                        </div>
                      </div>
                    )}
                    <div className="two-column-data">
                      <div className="data-title">Subtotal</div>
                      <div className="data-value">
                        {"$"}
                        {upcomingInvoice.subtotal}
                      </div>
                    </div>
                    <div className="two-column-data">
                      <div className="data-title">
                        Discount (
                        {upcomingInvoice.discount_type === "amount" ? "$ " : ""}
                        {upcomingInvoice.discount_amount}
                        {upcomingInvoice.discount_type === "percent" ? "%" : ""}
                        )
                      </div>
                      <div className="data-value">
                        -{"$"}
                        {upcomingInvoice.discount}
                      </div>
                    </div>
                    <div className="two-column-data">
                      <div className="data-title">Tax</div>
                      <div className="data-value">
                        {"$"}
                        {upcomingInvoice.tax}
                      </div>
                    </div>
                    <div className="two-column-data">
                      <div className="data-title">Total</div>
                      <div className="data-value">
                        {"$"}
                        {upcomingInvoice.total}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )} */}
      {/* <div style={{ marginBottom: "30px" }}>
            <button
              className="btn-teal c-white"
              onClick={() => {
                window.open(process.env.REACT_APP_STRIPE_PORTAL_LINK, "_blank");
              }}
            >
              Update Payment Info on Stripe
            </button>
          </div> */}
      {/* </div> */}
      <UpdateGiftCardModal />
      {/* </div> */}
    </>
  );
};

export default DashboardSubscription;
