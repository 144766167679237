import React, { useState, useContext, Fragment, useEffect } from 'react';

import { Context } from '../context/Context';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import api from '../api/api';
import { validateWhiteSpace, validatePassword } from './util';

const ChangePasswordModal = () => {
  const {
    user,
    showChangePasswordModal,
    handleCloseChangePasswordModal,
    updatePasswordLoading,
    setUpdatePasswordLoading,
    token,
  } = useContext(Context);

  const [creds, setCreds] = useState({
    current_password: '',
    new_password: '',
    password_confirm: '',
  });

  useEffect(() => {
    setCreds({
      current_password: '',
      new_password: '',
      password_confirm: '',
    });
  }, [showChangePasswordModal]);

  const changePassword = () => {
    setUpdatePasswordLoading(true);
    api.handleUpdatePassword(token, user.id, creds, handleCloseChangePasswordModal, setUpdatePasswordLoading);
    setUpdatePasswordLoading(false);
  };

  const onChangeInput = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSave = () => {
    if (validateWhiteSpace(creds.current_password) === -1 || creds.current_password === '') {
      alert('Current password is required.');
    } else if (validatePassword(creds.new_password) === -1) {
      alert('Password must be 8 characters or longer & include uppercase, lowercase, and number, special characters optional.');
    } else if (validateWhiteSpace(creds.password_confirm) === -1 || creds.password_confirm === '') {
      alert('Confirm password is required.');
    } else if (creds.new_password !== creds.password_confirm) {
      alert('Password does not match.');
    } else {
      changePassword();
    }
  };

  const popover = (
    <Popover className='modal-hint-popover' id='popover-basic'>
      <Popover.Title as='h3'>Password</Popover.Title>
      <Popover.Content>
        Password must be <strong>8 characters or longer</strong> & include <strong>uppercase</strong>,{' '}
        <strong>lowercase</strong>, and <strong>number</strong>, special characters optional.
      </Popover.Content>
    </Popover>
  );

  return (
    <Modal
      className='c2c-modal'
      show={showChangePasswordModal}
      onHide={handleCloseChangePasswordModal}
      centered
      backdrop='static'
    >
      <div className='btn-close-modal' onClick={handleCloseChangePasswordModal}></div>
      <h2 className='title t-h1 c-grayish-navy'> Update Your Password </h2>
      <div className='password-holder'>
        <div className='form-group'>
          <div className='input-wrapper'>
            <input
              className='text-holder t-h1'
              type='password'
              name='current_password'
              placeholder='Current Password'
              value={creds.current_password}
              onChange={onChangeInput}
            />
            <div className='input-subtext'></div>
          </div>
          <div className='input-wrapper'>
            <input
              className='text-holder t-h1'
              type='password'
              name='new_password'
              placeholder='New Password'
              value={creds.new_password}
              onChange={onChangeInput}
            />
            <div className='input-subtext with-icon'>
              <span className='input-helper'>e.g. C2cccccc</span>
              <OverlayTrigger trigger='hover' placement='top' overlay={popover}>
                <span className='input-helper-icon fas fa-question-circle'></span>
              </OverlayTrigger>
            </div>
          </div>
          <div className='input-wrapper'>
            <input
              className='text-holder t-h1'
              type='password'
              name='password_confirm'
              placeholder='Confirm New Password'
              value={creds.password_confirm}
              onChange={onChangeInput}
            />
            <div className='input-subtext'></div>
          </div>
        </div>
      </div>
      <div className='btn-holder'>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={() => handleOnSave()}
          disabled={updatePasswordLoading}
        >
          {updatePasswordLoading && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
          {updatePasswordLoading && <span>Updating Password</span>}
          {!updatePasswordLoading && <span>Update Password</span>}
        </button>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
