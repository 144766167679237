import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Context } from '../context/Context'
import ConfirmModal from './ConfirmModal'

import { isLogin, isSignUpPage } from './util'

const Wizard = ({ children, handleAddStudent, isSingle }) => {
  const {
    data,
    emailexists,
    studentEmailExists,
    formErrors,
    showSignUpConfirmModal,
    handleShowSignUpConfirmModal,
    handleCloseSignUpConfirmModal,
    activeIndex,
    setActiveIndex,
    setSelectedOption,
  } = useContext(Context)

  React.useEffect(() => {
    if (isSingle) {
      data.subscription_type = 'theme'
    }
  }, [isSingle])

  const maxIndex = children.filter(child => child !== false).length
  // const [activeIndex, setActiveIndex] = useState( isLogin() ? 2 : 1);
  const [confirmIsBusy, setConfirmIsBusy] = useState(false)
  const [confirmModalElements, setConfirmModalElements] = useState({
    title: '',
    content: '',
    onConfirm: () => {},
    onConfirmText: '',
    onCancel: () => {},
    onCancelText: '',
  })

  const steps = React.Children.map(
    children.filter(child => child !== false),
    (child, i) => {
      // if (isLogin() && i === 0) {
      //   return null;
      // }

      const validChildren = children.filter(child => child !== false)

      if (child) {
        return React.cloneElement(child, {
          stepNumber: i + 1,
          total: validChildren.length,
          active: activeIndex === i + 1,
        })
      }
    }
  )

  const prevIndex = () => {
    if (activeIndex <= 1) {
      setActiveIndex(1)
    } else {
      setActiveIndex(activeIndex - 1)
    }
  }

  const onAddNewStudent = () => {
    handleCloseSignUpConfirmModal()
    handleAddStudent()
    setActiveIndex(isLogin() ? 1 : 2)
    setSelectedOption(null)
  }

  const addStudent = () => {
    if (validateWhiteSpace(data.plan_selected) === -1 || data.plan_selected === '') {
      formErrors.plan_selected = 'Subscription Plan is required'
      alert('Subscription Plan is required')
    } else if (
      data.plan_selected.includes('Weekly Gift Card') &&
      (validateWhiteSpace(data.gift_card) === -1 || data.gift_card === '')
    ) {
      alert('Gift Card is required')
    } else if (validateWhiteSpace(data.student_first_name) === -1 || data.student_first_name === '') {
      alert('First name is required')
    } else if (validateWhiteSpace(data.student_last_name) === -1 || data.student_last_name === '') {
      alert('Last name is required')
    } else if (
      (validateWhiteSpace(data.schoolname) === -1 || data.schoolname === '') &&
      data.subscription_type !== 'theme'
    ) {
      alert('School is required')
    } else if (
      (validateWhiteSpace(data.semester) === -1 || data.semester === '') &&
      data.subscription_type !== 'theme'
    ) {
      alert(`First day of current ${data.subscription_type === 'semester' ? 'semester' : 'quarter'} is required`)
    } else if (
      (validateWhiteSpace(data.season) === -1 || data.season === '') &&
      data.subscription_type === 'semester'
    ) {
      alert('Starting semester is required')
    } else if (
      (validateWhiteSpace(data.quarter) === -1 || data.quarter === '') &&
      data.subscription_type === 'quarter'
    ) {
      alert('Starting quarter is required')
    } else if (
      validateEmailAddress(data.student_email) === -1 &&
      data.student_email !== '' &&
      data.subscription_type !== 'theme'
    ) {
      alert('Invalid email address')
    } else if (
      validateEmailExists(data.student_email) === -1 &&
      data.student_email !== '' &&
      data.subscription_type !== 'theme'
    ) {
      alert('Email already in use in one of your students')
    } else if (studentEmailExists === true && data.student_email !== '' && data.subscription_type !== 'theme') {
      alert('Email already taken')
    } else if (
      (validateWhiteSpace(data.student_street) === -1 || data.student_street === '') &&
      data.subscription_type === 'theme'
    ) {
      alert('Address 1 is required')
    } else {
      setConfirmModalElements({
        title: `Proceed adding new ${data.subscription_type === 'theme' ? 'recipient' : 'student'}?`,
        content: `Please make sure you entered current ${
          data.subscription_type === 'theme' ? 'recipient' : 'student'
        }'s info correctly.`,
        onConfirm: onAddNewStudent,
        onConfirmText: 'Proceed',
        onCancel: () => {
          handleCloseSignUpConfirmModal()
          setActiveIndex(isLogin() ? 1 : 2)
        },
        onCancelText: 'Edit',
      })
      handleShowSignUpConfirmModal()
    }
  }

  const validateEmailAddress = email => {
    const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/
    if (regex.test(email)) {
      return 1
    } else {
      return -1
    }
  }

  const validateEmailExists = email => {
    const arr = data.students
    const found = arr.some(el => el.student_email === email)
    if (!found) {
      return 1
    } else {
      return -1
    }
  }

  const validatePassword = password => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]<>\/\\',-]{8,}$/
    if (regex.test(password)) {
      return 1
    } else {
      return -1
    }
  }

  const validateWhiteSpace = data => {
    const regex = /^\s+$/
    if (regex.test(data)) {
      return -1
    } else {
      return 1
    }
  }

  const onConfirm = () => {
    handleCloseSignUpConfirmModal()
    if (validateWhiteSpace(data.student_first_name) !== -1 || data.student_first_name !== '') {
      handleAddStudent()
    }
    setActiveIndex(activeIndex + 1)
  }

  const checkIfHaveThemePack = () => {
    return data.students.some(student => student.subscription_type === 'theme')
  }

  const checkIfHaveCollegeSub = () => {
    return data.students.some(student => student.subscription_type !== 'theme')
  }

  const StudentTable = () => (
    <div className="all-students-holder">
      <label className="info-title t-h1 c-grayish-navy">{`Your ${
        checkIfHaveThemePack() || data.subscription_type === 'theme' ? 'Recipients' : 'Students'
      }`}</label>
      <div className="subscriptions-table">
        <div className="table-header">
          <div className="header-name">{`${
            checkIfHaveThemePack() || data.subscription_type === 'theme' ? '' : 'Student'
          } Name`}</div>
          <div className="header-subscription">Subscription Plan</div>
        </div>
        <div className="subscriptions">
          <div className="subscription">
            <div className="student-name">{data.student_first_name + ' ' + data.student_last_name}</div>
            <div className="student-subscription">{data.plan_selected}</div>
          </div>
          {data.students &&
            data.students.map((item, i) => {
              return (
                <div className="subscription" key={i}>
                  <div className="student-name">{item.student_first_name + ' ' + item.student_last_name}</div>
                  <div className="student-subscription">{item.plan_selected}</div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )

  const nextIndex = () => {
    const formActiveIndex = isLogin() ? activeIndex + 1 : activeIndex
    if (formActiveIndex === 1) {
      if (validateWhiteSpace(data.first_name) === -1 || data.first_name === '') {
        alert('First name is required')
      } else if (validateWhiteSpace(data.last_name) === -1 || data.last_name === '') {
        alert('Last name is required')
      } else if (validateWhiteSpace(data.email) === -1 || data.email === '') {
        alert('Email is required')
      } else if (validateEmailAddress(data.email) === -1) {
        alert('Invalid email address')
      } else if (emailexists === true) {
        alert('Email already taken')
      } else if (!isValidPhoneNumber(data.cell_phone) && data.cell_phone !== '') {
        alert('Not a valid phone number')
      } else if (validatePassword(data.password) === -1) {
        alert(
          'Password must be 8 characters or longer & include uppercase, lowercase, and number, special characters optional.'
        )
      } else if (validateWhiteSpace(data.confirmPassword) === -1 || data.confirmPassword === '') {
        alert('Confirm password is required')
      } else if (data.password !== data.confirmPassword) {
        alert('Password does not match')
      } else if (validateWhiteSpace(data.street) === -1 || data.street === '') {
        alert('Street Address is required')
      } else if (validateWhiteSpace(data.city) === -1 || data.city === '') {
        alert('City is required')
      } else if (validateWhiteSpace(data.state) === -1 || data.state === '') {
        alert('State is required')
      } else if (validateWhiteSpace(data.zipcode) === -1 || data.zipcode === '') {
        alert('Zipcode is required')
      } else {
        setActiveIndex(activeIndex + 1)
      }
    } else if (formActiveIndex === 2) {
      if (validateWhiteSpace(data.student_first_name) === -1 || data.student_first_name === '') {
        alert('First name is required')
      } else if (validateWhiteSpace(data.student_last_name) === -1 || data.student_last_name === '') {
        alert('Last name is required')
      } else if (
        (validateWhiteSpace(data.schoolname) === -1 || data.schoolname === '') &&
        data.subscription_type !== 'theme'
      ) {
        alert('School is required')
      } else if (
        (validateWhiteSpace(data.semester) === -1 || data.semester === '') &&
        data.subscription_type !== 'theme'
      ) {
        alert(`First day of current ${data.subscription_type === 'semester' ? 'semester' : 'quarter'} is required`)
      } else if (
        (validateWhiteSpace(data.season) === -1 || data.season === '') &&
        data.subscription_type === 'semester'
      ) {
        alert('Starting semester is required')
      } else if (
        (validateWhiteSpace(data.quarter) === -1 || data.quarter === '') &&
        data.subscription_type === 'quarter'
      ) {
        alert('Starting quarter is required')
      } else if (
        validateEmailAddress(data.student_email) === -1 &&
        data.student_email !== '' &&
        data.subscription_type !== 'theme'
      ) {
        alert('Invalid email address')
      } else if (
        validateEmailExists(data.student_email) === -1 &&
        data.student_email !== '' &&
        data.subscription_type !== 'theme'
      ) {
        alert('Email already in use in one of your students')
      } else if (studentEmailExists === true && data.student_email !== '' && data.subscription_type !== 'theme') {
        alert('Email already taken')
      } else if (
        (validateWhiteSpace(data.student_street) === -1 || data.student_street === '') &&
        data.subscription_type === 'theme'
      ) {
        alert('Address 1 is required')
      }
      // else if (
      //   (validateWhiteSpace(data.student_unit) === -1 ||
      //   data.student_unit === "") && (data.subscription_type === "theme")
      // ) {
      //   alert('Apt/Suite/Unit,Building is required');
      // }
      else if (
        (validateWhiteSpace(data.student_city) === -1 || data.student_city === '') &&
        data.subscription_type === 'theme'
      ) {
        alert('City is required')
      } else if (
        (validateWhiteSpace(data.student_state) === -1 || data.student_state === '') &&
        data.subscription_type === 'theme'
      ) {
        alert('State is required')
      } else if (
        (validateWhiteSpace(data.student_zipcode) === -1 || data.student_zipcode === '') &&
        data.subscription_type === 'theme'
      ) {
        alert('Zipcode is required')
      } else if (validateWhiteSpace(data.plan_selected) === -1 || data.plan_selected === '') {
        alert('Subscription Plan is required')
      } else if (
        data.plan_selected.includes('Weekly Gift Card') &&
        (validateWhiteSpace(data.gift_card) === -1 || data.gift_card === '')
      ) {
        alert('Gift Card is required')
      } else {
        setActiveIndex(activeIndex + 1)
      }
      // } else {
      //   const getPopupContent = () => {
      //     return `Please make sure you entered the info correctly. ${
      //       checkIfHaveThemePack() || data.subscription_type === 'theme'
      //         ? 'If you selected a Theme pack, the first card will be sent tonight at 11pm PST.'
      //         : ''
      //     }`;
      //   };

      //   setConfirmModalElements({
      //     title: 'Proceed to checkout?',
      //     content: getPopupContent(),
      //     onConfirm: onConfirm,
      //     onConfirmText: 'Proceed',
      //     onCancel: () => {
      //       handleCloseSignUpConfirmModal();
      //       setActiveIndex(isLogin() ? 1 : 2);
      //     },
      //     onCancelText: 'Edit',
      //     additionalMessage:
      //       'By continuing, you acknowledge this is a recurring subscription that will continue until you cancel.',
      //   });
      //   handleShowSignUpConfirmModal();
      // }
    } else if (formActiveIndex === 3) {
      if (validateWhiteSpace(data.greeting) === -1 || data.greeting === '') {
        alert('Greeting is required')
      } else if (validateWhiteSpace(data.nick_name) === -1 || data.nick_name === '') {
        alert('Nickname is required')
      } else if (validateWhiteSpace(data.sign_off) === -1 || data.sign_off === '') {
        alert('Sign off is required')
      } else if (validateWhiteSpace(data.personal_sign_off) === -1 || data.personal_sign_off === '') {
        alert('Name as sign off is required')
      } else if (validateWhiteSpace(data.message_box1) === -1 || data.message_box1 === '') {
        alert('Message is required')
      } else {
        const getPopupContent = () => {
          return `Please make sure you entered the info correctly. ${
            checkIfHaveThemePack() || data.subscription_type === 'theme'
              ? 'If you selected a Theme pack, the first card will be sent tonight at 11pm PST.'
              : ''
          }`
        }

        setConfirmModalElements({
          title: 'Proceed to checkout?',
          content: getPopupContent(),
          onConfirm: onConfirm,
          onConfirmText: 'Proceed',
          onCancel: () => {
            handleCloseSignUpConfirmModal()
            setActiveIndex(isLogin() ? 1 : 2)
          },
          onCancelText: 'Review',
          additionalMessage:
            'By continuing, you acknowledge this is a recurring subscription that will continue until you cancel.',
        })
        handleShowSignUpConfirmModal()
      }
    } else if (formActiveIndex === (isSingle ? 3 : 4)) {
      if (validateWhiteSpace(data.plan_selected) === -1 || data.plan_selected === '') {
        alert('Subscription Plan is required')
      } else if (
        data.plan_selected.includes('Weekly Gift Card') &&
        (validateWhiteSpace(data.gift_card) === -1 || data.gift_card === '')
      ) {
        alert('Gift Card is required')
      } else {
        const getPopupContent = () => {
          return `Please make sure you entered the info correctly. ${
            checkIfHaveThemePack() || data.subscription_type === 'theme'
              ? 'If you selected a Theme pack, the first card will be sent tonight at 11pm PST.'
              : ''
          }`
        }

        setConfirmModalElements({
          title: 'Proceed to checkout?',
          content: getPopupContent(),
          onConfirm: onConfirm,
          onConfirmText: 'Proceed',
          onCancel: () => {
            handleCloseSignUpConfirmModal()
            setActiveIndex(isLogin() ? 1 : 2)
          },
          onCancelText: 'Edit',
          additionalMessage:
            'By continuing, you acknowledge this is a recurring subscription that will continue until you cancel.',
        })
        handleShowSignUpConfirmModal()
      }
    } else {
      setActiveIndex(maxIndex)
    }
  }

  return (
    <div className="wizard">
      <div className="steps">{steps}</div>
      <div className="fields">
        {children
          .filter(child => {
            return child !== false
          })
          .map((child, i) => {
            return (
              <React.Fragment key={i}>
                {React.cloneElement(child.props.children, {
                  className: clsx('content', { active: activeIndex === i + 1 }),
                })}
              </React.Fragment>
            )
          })}
      </div>
      {activeIndex === (isLogin() ? 2 : 3) && !isSingle && (
        <div className="button-holder" style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            onClick={() => addStudent()}
            style={{ marginBottom: '10px', fontSize: '15px', width: 'fit-content' }}
            className="btn-purple c-purple t-uppercase d-flex align-items-center">
            <span>{'Add Another Student'}</span>
          </button>
        </div>
      )}
      <div className="controls">
        {activeIndex > 1 && activeIndex !== maxIndex && (
          <span className="back-button-holder">
            <button className="btn-pagination" onClick={prevIndex}>
              Back
            </button>
          </span>
        )}
        {maxIndex !== activeIndex && (
          <span className="next-button-holder">
            <button className="btn-pagination" onClick={nextIndex}>
              Next
            </button>
          </span>
        )}
      </div>
      <div className="steps bullets">{steps}</div>
      <ConfirmModal
        show={showSignUpConfirmModal}
        onHide={handleCloseSignUpConfirmModal}
        onConfirm={confirmModalElements.onConfirm}
        onCancel={confirmModalElements.onCancel}
        title={confirmModalElements.title}
        content={confirmModalElements.content}
        onCancelText={confirmModalElements.onCancelText}
        onConfirmText={confirmModalElements.onConfirmText}
        additionalContent={<StudentTable />}
        isBusy={confirmIsBusy}
        additionalMessage={confirmModalElements.additionalMessage}
      />
    </div>
  )
}

export default Wizard
