import React, { useContext, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Spinner from 'react-bootstrap/Spinner'

import { Context } from '../context/Context'
import api from '../api/api'

import AddAffiliateModal from './AddAffiliateModal'

const AdminManageAffilliates = () => {
  const [affiliateData, setAffiliateData] = useState({
    id: null,
    name: '',
    code: '',
    email: '',
  })
  const [affiliateOrig, setAffiliateOrig] = useState(null)

  const { tokenAdmin, handleCloseLoaderModal } = useContext(Context)

  const [affiliates, setAffiliates] = useState([])
  const [affiliatesLoading, setAffiliatesLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const getAffiliates = async () => {
      try {
        setAffiliatesLoading(true)
        const affiliatesData = await api.getAffiliates(tokenAdmin)
        setAffiliates(affiliatesData)
        handleCloseLoaderModal()
      } catch (e) {
        console.log(e)
      } finally {
        setAffiliatesLoading(false)
      }
    }
    if (tokenAdmin) {
      getAffiliates()
    }
  }, [tokenAdmin])

  const templateColumns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Affiliate Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      ignoreRowClick: false,
    },
    {
      name: 'Action',
      selector: 'action',
      sortable: false,
      ignoreRowClick: false,
      cell: row => (
        <button
          className="action-btn"
          onClick={() => {
            const siteUrl = `https://app.cards2college.com/#/sign-up?affiliate=${row?.code}`
            navigator.clipboard.writeText(siteUrl)
            alert('Link copied to clipboard')
          }}>
          {'Copy Affiliate Link'}
        </button>
      ),
    },
  ]

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        width: '100%',
        maxWidth: '303px',
        // minWidth: '200px',
      },
    },
  }

  const handleOnRowClicked = (row, event) => {
    setAffiliateData(prev => ({ ...row }))
    setAffiliateOrig(prev => ({ ...row }))
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setAffiliateData(prev => ({
      id: null,
      name: '',
      code: '',
      email: '',
    }))
  }

  return (
    <div className="dashboard-body">
      <div className="container dash-container">
        <div className="section-heading-holder">
          <div className="c-teal section-title">Manage Affiliates</div>
          <button
            className="action-btn"
            onClick={() => {
              setIsOpen(true)
            }}>
            Add Affiliate
          </button>
        </div>

        <div className="table-holder templates-table">
          <DataTable
            columns={templateColumns}
            data={affiliates}
            defaultSortField={'id'}
            pagination={true}
            customStyles={customStyles}
            highlightOnHover={true}
            pointerOnHover={false}
            noHeader={true}
            selectableRowsHighlight={true}
            progressPending={affiliatesLoading}
            progressComponent={<Spinner animation="border" role="status" aria-hidden="true" />}
            onRowClicked={handleOnRowClicked}
            responsive={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 16, 32]}
            // sortFunction={handleSortTemplates}
          />
        </div>
      </div>
      <AddAffiliateModal
        isOpen={isOpen}
        closeModal={handleCloseModal}
        setAffiliates={setAffiliates}
        affiliateData={affiliateData}
        setAffiliateData={setAffiliateData}
        affiliates={affiliates}
        affiliateOrig={affiliateOrig}
      />
    </div>
  )
}

export default AdminManageAffilliates
