import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import { Context } from "../context/Context";

import { ReactComponent as IconHome } from "../assets/images/home.svg";
import { ReactComponent as IconCards } from "../assets/images/22-Mail.svg";
import { ReactComponent as IconStudentInfo } from "../assets/images/Group 701.svg";
import { ReactComponent as IconSubs } from "../assets/images/folded-corner-paper.svg";
import { ReactComponent as IconBilling } from "../assets/images/Group 723.svg";
import { ReactComponent as IconContact } from "../assets/images/mail.svg";
import { ReactComponent as IconChangePassword } from "../assets/images/padlock.svg";
import { ReactComponent as IconUser } from "../assets/images/user.svg";
import { ReactComponent as IconPhone } from "../assets/images/phone.svg";
// import { ReactComponent as IconShoppingBag } from '../assets/images/shopping-bag.svg'
import { MARKETING_SITE_URL } from "../helpers/constants";
import Joyride, { EVENTS } from "react-joyride";

const LeftSidebar = () => {
  const { user, handleShowChangePasswordModal, selectedStudent, runTutorial, leftSideBar } =
    useContext(Context);

  const [btnToggle, openSideBar] = useState(false);

  const toggle = () => {
    openSideBar(!btnToggle);
  };

  // console.log(selectedStudent)

  const toggleOnClickItem = () => {
    if (window.innerWidth <= 767) {
      openSideBar(!btnToggle);
    }
  };

  const leftSidebarInfo = [
    {
      userfName: "Jane",
      userlName: "Doe",
      sidebarItems: [
        {
          icon: <IconHome />,
          label: "Home",
          action: () => {
            window.location.hash = "#/dashboard";
          },
        },
        {
          icon: <IconCards />,
          label: "Cards Sent",
          action: () => {
            window.location.hash = "#/cards-sent";
          },
        },
        {
          icon: <IconStudentInfo />,
          label:
            selectedStudent.subscription_type != "theme"
              ? "Student Info"
              : "Address Info",
          action: () => {
            window.location.hash = "#/student-info";
          },
        },
        {
          icon: <IconSubs />,
          label: "Subscription Details",
          action: () => {
            window.location.hash = "#/subcription-details";
          },
        },
        // {
        //   icon: <IconBilling />,
        //   label: "Billing & Payments",
        //   action: e => e.preventDefault()
        // },
        {
          icon: <IconContact />,
          label: "Contact Us",
          action: () => {
            window.location.hash = "#/about/#contact";
          },
        },
        {
          icon: <IconChangePassword />,
          label: "Change Password",
          action: handleShowChangePasswordModal,
        },
        {
          icon: <IconUser />,
          label: "Profile",
          action: handleShowChangePasswordModal,
        },
        // {
        //   icon: <IconShoppingBag />,
        //   label: 'Shop',
        //   action: () => {
        //     window.location.hash = '/'
        //   },
        // },
      ],
    },
  ];

  return (
    <>
      <div
        // className={
        //   btnToggle
        //     ? "sidebar-wrapper left"
        //     : "sidebar-wrapper sidebar-collapsed-wrapper left"
        // }
        className={
          runTutorial ? (leftSideBar === 'opened' ? "sidebar-wrapper left" : "sidebar-wrapper sidebar-collapsed-wrapper left") :
          (btnToggle
            ? "sidebar-wrapper left"
            : "sidebar-wrapper sidebar-collapsed-wrapper left")
        }
      >
        <div className="sidebar-list">
          <div
            onClick={toggle}
            // className={
            //   btnToggle ? "sidebar-toggler opened" : "sidebar-toggler closed"
            // }
            className={
              runTutorial ? `sidebar-toggler ${leftSideBar}` : (btnToggle ? "sidebar-toggler opened" : "sidebar-toggler closed")
              // `sidebar-toggler ${leftSideBar}`
            }
            id="left-sidebar-toggle"
          ></div>
          <div className="sidebar-title c-white">Welcome {user.first_name}</div>
          <Link
            smooth
            to="/dashboard"
            className="sidebar-item"
            onClick={toggleOnClickItem}
          >
            <div className="icon-holder">
              <span>
                <IconHome />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Home</p>
            </div>
          </Link>
          <Link
            smooth
            to="/cards-sent"
            className="sidebar-item"
            onClick={toggleOnClickItem}
          >
            <div className="icon-holder">
              <span>
                <IconCards />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Cards Sent</p>
            </div>
          </Link>
          <Link
            smooth
            to="/student-info"
            className="sidebar-item"
            onClick={toggleOnClickItem}
          >
            <div className="icon-holder">
              <span>
                <IconStudentInfo />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">
                {selectedStudent.subscription_type != "theme"
                  ? "Student Info"
                  : "Address Info"}
              </p>
            </div>
          </Link>
          {/* <Link
            smooth
            to="/subscription-details"
            className="sidebar-item"
            onClick={toggleOnClickItem}
          >
            <div className="icon-holder">
              <span>
                <IconSubs />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Subscription Details</p>
            </div>
          </Link> */}
          <div className="sidebar-item" onClick={handleShowChangePasswordModal}>
            <div className="icon-holder">
              <span>
                <IconChangePassword />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Change Password</p>
            </div>
          </div>
          <Link
            smooth
            to="/profile"
            className="sidebar-item"
            onClick={toggleOnClickItem}
            id='profile'
          >
            <div className="icon-holder">
              <span>
                <IconUser />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">My Account</p>
            </div>
          </Link>
          <div>
            <div
              className="sidebar-title c-white"
              style={{ marginTop: "24px", fontSize: "22px" }}
            >
              Contact Us
            </div>
            {/* <a
              href={`${MARKETING_SITE_URL}/about/#contact`}
              className="sidebar-item"
              onClick={toggleOnClickItem}
            >
              <div className="icon-holder">
                <span>
                  <IconContact />
                </span>
              </div>
              <div className="label-holder">
                <p className="t-paragraph label">Contact Us</p>
              </div>
            </a> */}
            <div
              className="sidebar-item"
              onClick={(e) => {
                window.location.href = "mailto:support@cards2college.com";
                e.preventDefault();
              }}
            >
              <div className="icon-holder">
                <span>
                  <IconContact />
                </span>
              </div>
              <div className="label-holder">
                <p className="t-paragraph label" style={{ fontSize: "14px" }}>
                  support@cards2college.com
                </p>
              </div>
            </div>
            <div
              className="sidebar-item"
              onClick={(e) => {
                window.location.href = "tel:7205418168";
                e.preventDefault();
              }}
            >
              <div className="icon-holder">
                <span>
                  <IconPhone />
                </span>
              </div>
              <div className="label-holder">
                <p className="t-paragraph label">720-541-8168</p>
              </div>
            </div>
          </div>
          {/* <Link smooth to="/" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconShoppingBag />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Shop</p>
            </div>
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
