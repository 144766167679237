import React, { useContext } from 'react'

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { Context } from '../context/Context';

const LoaderModal = () => {
  const { showLoaderModal, handleShowLoaderModal } = useContext(Context);

  return (
    <Modal
      className="loader-modal"
      show={showLoaderModal}
      onHide={handleShowLoaderModal}
      centered
      backdrop={'static'}
      keyboard={false}
      animation={false}
      size={'sm'}
    >
      <div className="loader-holder">
        <Spinner
          className="c-purple"
          animation="border"
          role="status"
          aria-hidden="true"
        />
        <div className="loader-text">Please wait ...</div>
      </div>
    </Modal>
  )
}

export default LoaderModal
