import React, { useContext, useState } from 'react';

import { Context } from '../context/Context';
import api from '../api/api';

import CardPreviewModal from './CardPreviewModal';

const DashboardCardsSent = () => {
  const { handleShowCardPreviewModal, allCards, lastWeekCard, selectedStudent } = useContext(Context);

  const [previewUrl, setPreviewUrl] = useState('');
  const [sentDate, setSentDate] = useState('');

  const lastWeeksCard = {
    frontCover: require('../assets/images/24963265.png'),
    previewUrl:
      'https://legacy.thankster.com/api/v1/api_projects/renderProjectPreview?api_key=e1ace8194004a089cc7400b86d156637&thanksterProjectID=1422163151&userApiKey=28b17144410c60546b0890d3dd2f3e09',
  };

  const allCardsSent = [
    {
      sentDate: 'Sept. 10',
      frontCover: require('../assets/images/24963281.png'),
      previewUrl:
        'https://legacy.thankster.com/api/v1/api_projects/renderProjectPreview?api_key=e1ace8194004a089cc7400b86d156637&thanksterProjectID=1422163151&userApiKey=28b17144410c60546b0890d3dd2f3e09',
    },
    {
      sentDate: 'Sept. 01',
      frontCover: require('../assets/images/24963411.png'),
      previewUrl:
        'https://legacy.thankster.com/api/v1/api_projects/renderProjectPreview?api_key=e1ace8194004a089cc7400b86d156637&thanksterProjectID=1422163151&userApiKey=28b17144410c60546b0890d3dd2f3e09',
    },
    {
      sentDate: 'Aug. 24',
      frontCover: require('../assets/images/24963428.png'),
      previewUrl: 'https://www.youtube.com/embed/btvH1naQXtg',
    },
    {
      sentDate: 'Sept. 10',
      frontCover: require('../assets/images/24963281.png'),
      previewUrl:
        'https://legacy.thankster.com/api/v1/api_projects/renderProjectPreview?api_key=e1ace8194004a089cc7400b86d156637&thanksterProjectID=1422163151&userApiKey=28b17144410c60546b0890d3dd2f3e09',
    },
    {
      sentDate: 'Sept. 01',
      frontCover: require('../assets/images/24963411.png'),
      previewUrl:
        'https://legacy.thankster.com/api/v1/api_projects/renderProjectPreview?api_key=e1ace8194004a089cc7400b86d156637&thanksterProjectID=1422163151&userApiKey=28b17144410c60546b0890d3dd2f3e09',
    },
    {
      sentDate: 'Aug. 24',
      frontCover: require('../assets/images/24963428.png'),
      previewUrl: 'https://www.youtube.com/embed/btvH1naQXtg',
    },
  ];

  const showCardPreviewModal = (url, date) => {
    setPreviewUrl(url);
    setSentDate(date);
    handleShowCardPreviewModal();
  };

  return (
    <div className='dashboard-body'>
      <div className='container dash-container'>
        <div className='c-teal section-title'>Last Week's Card</div>
        {lastWeekCard ? (
          <div className='last-weeks-card-wrapper'>
            <div className='cards-holder'>
              <div className='single-card-holder'>
                {selectedStudent.subscription_type !== 'theme' ? (
                  <img className='weekly-card' src={`${lastWeekCard.front}?${new Date().getTime()}`} />
                ) : (
                  <img className='weekly-card' src={lastWeekCard.front} />
                )}
              </div>
            </div>
            <div className='section-btn-holder'>
              <button
                className='btn-white c-purple'
                onClick={() => showCardPreviewModal(lastWeekCard.previewUrl, "Last Week's")}
              >
                View
              </button>
            </div>
          </div>
        ) : (
          <div className='t-h4'>Sorry, no preview available.</div>
        )}
        <div className='all-cards-wrapper'>
          <div className='c-teal section-title'>All Cards Sent</div>
          {
            allCards ? (
              <div className='card-sent-wrapper'>
                {allCards.map((card, index) => {
                  return (
                    <div key={index} className='card-sent-holder'>
                      <div className='card-sent-details-wrapper'>
                        <div className='c-teal card-sent-date'>{card.sentDate}</div>
                        <div className='card-sent-img-holder'>
                          {/* <img src={`${card.front}?${new Date().getTime()}`} /> */}
                          {selectedStudent.subscription_type !== 'theme' ? (
                            <img src={`${card.front}?${new Date().getTime()}`} />
                          ) : (
                            <img src={card.front} />
                          )}
                        </div>
                        <button
                          className='btn-teal c-white card-view-btn'
                          onClick={() => showCardPreviewModal(card.previewUrl, card.sentDate)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='t-h4'>Sorry, no preview available.</div>
            )
            // <div className="card-sent-holder">
            //   <div className="card-sent-details-wrapper">
            //     <div className="c-teal card-sent-date">{allCardsSent[0].sentDate}</div>
            //     <div className="card-sent-img-holder">
            //       <img src={allCardsSent[0].frontCover} />
            //     </div>
            //     <button className="btn-teal c-white card-view-btn" onClick={() => showCardPreviewModal(allCardsSent[0].previewUrl, allCardsSent[0].sentDate)}>View</button>
            //   </div>
            // </div>
          }
        </div>
      </div>
      <CardPreviewModal cardPreviewUrl={previewUrl} sentDate={sentDate} />
    </div>
  );
};

export default DashboardCardsSent;
