import React, { Fragment, useEffect, useContext, useState } from 'react';

import cookie from 'react-cookies';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Badge from 'react-bootstrap/Badge';

import SweetAlert from 'sweetalert2-react';

import profileIcon from './../assets/images/team-member-2.png';
import { ReactComponent as IconShoppingBag } from '../assets/images/shopping-bag.svg';

import LoaderModal from './LoaderModal';
import AddStudentModal from './AddStudentModal';
import AddNewSubscriptionModal from './NewComponents/AddNewSubscriptionModal';

import { Context } from '../context/Context';
import api from '../api/api';

import { MARKETING_SITE_URL } from '../helpers/constants';

import PaymentSuccessModal from '../components/NewComponents/PaymentSuccessModal';

import Tutorial from '../components/NewComponents/Tutorial';

const DashHeader = () => {
  const {
    token,
    setToken,
    setUser,
    setCustomer,
    setPaymentMethods,
    setInvoice,
    setUpcomingInvoice,
    setStudents,
    students,
    selectedStudent,
    setSelectedStudent,
    setSubscriptions,
    setCurrentCard,
    setLastWeekCard,
    setAllCards,
    setPlans,
    setSchools,
    setUserDataLoaded,
    setStudentDataLoaded,
    handleShowLoaderModal,
    handleCloseLoaderModal,
    handleShowAddStudentModal,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
    setSchoolSemDetails,
    setThemePackTemplates,
    handleShowPaymentSuccessModal,
    afterSignUp,
    showPersonalCardModal,
    data,
    handleRunTutorial
  } = useContext(Context);

  const [showSwal, setShowSwal] = useState(false);

  const openMenu = () => {
    // document.body.classList.toggle('no-scroll');
  };

  const onClickStudent = (index) => {
    if (window.innerWidth <= 991) {
      document.getElementById('basic-navbar-nav').classList.remove('show');
      // openMenu();
    }
    handleShowLoaderModal();
    setSubscriptions(null);
    setInvoice(null);
    setUpcomingInvoice(null);
    setCurrentCard(null);
    api
      .handleGetStudent(
        token,
        setInvoice,
        setUpcomingInvoice,
        index,
        setSelectedStudent,
        setSubscriptions,
        setCurrentCard,
        setLastWeekCard,
        setAllCards,
        handleCloseLoaderModal,
        newRecipientData,
        setNewRecipientData,
        getSubscriptionType
      )
      .then((res) => {
        handleCloseLoaderModal();
      });
  };

  const onClickLogout = () => {
    handleShowLoaderModal();
    api
      .handleLogout(
        token,
        handleCloseLoaderModal,
        setUser,
        setStudents,
        setSelectedStudent,
        setSubscriptions,
        setCurrentCard,
        setLastWeekCard,
        setAllCards,
        setToken,
        setInvoice,
        setUpcomingInvoice,
        setCustomer,
        setPaymentMethods,
        newRecipientData,
        setNewRecipientData,
        getSubscriptionType
      )
      .then(() => {
        cookie.remove('jwt', { path: '/' });
        window.location.hash = '#/';
        setTimeout(() => {
          handleCloseLoaderModal();
        }, 1000);
      });
  };

  useEffect(() => {
    handleShowLoaderModal();
    const auth = cookie.load('jwt');
    setToken(auth);
    if (auth === 'false' || auth === null) {
    } else {
      api
        .handleGetUser(auth, setUser, setUserDataLoaded, setCustomer, setPaymentMethods, setInvoice, setUpcomingInvoice)
        .then(() => {
          return api.handleGetAllStudents(
            auth,
            setInvoice,
            setUpcomingInvoice,
            setStudents,
            selectedStudent,
            setSelectedStudent,
            setSubscriptions,
            setCurrentCard,
            setLastWeekCard,
            setAllCards,
            handleCloseLoaderModal,
            setStudentDataLoaded,
            newRecipientData,
            setNewRecipientData,
            getSubscriptionType
          );
        })
        .then(() => {
          return api.allPlans(setPlans);
        })
        .then(() => {
          return api.handleGetSchools(setSchools);
        })
        // .then(() => {
        //   return api.getAllThemePacks(auth, setThemePackTemplates)
        // })
        .then(() => {
          return api.handleGetSemDetails(setSchoolSemDetails);
        })
        .then(() => {
          handleCloseLoaderModal(() => {
            afterSignUp && handleShowPaymentSuccessModal();
          });
        });
    }
  }, []);

  useEffect(() => {}, [setSelectedStudent]);

  // console.log("SELECTED STUDENT:::", selectedStudent)

  const getVariant = (type) => {
    if (type === 'semester') {
      return 'primary';
    } else if (type === 'quarter') {
      return 'secondary';
    } else {
      return 'info';
    }
  };

  const TutorialNav = ({id}) => {
    return (
      <Nav id={id}>
        <div className="nav-item">
          <Nav.Link className='tutorial-link' onClick={handleRunTutorial}>&#x24D8;&nbsp;Tutorial</Nav.Link>
        </div>
      </Nav>
    )
  }

  useEffect(() => {
    if(selectedStudent?.greeting == "" || selectedStudent?.name_as_sign_off == "" || selectedStudent?.nick_name == "" || selectedStudent?.sign_off == "") {
      showPersonalCardModal()
    }
  }, [selectedStudent])

  useEffect(() => {
    console.log('students', students);
  }, [students]);

  return (
    <header className='dash-header'>
      <Navbar sticky='top' expand='lg'>
        <Navbar.Brand href={MARKETING_SITE_URL} className='navbar-brand'>
          <div className='logo-holder'>
            <img src={require('../assets/images/logo.png')} className='logo-img' alt='giftsandtalents' />
          </div>
        </Navbar.Brand>
        {/* <Navbar.Brand href="#home" className="navbar-brand">
          <div className="logo-holder">
            <img src={require('../assets/images/logo.png')} className="logo-img" alt="giftsandtalents" />
          </div>
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls='basic-navbar-nav' onClick={openMenu}>
          <span className='icon-bar'></span>
          <span className='icon-bar'></span>
          <span className='icon-bar'></span>
        </Navbar.Toggle>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav>
            <NavDropdown
              title={selectedStudent.first_name ? selectedStudent.first_name : 'Loading...'}
              id='collapsible-nav-dropdown'
              className='c-purple t-h3'
            >
              {students.length > 0 ? (
                <Fragment>
                  {students
                    .filter((item) => item.subscription_status === '1')
                    .map((item, i) => {
                      return (
                        <NavDropdown.Item key={i} onClick={() => onClickStudent(item.id)}>
                          {item.first_name + ' ' + item.last_name}{' '}
                          <Badge variant={getVariant(item.subscription_type)} className='t-h4' style={{ fontSize: '12px' }}>
                            {item.subscription_type}
                          </Badge>
                        </NavDropdown.Item>
                      );
                    })}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleShowAddStudentModal}>Add Another Student / Recipient</NavDropdown.Item>
                </Fragment>
              ) : (
                ''
              )}
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
            </NavDropdown>
            <TutorialNav id="tutorial-nav-two"/>
          </Nav>
          <TutorialNav id="tutorial-nav"/>
          <Nav>
            {/* <div className="nav-item">
              <Nav.Link href="" className="refer-link">Refer a Friend And Save</Nav.Link>
            </div> */}
            {/* <div className="nav-item">
              <Nav.Link href="" className="share-link">Share The App</Nav.Link>
            </div> */}
            {/* <div className="nav-item">
              <Nav.Link href="" className="icon-link">
                <img src={profileIcon} alt="profile" />
              </Nav.Link>
            </div> */}
            <div className='nav-item' id="care-packages">
              <Nav.Link href={MARKETING_SITE_URL + '/shop/'} className='icon-link shop-link' target="_blank">
                <IconShoppingBag />
                Care Packages
              </Nav.Link>
            </div>
            <div className='nav-item'>
              <Nav.Link className='logout-link' onClick={onClickLogout}>
                Logout
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LoaderModal />
      <AddStudentModal />
      <AddNewSubscriptionModal />
      {/* {showSwal && (
        <SweetAlert
          show="true"
          title={'Oops!'}
          text={'This feature is under construction!'}
          onConfirm={() => setShowSwal(false)}
        />
      )} */}
      <PaymentSuccessModal />
      <Tutorial />
    </header>
  );
};

export default DashHeader;
