import React from 'react'

const InfoBox = ({ heading, subheading, number }) => {
  return (
    <div className="info-box-holder">
      <div className="info-box-details-wrapper">
        <div className="info-box-content-left">
          <div className="info-box-heading">{heading}</div>
          <div className="info-box-subheading">{subheading}</div>
        </div>
        <div className="info-box-content-right">
          <div className="info-box-numbers">
            <span>{number}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBox
