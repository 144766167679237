import React, { useState, useContext } from 'react';
import Nav from "react-bootstrap/Nav";
import { HashLink as NavLink } from "react-router-hash-link";
import Spinner from 'react-bootstrap/Spinner'

import api from '../api/api';
import { Context } from '../context/Context';

const ForgotPassword = () => {
  const { resetPasswordLoading, setResetPasswordLoading, handleClose } = useContext(Context);
  const [email, setEmail] = useState(null);

  const validateEmailAddress = email => {
    const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (regex.test(email)) {
      return 1;
    } else {
      return -1;
    }
  };

  const forgotPassword = () => {
    if(validateEmailAddress(email) === -1) {
      alert("Invalid email address");
    } else {
      setResetPasswordLoading(true);
      api.handleForgotPassword(email, setResetPasswordLoading)
    }
  };

  const onChangeInput = e => {
    setEmail(e.target.value);
  };



  sessionStorage.clear();
  return (
    <main className="landing forgot-password">
      <div className="fp-header">
        <h3 className="t-h3 fp-title">Did you forgot your password?</h3>
        <p className="fp-instructions">Enter your email address you're using for your account below and we will send you a password reset link.</p>
      </div>
      <form onSubmit={e => e.preventDefault()}>
        <input
          type="email"
          name="email"
          placeholder="email"
          onChange={onChangeInput}
        />
        <button
          className="btn-teal c-white"
          type="submit"
          onClick={() => forgotPassword()}
          disabled={resetPasswordLoading}
        >
          { resetPasswordLoading && <Spinner
            className="mr-2"
            animation="border"
            role="status"
            aria-hidden="true"
          /> }
          { resetPasswordLoading && <span>Please wait</span> }
          { !resetPasswordLoading && <span>Reset Password</span> }
        </button>
        <Nav.Link
          as={NavLink}
          smooth
          to="/login"
          className="landing-back-btn"
        >
          Back to Login
        </Nav.Link>
      </form>
    </main>
  );
};

export default ForgotPassword;
