import React from 'react'

const Iframe = (props) => {
  return (
    <div className="h-100">
      <iframe
        id="iframe"
        src={props.src}
        width={props.width}
        height={props.height}
        style={{ border: 'none' }}
        onLoad={props.handleOnLoad}
      />
    </div>
  )
}

export default Iframe
