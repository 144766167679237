import React, { useContext } from "react"

import SignUp from '../components/SignUp'

const Register = () => {
  return (
    <main id="signUp" className="sign-up">
      <div className="container custom-container">
        <SignUp />
      </div>
    </main>
  )
}

export default Register