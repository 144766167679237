import React, { useContext, useState, useEffect } from "react";

import { Context } from "../context/Context";

import CollegeForm from "../components/CollegeForm";
import ConfirmModal from "../components/ConfirmModal";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import api from "../api/api";
import { validateWhiteSpace, validateCollegeDates } from "./util";

const UpdateCollegeModal = ({ college, semDetails }) => {
  const {
    tokenAdmin,
    setUpdateCollegeLoading,
    updateCollegeLoading,
    setDeleteCollegeLoading,
    deleteCollegeLoading,
    showUpdateCollegeModal,
    handleCloseUpdateCollegeModal,
    setSchools,
    setCollegeData,
    currentSemDetails,
    setCurrentSemDetails
  } = useContext(Context);

  const [data, setData] = useState({
    id: "",
    college_name: "",
    nickname: "",
    city: "",
    state: "",
    zipcode: "",
    website_link: "",
    mental_health_link: "",
    fall_semester_start_date: "",
    spring_semester_start_date: "",
    next_fall_semester_start_date: "",
    next_spring_semester_start_date: "",
  });

  const [confirmModalElements, setConfirmModalElements] = useState({
    title: "",
    content: "",
    onConfirm: () => {},
    onConfirmText: "",
  });

  const [showConfirm, setShowConfirm] = useState(false);

  const [confirmIsBusy, setConfirmIsBusy] = useState(false)

  // const [schoolDates, setSchoolDates] = useState({
  //   selectedFallStartDate: null,
  //   selectedSpringStartDate: null,
  //   selectedNextFallStartDate: null,
  //   selectedNextSpringStartDate: null,
  // });

  useEffect(() => {
    setData({
      id: college.id,
      college_name: college.college_name,
      nickname: college.nickname,
      city: college.city,
      state: college.state,
      zipcode: college.zipcode,
      website_link: college.website_link,
      mental_health_link: college.mental_health_link,
      fall_semester_start_date: college.fall_semester_start_date,
      spring_semester_start_date: college.spring_semester_start_date,
      next_fall_semester_start_date: college.next_fall_semester_start_date,
      next_spring_semester_start_date: college.next_spring_semester_start_date,
    });

    // const fallDate = college.fall_semester_start_date;
    // const springDate = college.spring_semester_start_date;
    // const nextFallDate = college.next_fall_semester_start_date;
    // const nextSpringDate = college.next_spring_semester_start_date;

    // setSchoolDates({
    //   selectedFallStartDate: new Date(fallDate ? fallDate : null),
    //   selectedSpringStartDate: new Date(springDate ? springDate : null),
    //   selectedNextFallStartDate: new Date(nextFallDate ? nextFallDate : null),
    //   selectedNextSpringStartDate: new Date(
    //     nextSpringDate ? nextSpringDate : null
    //   ),
    // });
    
    setCollegeData(college)
  }, [showUpdateCollegeModal]);

  const handleOnChange = (e, name) => {
    if (e === null) return;
    let target = null;
    let value = null;

    if (e.target !== undefined) {
      target = e.target.name;
      value = e.target.value;
    }

    if (e.target === undefined) {
      // let selectedDate = e;
      // let formatedDate =
      //   selectedDate.getMonth() +
      //   1 +
      //   "/" +
      //   selectedDate.getDate() +
      //   "/" +
      //   selectedDate.getFullYear();

      // let dateKey = "";

      // setData({
      //   ...data,
      //   [name]: formatedDate,
      // });

      // if (name === "fall_semester_start_date") {
      //   dateKey = "selectedFallStartDate";
      // } else if (name === "spring_semester_start_date") {
      //   dateKey = "selectedSpringStartDate";
      // } else if (name === "next_fall_semester_start_date") {
      //   dateKey = "selectedNextFallStartDate";
      // } else {
      //   dateKey = "selectedNextSpringStartDate";
      // }

      // setSchoolDates({
      //   ...schoolDates,
      //   [dateKey]: e,
      // });
    } else {
      setData({
        ...data,
        [target]: value,
      });
    }
  };

  const updateCollegeInfo = () => {
    setUpdateCollegeLoading(true);
    api.handleManageCollegeData(
      tokenAdmin,
      data,
      "update",
      setUpdateCollegeLoading,
      handleCloseUpdateCollegeModal,
      setSchools,
      currentSemDetails,
      setCurrentSemDetails,
    );
  };
  const deleteCollege = () => {
    setDeleteCollegeLoading(true);
    setConfirmIsBusy(true)

    api.handleDeleteCollegeData(
      tokenAdmin,
      data.id,
      setDeleteCollegeLoading,
      handleCloseUpdateCollegeModal,
      setSchools,
      setConfirmIsBusy,
      setShowConfirm
    );
  };

  const onClickDelete = () => {
    setConfirmModalElements({
      title: "Are you sure?",
      content: "Do you really want to delete this college?",
      onConfirm: () => {
        deleteCollege();
      },
      onConfirmText: "Delete",
    });
    setShowConfirm(true);
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (
      validateWhiteSpace(data.college_name) === -1 ||
      data.college_name === ""
    ) {
      alert("College name is required");
    } else if (
      validateWhiteSpace(data.nickname) === -1 ||
      data.nickname === ""
    ) {
      alert("College nickname is required");
    } else if (validateWhiteSpace(data.city) === -1 || data.city === "") {
      alert("City is required");
    } else if (validateWhiteSpace(data.state) === -1 || data.state === "") {
      alert("State is required");
    } else if (validateWhiteSpace(data.zipcode) === -1 || data.zipcode === "") {
      alert("Zip code is required");
    } 
    // else if (
    //   validateWhiteSpace(data.fall_semester_start_date) === -1 ||
    //   data.fall_semester_start_date === ""
    // ) {
    //   alert("Fall semester start date is required");
    // } else if (
    //   validateWhiteSpace(data.spring_semester_start_date) === -1 ||
    //   data.spring_semester_start_date === ""
    // ) {
    //   alert("Spring semester start date is required");
    // } else if (
    //   validateWhiteSpace(data.stnext_fall_semester_start_dateate) === -1 ||
    //   data.next_fall_semester_start_date === ""
    // ) {
    //   alert("Next fall semester start date is required");
    // } else if (
    //   validateWhiteSpace(data.next_spring_semester_start_date) === -1 ||
    //   data.next_spring_semester_start_date === ""
    // ) {
    //   alert("Next spring semester start date is required");
    // }
    else if (!validateCollegeDates(currentSemDetails)) {
      alert("School dates are not valid. Please check if you've added one.");
    } 
    else {
      updateCollegeInfo();
    }
  };

  return (
    <Modal
      className="c2c-modal"
      show={showUpdateCollegeModal}
      onHide={handleCloseUpdateCollegeModal}
      centered
      backdrop={"static"}
    >
      <div
        className="btn-close-modal"
        onClick={handleCloseUpdateCollegeModal}
      ></div>

      <CollegeForm
        data={data}
        handleOnChange={handleOnChange}
        title={data.college_name}
      />

      <button className="action-btn btn-delete" onClick={() => onClickDelete()}>
        <span className="far fa-trash-alt pr-2"></span>
        <span>Delete this college</span>
      </button>

      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleCloseUpdateCollegeModal()}
          disabled={updateCollegeLoading && deleteCollegeLoading}
        >
          <span>Cancel</span>
        </button>
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={updateCollegeLoading && deleteCollegeLoading}
        >
          {updateCollegeLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {updateCollegeLoading && <span>Please wait ...</span>}
          {!updateCollegeLoading && <span>Done</span>}
        </button>
      </div>
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={confirmModalElements.onConfirm}
        onCancel={() => setShowConfirm(false)}
        title={confirmModalElements.title}
        content={confirmModalElements.content}
        onCancelText={"Cancel"}
        onConfirmText={confirmModalElements.onConfirmText}
        isBusy={confirmIsBusy}
      />
    </Modal>
  );
};

export default UpdateCollegeModal;
