import React, { useState, useContext } from 'react'

import cookie from 'react-cookies'

import Spinner from 'react-bootstrap/Spinner'

import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Context } from '../context/Context'

import axios from 'axios'
import api_base from '../api/api_base_url'
import api from '../api/api'
import LoaderModal from './LoaderModal'
import { useLocation } from 'react-router'

import { isLogin } from './util'

// import { logEvent, Result, ErrorResult } from './util';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Merri Weather',
      fontSize: '17px',
      fontWeight: 'Bold',
      color: '#525D7D',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#979dac',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const CheckoutForm = () => {
  const {
    token,
    data,
    signupPayLoading,
    setSignupPayLoading,
    handleClose,
    setLoginLoading,
    setIsAddingNewStudent,
    resetSignUpForm,
    handleShowLoaderModal,
    handleShowPaymentSuccessModal,
    setAfterSignUp,
    handleOnChange,
    couponVerificationLoading,
    setCouponVerificationLoading,
  } = useContext(Context)

  const stripe = useStripe()
  const elements = useElements()
  const [name, setName] = useState('')
  const [postal, setPostal] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  let studentCtr = 0
  const { search } = useLocation()

  const handleSubmit = event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    setSignupPayLoading(true)

    createPaymentMethod()
  }

  const createPaymentMethod = async event => {
    if (!stripe || !elements || signupPayLoading) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: data.email,
        name,
        phone: data.cell_phone ? data.cell_phone : '+335555555555',
        address: {
          postal_code: postal,
        },
      },
    })
    stripePaymentMethodHandler(result)
  }

  const stripePaymentMethodHandler = async result => {
    if (result.error) {
      // Show error in payment form
      setErrorMessage(result.error.message)
      setPaymentMethod(null)
      setSignupPayLoading(false)
      alert(result.error.message)
    } else {
      const stripe_api_url = api_base + '/api/create-customer'
      const stripe_headers = {
        headers: { 'Content-Type': 'application/json' },
      }

      const customer_data = {
        email: result.paymentMethod.billing_details.email,
        phone: result.paymentMethod.billing_details.phone,
        name: result.paymentMethod.billing_details.name,
        payment_method: result.paymentMethod.id,
        students: data.students,
        coupon: data.coupon,
        coupon_validated: data.couponValidated,
      }

      console.log('REQUEST BODY[/create-customer]::', customer_data)

      await axios
        .post(stripe_api_url, customer_data, stripe_headers)
        .then(res => {
          if (res.data.status === 'success') {
            const student_api_url = api_base + '/api/signup'
            const student_headers = {
              headers: { 'Content-Type': 'application/json' },
            }

            const queryParams = new URLSearchParams(search)
            const affiliateCode = queryParams.get('affiliate')

            const request_body = {
              customer_id: res.data.customer_id,
              parent: data,
              students: res.data.students,
              affiliate_code: affiliateCode,
            }

            console.log('REQUEST BODY[/signup]::', request_body)

            axios
              .post(student_api_url, request_body, student_headers)
              .then(res => {
                if (res.data.status === 'success') {
                  handleShowLoaderModal()
                  api.registerMailChimp(data)
                  api.handleLogin(data, setLoginLoading, setIsAddingNewStudent).then(() => {
                    const auth_token = cookie.load('jwt')
                    if (data.sms === true && auth_token) {
                      api.registerPhoneTwilio(auth_token, data.cell_phone)
                    }
                    resetSignUpForm()
                    setSignupPayLoading(false)
                    handleClose()
                    setAfterSignUp(true)
                  })
                } else {
                  alert("There's an error saving your data.")
                  window.location.reload()
                  //resetSignUpForm();
                  setSignupPayLoading(false)
                }
              })
              .catch(e => {
                setSignupPayLoading(false)
                alert(`[Error ${e.response.status}] ${e.response.data.message}`)
              })
          } else {
            setSignupPayLoading(false)
            alert(res.data.message)
          }
        })
        .catch(e => {
          console.log(e)
          setSignupPayLoading(false)
          alert(`[Error ${e.response.status}] ${e.response.data.message}`)
        })

      setPaymentMethod(result.paymentMethod)
      setErrorMessage(null)
    }
  }

  const verifyCoupon = () => {
    setCouponVerificationLoading(true)
    api.validateCoupon(data, setCouponVerificationLoading)
  }

  return (
    <div className="card-wrapper">
      <form onSubmit={handleSubmit}>
        <div className="coupon-card-wrapper">
          <div className="card-holder name-card">
            <label htmlFor="name">
              <span>Name on Card</span>
              <input
                id="name"
                className="text-holder t-h1"
                name="card"
                required
                placeholder="Jenny Rosen"
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}
              />
            </label>
          </div>

          <div className="coupon-holder">
            <div className="input-wrapper">
              <input
                className="t-h1"
                type="text"
                name="coupon"
                placeholder="Coupon or Promo Code"
                onChange={handleOnChange}
              />
              <div className="input-subtext"></div>
            </div>

            <button
              className="btn-purple c-purple d-flex"
              disabled={couponVerificationLoading}
              onClick={() => verifyCoupon()}>
              {couponVerificationLoading && (
                <Spinner className="mr-2 mt-1" animation="border" role="status" aria-hidden="true" size="sm" />
              )}
              {couponVerificationLoading && <span>Verifying ...</span>}
              {!couponVerificationLoading && <span>Apply Code</span>}
            </button>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
          <div className="card-holder" style={{ width: '100%' }}>
            <div className="info-holder no-margin">
              <label>Card Information</label>

              <CardNumberElement id="cardNumber" className="text-holder t-h1" options={ELEMENT_OPTIONS} />
              <CardExpiryElement id="expiry" className="text-holder t-h1" options={ELEMENT_OPTIONS} />
              <CardCvcElement id="cvc" className="text-holder t-h1" options={ELEMENT_OPTIONS} />
              <input
                id="postal"
                className="text-holder t-h1"
                required
                placeholder="Zipcode"
                value={postal}
                onChange={e => {
                  setPostal(e.target.value)
                }}
              />
            </div>
            {/* {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <button className="btn-teal c-white card-h card-h-3 d-flex" disabled={!stripe && signupPayLoading}>
            {signupPayLoading && <Spinner className="mr-2" animation="border" role="status" aria-hidden="true" />}
            {signupPayLoading && <span>Please wait...</span>}
            {!signupPayLoading && 'Submit Payment'}
          </button>
        </div>
      </form>
      <LoaderModal />
    </div>
  )
}

export default CheckoutForm
