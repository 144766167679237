import axios from 'axios'
import api_base from './api_base_url'
import cookie from 'react-cookies'

const registerPhoneTwilio = (token, data) => {
  const api_url = api_base + '/api/twilio/send'

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }

  const twilio_data = {
    number: data,
    message: 'WELCOME',
  }

  axios
    .post(api_url, twilio_data, headers)
    .then(res => {})
    .catch(e => {
      console.log(e)
    })
}

const registerMailChimp = data => {
  const api_url = api_base + '/api/mailchimp/register'

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: "Bearer " + token,
    },
  }

  const mailchimp_date = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
  }

  axios
    .post(api_url, mailchimp_date, headers)
    .then(res => {})
    .catch(e => {
      console.log(e)
    })
}

const getCurrentCard = async (token, student_id, setCurrentCard, handleCloseLoaderModal) => {
  const api_url = api_base + '/api/thankster/get-current-card'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  await axios
    .post(api_url, { student_id: student_id }, headers)
    .then(res => {
      setCurrentCard(res.data)
      // handleCloseLoaderModal();
    })
    .catch(error => {
      console.log(error)
    })
}

const getLastWeekCard = async (token, student_id, setLastWeekCard, handleCloseLoaderModal) => {
  const api_url = api_base + '/api/thankster/get-last-week-card'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  await axios
    .post(api_url, { student_id: student_id }, headers)
    .then(res => {
      if (res.data.status === 'success') {
        setLastWeekCard(res.data.card)
        // handleCloseLoaderModal();
      }
    })
    .catch(error => {
      console.log(error)
    })
}

const getAllCards = async (token, student_id, setAllCards, handleCloseLoaderModal) => {
  const api_url = api_base + '/api/thankster/get-all-card'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  await axios
    .post(api_url, { student_id: student_id }, headers)
    .then(res => {
      if (res.data.status === 'success') {
        setAllCards(res.data.cards)
        // handleCloseLoaderModal();
      }
    })
    .catch(error => {
      console.log(error)
    })
}

const getSubscription = async (
  token,
  subscription_id,
  student_id,
  setInvoice,
  setUpcomingInvoice,
  setSubscriptions,
  handleCloseLoaderModal
) => {
  const api_url = api_base + '/api/stripe/get-subscription'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  await axios
    .post(api_url, { subscription_id: subscription_id, student_id: student_id }, headers)
    .then(res => {
      if (res.data.status === 'success') {
        setSubscriptions(res.data.subscriptions)
        setInvoice(res.data.invoice)
        setUpcomingInvoice(res.data.upcoming_invoice)
        // handleCloseLoaderModal();
      }
    })
    .catch(error => {
      console.log(error)
    })
}

const getCustomer = (token, customer_id, setCustomer, setPaymentMethods, setInvoice, setUpcomingInvoice) => {
  const api_url = api_base + '/api/stripe/get-customer'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  axios
    .post(api_url, { customer_id: customer_id }, headers)
    .then(res => {
      // console.log(res.data);
      setCustomer(res.data)
      getPaymentMethods(token, customer_id, setPaymentMethods)
    })
    .catch(error => {
      console.log(error)
    })
}

const getPaymentMethods = async (token, customer_id, setPaymentMethods) => {
  const api_url = api_base + '/api/stripe/get-payment-methods'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  await axios
    .post(api_url, { customer_id: customer_id }, headers)
    .then(res => {
      setPaymentMethods(res.data.data)
    })
    .catch(error => {
      console.log(error)
    })
}

// const getUpcomingInvoice = (customer_id, student_id, setInvoice) => {
//   const api_url = api_base + "/api/stripe/get-upcoming-invoice";
//   const headers = {
//     headers: { "Content-Type": "application/json" },
//   };
//   axios
//     .post(
//       api_url,
//       { customer_id: customer_id, student_id: student_id },
//       headers
//     )
//     .then((res) => {
//       setInvoice(res.data);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

const getSchools = async (token, setSchools) => {
  const api_url = api_base + '/api/get-schools'
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
  await axios
    .get(api_url, headers)
    .then(res => {
      const sortedSchools = res.data.sort((a, b) => {
        const schoolA = a.college_name.toUpperCase()
        const schoolB = b.college_name.toUpperCase()

        let comparison = 0
        if (schoolA > schoolB) {
          comparison = 1
        } else if (schoolA < schoolB) {
          comparison = -1
        }
        return comparison
      })

      setSchools(sortedSchools)
    })
    .catch(error => {
      console.log('Error fetching and parsing data', error)
    })
}

const api = {
  getCustomer: async (token, customer_id, setCustomer, setPaymentMethods, setInvoice) => {
    const api_url = api_base + '/api/stripe/get-customer'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    axios
      .post(api_url, { customer_id: customer_id }, headers)
      .then(res => {
        setCustomer(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  getPaymentMethods: async (token, customer_id, setPaymentMethods) => {
    const api_url = api_base + '/api/stripe/get-payment-methods'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, { customer_id: customer_id }, headers)
      .then(res => {
        setPaymentMethods(res.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateDefaultPaymentMethod: async (
    token,
    payment_method_id,
    customer_id,
    setCustomer,
    setPaymentMethods,
    setShowConfirm,
    setConfirmIsBusy
  ) => {
    const api_url = api_base + '/api/stripe/update-default-payment-method'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, { payment_method: payment_method_id, customer_id }, headers)
      .then(res => {})
      .catch(error => {
        console.log(error)
      })
  },
  removePaymentMethod: async (token, payment_method_id, customer_id) => {
    const api_url = api_base + '/api/stripe/remove-payment-method'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, { payment_method: payment_method_id }, headers)
      .then(res => {})
      .catch(error => {
        console.log(error)
      })
  },
  registerPhoneTwilio: (token, data) => {
    const api_url = api_base + '/api/twilio/send'

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    const twilio_data = {
      number: data,
      message: 'WELCOME',
    }

    axios
      .post(api_url, twilio_data, headers)
      .then(res => {})
      .catch(e => {
        console.log(e)
      })
  },

  registerMailChimp: data => {
    const api_url = api_base + '/api/mailchimp/register'

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
    }

    const mailchimp_date = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
    }

    axios
      .post(api_url, mailchimp_date, headers)
      .then(res => {})
      .catch(e => {
        console.log(e)
      })
  },
  handleLoginAdmin: (data, setAdminLoginLoading) => {
    const api_url = api_base + '/api/super-admin/login-admin'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    axios
      .post(api_url, data, headers)
      .then(res => {
        if (res.data.access_token !== null) {
          cookie.save('jwt-admin', res.data.access_token, { path: '/' })
          window.location.hash = '#/admin-dashboard'
          setAdminLoginLoading(false)
        }
      })
      .catch(e => {
        setAdminLoginLoading(false)
        alert('Invalid username or password')
      })
  },
  handleLogoutAdmin: (token, setTokenAdmin, handleCloseLoaderModal) => {
    const api_url = api_base + '/api/super-admin/logout-admin'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    axios
      .post(api_url, token, headers)
      .then(res => {
        if (res.data.result === 'success') {
          cookie.remove('jwt-admin', { path: '/' })
          setTokenAdmin(null)
          window.location.hash = '#/'
          handleCloseLoaderModal()
        }
      })
      .catch(e => {
        console.log(e)
      })
  },
  handleLogin: async (data, setLoginLoading, setIsAddingNewStudent) => {
    const api_url = api_base + '/api/auth/login'
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    }
    await axios
      .post(api_url, data, headers)
      .then(res => {
        if (res.data.access_token !== null) {
          cookie.save('jwt', res.data.access_token, { path: '/' })
          window.location.hash = '#/dashboard'
          setLoginLoading(false)
        }
      })
      .catch(e => {
        setLoginLoading(false)
        alert('Invalid username or password')
      })
  },
  handleLogout: async (
    token,
    handleCloseLoaderModal,
    setUser,
    setStudents,
    setSelectedStudent,
    setSubscriptions,
    setCurrentCard,
    setLastWeekCard,
    setAllCards,
    setToken,
    setInvoice,
    setUpcomingInvoice,
    setCustomer,
    setPaymentMethods
  ) => {
    const api_url = api_base + '/api/auth/logout'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, token, headers)
      .then(res => {
        if (res.data.result === 'success') {
          setToken(null)
          setUser({})
          setStudents([])
          setSelectedStudent([])
          setSubscriptions(null)
          setInvoice(null)
          setUpcomingInvoice(null)
          setCustomer([])
          setPaymentMethods([])
          setCurrentCard({})
          setLastWeekCard({})
          setAllCards([])
        }
      })
      .catch(e => {
        alert(`[Error ${e.response.status}] ${e.response.data.message}`)
      })
  },
  handleForgotPassword: (email, setResetPasswordLoading) => {
    const api_url = api_base + '/api/forgot-password'
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    }
    axios
      .post(api_url, { email: email }, headers)
      .then(res => {
        if (res.data.status === 'success') {
          alert('New password has been sent to your email')
          window.location.hash = '#/login'
          setResetPasswordLoading(false)
        } else {
          alert('Email does not exist')
          setResetPasswordLoading(false)
        }
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleUpdatePassword: (token, id, data, handleCloseChangePasswordModal, setUpdatePasswordLoading) => {
    const api_url = api_base + '/api/update-password'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    axios
      .post(api_url, { id: id, data: data }, headers)
      .then(res => {
        if (res.data.status === 'success') {
          alert(res.data.message)
          handleCloseChangePasswordModal()
          setUpdatePasswordLoading(false)
        } else {
          alert(res.data.message)
        }
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleGetUser: async (
    token,
    setUser,
    setUserDataLoaded,
    setCustomer,
    setPaymentMethods,
    setInvoice,
    setUpcomingInvoice
  ) => {
    const api_url = api_base + '/api/auth/me'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, token, headers)
      .then(res => {
        setUser(res.data)
        getCustomer(token, res.data.customer_id, setCustomer, setPaymentMethods, setInvoice, setUpcomingInvoice)
        setUserDataLoaded(true)
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleUpdateUser: async (token, data, user, setUser) => {
    const api_url = api_base + '/api/update-user'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, data, headers)
      .then(res => {
        if (res.data.status === 'success') {
          setUser(res.data.user)
          if (res.data.user.cell_phone !== user.cell_phone) {
            if (res.data.user.sms === '1') {
              registerPhoneTwilio(token, res.data.user.cell_phone)
            }
          }
          if (res.data.user.email !== user.email) {
            registerMailChimp(res.data.user)
          }
        }
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleGetAllStudents: async (
    token,
    setInvoice,
    setUpcomingInvoice,
    setStudents,
    selectedStudent,
    setSelectedStudent,
    setSubscriptions,
    setCurrentCard,
    setLastWeekCard,
    setAllCards,
    handleCloseLoaderModal,
    setStudentDataLoaded,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType
  ) => {
    const api_url = api_base + '/api/get-all-students'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .get(api_url, headers)
      .then(res => {
        let allStudents = res.data.map(student => {
          student.subscription_type = getSubscriptionType(student)
          return student
        })
        setStudents(allStudents)
        if (selectedStudent.length === 0) {
          const currentStudent = res.data[0]
          setSelectedStudent({
            ...currentStudent,
            subscription_type: getSubscriptionType(currentStudent),
          })
          setNewRecipientData({
            ...newRecipientData,
            student_first_name: currentStudent.first_name,
            student_last_name: currentStudent.last_name,
            schoolname: currentStudent.school,
            student_email: currentStudent.email,
            student_street: currentStudent.street,
            student_unit: currentStudent.unit,
            student_city: currentStudent.city,
            student_state: currentStudent.state,
            student_zipcode: currentStudent.zipcode,
            subscription_id: currentStudent.subscription_id,
          })

          setStudentDataLoaded(true)
          return Promise.all([
            getSubscription(
              token,
              currentStudent.subscription_id,
              currentStudent.student_id,
              setInvoice,
              setUpcomingInvoice,
              setSubscriptions,
              handleCloseLoaderModal
            ),
            getCurrentCard(token, currentStudent.student_id, setCurrentCard, handleCloseLoaderModal),
            getLastWeekCard(token, currentStudent.student_id, setLastWeekCard, handleCloseLoaderModal),
            getAllCards(token, currentStudent.student_id, setAllCards, handleCloseLoaderModal),
          ])
        }
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleGetAllStudentsAfterAdd: async (
    token,
    setInvoice,
    setStudents,
    selectedStudent,
    setSelectedStudent,
    setSubscriptions,
    setCurrentCard,
    setLastWeekCard,
    setAllCards,
    handleCloseLoaderModal,
    setStudentDataLoaded,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType
  ) => {
    const api_url = api_base + '/api/get-all-students'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .get(api_url, headers)
      .then(res => {
        let allStudents = res.data.map(student => {
          student.subscription_type = getSubscriptionType(student)
          return student
        })
        setStudents(allStudents)
        const currentStudent = res.data[res.data.length - 1]
        setSelectedStudent({
          ...currentStudent,
          subscription_type: getSubscriptionType(currentStudent),
        })
        setNewRecipientData({
          ...newRecipientData,
          student_first_name: currentStudent.first_name,
          student_last_name: currentStudent.last_name,
          schoolname: currentStudent.school,
          student_email: currentStudent.email,
          student_street: currentStudent.street,
          student_unit: currentStudent.unit,
          student_city: currentStudent.city,
          student_state: currentStudent.state,
          student_zipcode: currentStudent.zipcode,
          subscription_id: currentStudent.subscription_id,
        })

        setStudentDataLoaded(true)
        getSubscription(
          token,
          currentStudent.subscription_id,
          currentStudent.student_id,
          setInvoice,
          setSubscriptions,
          handleCloseLoaderModal
        )
        getCurrentCard(token, currentStudent.student_id, setCurrentCard, handleCloseLoaderModal)
        getLastWeekCard(token, currentStudent.student_id, setLastWeekCard, handleCloseLoaderModal)
        getAllCards(token, currentStudent.student_id, setAllCards, handleCloseLoaderModal)
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleGetStudent: async (
    token,
    setInvoice,
    setUpcomingInvoice,
    student_id,
    setSelectedStudent,
    setSubscriptions,
    setCurrentCard,
    setLastWeekCard,
    setAllCards,
    handleCloseLoaderModal,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType
  ) => {
    const api_url = api_base + '/api/get-student'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, { student_id: student_id }, headers)
      .then(res => {
        const currentStudent = res.data
        setSelectedStudent({
          ...currentStudent,
          subscription_type: getSubscriptionType(currentStudent),
        })
        setNewRecipientData({
          ...newRecipientData,
          student_first_name: currentStudent.first_name,
          student_last_name: currentStudent.last_name,
          schoolname: currentStudent.school,
          student_email: currentStudent.email,
          student_street: currentStudent.street,
          student_unit: currentStudent.unit,
          student_city: currentStudent.city,
          student_state: currentStudent.state,
          student_zipcode: currentStudent.zipcode,
          subscription_id: currentStudent.subscription_id,
        })
        return Promise.all([
          getSubscription(
            token,
            res.data.subscription_id,
            res.data.student_id,
            setInvoice,
            setUpcomingInvoice,
            setSubscriptions,
            handleCloseLoaderModal
          ),
          getCurrentCard(token, res.data.student_id, setCurrentCard, handleCloseLoaderModal),
          getLastWeekCard(token, res.data.student_id, setLastWeekCard, handleCloseLoaderModal),
          getAllCards(token, res.data.student_id, setAllCards, handleCloseLoaderModal),
        ])
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleUpdateStudent: async (
    token,
    data,
    setSelectedStudent = null,
    newRecipientData = null,
    setNewRecipientData = null,
    getSubscriptionType = null
  ) => {
    const api_url = api_base + '/api/update-student'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    console.log('STUDENT DATA[UpdateStudent]:::', data)
    await axios
      .post(api_url, data, headers)
      .then(res => {
        if (res.data.status === 'success') {
          const currentStudent = res.data.student

          setSelectedStudent &&
            getSubscriptionType &&
            setSelectedStudent({
              ...currentStudent,
              subscription_type: getSubscriptionType(currentStudent),
            })
          setNewRecipientData &&
            newRecipientData &&
            setNewRecipientData({
              ...newRecipientData,
              student_first_name: currentStudent.first_name,
              student_last_name: currentStudent.last_name,
              schoolname: currentStudent.school,
              student_email: currentStudent.email,
              student_street: currentStudent.street,
              student_unit: currentStudent.unit,
              student_city: currentStudent.city,
              student_state: currentStudent.state,
              student_zipcode: currentStudent.zipcode,
              subscription_id: currentStudent.subscription_id,
            })
        } else {
          alert("An error occured while updating student's data. Please try again or contact support.")
        }
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleUpdateSubscription: async (token, data, updateSubcription) => {
    const api_url = api_base + '/api/update-subscription'
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_url, data, headers)
      .then(res => {
        updateSubcription(res.data.subscription)
        alert('Your request to update your subscription has been sent. You will receive a verification email shortly.')
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  handleGetSchools: async setSchools => {
    const api_url = api_base + '/api/get-schools'
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    }
    await axios
      .get(api_url, headers)
      .then(res => {
        const sortedSchools = res.data.sort((a, b) => {
          const schoolA = a.college_name.toUpperCase()
          const schoolB = b.college_name.toUpperCase()

          let comparison = 0
          if (schoolA > schoolB) {
            comparison = 1
          } else if (schoolA < schoolB) {
            comparison = -1
          }
          return comparison
        })

        setSchools(sortedSchools)
      })
      .catch(error => {
        // console.log("Error fetching and parsing data", error);
      })
  },
  handleGetSchool: async (name, setSchool) => {
    const api_url = api_base + '/api/get-school'
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    }
    await axios
      .post(api_url, name, headers)
      .then(res => {
        setSchool(res.data)
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  // handleCreateSchool: async (data) => {
  //   const api_url = api_base + "/api/create-school";
  //   const headers = {
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   await axios
  //     .post(api_url, data, headers)
  //     .then((res) => {
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching and parsing data", error);
  //     });
  // },
  // handleOnSubmit: (token, data, handleParentID) => {
  //   const api_url = api_base + "/api/signup";
  //   const headers = {
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   axios
  //     .post(api_url, data, headers)
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         if (data.sms === true) {
  //           this.registerPhoneTwilio(token, data.cell_phone);
  //         }
  //         if (data.newsletter === true) {
  //           this.registerMailChimp(data);
  //         }
  //         handleParentID(res.data.parent_id);
  //         return res.data.parent_id;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
  // handleAddStudents: (
  //   data,
  //   parent_id,
  //   semester_start_date,
  //   subscription_id
  // ) => {
  //   const api_url = api_base + "/api/add-students";
  //   const headers = {
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   axios
  //     .post(
  //       api_url,
  //       {
  //         data: data,
  //         parent_id: parent_id,
  //         semester_start_date: semester_start_date,
  //         subscription_id: subscription_id,
  //       },
  //       headers
  //     )
  //     .then((res) => {
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
  // handleContacUs: async (data) => {
  //   const api_url = api_base + "/api/contact-us";
  //   const headers = {
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   await axios
  //     .post(api_url, data, headers)
  //     .then((res) => {
  //       alert("Your message has been sent to the administrator");
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching and parsing data", error);
  //     });
  // },
  allPlans: async setPlans => {
    await axios
      .get(api_base + '/api/stripe-plans')
      .then(response => {
        setPlans(response.data)
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error)
      })
  },
  validateCoupon: (data, setLoading) => {
    const api_url = api_base + '/api/stripe/validate-coupon'
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    }
    axios
      .post(api_url, { coupon: data.coupon, students: data.students }, headers)
      .then(res => {
        if (res.data.status === 'success') {
          alert(res.data.message)
          setLoading(false)
        } else {
          alert(res.data.message)
          setLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  validateEmail: async (data, setEmailExists) => {
    const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/
    if (regex.test(data.email)) {
      await axios
        .get(api_base + '/api/email-exists', {
          params: {
            email: data.email,
          },
        })
        .then(res => {
          if (res.data.status === 'exists') {
            setEmailExists(true)
          } else {
            setEmailExists(false)
          }
        })
        .catch(e => {
          console.log(e.message)
        })
    }
  },
  validateUpdateEmail: async (data, setEmailExists) => {
    const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/
    if (regex.test(data.email)) {
      await axios
        .get(api_base + '/api/email-exists', {
          params: {
            parent_id: data.parent_id,
            email: data.email,
          },
        })
        .then(res => {
          if (res.data.status === 'exists') {
            setEmailExists(true)
          } else {
            setEmailExists(false)
          }
        })
    }
  },
  studentValidateEmail: async (data, setEmailExists) => {
    const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/
    if (regex.test(data.email)) {
      await axios
        .get(api_base + '/api/student-email-exists', {
          params: {
            email: data.email,
          },
        })
        .then(res => {
          if (res.data.status === 'exists') {
            setEmailExists(true)
          } else {
            setEmailExists(false)
          }
        })
    }
  },
  // listCards: async (setPlans) => {
  //   await axios
  //     .get(api_base + "/api/thankster/list-cards")
  //     .then((response) => {
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching and parsing data", error);
  //     });
  // },

  handleSuperAdminSummary: async (token, setSuperAdminSummary) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/summary', headers).then(res => {
      setSuperAdminSummary(res.data)
    })
  },

  handleGenerateNewStudentReport: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/new-student-report', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'New Student Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateWeeklyGiftReport: async (token, cardType) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(
        api_base + '/api/super-admin/weekly-gift-report',
        {
          card_type: cardType,
        },
        headers
      )
      .then(res => {
        if (res.data.status == 'have data') {
          const csvData = objectToCsv(res.data.data)
          download(csvData, cardType + ' Report.csv')
        } else {
          alert('no data found')
        }
      })
  },

  handleGenerateWeeklyCardSent: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/weekly-card-sent', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'Weekly Card Sent Report.csv')
      } else {
        alert('No data found')
      }
    })
  },

  handleGenerateSubscriberData: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/export-data-by-subscriber', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'Subscriber Data Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateNewsletterSubscriberData: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/generate-newsletter-subscriber', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'Newsletter Subscriber Data Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateActiveStudent: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/generate-active-student', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'Active Students Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateTotalTaxCollected: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/total-tax-collected', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'Total Tax Collected Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateCollegeDates: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/export-data-by-school', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'College Dates Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateSmsSent: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + '/api/super-admin/generate-sms-sent', headers).then(res => {
      if (res.data.status == 'have data') {
        const csvData = objectToCsv(res.data.data)
        download(csvData, 'SMS Sent Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateStudentSeries: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    await axios.get(api_base + '/api/super-admin/generate-student-series', headers).then(res => {
      if (res.status === 200) {
        const seriesData = res?.data?.data
        let processedata = []

        if (seriesData) {
          processedata = seriesData?.map(data => ({
            ...data,
            current_series: JSON.stringify(data.current_series),
            next_series: JSON.stringify(data.next_series),
          }))
        }

        const csvData = objectToCsv(processedata)
        download(csvData, 'Student Series Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleGenerateAffiliates: async token => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    await axios.get(api_base + '/api/super-admin/generate-affiliates-report', headers).then(res => {
      if (res.status === 200) {
        const data = res?.data?.data

        const affiliateData = []
        for (const affiliate of data) {
          const students = affiliate?.students || []

          if (students.length === 0) {
            continue
          }

          const studentData = students?.map(student => ({
            'Affiliate Name': affiliate?.affiliate_name,
            'Affiliate Link': `https://app.cards2college.com/#/signup?affiliate_code=${affiliate?.affiliate_code}`,
            'Affiliate Email': affiliate?.affiliate_email,
            'Student Name': student?.name,
            'Student Signup Date': new Date(student?.student_created_at).toLocaleDateString('en-US'),
            'Parent Name': student?.parent_name,
            'Parent Email': student?.parent_email,
            'Parent Signup Date': new Date(student?.parent_created_at).toLocaleDateString('en-US'),
          }))

          affiliateData.push(studentData)
        }

        const flattenedData = affiliateData.flat()

        const csvData = objectToCsv(flattenedData)
        download(csvData, 'Affiliates Report.csv')
      } else {
        alert('no data found')
      }
    })
  },

  handleManageCollegeData: async (
    token,
    objectData,
    action,
    loading,
    closeModal,
    setSchools,
    currentSemDetails,
    setCurrentSemDetails
  ) => {
    if (action === 'create') {
      objectData.dates = currentSemDetails
    }
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_base + `/api/super-admin/manage-school-data/${action}`, objectData, headers)
      .then(res => {
        if (res.data.status == 'success') {
          console.log(this)
          getSchools(token, setSchools)
          setCurrentSemDetails([])
          loading(false)
          closeModal()
        } else {
          alert("There's something wrong processing your data. Please try again later.")
        }
      })
      .catch(e => {
        alert(`[Error ${e.response.status}] ${e.response.data.message}`)
      })
  },
  handleDeleteCollegeData: async (token, id, loading, closeModal, setSchools, setConfirmIsBusy, setShowConfirm) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .delete(api_base + `/api/super-admin/delete-school-data/${id}`, headers)
      .then(res => {
        if (res.data.msg == 'success') {
          getSchools(token, setSchools).then(() => {
            loading(false)
            setShowConfirm(false)
            setConfirmIsBusy(false)
            closeModal()
          })
        } else {
          alert("There's something wrong deleting your data. Please try again later.")
        }
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },

  handleGetTemplateData: async (token, setTemplates) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios.get(api_base + `/api/super-admin/show-card-template`, headers).then(res => {
      setTemplates(res.data)
    })
  },

  handleManageTemplateData: async (token, objectData, action) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_base + `/api/super-admin/manage-card-template/${action}`, objectData, headers)
      .then(res => {
        if (res.data.status == 'success') {
        } else {
          console.log('An error occured!', res.data.status)
        }
      })
      .catch(error => console.log(error))
  },
  handleGetSubscribers: async (token, setSubscribers, setUpdateSubscriberLoading, handleCloseUpdateSubscriberModal) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .get(api_base + `/api/super-admin/subscriber-list`, headers)
      .then(res => {
        if (res.data.status == 'have data') {
          let susbcribers = res.data.data
          setSubscribers(susbcribers)

          if (setUpdateSubscriberLoading || handleCloseUpdateSubscriberModal) {
            setUpdateSubscriberLoading(false)
            handleCloseUpdateSubscriberModal()
          }
        } else {
          // alert(res.data.status);
          // console.log("An error occured!", res.data.status);
          if (setUpdateSubscriberLoading || handleCloseUpdateSubscriberModal) {
            setUpdateSubscriberLoading(false)
            handleCloseUpdateSubscriberModal()
          }
        }
      })
      .catch(error => console.log(error))
  },
  handleManageSubscriberData: async (
    token,
    data
    // setUpdateSubscriberLoading,
    // handleCloseUpdateSubscriberModal
  ) => {
    const req = {
      // fall_semester_start_date: data.fall_semester_start_date,
      // spring_semester_start_date: data.spring_semester_start_date,
      // next_fall_semester_start_date: data.next_fall_semester_start_date,
      // next_spring_semester_start_date: data.next_spring_semester_start_date,
      start_date: data.start_date,
      subscription_status: data.subscription_status,
    }
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .post(api_base + `/api/super-admin/manage-subscriber/${data.student_id}`, req, headers)
      .then(res => {
        if (res.data.status === 'success') {
          // setSubscribers(res.data.data);
          // setUpdateSubscriberLoading(false);
          // handleCloseUpdateSubscriberModal();
          // console.log(res.data);
        } else {
          alert(res.data.msg)
          // setUpdateSubscriberLoading(false);
          // handleCloseUpdateSubscriberModal();
          console.log('An error occured!', res.data.status)
        }
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleGetTwilioMsgs: async (token, setTwilioData) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .get(api_base + `/api/twilio-message`, headers)
      .then(res => {
        // console.log(res.data);
        setTwilioData(res.data)
      })
      .catch()
  },
  handleUpdateTwilioMsg: async (token, data) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    await axios
      .patch(api_base + `/api/twilio-message/${data.id}`, data, headers)
      .then(res => {
        // console.log(res);
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleAddDateToExistingCollege: async (token, semDate, collegeData) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + '/api/school-sem'
    semDate.coll_id = collegeData.id

    await axios
      .post(endpoint, semDate, headers)
      .then(res => {
        // console.log(res)
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleAddDateToCustomSchool: async (token, semDate, customSchoolId) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + '/api/custom-school-sem'
    semDate.coll_id = customSchoolId

    await axios
      .post(endpoint, semDate, headers)
      .then(res => {
        // console.log(res)
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleUpdateSemDetail: async (token, semDate) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + `/api/school-sem/${semDate.id}`

    await axios
      .patch(endpoint, semDate, headers)
      .then(res => {
        // console.log(res);
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleUpdateCustomSchoolSemDetail: async (token, semDate) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + `/api/custom-school-sem/${semDate.id}`

    await axios
      .patch(endpoint, semDate, headers)
      .then(res => {
        // console.log(res);
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleDeleteSemDetail: async (token, semDate) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + `/api/school-sem/${semDate.id}`

    await axios
      .delete(endpoint, headers)
      .then(res => {
        // console.log(res);
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  // handleDeleteCustomSchoolSemDetail: async (semDate) => {
  //   const endpoint = api_base + `/api/custom-school-sem/${semDate.id}`

  //   await axios.delete(endpoint).then((res) => {
  //     console.log(res)
  //   }).catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  // },
  getAllThemePacks: async (token, setThemePackTemplates) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + '/api/themepack'

    await axios
      .get(endpoint, headers)
      .then(res => {
        const themePacks = res.data
        // console.log(themePacks);
        setThemePackTemplates(themePacks)
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },

  handleAddThemePackTemplate: async (token, newThemePack) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + '/api/themepack'

    const request_body = newThemePack

    await axios
      .post(endpoint, request_body, headers)
      .then(res => {
        const themePacks = res.data
        // console.log(themePacks);
        // setThemePackTemplates(themePacks)
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },

  handleUpdateThemePackTemplate: async (token, templateData) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    const endpoint = api_base + `/api/themepack/${templateData.id}`

    const request_body = templateData

    await axios
      .patch(endpoint, request_body, headers)
      .then(res => {
        console.log(res)
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },

  handleGetSemDetails: async setSchoolSemDetails => {
    const endpoint = api_base + `/api/school-sem`

    await axios
      .get(endpoint)
      .then(res => {
        setSchoolSemDetails(res.data)
      })
      .catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))
  },
  handleGetCustomSchoolsSemDetails: async setCustomSchoolSemDetails => {
    const endpoint = api_base + `/api/custom-school-sem`

    // await axios.get(endpoint).then((res) => {
    //   setCustomSchoolsSemDetails(res.data)
    // }).catch(e => alert(`[Error ${e.response.status}] ${e.response.data.message}`))

    return new Promise((resolve, reject) => {
      setTimeout(function () {
        setCustomSchoolSemDetails([
          {
            id: '1',
            coll_id: '1',
            acad_year: '2020-2021',
            date_start: '2020-08-24',
            sem_type: 'Q1',
          },
          {
            id: '2',
            coll_id: '1',
            acad_year: '2021-2022',
            date_start: '2021-08-25',
            sem_type: 'Q2',
          },
        ])
        resolve('Success!')
      }, 500)
    })
  },
  // handleGetCustomSchools: async (setCustomSchools) => {
  //   const endpoint = api_base + `/api/get-custom-schools`

  //   // await axios.get(endpoint).then((res) => {
  //   //   setCustomSchools(res.data)
  //   // }).catch(e => alert(e.message))

  //   return new Promise((resolve, reject) => {
  //     setTimeout( function() {
  //       setCustomSchools([
  //         {
  //           id: "1",
  //           college_name: "QS University",
  //           student_id: "00001-2",
  //           qtr_or_sem: "Q"
  //         },
  //         {
  //           id: "2",
  //           college_name: "QS2 University",
  //           student_id: "00001-3",
  //           qtr_or_sem: "Q"
  //         }
  //       ])
  //       resolve("Success!")
  //     }, 500)
  //   })
  // },
  getCurrentCard: async (token, student_id, setCurrentCard) => {
    const api_url = api_base + '/api/thankster/get-current-card'

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    await axios
      .post(api_url, { student_id: student_id }, headers)
      .then(res => {
        setCurrentCard(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },

  getAffiliates: async token => {
    const api_url = api_base + '/api/affiliates'

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    const { data } = await axios.get(api_url, { ...headers })

    return data
  },

  addAffiliate: async (token, payload) => {
    const api_url = api_base + '/api/affiliates'

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    const { data } = await axios.post(api_url, payload, { ...headers })

    return data
  },

  deleteAffiliate: async (token, id) => {
    const api_url = api_base + '/api/affiliates/' + id

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    const { data } = await axios.delete(api_url, { ...headers })

    return data
  },

  updateAffiliate: async (token, id, payload) => {
    const api_url = api_base + '/api/affiliates/' + id

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    const { data } = await axios.put(api_url, payload, { ...headers })

    return data?.data
  },
}

const objectToCsv = jsonData => {
  const csvRows = []

  // get the headers
  const headers = Object.keys(jsonData[0])
  csvRows.push(headers.join(','))

  // loop over the rows
  for (const row of jsonData) {
    const values = headers.map(header => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"')
      return `"${escaped}"`
    })
    csvRows.push(values.join(','))
  }

  return csvRows.join('\n')
}

const download = (data, filename) => {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export default api
