import React, { useContext, useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";

import { Context } from "../context/Context";

import ConfirmModal from "../components/ConfirmModal";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { validateWhiteSpace } from "./util";
import api from "../api/api";
import AddCollegeDateModal from "./CollegeDates/AddCollegeDateModal";
import UpdateCollegeDateModal from "./CollegeDates/UpdateCollegeDateModal";
import StudentCollegeFieldGroup from "./NewComponents/StudentCollegeFieldGroup";

const UpdateSubscriberModal = ({ subscriber }) => {
  const {
    setUpdateSubscriberLoading,
    updateSubscriberLoading,
    showUpdateSubscriberModal,
    handleCloseUpdateSubscriberModal,
    setSubscribers,
    customSchoolId,
    handleCloseAddCollegeDateModal,
    currentSemDetails,
    setCurrentSemDetails,
    handleCloseUpdateCollegeDateModal,
    setCollegeDateProcessState,
    currentCollegeDateIndex,
    setCustomSchoolSemDetails,
    schools,
    tokenAdmin,
  } = useContext(Context);

  const handleUpdateSemDate = (updatedDate) => {
    let dates = [...currentSemDetails];
    dates[currentCollegeDateIndex] = updatedDate;
    setCollegeDateProcessState(true);
    api
      .handleUpdateCustomSchoolSemDetail(tokenAdmin, updatedDate)
      .then(() => {
        return api.handleGetCustomSchoolsSemDetails(setCustomSchoolSemDetails);
      })
      .then(() => {
        setCurrentSemDetails(dates);
        setCollegeDateProcessState(false);
        handleCloseUpdateCollegeDateModal();
      });
  };

  const handleAddDate = (newSemDate) => {
    api
      .handleAddDateToCustomSchool(tokenAdmin, newSemDate, customSchoolId)
      .then(() => {
        return api.handleGetCustomSchoolsSemDetails(setCustomSchoolSemDetails);
      })
      .then(() => {
        setCurrentSemDetails(currentSemDetails.concat(newSemDate));
        handleCloseAddCollegeDateModal();
      });
  };

  const [data, setData] = useState({
    email: "",
    first_name: "",
    id: "",
    is_custom_school: "", // update student var
    last_name: "",
    parent_id: "",
    school: "", // update student var
    start_date: "", // update student var
    student_id: "",
    subscription_status: "",
    subscription_type: "",

    //Novermber 14 2022 updates
    street: "",
    unit: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmIsBusy, setConfirmIsBusy] = useState(false);
  const [confirmModalElements, setConfirmModalElements] = useState({
    title: "",
    content: "",
    onConfirm: () => {},
    onConfirmText: "",
  });

  const [selectedSemesterDate, setSelectedSemesterDate] = React.useState(null);
  const [isAddingNewSchool, setIsAddingNewSchool] = React.useState(false);
  const [selectedSchool, setSelectedSchool] = React.useState("default");

  const handleOnChangeSchoolInfo = (e) => {
    if (e === null) return;
    let target = null;
    let value = null;

    if (e.target !== undefined) {
      target = e.target.name;
      value = e.target.value;
    }

    if (e.target === undefined) {
      // for custom school start date
      // console.log("START DATE")
      let selectedDate = e;
      let formatedDate =
        selectedDate.getMonth() +
        1 +
        "/" +
        selectedDate.getDate() +
        "/" +
        selectedDate.getFullYear();
      setSelectedSemesterDate(e);
      setData({
        ...data,
        ["start_date"]: formatedDate,
      });
    } else if (target === "school_dropdown") {
      // mainly for schools dropdown (existing schools)
      // console.log("HERE")
      setData({
        ...data,
        ["school"]: "",
        ["start_date"]: "",
      });

      if (value === "default") {
        setIsAddingNewSchool(false);
        setSelectedSchool("default");
      } else if (value === "add-new-school") {
        setSelectedSchool("add-new-school");
        setIsAddingNewSchool(true);
      } else {
        setData({
          ...data,
          ["school"]: value,
          ["start_date"]: "0",
        });
        setSelectedSchool(value);
        setIsAddingNewSchool(false);
      }
    } else {
      console.log(e.target);

      // other fields e.g custom school name
      setData({
        ...data,
        [target]: value,
      });
    }
  };

  const isCollegeSubscription = (type) =>
    ["q1", "q2", "q3", "q4", "fall", "spring"].includes(type);

  useEffect(() => {
    // console.log(subscriber)
    setData({
      email: subscriber.email,
      first_name: subscriber.first_name,
      id: subscriber.id,
      is_custom_school: subscriber.is_custom_school,
      last_name: subscriber.last_name,
      parent_id: subscriber.parent_id,
      school: subscriber.school,
      start_date: subscriber.start_date,
      student_id: subscriber.student_id,
      subscription_status: subscriber.subscription_status,
      subscription_type: subscriber.subscription_type,

      //Novermber 14 2022 updates
      street: subscriber.street,
      unit: subscriber.unit,
      city: subscriber.city,
      state: subscriber.state,
      zipcode: subscriber.zipcode,
      country: subscriber.country,
      company: subscriber.company,
    });

    const selectedDate = subscriber.start_date;
    const selectedSchool = subscriber.school;

    setSelectedSemesterDate(
      selectedDate && selectedDate != "0" ? new Date(selectedDate) : null
    );

    const schoolExists = schools.some(
      (school) => school.college_name == selectedSchool
    );

    if (schoolExists) {
      setIsAddingNewSchool(false);
      setSelectedSchool(selectedSchool);
    } else {
      setIsAddingNewSchool(true);
      setSelectedSchool("add-new-school");
    }
  }, [showUpdateSubscriberModal]);

  const handleOnChangeStatus = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      ["subscription_status"]: value,
    });
  };

  const updateSubscriberInfo = () => {
    setUpdateSubscriberLoading(true);
    setConfirmIsBusy(true);

    api
      .handleManageSubscriberData(tokenAdmin, data)
      .then(() => {
        let updateData = {
          id: data.id,
          school: data.school,
          first_day_of_semester: data.start_date,

          street: data.street,
          unit: data.unit,
          city: data.city,
          state: data.state,
          zipcode: data.zipcode,
          country: data.country,
          company: data.company
        };
        return api.handleUpdateStudent(tokenAdmin, updateData);
      })
      .then(() => {
        return api.handleGetSubscribers(
          tokenAdmin,
          setSubscribers,
          setUpdateSubscriberLoading,
          handleCloseUpdateSubscriberModal
        );
      })
      .then(() => {
        setCurrentSemDetails([]);
        setConfirmIsBusy(false);
        setShowConfirm(false);
      });
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    // console.log("FINAL DATA:::", data);

    if (
      (validateWhiteSpace(data.start_date) === -1 ||
        data.start_date === "" ||
        data.start_date === null) &&
      isAddingNewSchool &&
      isCollegeSubscription(data.subscription_type)
    ) {
      alert("First day of current semester is required");
    } else if (
      (validateWhiteSpace(data.school) === -1 ||
        data.school === "" ||
        data.school === null) &&
      isCollegeSubscription(data.subscription_type)
    ) {
      alert("School is required");
    } else {
      setConfirmModalElements({
        title: "Are you sure?",
        content: "Do you really want to update this subscriber?",
        onConfirm: () => {
          updateSubscriberInfo();
        },
        onConfirmText: "Update",
      });
      setShowConfirm(true);
    }
  };

  return (
    <Modal
      className="c2c-modal"
      show={showUpdateSubscriberModal}
      onHide={handleCloseUpdateSubscriberModal}
      centered
      backdrop={"static"}
    >
      <div
        className="btn-close-modal"
        onClick={handleCloseUpdateSubscriberModal}
      ></div>

      <div className="subscriber-form-holder">
        <h2 className="title t-h1 c-grayish-navy">{`${data.first_name} ${data.last_name}`}</h2>

        {data.subscription_type &&
          !data.subscription_type.toLowerCase().includes("theme") && (
            <div className="student-details-holder">
              <div className="form-group required">
                <h3 className="info-title t-h1 c-grayish-navy">
                  Subscriber College Info
                </h3>

                <StudentCollegeFieldGroup
                  data={data}
                  setData={setData}
                  handleOnChange={handleOnChangeSchoolInfo}
                  semesterDate={selectedSemesterDate}
                  selectedSchool={selectedSchool}
                  isAddingNewSchool={isAddingNewSchool}
                />
                {/* { */}
                {/* subscriber.is_custom_school === 'false' ? */}
                {/* <p>Can't update non custom school. Please use <Link smooth to="/manage-colleges" onClick={handleCloseUpdateSubscriberModal}>Manage Colleges</Link>.</p> : */}

                {/* <div className="input-wrapper">
                    <DatePicker
                      className="text-holder t-h1"
                      name="start_date"
                      onChange={handleOnChangeStartDate}
                      selected={startDate || ''}
                      placeholderText="Start Date"
                    />
                    <div className="input-subtext">Start Date</div>
                  </div> */}
                {/* } */}
              </div>

              <div className="address-holder-two">
                <div className="form-group">
                  <span className="title-holder">
                    <h3 className="info-title t-h1 c-grayish-navy">
                      {data.subscription_type != "theme"
                        ? "Student Address"
                        : "Address"}
                    </h3>
                    {/* <span className="label c-grayish-navy">
                Don't have their address yet? No problem. You can add it later.
              </span> */}
                  </span>

                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="street"
                      placeholder="Address 1"
                      value={data.street || ""}
                      onChange={handleOnChangeSchoolInfo}
                    />
                    <div className="input-subtext"></div>
                  </div>

                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="unit"
                      placeholder="Address 2"
                      value={data.unit || ""}
                      onChange={handleOnChangeSchoolInfo}
                    />
                    <div className="input-subtext"></div>
                  </div>

                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="city"
                      placeholder="City"
                      value={data.city || ""}
                      onChange={handleOnChangeSchoolInfo}
                    />
                    <div className="input-subtext"></div>
                  </div>

                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="state"
                      placeholder="State"
                      value={data.state || ""}
                      onChange={handleOnChangeSchoolInfo}
                    />
                    <div className="input-subtext"></div>
                  </div>

                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="zipcode"
                      placeholder="Zipcode"
                      value={data.zipcode || ""}
                      onChange={handleOnChangeSchoolInfo}
                    />
                    <div className="input-subtext"></div>
                  </div>

                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="company"
                      placeholder="Company"
                      value={data.company || ""}
                      onChange={handleOnChangeSchoolInfo}
                    />
                    <div className="input-subtext"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className="subscription-status-holder">
          <div className="form-group required">
            <h3 className="info-title t-h1 c-grayish-navy">
              Subscription Status
            </h3>
            <div className="input-wrapper" style={{ width: "100%" }}>
              <select
                name="subscription_status"
                className="text-holder t-h1"
                value={data.subscription_status}
                onChange={handleOnChangeStatus}
              >
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </select>
              <div className="input-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleCloseUpdateSubscriberModal()}
          disabled={updateSubscriberLoading}
        >
          <span>Cancel</span>
        </button>
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={updateSubscriberLoading}
        >
          {updateSubscriberLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {updateSubscriberLoading && <span>Please wait ...</span>}
          {!updateSubscriberLoading && <span>Update</span>}
        </button>
      </div>

      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={confirmModalElements.onConfirm}
        onCancel={() => setShowConfirm(false)}
        title={confirmModalElements.title}
        content={confirmModalElements.content}
        onCancelText={"Cancel"}
        onConfirmText={confirmModalElements.onConfirmText}
        isBusy={confirmIsBusy}
      />
      <AddCollegeDateModal handleAddDate={handleAddDate} />
      <UpdateCollegeDateModal handleUpdate={handleUpdateSemDate} />
    </Modal>
  );
};

export default UpdateSubscriberModal;
