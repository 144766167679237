import React, { useContext, useState, useEffect } from 'react'

import { Context } from '../context/Context'

import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import api from '../api/api'
import { validateWhiteSpace } from './util'
import api_base from '../api/api_base_url'

const UpdateTwilioMsgModal = ({ twilioData, twilioIsLoading, setTwilioIsLoading }) => {
  const {
    showUpdateTwilioModal,
    handleCloseUpdateTwilioModal,
    setTwilioMessages,
    tokenAdmin
  } = useContext(Context)

  const [data, setData] = useState({})

  useEffect(() => {
    setData({
      ...twilioData
    })
  }, [showUpdateTwilioModal])

  const handleOnChange = function (e) {
    const value = e.target.value
    setData({
      ...data,
      message_content: value
    })
  }

  const updateTwilioMsg = () => {
    setTwilioIsLoading(true)

    api.handleUpdateTwilioMsg(tokenAdmin, data).then(() => {
      api.handleGetTwilioMsgs(tokenAdmin, setTwilioMessages).then(() => {
        setTwilioIsLoading(false)
        handleCloseUpdateTwilioModal()
      })
    });

  }

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (
      validateWhiteSpace(data.message_content) === -1 ||
      data.message_content === ""
    ) {
      alert("Message content is required.");
    } else {
      updateTwilioMsg();
    }
  }

  return (
    <Modal
      className="c2c-modal"
      show={showUpdateTwilioModal}
      onHide={handleCloseUpdateTwilioModal}
      centered
      backdrop={'static'}
    >
      <div
        className="btn-close-modal"
        onClick={handleCloseUpdateTwilioModal}
      ></div>

      <h2 className="title t-h1 c-grayish-navy">Update Twilio Message - {data.message_code}</h2>

      <div className="form-group">
        <div className="twilio-message-info-holder">
          <div className="form-group">
            <div className="title-holder">
              <h3 className="info-title t-h1 c-grayish-navy">Message Info</h3>
            </div>
            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                placeholder="ID"
                value={data.id || ''}
                disabled
              />
              <div className="input-subtext">ID</div>
            </div>
            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                placeholder="Message Code"
                value={data.message_code || ''}
                disabled
              />
              <div className="input-subtext">Message Code</div>
            </div>
          </div>
        </div>
        <div className="twilio-message-content-holder">
          <div className="form-group required">
            <label className="info-title t-h1 c-grayish-navy">Message Content</label>
            <textarea
              className="message-box"
              name="message_content"
              value={data.message_content || ''}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className="twilio-message-desc-holder">
          <div className="form-group">
            <label className="info-title t-h1 c-grayish-navy">Message Description</label>
            <textarea
              className="message-box"
              name="message_desc"
              value={data.message_desc || ''}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleCloseUpdateTwilioModal()}
          disabled={twilioIsLoading}
        >
          <span>Cancel</span>
        </button>
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={twilioIsLoading}
        >
          {twilioIsLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {twilioIsLoading && <span>Please wait ...</span>}
          {!twilioIsLoading && <span>Update</span>}
        </button>
      </div>
    </Modal>
  )
}

export default UpdateTwilioMsgModal
