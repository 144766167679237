import React, { useContext } from 'react';

import { Context } from '../context/Context';

import Modal from 'react-bootstrap/Modal';
import Wizard from '../components/Wizard';
import Step from '../components/Step';
import StepOne from '../components/ContactStepOne';
import StepTwo from '../components/ContactStepTwo';
import StepThree from '../components/ContactStepThree';
import ContactStepSubscription from '../components/ContactStepSubscription';
import StepFour from '../components/ContactStepFour';
import api from '../api/api';

const ModalContainer = () => {
  const {
    show,
    handleClose,
    plans,
    defaultPlans,
    setEmailExists,
    setStudentEmailExists,
    setSuccess,
    data,
    handleOnChange,
    handleAddStudent,
    handlePhoneChange,
    handleOnChangeSchool,
    isAddingNewSchool,
    selectedSemesterDate,
    schools,
    handleOnSubscriptionChange,
    handleDeleteSubscription,
    couponVerificationLoading,
    setCouponVerificationLoading,
  } = useContext(Context);

  return (
    <Modal className='c2c-modal' show={show} backdrop='static' onHide={handleClose} centered>
      <div className='btn-close-modal' onClick={handleClose}></div>
      <div className='logo-holder'>
        <img src={require('../assets/images/logo.png')} className='logo-img' alt='giftsandtalents' />
      </div>
      <Wizard handleAddStudent={handleAddStudent}>
        <Step name='Your Information' value='1'>
          <div>
            <StepOne
              data={data}
              handleOnChange={handleOnChange}
              handlePhoneChange={handlePhoneChange}
              validateEmail={(e) => {
                handleOnChange(e);
                api.validateEmail({ email: e.target.value }, setEmailExists);
              }}
            />
          </div>
        </Step>
        <Step name='Student Information' value='2'>
          <div>
            <StepTwo
              data={data}
              handleOnChange={handleOnChange}
              handleOnChangeSchool={handleOnChangeSchool}
              isAddingNewSchool={isAddingNewSchool}
              selectedSemesterDate={selectedSemesterDate}
              schools={schools}
              validateEmail={(e) => {
                handleOnChange(e);
                api.studentValidateEmail({ email: e.target.value }, setStudentEmailExists);
              }}
            />
          </div>
        </Step>
        <Step name='Custom Message' value='3'>
          <div>
            <StepThree data={data} handleOnChange={handleOnChange} />
          </div>
        </Step>
        <Step name='Subscription Details' value='4'>
          <div>
            <ContactStepSubscription
              data={data}
              plans={defaultPlans}
              select={data.plan_selected}
              handleOnChange={handleOnChange}
            />
          </div>
        </Step>
        <Step name='Payment' value='5'>
          <div>
            <StepFour
              data={data}
              handleOnChange={handleOnChange}
              select={data.plan_selected}
              plans={defaultPlans}
              handleOnSubscriptionChange={handleOnSubscriptionChange}
              handleDeleteSubscription={handleDeleteSubscription}
              couponVerificationLoading={couponVerificationLoading}
              setCouponVerificationLoading={setCouponVerificationLoading}
            />
          </div>
        </Step>
      </Wizard>
    </Modal>
  );
};

export default ModalContainer;
