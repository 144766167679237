import React, { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Spinner from "react-bootstrap/Spinner";

import CardPlan from "../components/CardPlan";
import { Context } from "../context/Context";

// const cardInfo =
// {
//   hasImages: true,
//   planTitle: "Weekly Card + Monthly Gift + Weekly Gift Card",
//   btnText: 'Get Started',
//   doesFlip: true,
//   planImgs: [
//     {
//       planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png")
//     },
//     {
//       planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
//     },
//     {
//       planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png")
//     },
//     {
//       planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
//     },
//     {
//       planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png")
//     }
//   ],
// }

const cardInfo = {
  hasImages: false,
  planTitle: "Cancelled",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png"),
    },
  ],
};

const WeeklyCardInfo = {
  hasImages: true,
  planTitle: "Weekly Card",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
  ],
};

const WeeklyCardPlusWeeklyGiftCardInfo = {
  hasImages: true,
  planTitle: "Weekly Card + Weekly Gift Card",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png"),
    },
  ],
};

const WeeklyCardPlusMonthlyGift = {
  hasImages: true,
  planTitle: "Weekly Card + Monthly Gift",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png"),
    },
  ],
};

const WeeklyCardPlusMonthlyGiftPlusWeeklyGiftCardInfo = {
  hasImages: true,
  planTitle: "Weekly Card + Monthly Gift + Weekly Gift Card",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png"),
    },
    {
      planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png"),
    },
  ],
};

// THEME PACK CARD INFO
const ThemePackFaithCardInfo = {
  hasImages: true,
  planTitle: "Theme Pack Faith",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png")
    // }
  ],
};

const ThemePackAffirmationCardInfo = {
  hasImages: true,
  planTitle: "Theme Pack Affirmation",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png")
    // }
  ],
};

const ThemePackThinkingOfYouCardInfo = {
  hasImages: true,
  planTitle: "Theme Pack Thinking of You",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png")
    // }
  ],
};

const ThemePackEncouragementCardInfo = {
  hasImages: true,
  planTitle: "Theme Pack Encouragement",
  btnText: "Get Started",
  doesFlip: true,
  planImgs: [
    {
      planImg: require("../assets/images/Choose Your Plan Card Icon@2x.png"),
    },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Present Icon@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Plus Sign@2x.png")
    // },
    // {
    //   planImg: require("../assets/images/Choose Your Plan Gift Card Icon@2x.png")
    // }
  ],
};
// END THEME PACK CARD INFO

function DisplayCardPlan(props) {
  const plan = props.plan;
  const dayOfWeek = props.dayOfWeek;

  if (props.subscriptionStatus == 0) {
    return <CardPlan cardInfo={cardInfo} />;
  }

  if (plan === "Weekly Card") {
    return <CardPlan cardInfo={WeeklyCardInfo} />;
  } else if (plan === "Weekly Card + Weekly Gift Card") {
    return <CardPlan cardInfo={WeeklyCardPlusWeeklyGiftCardInfo} />;
  } else if (plan === "Weekly Card + Monthly Gift") {
    return <CardPlan cardInfo={WeeklyCardPlusMonthlyGift} />;
  } else if (plan === "Weekly Card + Monthly Gift + Weekly Gift Card") {
    return <CardPlan cardInfo={WeeklyCardPlusMonthlyGiftPlusWeeklyGiftCardInfo} />;
  }
  // FOR THEME PACK SUBSCRIPTIONS
  else if (plan === "Theme Pack Faith") {
    return <CardPlan cardInfo={ThemePackFaithCardInfo} dayOfWeek={dayOfWeek} />;
  } else if (plan === "Theme Pack Affirmation") {
    return <CardPlan cardInfo={ThemePackAffirmationCardInfo} dayOfWeek={dayOfWeek} />;
  } else if (plan === "Theme Pack Thinking of You") {
    return <CardPlan cardInfo={ThemePackThinkingOfYouCardInfo} dayOfWeek={dayOfWeek} />;
  } else if (plan === "Theme Pack Encouragement") {
    return <CardPlan cardInfo={ThemePackEncouragementCardInfo} dayOfWeek={dayOfWeek} />;
  }
  // END THEME PACK SUBSCRIPTIONS
  else {
    return <CardPlan cardInfo={cardInfo} />;
  }
}

const GoToCardsSent = () => {
  window.location.hash = "#/cards-sent";
};

const QuickAction = ({ actionInfo, selectedStudent }) => {
  const { lastWeekCard, handleShowPlanModal, handleShowAddressModal, userDataLoaded, studentDataLoaded } =
    useContext(Context);
  // console.log("QUICK ACTION SECTION[TEMP]:::", selectedStudent);
  return (
    <div className="action-card-holder">
      {actionInfo.hasSubscribed ? (
        <div className={`details-wrapper${!actionInfo.hasBtn ? " details-wrapper--no-btn" : ""}`}>
          <div className="c-teal card-h card-h-1">{actionInfo.cardTitle}</div>
          {selectedStudent.plan_selected ? (
            <DisplayCardPlan
              plan={selectedStudent.plan_selected}
              subscriptionStatus={selectedStudent.subscription_status}
              dayOfWeek={selectedStudent.day_of_week}
            />
          ) : (
            <div className="c-purple">
              <Spinner animation="border" role="status" aria-hidden="true" />
            </div>
          )}
          {/* <CardPlan cardInfo={cardInfo} /> */}
          {actionInfo.hasBtn != undefined && actionInfo.hasBtn && (
            <button
              className="btn-teal c-white card-h card-h-3"
              onClick={handleShowPlanModal}
              disabled={!studentDataLoaded}
              id={actionInfo.id}
            >
              {actionInfo.btnText}
            </button>
          )}
        </div>
      ) : (
        ""
      )}
      {actionInfo.hasStudentInfo ? (
        <div className="details-wrapper">
          <div className="c-teal card-h-1 card-h ">{actionInfo.cardTitle}</div>
          {selectedStudent.first_name ? (
            <div className="student-detail-wrapper">
              <div className="c-purple t-h3 student-name">
                {selectedStudent.first_name + " " + selectedStudent.last_name}
              </div>
              <div className="c-teal card-h-2 card-h">Mailing Address</div>
              {/* {selectedStudent.subscription_type !== "theme" && (
                <div className="c-purple student-details">{selectedStudent.school}</div>
              )} */}
              {selectedStudent.company && (<div className="c-purple student-details">
                {selectedStudent.company ? selectedStudent.company : ""}
              </div>)}
              {selectedStudent.street && (<div className="c-purple student-details">
                {selectedStudent.street ? selectedStudent.street : ""}
              </div>)}
              {selectedStudent.unit && (<div className="c-purple student-details">
                {selectedStudent.unit ? selectedStudent.unit : " "}
              </div>)}
              {(selectedStudent.city || selectedStudent.state || selectedStudent.zipcode) && (<div className="c-purple student-details">
                {selectedStudent.city ? selectedStudent.city + ", " : " "}
                {selectedStudent.state ? selectedStudent.state + " " : " "}
                {selectedStudent.zipcode ? selectedStudent.zipcode : ""}
              </div>)}
            </div>
          ) : (
            <div className="student-detail-wrapper">
              <div className="c-purple">
                <Spinner animation="border" role="status" aria-hidden="true" />
              </div>
              <div className="c-teal card-h-2 card-h">Mailing Address</div>
              <div className="c-purple">
                <Spinner animation="border" role="status" aria-hidden="true" />
              </div>
            </div>
          )}
          <button
            className="btn-teal c-white card-h card-h-3"
            onClick={handleShowAddressModal}
            disabled={!studentDataLoaded}
            id={actionInfo.id}
          >
            {actionInfo.btnText}
          </button>
        </div>
      ) : (
        ""
      )}
      {actionInfo.hasLastWeekCard ? (
        <div className="details-wrapper">
          <div className="c-teal card-h card-h-1">{actionInfo.cardTitle}</div>
          {lastWeekCard && (
            <div className="card-img-holder">
              {/* <img src={lastWeekCard.front} alt="Cover" /> */}
              {selectedStudent.subscription_type !== "theme" ? (
                <img src={`${lastWeekCard.front}?${new Date().getTime()}`} alt="Cover" />
              ) : (
                <img src={lastWeekCard.front} alt="Cover" />
              )}
            </div>
          )}
          {lastWeekCard && <div className="card-label t-h3">Cover</div>}
          {!lastWeekCard && <div className="t-h3">Sorry, no preview available.</div>}
          <button className="btn-teal c-white card-h card-h-3" onClick={GoToCardsSent} disabled={!studentDataLoaded} id={actionInfo.id}> 
            {actionInfo.btnText}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default QuickAction;
