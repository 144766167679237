import React, { useContext, Fragment } from 'react';

import { Context } from '../../context/Context';

import Modal from 'react-bootstrap/Modal';

const PaymentSuccessModal = () => {
  const { showPaymentSuccessModal, handleClosePaymentSuccessModal, yourMessageButtonRef, handleShowEditMessageModal } =
    useContext(Context);

  const handleSetEditMessage = () => {
    handleClosePaymentSuccessModal();
    yourMessageButtonRef.current.click();
    handleShowEditMessageModal();
  };

  return (
    <Modal
      className='c2c-modal confirm-modal payment-success-modal'
      show={showPaymentSuccessModal}
      onHide={handleClosePaymentSuccessModal}
      centered
      backdrop='static'
    >
      <div className='btn-close-modal' onClick={handleClosePaymentSuccessModal}></div>
      <h2 className=' title t-h1 c-purple newsletter-title'>Welcome to Cards2College!</h2>
      <p className='newsletter-content'>Your purchase has been confirmed. Welcome to your dashboard!</p>
      <p className='newsletter-content'>
        {'Thank you for partnering with us to spread kindness & encouragement one weekly card at a time!'}
      </p>
      <p className='newsletter-content'>
        {'Please take a moment to '}
        <b onClick={handleSetEditMessage} className='c-teal' style={{ cursor: 'pointer' }}>
          {'set your greeting and sign off '}{' '}
        </b>
        {'for your student’s cards as well as add a default message.'}
      </p>
      <p className='newsletter-content'>{'"Alone we can do so little, together, we can do so much." ~ Helen Keller'}</p>
    </Modal>
  );
};

export default PaymentSuccessModal;
