import React, { useState, useContext } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Context } from '../context/Context';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const ContactStepOne = ({ data, handleOnChange, handlePhoneChange, validateEmail }) => {
  const [value, setValue] = useState();
  const { formErrors, emailexists } = useContext(Context);

  const popover = (
    <Popover className='modal-hint-popover' id='popover-basic'>
      <Popover.Title as='h3'>Password</Popover.Title>
      <Popover.Content>
        Password must be <strong>8 characters or longer</strong> & include <strong>uppercase</strong>,{' '}
        <strong>lowercase</strong>, and <strong>number</strong>, special characters optional.
      </Popover.Content>
    </Popover>
  );

  return (
    <div className='step-one purple-border'>
      <h2 className='title t-h1' style={{ color: '#6E4C96' }}>
        Tell Us About You
      </h2>
      <div className='about-you-holder'>
        <div className='contact-holder'>
          <div className='form-group required'>
            <h3 className='info-title t-h1 c-grayish-navy'>Contact Details</h3>
            <div className='input-wrapper'>
              <input
                className={`text-holder t-h1 required`}
                type='text'
                name='first_name'
                placeholder='First Name'
                onChange={handleOnChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='last_name'
                placeholder='Last Name'
                onChange={handleOnChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className={`text-holder t-h1`}
                type='email'
                name='email'
                placeholder='Email Address'
                onChange={validateEmail}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <PhoneInput
                className='text-holder t-h1 react-phone'
                placeholder='Mobile Phone Number'
                name='cell_phone'
                defaultCountry='US'
                value={formatPhoneNumberIntl(value)}
                onChange={handlePhoneChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                id='password'
                className='text-holder t-h1'
                type='password'
                name='password'
                placeholder='Password'
                onChange={handleOnChange}
              />
              <div className='input-subtext with-icon'>
                <span className='input-helper'>e.g. C2cccccc</span>
                <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={popover}>
                  <span className='input-helper-icon fas fa-question-circle'></span>
                </OverlayTrigger>
              </div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='password'
                name='confirmPassword'
                placeholder='Confirm Password'
                onChange={handleOnChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                type='checkbox'
                id='sms'
                name='sms'
                onChange={handleOnChange}
                checked={data.sms}
                disabled
              />
              <label htmlFor='sms' className='text-holder check-holder t-h3 purple'>
                I agree to receive weekly text reminders.
              </label>
              <div className='input-subtext'></div>
            </div>
          </div>
        </div>
        <div className='address-holder'>
          <div className='form-group required'>
            <h3 className='info-title t-h1 c-grayish-navy'>Your Mailing Address</h3>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='street'
                placeholder='Street Address'
                onChange={handleOnChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='unit'
                placeholder='Apt/Suite/Unit,Building'
                onChange={handleOnChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input className='text-holder t-h1' type='text' name='city' placeholder='City' onChange={handleOnChange} />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input className='text-holder t-h1' type='text' name='state' placeholder='State' onChange={handleOnChange} />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input className='text-holder t-h1' type='text' name='zipcode' placeholder='Zipcode' onChange={handleOnChange} />
              <div className='input-subtext'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactStepOne;