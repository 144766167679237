import React, { useRef, useState, useEffect } from "react";

const CardImagesForm = ({ data, handleOnChange, title }) => {
  const coverImageInput = useRef(null);
  const messageImageInput = useRef(null);
  const [coverImageClicked, setCoverImageClicked] = useState(false);
  const [messageImageClicked, setMessageImageClicked] = useState(false);

  const orderRef = useRef(null)

  const handleClickChange = (event) => {
    const id = event.target.dataset.imageBtn;

    if (id === "coverImageBtn") {
      coverImageInput.current.click();
      setCoverImageClicked(true);
    } else if (id === "messageImageBtn") {
      messageImageInput.current.click();
      setMessageImageClicked(true);
    } else {
      alert("No id found");
    }
  };

  useEffect(() => {
    orderRef.current.value = data.order
  }, [data])

  return (
    <>
      <h2 className="title t-h1 c-grayish-navy">{title}</h2>
      <div className="template-info-holder">
        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Template Info</h3>
          </span>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="order_"
              placeholder="Order"
              readOnly
              disabled
              ref={orderRef}
            />
            <div className="input-subtext">Order</div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="template_id"
              placeholder="Template ID"
              value={data.template_id || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template ID</div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="template_name"
              placeholder="Template Name"
              value={data.template_name || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template Name</div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="quarter_order"
              placeholder="Quarter Order"
              value={data.quarter_order || ""}
              onChange={handleOnChange}
              maxLength="3"
            />
            <div className="input-subtext">Quarter Order</div>
          </div>
        </div>
      </div>
      <div className="template-images-wrapper">
        <div className="cover-image-holder">
          <div className="form-group required">
            <h3 className="info-title t-h1 c-grayish-navy">Front Card Cover</h3>
            <input
              className="text-holder t-h1"
              type="file"
              name="cover_image"
              accept="image/png"
              ref={coverImageInput}
              onChange={handleOnChange}
              hidden
            />
            <div className="image-wrapper">
              <img
                src={
                  coverImageClicked
                    ? data.cover_image_src
                    : data.cover_image_src ? data.cover_image_src + `?${new Date().getTime()}` : null
                }
              />
              <button
                data-image-btn="coverImageBtn"
                className="btn-image"
                onClick={handleClickChange}
              >
                {data.cover_image_src ? "Change Photo" : "Add Photo"}
              </button>
            </div>
          </div>
        </div>
        <div className="message-image-holder">
          <div className="form-group required">
            <h3 className="info-title t-h1 c-grayish-navy">Inside 1 Message</h3>
            <input
              className="text-holder t-h1"
              type="file"
              name="message_image"
              accept="image/png"
              ref={messageImageInput}
              onChange={handleOnChange}
              hidden
            />
            <div className="image-wrapper">
              <img
                src={
                  messageImageClicked
                    ? data.message_image_src
                    : data.message_image_src ? data.message_image_src + `?${new Date().getTime()}` : null
                }
              />
              <button
                data-image-btn="messageImageBtn"
                className="btn-image"
                onClick={handleClickChange}
              >
                {data.message_image_src ? "Change Photo" : "Add Photo"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardImagesForm;
