import React from 'react';

import C2CModal from '../NewComponents/C2CModal';
import { Context } from '../../context/Context';
import CollegeDateForm from './CollegeDateForm';

const AddCollegeDateModal = ({ handleAddDate }) => {
  const { addCollegeDateModalState, handleCloseAddCollegeDateModal, handleShowAddCollegeDateModal, collegeData } =
    React.useContext(Context);

  React.useEffect(() => {
    // console.log(collegeData)
  }, [handleShowAddCollegeDateModal]);

  return (
    <C2CModal
      show={addCollegeDateModalState}
      onHide={handleCloseAddCollegeDateModal}
      title={`Add New Date`}
      customClass={'confirm-modal college-date-modal'}
    >
      <CollegeDateForm onSave={handleAddDate} saveBtnText={'Add'} onCancel={handleCloseAddCollegeDateModal} />
    </C2CModal>
  );
};

export default AddCollegeDateModal;
