import React, { useContext, useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import DataTable from 'react-data-table-component'
import Spinner from 'react-bootstrap/Spinner'

import { Context } from '../context/Context'
import api from '../api/api'
import api_base from '../api/api_base_url'

import UpdateTemplateModal from '../components/UpdateTemplateModal'
import AddTemplateModal from '../components/AddTemplateModal'
import UpdateThemePackTemplateModal from './TemplateUploader/UpdateThemePackTemplateModal'
import AddThemePackTemplateModal from './TemplateUploader/AddThemePackTemplateModal'
import AddAffiliateModal from './AddAffiliateModal'

const AdminManageTemplates = () => {
  const {
    tokenAdmin,
    templates,
    setTemplates,
    themePackTemplates,
    setThemePackTemplates,
    handleShowUpdateTemplateModal,
    handleShowAddTemplateModal,
    handleCloseLoaderModal,
    currentTemplateCat,
    setCurrentTemplateCat,
    handleShowUpdateThemePackTemplateModal,
    handleShowAddThemePackTemplateModal,
    setCurrentThemePackTemplate,
  } = useContext(Context)

  const [templateIsLoading, setTemplateIsLoading] = useState(true)

  const [templateData, setTemplateData] = useState({})

  useEffect(() => {
    if (tokenAdmin) {
      api.handleGetTemplateData(tokenAdmin, setTemplates).then(() => {
        handleCloseLoaderModal()
        setTemplateIsLoading(false)
      })
    }
  }, [setTemplates, tokenAdmin])

  const themeTemplateColumns = [
    {
      name: 'Theme Name',
      selector: 'theme_name',
      sortable: true,
    },
    {
      name: 'Stripe Theme Code',
      selector: 'theme_code',
      sortable: false,
    },
    {
      name: '[1st] Front Card Cover',
      selector: 'front_image',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.front_image} />
        </div>
      ),
    },
    {
      name: '[1st] Inside 1 Message',
      selector: 'inside_message',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.inside_message} />
        </div>
      ),
    },
    {
      name: '[2nd] Front Card Cover',
      selector: 'front_image2',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.front_image2} />
        </div>
      ),
    },
    {
      name: '[2nd] Inside 1 Message',
      selector: 'inside_message2',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.inside_message2} />
        </div>
      ),
    },
    {
      name: '[3rd] Front Card Cover',
      selector: 'front_image3',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.front_image3} />
        </div>
      ),
    },
    {
      name: '[3rd] Inside 1 Message',
      selector: 'inside_message3',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.inside_message3} />
        </div>
      ),
    },
    {
      name: '[4th] Front Card Cover',
      selector: 'front_image4',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.front_image4} />
        </div>
      ),
    },
    {
      name: '[4th] Inside 1 Message',
      selector: 'inside_message4',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={row.inside_message4} />
        </div>
      ),
    },
  ]

  const collegeTemplateColumns = [
    {
      name: 'Order',
      selector: 'order',
      sortable: true,
    },
    {
      name: 'Template Name',
      selector: 'template_name',
      sortable: false,
    },
    {
      name: 'Front Card Cover',
      selector: 'template_id',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={`${api_base}/storage/templates/${row.template_id}.png?${new Date().getTime()}`} />
        </div>
      ),
      ignoreRowClick: false,
    },
    {
      name: 'Inside 1 Message',
      selector: 'message_1',
      sortable: false,
      cell: row => (
        <div className="template-image-holder">
          <img src={`${api_base}/storage/messages/${row.message_1}.png?${new Date().getTime()}`} />
        </div>
      ),
      ignoreRowClick: false,
    },
  ]

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        width: '100%',
        maxWidth: '303px',
        // minWidth: '200px',
      },
    },
  }

  const handleOnRowClicked = (row, event) => {
    setTemplateData(row)
    if (currentTemplateCat === 'template_theme') {
      setCurrentThemePackTemplate(row)
      handleShowUpdateThemePackTemplateModal()
    } else {
      handleShowUpdateTemplateModal()
    }
  }

  // const handleSortTemplates = (rows, field, direction) => {
  //   return rows.sort((a, b) => {
  //     const templateA = parseInt(a.order);
  //     const templateB = parseInt(b.order);

  //     let comparison = 0;
  //     if (templateA > templateB) {
  //       comparison = 1;
  //     } else if (templateA < templateB) {
  //       comparison = -1;
  //     }
  //     return comparison;
  //   });
  // };

  const onChangeTemplateCategory = e => {
    const target = e.target
    const name = target.name
    const value = target.value

    setCurrentTemplateCat(value)
    setTemplateIsLoading(true)
    if (value === 'template_college') {
      api.handleGetTemplateData(tokenAdmin, setTemplates).then(() => {
        setTemplateIsLoading(false)
      })
    } else {
      api.getAllThemePacks(tokenAdmin, setThemePackTemplates).then(() => {
        setTemplateIsLoading(false)
      })
    }
  }

  return (
    <div className="dashboard-body">
      <div className="container dash-container">
        <div className="section-heading-holder">
          <div className="c-teal section-title">Manage Card Templates</div>
          <button
            className="action-btn"
            onClick={() => {
              if (currentTemplateCat === 'template_theme') {
                handleShowAddThemePackTemplateModal()
              } else {
                handleShowAddTemplateModal()
              }
            }}>
            Add Template
          </button>
        </div>

        <div className="template-ctrlr">
          <h3 className="template-ctrlr__title">Template Category:</h3>
          <div className="template-ctrlr__options">
            <input
              type="radio"
              id="template_college"
              name="active_template"
              value="template_college"
              defaultChecked={true}
              onChange={onChangeTemplateCategory}
            />
            <label htmlFor="template_college" className="text-holder check-holder t-h3 purple">
              College
            </label>
            <input
              type="radio"
              id="template_theme"
              name="active_template"
              value="template_theme"
              defaultChecked={false}
              onChange={onChangeTemplateCategory}
            />
            <label htmlFor="template_theme" className="text-holder check-holder t-h3 purple">
              Theme Pack
            </label>
          </div>
        </div>

        <div className="table-holder templates-table">
          <DataTable
            columns={currentTemplateCat === 'template_college' ? collegeTemplateColumns : themeTemplateColumns}
            data={currentTemplateCat === 'template_college' ? templates : themePackTemplates}
            defaultSortField={currentTemplateCat === 'template_college' ? 'order' : 'theme_name'}
            pagination={true}
            customStyles={customStyles}
            highlightOnHover={true}
            pointerOnHover={false}
            noHeader={true}
            selectableRowsHighlight={true}
            progressPending={templateIsLoading}
            progressComponent={<Spinner animation="border" role="status" aria-hidden="true" />}
            onRowClicked={handleOnRowClicked}
            responsive={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 8, 16, 32]}
            // sortFunction={handleSortTemplates}
          />
        </div>
      </div>
      <UpdateTemplateModal template={templateData} />
      <UpdateThemePackTemplateModal />
      <AddThemePackTemplateModal />
      <AddTemplateModal />
    </div>
  )
}

export default AdminManageTemplates
