import React from 'react';
import CollegeDates from './CollegeDates/CollegeDates';
import { Context } from '../context/Context';
import api from '../api/api';
import AddCollegeDateModal from './CollegeDates/AddCollegeDateModal';
import UpdateCollegeDateModal from './CollegeDates/UpdateCollegeDateModal';

const CollegeForm = ({ data, handleOnChange, title }) => {
  const {
    tokenAdmin,
    handleShowAddCollegeDateModal,
    currentSemDetails,
    setCurrentSemDetails,
    handleShowUpdateCollegeDateModal,
    setSchoolSemDetails,
    setCurrentCollegeDate,
    setCurrentCollegeDateIndex,
    collegeCurrentProcess,
    handleShowLoaderModal,
    handleCloseLoaderModal,
    currentCollegeDateIndex,
    handleCloseUpdateCollegeDateModal,
    setCollegeDateProcessState,
    collegeData,
    handleCloseAddCollegeDateModal,
  } = React.useContext(Context);

  const handleUpdate = (idx) => {
    setCurrentCollegeDate(currentSemDetails[idx]);
    setCurrentCollegeDateIndex(idx);
    handleShowUpdateCollegeDateModal();
  };

  const handleDelete = (idx) => {
    let dates = [...currentSemDetails];

    if (dates.length > 1) {
      if (collegeCurrentProcess === 'add') {
        dates.splice(idx, 1);
        setCurrentSemDetails(dates);
      } else {
        const shouldDelete = window.confirm('Do you really want to delete this date?');
        if (shouldDelete) {
          handleShowLoaderModal();
          api
            .handleDeleteSemDetail(tokenAdmin, dates[idx])
            .then(() => {
              return api.handleGetSemDetails(setSchoolSemDetails);
            })
            .then(() => {
              dates.splice(idx, 1);
              setCurrentSemDetails(dates);
              handleCloseLoaderModal();
            });
        }
      }
    } else {
      alert('Action terminated. Dates should not be empty.');
    }
  };

  const handleUpdateSemDate = (updatedDate) => {
    let dates = [...currentSemDetails];
    dates[currentCollegeDateIndex] = updatedDate;
    if (collegeCurrentProcess === 'add') {
      setCurrentSemDetails(dates);
      handleCloseUpdateCollegeDateModal();
    } else {
      setCollegeDateProcessState(true);
      api
        .handleUpdateSemDetail(tokenAdmin, updatedDate)
        .then(() => {
          return api.handleGetSemDetails(setSchoolSemDetails);
        })
        .then(() => {
          setCurrentSemDetails(dates);
          setCollegeDateProcessState(false);
          handleCloseUpdateCollegeDateModal();
        });
    }
  };

  const handleAddDate = (newSemDate) => {
    // console.log(newSemDate)
    setCollegeDateProcessState(true);
    if (collegeCurrentProcess === 'add') {
      setCurrentSemDetails(currentSemDetails.concat(newSemDate));
      handleCloseAddCollegeDateModal();
    } else {
      api
        .handleAddDateToExistingCollege(tokenAdmin, newSemDate, collegeData)
        .then(() => {
          return api.handleGetSemDetails(setSchoolSemDetails);
        })
        .then(() => {
          setCurrentSemDetails(currentSemDetails.concat(newSemDate));
          handleCloseAddCollegeDateModal();
        });
    }
  };

  return (
    <React.Fragment>
      <div className='college-form-holder'>
        <h2 className='title t-h1 c-grayish-navy'>{title}</h2>
        <div className='college-name-holder'>
          <div className='form-group required'>
            <h3 className='info-title t-h1 c-grayish-navy'>College Name</h3>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='college_name'
                value={data.college_name || ''}
                onChange={handleOnChange}
                placeholder='College Name'
              />
              <div className='input-subtext'>College Name</div>
            </div>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='nickname'
                value={data.nickname || ''}
                onChange={handleOnChange}
                placeholder='College Nickname'
              />
              <div className='input-subtext'>College Nickname</div>
            </div>
          </div>
        </div>
        <div className='college-address-holder'>
          <div className='form-group required'>
            <h3 className='info-title t-h1 c-grayish-navy'>College Address</h3>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='city'
                value={data.city || ''}
                onChange={handleOnChange}
                placeholder='City'
              />
              <div className='input-subtext'>City</div>
            </div>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='state'
                value={data.state || ''}
                onChange={handleOnChange}
                placeholder='State'
              />
              <div className='input-subtext'>State</div>
            </div>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='zipcode'
                value={data.zipcode || ''}
                onChange={handleOnChange}
                placeholder='Zip Code'
              />
              <div className='input-subtext'>Zip Code</div>
            </div>
          </div>
        </div>
        <div className='college-links-holder'>
          <div className='form-group'>
            <h3 className='info-title t-h1 c-grayish-navy'>Resources Links</h3>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='website_link'
                value={data.website_link || ''}
                onChange={handleOnChange}
                placeholder='Website Link'
              />
              <div className='input-subtext'>Website Link</div>
            </div>
            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='mental_health_link'
                value={data.mental_health_link || ''}
                onChange={handleOnChange}
                placeholder='Mental Health Link'
              />
              <div className='input-subtext'>Mental Health Link</div>
            </div>
          </div>
        </div>
        <div className='college-dates-holder'>
          <div className='form-group required'>
            <div className='form-title form-title--with-btn'>
              <h3 className='info-title t-h1 c-grayish-navy'>College Dates</h3>
              <button className='action-btn' onClick={handleShowAddCollegeDateModal}>
                Add Date
              </button>
            </div>

            <CollegeDates handleDelete={handleDelete} handleUpdate={handleUpdate} />

            {/* <div className="input-wrapper">
              <DatePicker
                className="text-holder t-h1"
                name="fall_semester_start_date"
                onChange={(e) => handleOnChange(e, 'fall_semester_start_date')}
                selected={schoolDates.selectedFallStartDate || ''}
                placeholderText="Fall Start Date"
              />
              <div className="input-subtext"></div>
            </div>
            <div className="input-wrapper">
              <DatePicker
                className="text-holder t-h1"
                name="spring_semester_start_date"
                onChange={(e) => handleOnChange(e, 'spring_semester_start_date')}
                selected={schoolDates.selectedSpringStartDate || ''}
                placeholderText="Spring Start Date"
              />
              <div className="input-subtext"></div>
            </div>
            <div className="input-wrapper">
              <DatePicker
                className="text-holder t-h1"
                name="next_fall_semester_start_date"
                onChange={(e) =>
                  handleOnChange(e, 'next_fall_semester_start_date')
                }
                selected={schoolDates.selectedNextFallStartDate || ''}
                placeholderText="Next Fall Start Date"
              />
              <div className="input-subtext"></div>
            </div>
            <div className="input-wrapper">
              <DatePicker
                className="text-holder t-h1"
                name="next_spring_semester_start_date"
                onChange={(e) =>
                  handleOnChange(e, 'next_spring_semester_start_date')
                }
                selected={schoolDates.selectedNextSpringStartDate || ''}
                placeholderText="Next Spring Start Date"
              />
              <div className="input-subtext"></div>
            </div> */}
          </div>
        </div>
      </div>
      <AddCollegeDateModal handleAddDate={handleAddDate} />
      <UpdateCollegeDateModal handleUpdate={handleUpdateSemDate} />
    </React.Fragment>
  );
};

export default CollegeForm;
