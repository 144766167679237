import React from 'react'
import { Context } from '../../context/Context'

const CollegeDates = ({handleUpdate, handleDelete}) => {
  const {
    currentSemDetails
  } = React.useContext(Context)

  const semTypes = [
    {
      id: "F",
      label: "Fall"
    },
    {
      id: "S",
      label: "Spring"
    },
    {
      id: "Q1",
      label: "Quarter 1"
    },
    {
      id: "Q2",
      label: "Quarter 2"
    },
    {
      id: "Q3",
      label: "Quarter 3"
    }
  ]

  return (
    <React.Fragment>
      <div className="c2c-table">
        <div className="c2c-table__header">
          <div className="c2c-table__row">
            <div className="c2c-table__column">Type</div>
            <div className="c2c-table__column">Date Start</div>
            <div className="c2c-table__column">Academic Year</div>
            <div className="c2c-table__column">Actions</div>
          </div>
        </div>
        <div className="c2c-table__body">
          { currentSemDetails.length > 0 ?
            currentSemDetails.map((date, i) => {
              return (
                <div className="c2c-table__row" key={i}>
                  <div className="c2c-table__column">
                    {semTypes.find(el => el.id === date.sem_type) ? semTypes.find(el => el.id === date.sem_type).label : ""}
                  </div>
                  <div className="c2c-table__column">
                    {date.date_start}
                  </div>
                  <div className="c2c-table__column">
                    {date.acad_year}
                  </div>
                  <div className="c2c-table__column c2c-table__column--space-evenly">
                    <span onClick={() => handleUpdate(i)} className="fas fa-edit c2c-table__action c2c-table__action--edit"></span>
                    <span onClick={() => handleDelete(i)} className="fas fa-trash-alt c2c-table__action c2c-table__action--delete"></span>
                  </div>
                </div>
              )
            }) :
            <div className="c2c-table__row c-danger">This school doesn't have dates yet.</div>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default CollegeDates