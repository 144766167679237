import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Spinner from 'react-bootstrap/Spinner'

import CheckoutForm from './CheckoutForm'
import AddStudentCheckoutForm from './AddStudentCheckoutForm'
import ConfirmModal from './ConfirmModal'
import { isLogin } from './util'

import { Context } from '../context/Context'

import api from '../api/api'

import GiftCardDropdown from './NewComponents/GiftCardDropdown'
import { STRIPE_KEY } from '../helpers/constants'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// const stripePromise = loadStripe('pk_test_yWRGo5e1Uicpdg2e9GK41HCg');
const stripePromise = loadStripe(STRIPE_KEY)

const ContactStepFour = ({
  handleOnSubscriptionChange,
  handleDeleteSubscription,
  select,
  plans,
  data,
  handleSubscriptionId,
  handleOnChange,
  couponVerificationLoading,
  setCouponVerificationLoading,
}) => {
  const { setActiveIndex, quarterDefaultPlans, themeDefaultPlans, themePackTemplates, handleDeleteDeluxe } =
    useContext(Context)

  const listPlans =
    plans &&
    plans.map((item, i) => {
      return item.nickname !== 'Choose' ? (
        <option key={i} value={item.id}>
          {item.nickname}
        </option>
      ) : (
        ''
      )
    })

  const [confirmModalElements, setConfirmModalElements] = useState({
    title: '',
    content: '',
    onConfirm: () => {},
    onConfirmText: '',
  })

  const [showConfirm, setShowConfirm] = useState(false)

  // const handleDeleteSubscription = (index) => {
  //   alert('Subscription Deleted', index);
  //   setShowConfirm(false);
  // }

  const onClickDelete = index => {
    setConfirmModalElements({
      title: 'Are you sure?',
      content:
        data.students.length === 1
          ? 'Do you really want to delete this subscription? If you delete this you will have to start over.'
          : 'Do you really want to delete this subscription?',
      onConfirm: () => {
        handleDeleteSubscription(index)
        setShowConfirm(false)
        data.students.length === 0 && setActiveIndex(isLogin() ? 1 : 2)
      },
      onConfirmText: 'Delete',
    })
    setShowConfirm(true)
  }

  const verifyCoupon = () => {
    setCouponVerificationLoading(true)
    api.validateCoupon(data, setCouponVerificationLoading)
  }

  const getPlanOptions = planList => {
    return (
      planList &&
      planList.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.nickname}
          </option>
        )
      })
    )
  }

  const getPlanChoices = subscriptionType => {
    let planOptions, planList

    if (subscriptionType === 'quarter') {
      planList = quarterDefaultPlans
    } else if (subscriptionType === 'theme') {
      // planList = themeDefaultPlans.reduce((result, plan) => {
      //   let passed = themePackTemplates.filter(template => template.theme_name === plan.id)
      //   if (passed.length) {
      //     result.push(plan);
      //   }
      //   return result;
      // }, []);

      planList = themeDefaultPlans
    } else {
      planList = plans
    }

    planOptions = getPlanOptions(planList)

    return planList ? planOptions : <option value="">No Plans Available</option>
  }

  const handleDisplayDeluxe = subscription_type => {
    if (subscription_type === 'semester') {
      return '$60 / Semester'
    } else if (subscription_type === 'quarter') {
      return '$40  / Quarter'
    }
  }

  const listAllStudents =
    data.students &&
    data.students.map((item, i) => {
      return (
        <>
          <div className="subscription" key={i}>
            <div className="student-name">{item.student_first_name + ' ' + item.student_last_name}</div>
            {/* <div className="student-subscription">{item.plan_selected}</div> */}
            <div className="student-subscription">
              <select
                name="plan_selected"
                value={item.plan_selected}
                onChange={e => {
                  handleOnSubscriptionChange(e, i)
                }}>
                {getPlanChoices(item.subscription_type)}
              </select>
            </div>
            <div className="student-subscription-price">{item.plan_text_price}</div>
            <div className="student-subscription-action">
              <span
                onClick={() => {
                  onClickDelete(i)
                }}
                className="far fa-trash-alt"></span>
            </div>
          </div>

          {item?.availedDeluxe && (
            <div className="subscription" key={i}>
              <div className="student-name no-top"></div>
              <div className="student-subscription deluxe">{'Deluxe Upgrade'}</div>
              <div className="student-subscription-price deluxe">{handleDisplayDeluxe(item.subscription_type)}</div>
              <div className="student-subscription-action">
                <span
                  onClick={() => {
                    handleDeleteDeluxe(i)
                  }}
                  className="far fa-trash-alt"></span>
              </div>
            </div>
          )}
        </>
      )
    })

  const checkIfHaveThemePack = () => {
    return data.students.some(student => student.subscription_type === 'theme')
  }

  return (
    <div className="step-four payment-step">
      <div className="subscription-holder">
        <div className="form-group">
          <h3 className="info-title t-h1 c-grayish-navy">Review Your Subscriptions</h3>
          <div className="subscriptions-table-container">
            <div className="subscriptions-table">
              <div className="table-header">
                <div className="header-name">{`${checkIfHaveThemePack() ? '' : 'Student '}Name`}</div>
                <div className="header-subscription">Subscription Plan</div>
                <div className="header-price">Price</div>
                <div className="header-action"></div>
              </div>
              <div className="subscriptions">
                {/* start loop */}
                {data.students ? listAllStudents : ''}
                {/* end loop */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='coupon-holder'>
        <div className='input-wrapper'>
          <input className='t-h1' type='text' name='coupon' placeholder='Coupon or Promo Code' onChange={handleOnChange} />
          <div className='input-subtext'></div>
        </div>

        <div className='button-holder'>
          <button className='btn-purple c-purple d-flex' disabled={couponVerificationLoading} onClick={() => verifyCoupon()}>
            {couponVerificationLoading && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
            {couponVerificationLoading && <span>Verifying ...</span>}
            {!couponVerificationLoading && <span>Apply Code</span>}
          </button>
        </div>
      </div> */}

      <div className="payment-holder">
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">Enter Your Payment Details</h3>
          {/* <div className='label c-grayish-navy'>*applicable sales tax will be applied for Colorado sales</div> */}
          {isLogin() && (
            <Elements stripe={stripePromise}>
              <AddStudentCheckoutForm data={data} handleSubscriptionId={handleSubscriptionId} />
            </Elements>
          )}
          {!isLogin() && (
            <Elements stripe={stripePromise}>
              <CheckoutForm data={data} handleSubscriptionId={handleSubscriptionId} />
            </Elements>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={confirmModalElements.onConfirm}
        onCancel={() => setShowConfirm(false)}
        title={confirmModalElements.title}
        content={confirmModalElements.content}
        onCancelText={'Cancel'}
        onConfirmText={confirmModalElements.onConfirmText}
      />
    </div>
  )
}

export default ContactStepFour
