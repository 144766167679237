import React, { useContext, useState, useEffect } from "react";

import { Context } from "../context/Context";

import CollegeForm from "../components/CollegeForm";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import api from "../api/api";
import { validateWhiteSpace, validateCollegeDates } from "./util";

const AddCollegeModal = () => {
  const {
    tokenAdmin,
    setSchools,
    schoolDates,
    collegeData,
    handleOnChangeCollege,
    addCollegeLoading,
    setAddCollegeLoading,
    handleCloseAddCollegeModal,
    showAddCollegeModal,
    currentSemDetails,
    setCurrentSemDetails,
    setSchoolSemDetails,
  } = useContext(Context);

  const handleAddCollege = () => {
    setAddCollegeLoading(true);
    api.handleManageCollegeData(
      tokenAdmin,
      collegeData,
      "create",
      setAddCollegeLoading,
      handleCloseAddCollegeModal,
      setSchools,
      currentSemDetails,
      setCurrentSemDetails,
    ).then(() => {
      return api.handleGetSemDetails(setSchoolSemDetails)
    })
  };

  // temp form validation, need to transfer to more centralized form validation
  const onClickAdd = () => {
    if (
      validateWhiteSpace(collegeData.college_name) === -1 ||
      collegeData.college_name === ""
    ) {
      alert("College name is required");
    } else if (
      validateWhiteSpace(collegeData.nickname) === -1 ||
      collegeData.nickname === ""
    ) {
      alert("College nickname is required");
    } else if (
      validateWhiteSpace(collegeData.city) === -1 ||
      collegeData.city === ""
    ) {
      alert("City is required");
    } else if (
      validateWhiteSpace(collegeData.state) === -1 ||
      collegeData.state === ""
    ) {
      alert("State is required");
    } else if (
      validateWhiteSpace(collegeData.zipcode) === -1 ||
      collegeData.zipcode === ""
    ) {
      alert("Zip code is required");
    } 
    // else if (
    //   validateWhiteSpace(collegeData.fall_semester_start_date) === -1 ||
    //   collegeData.fall_semester_start_date === ""
    // ) {
    //   alert("Fall semester start date is required");
    // } else if (
    //   validateWhiteSpace(collegeData.spring_semester_start_date) === -1 ||
    //   collegeData.spring_semester_start_date === ""
    // ) {
    //   alert("Spring semester start date is required");
    // } else if (
    //   validateWhiteSpace(collegeData.stnext_fall_semester_start_dateate) ===
    //     -1 ||
    //   collegeData.next_fall_semester_start_date === ""
    // ) {
    //   alert("Next fall semester start date is required");
    // } else if (
    //   validateWhiteSpace(collegeData.next_spring_semester_start_date) === -1 ||
    //   collegeData.next_spring_semester_start_date === ""
    // ) {
    //   alert("Next spring semester start date is required");
    // } 

    else if (!validateCollegeDates(currentSemDetails)) {
      alert("School dates are not valid. Please check if you've added one.");
    }
    else {
      handleAddCollege();
    }
  };
  
  return (
    <Modal
      className="c2c-modal"
      show={showAddCollegeModal}
      onHide={handleCloseAddCollegeModal}
      centered
      backdrop={"static"}
    >
      <div
        className="btn-close-modal"
        onClick={handleCloseAddCollegeModal}
      ></div>

      <CollegeForm
        data={collegeData}
        handleOnChange={handleOnChangeCollege}
        title={"Add New College"}
      />

      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleCloseAddCollegeModal()}
          disabled={addCollegeLoading}
        >
          <span>Cancel</span>
        </button>
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => onClickAdd()}
          disabled={addCollegeLoading}
        >
          {addCollegeLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {addCollegeLoading && <span>Please wait ...</span>}
          {!addCollegeLoading && <span>Add</span>}
        </button>
      </div>
    </Modal>
  );
};

export default AddCollegeModal;
