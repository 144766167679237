import React from 'react'
import C2CModal from "../NewComponents/C2CModal";
import ThemePackTemplateForm from "./ThemePackTemplateForm"
import { Context } from "../../context/Context"
import api from '../../api/api'

const UpdateThemePackTemplateModal = () => {
  const {
    setThemePackTemplateProcessState,
    updateThemePackTemplateModalState,
    handleCloseUpdateThemePackTemplateModal,
    setThemePackTemplates,
    currentThemePackTemplate,
    tokenAdmin
  } = React.useContext(Context)

  const updateTemplate = (updatedTemplate) => {
    setThemePackTemplateProcessState(true)
    api.handleUpdateThemePackTemplate(tokenAdmin, updatedTemplate).then(() => {
      api.getAllThemePacks(tokenAdmin, setThemePackTemplates).then(() => {
        setThemePackTemplateProcessState(false)
        handleCloseUpdateThemePackTemplateModal()
      })
    })
  }

  return (
    <C2CModal
      show={updateThemePackTemplateModalState}
      onHide={handleCloseUpdateThemePackTemplateModal}
      title={`Update ${currentThemePackTemplate?.theme_name} Templates`}
    >
      <ThemePackTemplateForm onSave={updateTemplate} onCancel={handleCloseUpdateThemePackTemplateModal} saveBtnTxt={"Update"} />
    </C2CModal>
  )
}

export default UpdateThemePackTemplateModal