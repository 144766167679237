import React, { useState, useContext } from 'react'

import { Context } from '../context/Context';
import MonthlyNewsletterModal from '../components/MonthlyNewsletterModal';

import { ReactComponent as IconCap } from '../assets/images/Group 703.svg'
import { ReactComponent as IconCampus } from '../assets/images/campus.svg'
import { ReactComponent as IconNews } from '../assets/images/18-Mail.svg'
import { ReactComponent as IconAddSub } from '../assets/images/add_new_subscription.svg'

import {isMonday} from './util'

const RightSideBar = () => {
  const {
    selectedStudent,
    handleShowNewsletterModal,
    showAddSubscriptionModal,
    setCurrentSubscriptionType,
    setCurrentProcess,
    newRecipientData,
    setNewRecipientData,
    handleOnClickAddThemePack
  } = useContext(Context);

  const [btnToggle, openSideBar] = useState(false);

  const moreInfo = {
    studentIcon: <IconCap />,
    eventIcon: <IconCampus />,
    newsIcon: <IconNews />,
    addSubIcon: <IconAddSub />,
    fName: "Sarah",
    lName: "Smith",
    yearLvl: "Freshman",
    school: "University of Washington",
    eventList: [
      {
        month: "Aug",
        day: "24",
        eventName: "Fall Semester"
      },
      {
        month: "Jan",
        day: "04",
        eventName: "Spring Semester"
      },
      {
        month: "Aug",
        day: "23",
        eventName: "Next Fall Semester"
      },
      {
        month: "Jan",
        day: "10",
        eventName: "Next Spring Semester"
      },
    ],
    newsLetterTitle: "The Starfish Story",
    newsLetterContent: "An old man was walking on the beach one morning after a storm.  In the distance, he could see someone moving like a dancer.  As he came closer, he saw that it was a young woman picking up starfish and gently throwing them into the ocean.  “Young lady, why are you throwing starfish into the ocean?” “The sun is up, and the tide is going out and if I do not throw them in, they will die,” she said.  “But young lady, do you not realize that there are many miles of beach and thousands of starfish?  You cannot possible make a difference.” The young woman listened politely, then bent down, picked up another starfish and threw it into the sea.  “It made a difference for that one.”~Adapted from the original by Loren Eiseley"
  }

  const toggle = () => {
    openSideBar(!btnToggle)
  }

  const checkIfNextYear = (month, day) => {
    let  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let d = new Date()
    let y = d.getFullYear()
    let m = months.indexOf(months.find(mon => mon.includes(month)))
    let possibleQ1MonthsIdx = [7, 8, 9]
    if (!possibleQ1MonthsIdx.includes(m)) y += 1
    let schoolDate = new Date(y, m, parseInt(day))
    return !isMonday(schoolDate)
  }

  return(
    <>
      <div className={ btnToggle ? "sidebar-wrapper right" : "sidebar-wrapper sidebar-collapsed-wrapper right" }>
        <div className="sidebar-list">
          <div onClick={toggle} className={ btnToggle ? "sidebar-toggler opened" : "sidebar-toggler closed" }></div>
          <div className="student-info-holder c-purple line">
            <div className="icon-holder">
              <span>{moreInfo.studentIcon}</span>
            </div>
            <div className="t-h3 student-name">{selectedStudent.first_name + " " + selectedStudent.last_name}</div>
            {/* <div className="t-h3 student-level">{moreInfo.yearLvl}</div> */}
            
            { selectedStudent.subscription_type != "theme" &&
              <div className="student-school heading">{selectedStudent.school}</div> }
          </div>
          {
            selectedStudent.subscription_type != "theme" &&
            <div className="events-holder c-purple line">
              <div className="icon-holder">
                <span>{moreInfo.eventIcon}</span>
              </div>
              <div className="heading">Future Card Start Dates</div>
              {selectedStudent &&
                selectedStudent.subscription_type === "semester" ?
                (<React.Fragment>
                  {
                    selectedStudent.fall_semester_month && selectedStudent.fall_semester_day &&
                    <div className="single-event-holder">
                      <div className="date-holder">
                        <div className="event-month">{selectedStudent.fall_semester_month}</div>
                        <div className="event-day">{selectedStudent.fall_semester_day}</div>
                      </div>
                      <div className="event-name">Fall Semester</div>
                    </div>
                  }
                  {
                    selectedStudent.spring_semester_month && selectedStudent.spring_semester_day &&
                    <div className="single-event-holder">
                      <div className="date-holder">
                        <div className="event-month">{selectedStudent.spring_semester_month}</div>
                        <div className="event-day">{selectedStudent.spring_semester_day}</div>
                      </div>
                      <div className="event-name">Spring Semester</div>
                    </div>
                  }
                  {/* <div className="single-event-holder">
                    <div className="date-holder">
                      <div className="event-month">{selectedStudent.spring_semester_month ? selectedStudent.spring_semester_month : '??'}</div>
                      <div className="event-day">{selectedStudent.spring_semester_day ? selectedStudent.spring_semester_day : '??'}</div>
                    </div>
                    <div className="event-name">Spring Semester</div>
                  </div>
                  <div className="single-event-holder">
                    <div className="date-holder">
                      <div className="event-month">{selectedStudent.next_fall_semester_month ? selectedStudent.next_fall_semester_month : '??'}</div>
                      <div className="event-day">{selectedStudent.next_fall_semester_day ? selectedStudent.next_fall_semester_day : '??'}</div>
                    </div>
                    <div className="event-name">Next Fall Semester</div>
                  </div>
                  <div className="single-event-holder">
                    <div className="date-holder">
                      <div className="event-month">{selectedStudent.next_spring_semester_month ? selectedStudent.next_spring_semester_month : '??'}</div>
                      <div className="event-day">{selectedStudent.next_spring_semester_day ? selectedStudent.next_spring_semester_day : '??'}</div>
                    </div>
                    <div className="event-name">Next Spring Semester</div>
                  </div> */}
                </React.Fragment>) :
                (<React.Fragment>
                  {
                    selectedStudent.q1_month && selectedStudent.q1_day &&
                    <div className="single-event-holder">
                      <div className="date-holder">
                        <div className="event-month">{selectedStudent.q1_month}</div>
                        <div className="event-day">{selectedStudent.q1_day}</div>
                      </div>
                      {/* <div className="event-name">{checkIfNextYear(selectedStudent.q1_month, selectedStudent.q1_day) ? "Next" : ""} Fall Quarter</div> */}
                      <div className="event-name">Fall Quarter</div>
                    </div>
                  }
                  {
                    selectedStudent.q2_month && selectedStudent.q2_day &&
                    <div className="single-event-holder">
                      <div className="date-holder">
                        <div className="event-month">{selectedStudent.q2_month}</div>
                        <div className="event-day">{selectedStudent.q2_day}</div>
                      </div>
                      {/* <div className="event-name">{checkIfNextYear(selectedStudent.q2_month, selectedStudent.q2_day) ? "Next" : ""} Winter Quarter</div> */}
                      <div className="event-name">Winter Quarter</div>
                    </div>
                  }
                  {
                    selectedStudent.q3_month && selectedStudent.q3_day &&
                    <div className="single-event-holder">
                      <div className="date-holder">
                        <div className="event-month">{selectedStudent.q3_month}</div>
                        <div className="event-day">{selectedStudent.q3_day}</div>
                      </div>
                      {/* <div className="event-name">{checkIfNextYear(selectedStudent.q3_month, selectedStudent.q3_day) ? "Next" : ""} Pring Quarter</div> */}
                      <div className="event-name">Spring Quarter</div>
                    </div>
                  }
                  {/* <div className="single-event-holder">
                    <div className="date-holder">
                      <div className="event-month">{selectedStudent.q1_month ? selectedStudent.q1_month : '??'}</div>
                      <div className="event-day">{selectedStudent.q1_day ? selectedStudent.q1_day : '??'}</div>
                    </div>
                    <div className="event-name">Quarter 1</div>
                  </div>
                  <div className="single-event-holder">
                    <div className="date-holder">
                      <div className="event-month">{selectedStudent.q2_month ? selectedStudent.q2_month : '??'}</div>
                      <div className="event-day">{selectedStudent.q2_day ? selectedStudent.q2_day : '??'}</div>
                    </div>
                    <div className="event-name">Quarter 2</div>
                  </div>
                  <div className="single-event-holder">
                    <div className="date-holder">
                      <div className="event-month">{selectedStudent.q3_month ? selectedStudent.q3_month : '??'}</div>
                      <div className="event-day">{selectedStudent.q3_day ? selectedStudent.q3_day : '??'}</div>
                    </div>
                    <div className="event-name">Quarter 3</div>
                  </div> */}
                </React.Fragment>)
              }
            </div>
          }
          <div className="newsletter-holder" onClick={handleOnClickAddThemePack}>
            <div className="icon-holder">
              <span>{moreInfo.addSubIcon}</span>
            </div>
            <div className="heading c-purple">{'Sign up for a Theme Pack'}</div>
          </div>
          <div className="newsletter-holder" onClick={handleShowNewsletterModal}>
            <div className="icon-holder">
              <span>{moreInfo.newsIcon}</span>
            </div>
            <div className="heading c-purple">{'View Inspirational Story'}</div>
            {/* <div className="news-title t-h3 c-purple">{moreInfo.newsLetterTitle}</div>
            <div className="news-content">{moreInfo.newsLetterContent}</div> */}
          </div>
        </div>
        <MonthlyNewsletterModal newsletter={{title: moreInfo.newsLetterTitle, content: moreInfo.newsLetterContent}} />
      </div>
    </>
  )
}
export default RightSideBar
