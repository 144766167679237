import React, { useContext, useState, useEffect } from 'react';

import { Context } from '../context/Context';

import ConfirmModal from '../components/ConfirmModal';
import CardImagesForm from '../components/CardImagesForm';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api/api';
import { validateWhiteSpace } from './util';
import api_base from '../api/api_base_url';

const AddTemplateModal = () => {
  const {
    tokenAdmin,
    setAddTemplateLoading,
    addTemplateLoading,
    showAddTemplateModal,
    handleCloseAddTemplateModal,
    setTemplates,
    templateData,
    handleOnChangeTemplateData,
  } = useContext(Context);

  const addTemplate = () => {
    setAddTemplateLoading(true);
    // console.log(templateData)
    api
      .handleManageTemplateData(
        tokenAdmin,
        templateData,
        'create',
        setAddTemplateLoading,
        handleCloseAddTemplateModal
        // setTemplates
      )
      .then(() => {
        return api.handleGetTemplateData(tokenAdmin, setTemplates);
      })
      .then(() => {
        setAddTemplateLoading(false);
        handleCloseAddTemplateModal();
      });
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(templateData.order) === -1 || templateData.order === '') {
      alert('Order is required');
    } else if (validateWhiteSpace(templateData.template_id) === -1 || templateData.template_id === '') {
      alert('Template id is required');
    } else if (validateWhiteSpace(templateData.template_name) === -1 || templateData.template_name === '') {
      alert('Template name is required');
    } else if (
      validateWhiteSpace(templateData.quarter_order) === -1 ||
      templateData.quarter_order === '' ||
      templateData.quarter_order === undefined
    ) {
      alert('Quarter order is required');
    } else if (templateData?.quarter_order.length > 3) {
      alert('Quarter order max character length is 3');
    } else if (validateWhiteSpace(templateData.cover_image) === -1 || templateData.cover_image === '') {
      alert('Template front cover image is required');
    } else if (validateWhiteSpace(templateData.message_image) === -1 || templateData.message_image === '') {
      alert('Template inside 1 message image is required');
    } else {
      addTemplate();
    }
  };

  return (
    <Modal
      className='c2c-modal'
      show={showAddTemplateModal}
      onHide={handleCloseAddTemplateModal}
      centered
      backdrop={'static'}
    >
      <div className='btn-close-modal' onClick={handleCloseAddTemplateModal}></div>

      <CardImagesForm data={templateData} handleOnChange={handleOnChangeTemplateData} title={'Add New Template'} />

      <div className='btn-holder'>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={() => handleCloseAddTemplateModal()}
          disabled={addTemplateLoading}
        >
          <span>Cancel</span>
        </button>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={() => handleOnSave()}
          disabled={addTemplateLoading}
        >
          {addTemplateLoading && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
          {addTemplateLoading && <span>Please wait ...</span>}
          {!addTemplateLoading && <span>Add</span>}
        </button>
      </div>
    </Modal>
  );
};

export default AddTemplateModal;
