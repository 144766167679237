import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../context/Context'
import MonthlyNewsletterModal from '../components/MonthlyNewsletterModal'
import { ReactComponent as IconExport } from '../assets/images/export.svg'
import api from '../api/api'
import LoaderModal from './LoaderModal'

const AdminRightSidebar = () => {
  const { handleShowLoaderModal, handleCloseLoaderModal, tokenAdmin } = useContext(Context)
  const [btnToggle, openSideBar] = useState(false)

  const toggle = () => {
    openSideBar(!btnToggle)
  }

  useEffect(() => {})

  const GenerateReport = async type => {
    handleShowLoaderModal()
    if (type == 'Weekly Gift Card') {
      api.handleGenerateWeeklyGiftReport(tokenAdmin, 'Weekly Gift Card').then(() => handleCloseLoaderModal())
    } else if (type == 'Weekly Gift Boxes') {
      api.handleGenerateWeeklyGiftReport(tokenAdmin, 'Monthly Gift').then(() => handleCloseLoaderModal())
    } else if (type == 'Weekly Card Sent') {
      api.handleGenerateWeeklyCardSent(tokenAdmin).then(() => handleCloseLoaderModal())
    } else if (type == 'Subscriber') {
      await api.handleGenerateSubscriberData(tokenAdmin).then(() => handleCloseLoaderModal())
    } else if (type == 'Newsletter Subscriber') {
      await api.handleGenerateNewsletterSubscriberData(tokenAdmin).then(() => handleCloseLoaderModal())
    } else if (type == 'New Student') {
      api.handleGenerateNewStudentReport(tokenAdmin).then(() => handleCloseLoaderModal())
      // } else if (type == "Active Student") {
      //   api.handleGenerateActiveStudent(tokenAdmin).then(() => handleCloseLoaderModal());
    } else if (type == 'College Dates') {
      api.handleGenerateCollegeDates(tokenAdmin).then(() => handleCloseLoaderModal())
    } else if (type == 'SMS Sent') {
      api.handleGenerateSmsSent(tokenAdmin).then(() => handleCloseLoaderModal())
    } else if (type == 'Student Series') {
      api.handleGenerateStudentSeries(tokenAdmin).then(() => handleCloseLoaderModal())
    } else if (type === 'Affiliates') {
      api.handleGenerateAffiliates(tokenAdmin).then(() => handleCloseLoaderModal())
    }
    // else if (type == "Total Tax Collected") {
    //   api
    //     .handleGenerateTotalTaxCollected()
    //     .then(() => handleCloseLoaderModal());
    // }
  }

  return (
    <>
      <div className={btnToggle ? 'sidebar-wrapper right' : 'sidebar-wrapper sidebar-collapsed-wrapper right'}>
        <div className="sidebar-list">
          <div onClick={toggle} className={btnToggle ? 'sidebar-toggler opened' : 'sidebar-toggler closed'}></div>
          <div className="newsletter-holder" onClick={() => GenerateReport('New Student')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate New Student Data Report</div>
            {/* <div className="news-title t-h3 c-purple">{moreInfo.newsLetterTitle}</div>
            <div className="news-content">{moreInfo.newsLetterContent}</div> */}
          </div>
          <div className="newsletter-holder" onClick={() => GenerateReport('Weekly Gift Card')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate Weekly Gift Cards Report</div>
            {/* <div className="news-title t-h3 c-purple">{moreInfo.newsLetterTitle}</div>
            <div className="news-content">{moreInfo.newsLetterContent}</div> */}
          </div>
          <div className="newsletter-holder" onClick={() => GenerateReport('Weekly Gift Boxes')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate Weekly Gift Boxes Report</div>
            {/* <div className="news-title t-h3 c-purple">{moreInfo.newsLetterTitle}</div>
            <div className="news-content">{moreInfo.newsLetterContent}</div> */}
          </div>
          <div className="newsletter-holder" onClick={() => GenerateReport('Weekly Card Sent')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Cards Sent Report</div>
            {/* <div className="news-title t-h3 c-purple">{moreInfo.newsLetterTitle}</div>
            <div className="news-content">{moreInfo.newsLetterContent}</div> */}
          </div>
          <div className="newsletter-holder" onClick={() => GenerateReport('Subscriber')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate Subscribers Data Report</div>
          </div>

          <div className="newsletter-holder" onClick={() => GenerateReport('Newsletter Subscriber')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate Newsletter Subscribers Data Report</div>
          </div>

          {/* <div
            className="newsletter-holder"
            onClick={() => GenerateReport("Active Student")}
          >
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">
              Generate Active Student Report
            </div>
          </div> */}

          {/* <div
            className="newsletter-holder"
            onClick={() => GenerateReport("Total Tax Collected")}
          >
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">
              Generate Total Tax Collected Report
            </div>
     
          </div> */}

          <div className="newsletter-holder" onClick={() => GenerateReport('College Dates')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate College Dates Report</div>
          </div>

          <div className="newsletter-holder" onClick={() => GenerateReport('SMS Sent')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate SMS Sent Report</div>
          </div>

          <div className="newsletter-holder" onClick={() => GenerateReport('Student Series')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate Student Card Series Report</div>
          </div>

          <div className="newsletter-holder" onClick={() => GenerateReport('Affiliates')}>
            <div className="icon-holder">
              <span>
                <IconExport />
              </span>
            </div>
            <div className="heading c-purple">Generate Affiliates Report</div>
          </div>
        </div>
      </div>
      <LoaderModal />
    </>
  )
}
export default AdminRightSidebar
