import React from 'react'

import DatePicker from 'react-datepicker'

import { Context } from "../../context/Context";

const StudentCollegeFieldGroup = (props) => {
  const {
    schools,
  } = React.useContext(Context)

  const isQuarter = () => {
    const quarters = ["q1", "q2", "q3"]

    return quarters.includes(props.data.subscription_type)
  }

  const getSchoolsByType = (subscriptionType) => {
    return schools.reduce((result, school) => {
      if (subscriptionType == school.qtr_or_sem) {
        result.push(school);
      }
      return result;
    }, []);
  }

  const getSchoolOptions = () => {
    let schoolTypeAbbrv = "S";
    (isQuarter()) && (schoolTypeAbbrv = "Q");

    const filteredSchools = getSchoolsByType(schoolTypeAbbrv);

    return filteredSchools.length ? filteredSchools.map((school, index) => {
      return (
        <option key={index} value={school.college_name}>
          {school.college_name}
        </option>
      );
    }) : <option value="default">No Schools Available</option>
  }

  const isOnlyMonday = date => {
    const day = date.getDay();
    return day === 1;
  };

  return (
    <React.Fragment>
      <div className="input-wrapper">
        {schools && (
          <select
            name="school_dropdown"
            className="text-holder t-h1"
            value={props.selectedSchool}
            onChange={props.handleOnChange}
          >
            <option value="default">Choose a school</option>
            <optgroup label="Available schools">
              {getSchoolOptions()}
            </optgroup>
            <optgroup label="Didn't find your school?">
              <option value="add-new-school">Add another school</option>
            </optgroup>
          </select>
        )}
        <div className="input-subtext">School</div>
      </div>
      {props.isAddingNewSchool && (
        <React.Fragment>
          <div className="add-school-header label c-grayish-navy">
            Enter school name and start of classes date below:
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="school"
              placeholder="School Name"
              value={props.data.school || ''}
              onChange={props.handleOnChange}
            />
            <div className="input-subtext">School Name</div>
          </div>

          <div className="input-wrapper">
            <div className="input-label-wrapper">
              <DatePicker
                className="text-holder t-h1"
                name="first_day_of_semester"
                onChange={props.handleOnChange}
                selected={props.semesterDate || ''}
                filterDate={isOnlyMonday}
                placeholderText="Click to select a date"
              />
              <span>First Day of Current {isQuarter() ? "Quarter" : "Semester"}</span>
            </div>
            <div className="input-subtext"></div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default StudentCollegeFieldGroup