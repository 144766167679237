import React, { Fragment } from "react";
import { Context } from "../context/Context";
import GiftCardOptions from "./NewComponents/GiftCardOptions";

const ContactStepSubscription = ({
  handleOnChange,
  selectedPlan,
  plans,
  data,
}) => {
  const { quarterDefaultPlans, themeDefaultPlans, themePackTemplates } =
    React.useContext(Context);

  const getPlanOptions = (planList) => {
    return (
      planList &&
      planList.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.nickname}
          </option>
        );
      })
    );
  };

  // const getCurrentPlanMetaData = () => {
  //   const subscriptionType = data.subscription_type;
  //   let planList;

  //   if (subscriptionType === "quarter") {
  //     planList = quarterDefaultPlans;
  //   } else if (subscriptionType === "theme") {
  //     planList = themeDefaultPlans;
  //   } else {
  //     planList = plans;
  //   }

  //   return planList &&
  //   planList.map((item, i) => {
  //     return selectedPlan === item.id ? (
  //       <Fragment key={i}>
  //         <p className="text-price">{item.metadata.text_price}</p>
  //         <p className="note c-grayish-navy">{item.metadata.plan_desc}</p>
  //       </Fragment>
  //     ) : null;
  //   });
  // }

  // const getPlanList = () => {
  //   const subscriptionType = data.subscription_type;
  //   let planList;

  //   if (subscriptionType === "quarter") {
  //     planList = quarterDefaultPlans;
  //   } else if (subscriptionType === "theme") {
  //     planList = themeDefaultPlans;
  //   } else {
  //     planList = plans;
  //   }

  //   return planList;
  // }

  const getPlanChoices = () => {
    const subscriptionType = data.subscription_type;
    let planOptions, planList;

    if (subscriptionType === "quarter") {
      planList = quarterDefaultPlans;
    } else if (subscriptionType === "theme") {
      // planList = themeDefaultPlans.reduce((result, plan) => {
      //   let passed = themePackTemplates.filter(template => template.theme_name === plan.id)
      //   if (passed.length) {
      //     result.push(plan);
      //   }
      //   return result;
      // }, []);
      planList = themeDefaultPlans;
    } else {
      planList = plans;
    }

    planOptions = getPlanOptions(planList);

    return planList ? (
      planOptions
    ) : (
      <option value="">No Plans Available</option>
    );
  };

  return (
    <div className="step-subscription">
      <div className="subscription-holder">
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">{`Choose Current ${
            data.subscription_type === "theme" ? "" : "Student "
          }Subscription`}</h3>
          <div className="dropdown-holder">
            <select
              name="plan_selected"
              value={data.plan_selected}
              onChange={handleOnChange}
            >
              <option defaultValue>Choose</option>
              {getPlanChoices()}
            </select>
            {
              <Fragment>
                {data.plan_text_price && (
                  <p className="text-price">
                    <s>{data.plan_previous_price}</s> {data.plan_text_price}
                  </p>
                )}
                {data.plan_description && (
                  <Fragment>
                    <p className="note c-grayish-navy">
                      {data.plan_description}
                    </p>
                    {data.subscription_type !== "theme" && (
                      <p className="note c-grayish-navy">
                        {
                          "If you are interested in upgrading any of the care packages to Deluxe versions, please contact us via email."
                        }
                      </p>
                    )}
                  </Fragment>
                )}
              </Fragment>
            }
          </div>
        </div>
      </div>
      {/* {
        <GiftCardOptions data={data} handleOnChange={handleOnChange} />
      } */}
      <div className="label c-grayish-navy label-another-student">
        {data.subscription_type === "theme"
          ? "Send more cards?"
          : "Have more students?"}
      </div>
    </div>
  );
};

export default ContactStepSubscription;
