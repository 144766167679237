import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

import { Context } from '../context/Context';
import api from '../api/api';
import { validateWhiteSpace, validateEmailAddress } from './util';

const EditUserInfoModal = () => {
  const {
    user,
    setUser,
    showEditUserInfoModal,
    handleCloseEditUserInfoModal,
    updateUserInfoLoading,
    setUpdateUserInfoLoading,
    token,
    emailexists,
    setEmailExists,
  } = useContext(Context);

  const [value, setValue] = useState();

  const [data, setData] = useState({
    id: '',
    parent_id: '',
    first_name: '',
    last_name: '',
    cell_phone: '',
    email: '',
    street: '',
    unit: '',
    city: '',
    state: '',
    zipcode: '',
    sms: '',
    newsletter: '',
  });

  useEffect(() => {
    setData({
      id: user.id,
      parent_id: user.parent_id,
      first_name: user.first_name,
      last_name: user.last_name,
      cell_phone: user.cell_phone !== null ? user.cell_phone : '',
      email: user.email,
      street: user.street,
      unit: user.unit,
      city: user.city,
      state: user.state,
      zipcode: user.zipcode,
      sms: user.sms === '0' ? false : true,
      newsletter: user.newsletter === '0' ? false : true,
    });
    setValue(user.cell_phone !== null ? user.cell_phone : '');
  }, [showEditUserInfoModal]);

  const updateData = (e) => {
    const target = e.target.name;
    const value = e.target.value;

    if (target === 'newsletter' || target === 'sms') {
      setData({
        ...data,
        [target]: e.target.checked,
      });
    } else {
      setData({
        ...data,
        [target]: value,
      });
    }
  };

  const editUserInfo = () => {
    setUpdateUserInfoLoading(true);
    api.handleUpdateUser(token, data, user, setUser).then(() => {
      setUpdateUserInfoLoading(false);
      handleCloseEditUserInfoModal();
    });
  };

  const handlePhoneChange = (value) => {
    const isValid = isValidPhoneNumber(value);
    setData({
      ...data,
      cell_phone: value ? value : '',
      sms: value && isValid ? true : false,
    });
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(data.first_name) === -1 || data.first_name === '') {
      alert('First name is required');
    } else if (validateWhiteSpace(data.last_name) === -1 || data.last_name === '') {
      alert('Last name is required');
    } else if (!isValidPhoneNumber(data.cell_phone) && data.cell_phone !== '') {
      alert('Not a valid phone number');
    } else if (validateWhiteSpace(data.email) === -1 || data.email === '') {
      alert('Email is required');
    } else if (validateEmailAddress(data.email) === -1) {
      alert('Invalid email address');
    } else if (validateWhiteSpace(data.street) === -1 || data.street === '') {
      alert('Street is required');
    } else if (validateWhiteSpace(data.city) === -1 || data.city === '') {
      alert('City is required');
    } else if (validateWhiteSpace(data.state) === -1 || data.state === '') {
      alert('State is required');
    } else if (validateWhiteSpace(data.zipcode) === -1 || data.zipcode === '') {
      alert('Zipcode is required');
    } else {
      editUserInfo();
    }
  };

  return (
    <Modal
      className='c2c-modal edit-student-modal'
      show={showEditUserInfoModal}
      onHide={handleCloseEditUserInfoModal}
      centered
      backdrop={'static'}
    >
      <div className='btn-close-modal' onClick={handleCloseEditUserInfoModal}></div>
      <div className='step-one'>
        <h2 className='title t-h1 c-grayish-navy'>Update Your Info</h2>
        <div className='contact-holder'>
          <div className='form-group required'>
            <h3 className='info-title t-h1 c-grayish-navy'>Contact Details</h3>
            <div className='input-wrapper'>
              <input
                // className={`text-holder t-h1 required ${formErrors.first_name ? 'has-error' : ''}`}
                className={`text-holder t-h1 required`}
                type='text'
                name='first_name'
                placeholder='First Name'
                value={data.first_name || ''}
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='last_name'
                placeholder='Last Name'
                value={data.last_name || ''}
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className={`text-holder t-h1`}
                type='email'
                name='email'
                placeholder='Email Address'
                value={data.email || ''}
                onChange={(e) => {
                  updateData(e);
                  api.validateUpdateEmail({ parent_id: user.parent_id, email: e.target.value }, setEmailExists);
                }}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <PhoneInput
                className='text-holder t-h1 react-phone'
                placeholder='Mobile Phone'
                name='cell_phone'
                defaultCountry='US'
                value={formatPhoneNumberIntl(value)}
                onChange={handlePhoneChange}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input type='checkbox' id='newsletter' name='newsletter' checked={data.newsletter} onChange={updateData} />
              <label htmlFor='newsletter' className='text-holder check-holder t-h3 purple'>
                I want the newsletter!
              </label>
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                type='checkbox'
                id='sms'
                name='sms'
                // defaultChecked={data.sms}
                onChange={updateData}
                checked={data.sms}
                disabled
              />
              <label htmlFor='sms' className='text-holder check-holder t-h3 purple'>
                I agree to receive weekly text reminders.
              </label>
              <div className='input-subtext'></div>
            </div>
          </div>
        </div>
        <div className='address-holder'>
          <div className='form-group required'>
            <h3 className='info-title t-h1 c-grayish-navy'>Your Mailing Address</h3>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='street'
                value={data.street || ''}
                placeholder='Street Address'
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='unit'
                value={data.unit || ''}
                placeholder='Apt/Suite/Unit,Building'
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='city'
                value={data.city || ''}
                placeholder='City'
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='state'
                value={data.state || ''}
                placeholder='State'
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>

            <div className='input-wrapper'>
              <input
                className='text-holder t-h1'
                type='text'
                name='zipcode'
                value={data.zipcode || ''}
                placeholder='Zipcode'
                onChange={updateData}
              />
              <div className='input-subtext'></div>
            </div>
          </div>
        </div>
      </div>
      <div className='btn-holder'>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={() => handleOnSave()}
          disabled={updateUserInfoLoading}
        >
          {updateUserInfoLoading && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
          {updateUserInfoLoading && <span>Please wait</span>}
          {!updateUserInfoLoading && <span>Save</span>}
        </button>
      </div>
    </Modal>
  );
};

export default EditUserInfoModal;
