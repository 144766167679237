import React from "react";
import Modal from 'react-bootstrap/Modal';

const C2CModal = ({children, show, onHide, logoSrc, title, customClass}) => {
  return (
    <Modal
      className={`c2c-modal ${customClass}`}
      show={show}
      backdrop="static"
      onHide={onHide}
      centered
      keyboard={false}
    >
      <div className="btn-close-modal" onClick={onHide}></div>
      { (logoSrc != null && logoSrc != "") &&
        <div className="logo-holder">
          <img
            src={logoSrc}
            className="logo-img"
            alt="giftsandtalents"
          />
        </div>
      }
      <h2 className="title t-h1 c-grayish-navy">{title}</h2>
      {children} 
    </Modal>
  )
}

export default C2CModal