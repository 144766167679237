import React, { Fragment, useEffect, useContext, useState } from 'react'

import cookie from 'react-cookies'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import SweetAlert from 'sweetalert2-react'

import LoaderModal from './LoaderModal'

import { Context } from '../context/Context'
import api from '../api/api'

import { MARKETING_SITE_URL } from '../helpers/constants';

const AdminHeader = () => {
  const {
    tokenAdmin,
    setTokenAdmin,
    handleShowLoaderModal,
    handleCloseLoaderModal,
    setSchools,
  } = useContext(Context)

  const openMenu = () => {
    document.body.classList.toggle('no-scroll')
  }

  const onClickLogout = () => {
    handleShowLoaderModal()
    api.handleLogoutAdmin(tokenAdmin, setTokenAdmin, handleCloseLoaderModal)
  }

  useEffect(() => {
    const auth = cookie.load('jwt-admin')
    if (auth === 'false' || auth === null) {
    } else {
      setTokenAdmin(auth)
    }
  }, [])

  return (
    <header className="dash-header admin-header">
      <Navbar sticky="top" expand="lg">
        <Navbar.Brand href={MARKETING_SITE_URL} className="navbar-brand">
          <div className="logo-holder">
            <img
              src={require('../assets/images/logo.png')}
              className="logo-img"
              alt="giftsandtalents"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={openMenu}>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <div className="nav-item">
              <Nav.Link className="logout-link" onClick={onClickLogout}>
                Logout
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LoaderModal />
    </header>
  )
}

export default AdminHeader
