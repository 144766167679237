import React from 'react';
import clsx from 'clsx';
import { useContext } from 'react';
import { Context } from '../context/Context';

const Step = ({ stepNumber, name, active, value, isSingle }) => {
  const { data } = useContext(Context);

  //will be used to apply a class to step-label if it's a theme pack
  //theme pack has more steps, thus the fonts at mobile view should be smaller
  const isThemePack = () => {
    if (data.subscription_type === 'theme') {
      return true;
    }

    return false;
  };

  return (
    <div className={clsx('step', { active: active })}>
      {/* {stepNumber > 1} */}
      <div
        className={clsx('step-label', {
          'theme-label': isThemePack(),
        })}
      >
        <div>{name}</div>
      </div>
    </div>
  );
};

export default Step;
