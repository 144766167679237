import React, { useContext } from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
// import api from '../api/api';

const ConfirmModal = (props) => {
  return (
    <Modal className='c2c-modal confirm-modal' show={props.show} onHide={props.onHide} centered backdrop='static'>
      <div className='btn-close-modal' onClick={props.onHide}></div>
      <h2 className=' title t-h1 c-purple newsletter-title'>{props.title}</h2>
      <p className='newsletter-content'>{props.content}</p>
      {props.additionalContent}
      <div className='button-segments'>
        <button className='btn-white c-purple t-uppercase d-flex align-items-center' onClick={props.onCancel}>
          {props.onCancelText}
        </button>
        <button className='btn-teal c-white t-uppercase' onClick={props.onConfirm} disabled={props.isBusy}>
          {props.isBusy && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
          {props.isBusy && <span>Please wait</span>}
          {!props.isBusy && <span>{props.onConfirmText}</span>}
        </button>
      </div>
      {props.additionalMessage && (
        <div className='input-wrapper' style={{ paddingTop: '20px' }}>
          <h5 className='c-purple'>{props.additionalMessage}</h5>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmModal;
