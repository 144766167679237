import React, { useContext, useState, useEffect } from "react";

import { Context } from "../context/Context";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import api from "../api/api";
import { validateWhiteSpace } from "./util";

const ChangeStudentAddressModal = () => {
  const {
    token,
    showAddressModal,
    handleCloseAddressModal,
    selectedStudent,
    setSelectedStudent,
    onChangeStudentData,
    updateAddressLoading,
    setUpdateAddressLoading,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
  } = useContext(Context);

  const [data, setData] = useState({
    id: "",
    street: "",
    unit: "",
    city: "",
    state: "",
    zipcode: "",
    company: "",
  });

  useEffect(() => {
    setData({
      id: selectedStudent.id === null ? "" : selectedStudent.id,
      street: selectedStudent.street === null ? "" : selectedStudent.street,
      unit: selectedStudent.unit === null ? "" : selectedStudent.unit,
      city: selectedStudent.city === null ? "" : selectedStudent.city,
      state: selectedStudent.state === null ? "" : selectedStudent.state,
      zipcode: selectedStudent.zipcode === null ? "" : selectedStudent.zipcode,
      company: selectedStudent.company === null ? "" : selectedStudent.company,
    });
  }, [showAddressModal]);

  const updateData = (e) => {
    const target = e.target.name;
    const value = e.target.value;

    setData({
      ...data,
      [target]: value,
    });
  };

  const editStudentAddress = () => {
    setUpdateAddressLoading(true);
    api
      .handleUpdateStudent(
        token,
        data,
        setSelectedStudent,
        newRecipientData,
        setNewRecipientData,
        getSubscriptionType
      )
      .then(() => {
        setUpdateAddressLoading(false);
        handleCloseAddressModal();
      });
  };

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(data.street) === -1 || data.street === "") {
      alert("Street is required");
    } else if (validateWhiteSpace(data.city) === -1 || data.city === "") {
      alert("City is required");
    } else if (validateWhiteSpace(data.state) === -1 || data.state === "") {
      alert("State is required");
    } else if (validateWhiteSpace(data.zipcode) === -1 || data.zipcode === "") {
      alert("Zipcode is required");
    } else {
      editStudentAddress();
    }
  };

  return (
    <Modal
      className="c2c-modal"
      show={showAddressModal}
      onHide={handleCloseAddressModal}
      centered
      backdrop={"static"}
    >
      <div className="btn-close-modal" onClick={handleCloseAddressModal}></div>
      <h2 className="title t-h1 c-grayish-navy">
        Update {selectedStudent.first_name + "'s"} Mailing Address
      </h2>
      <div className="address-holder-two update-address">
        <div className="form-group required">
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="street"
              value={data.street || ""}
              onChange={updateData}
              placeholder="Address 1"
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="unit"
              value={data.unit || ""}
              onChange={updateData}
              placeholder="Address 2"
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="city"
              value={data.city || ""}
              onChange={updateData}
              placeholder="City"
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="state"
              value={data.state || ""}
              onChange={updateData}
              placeholder="State"
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="zipcode"
              value={data.zipcode || ""}
              onChange={updateData}
              placeholder="Zipcode"
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="company"
              placeholder="College Name"
              value={data.company || ""}
              onChange={updateData}
            />
            <div className="input-subtext">Only put college name if necessary for mailing address</div>
          </div>
        </div>
      </div>
      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={updateAddressLoading}
        >
          {updateAddressLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {updateAddressLoading && <span>Updating Address</span>}
          {!updateAddressLoading && <span>Update Address</span>}
        </button>
      </div>
    </Modal>
  );
};

export default ChangeStudentAddressModal;
