import React, { useContext, useEffect, useState } from 'react';

import api_base from '../api/api_base_url';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import QuickActionCard from './QuickActionCard';

import { Context } from '../context/Context';

const DashboardHome = () => {
  const {
    user,
    currentCard,
    selectedStudent,
    handleShowEditMessageModal,
    handleShowEditDefaultMessagesModal,
    setSelectedStudent,
    setCurrentCard,
    yourMessageButtonRef,
    dashTabActiveKey,
    runTutorial
  } = useContext(Context);

  // useEffect(() => {
  //   console.log('CURRENT CARD:::');
  // }, [setCurrentCard, setSelectedStudent]);

  const dashInfo = {
    btnList: [
      {
        btnIcon: require('./../assets/images/hand-pen-01.svg'),
        btnMessage: 'Edit your Message',
        handleOnClick: handleShowEditMessageModal,
        id: 'edit-your-message-btn'
      },
      {
        btnIcon: require('./../assets/images/hand-pen-01.svg'),
        btnMessage: 'Edit default messages',
        handleOnClick: handleShowEditDefaultMessagesModal,
        id: 'edit-default-messages-btn'
      },
    ],
    cardImgList: [
      {
        cardImg: require('./../assets/images/Hello Greeting Card Front@3x.png'),
        cardLabel: 'Front',
      },
      {
        cardImg: require('./../assets/images/Message 24963281.PNG'),
        cardLabel: 'Inside 1',
      },
      // {
      //   cardImg: require('./../assets/images/Screen Shot 2020-01-29 at 11.53.10 PM@2x.png'),
      //   cardLabel: "Back"
      // }
    ],
  };

  const actionInfo = [
    {
      hasSubscribed: true,
      cardTitle: 'My Subscription',
      btnText: 'Edit Details',
      hasBtn: selectedStudent.subscription_type != 'theme',
      dayOfWeek: selectedStudent.day_of_week,
      id: 'my-subscription'
    },
    {
      hasStudentInfo: true,
      cardTitle: selectedStudent.subscription_type != 'theme' ? 'My Student' : 'My Recipient',
      studentName: 'Sarah Smith',
      studentUniv: 'University of Washington',
      studentUnivAdd: '1234 University Row, Building 15',
      studentUnivCode: 'Seattle, WA 98331',
      btnText: 'Edit Address',
      id: 'my-student'
    },
    {
      hasLastWeekCard: true,
      cardTitle: "Last Week's Card",
      cardImg: require('./../assets/images/Screen Shot 2020-01-29 at 11.44.42 PM@3x.png'),
      cardLabel: 'Inside',
      btnText: 'See More',
      id: 'last-week-card'
    },
  ];

  const textSplitter = (text) => {
    if (text !== null && text !== undefined && text !== '') {
      return text.split('\n').map((item, i) => (
        <p key={i} style={{ marginBottom: 0 }}>
          {item}
        </p>
      ));
    } else {
      return null;
    }
  };

  const getDefaultMessage = () => {
    let msg = '';
    // console.log(selectedStudent);
    if (currentCard.current_message === 'custom' || selectedStudent.custom_message) {
      msg = selectedStudent.custom_message;
    } else if (currentCard.current_message === 'default_message_1') {
      msg = selectedStudent.default_message_1;
    } else if (currentCard.current_message === 'default_message_2') {
      msg = selectedStudent.default_message_2;
    } else if (currentCard.current_message === 'default_message_3') {
      msg = selectedStudent.default_message_3;
    }
    return msg;
  };

  const getCurrentCard = () => {
    const card = currentCard.templateIDs.findIndex((templateID) => templateID === currentCard.template_id);
    if (card !== -1) {
      return card + 1;
    } else {
      return 0;
    }
  };

  const [activeKey, setActiveKey] = useState('third');

  return (
    <div className='dashboard-body'>
      <div className='container dash-container'>
        <div className='weekly-card-wrapper tab-holder'>
          <div className='c-teal weekly-card-title'>
            This Week's Card <span className='weekly-card-span'>( {selectedStudent.plan_selected} )</span>
          </div>
          {currentCard ? (
            <Tab.Container defaultActiveKey='third' activeKey={ runTutorial ? dashTabActiveKey : activeKey} onSelect={(eventKey) => setActiveKey(eventKey)}>
              <Row>
                <Col sm={3}>
                  <Nav variant='pills' className='flex-column' id='week-card-menu'>
                    <Nav.Item>
                      <Nav.Link eventKey='first' id='front-cover'>Front Cover</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='second' id='weekly-inspiration'>Weekly Inspiration</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link ref={yourMessageButtonRef} eventKey='third' id='your-message'>
                        Your Message
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey='first'>
                      {/* <div className="preview-img-holder"><img src={dashInfo.cardImgList[0].cardImg} /></div> */}
                      <div className='preview-img-holder' id='front-cover-tab'>
                        {selectedStudent.subscription_type !== 'theme' ? (
                          <img alt='cover' src={`${currentCard.front}?${new Date().getTime()}`} />
                        ) : (
                          <img
                            alt='cover'
                            src={currentCard.front ? currentCard.front : require('./../assets/images/EOT Cover.png')}
                          />
                        )}
                        <span className='current-card-iterations'>
                          {currentCard.max_card ? `Card ${getCurrentCard()} of ${currentCard.max_card}` : ''}
                        </span>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='second'>
                      {/* <div className="preview-img-holder"><img src={dashInfo.cardImgList[1].cardImg} /></div> */}
                      <div className='preview-img-holder'>
                        {selectedStudent.subscription_type !== 'theme' ? (
                          <img alt='cover' src={`${currentCard.inside_1}?${new Date().getTime()}`} />
                        ) : (
                          <img
                            alt='cover'
                            src={
                              currentCard.inside_1
                                ? currentCard.inside_1
                                : require('./../assets/images/End of theme message.png')
                            }
                          />
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='third'>
                      <div className='inside-two-holder' id='your-message-tab'>
                        <div className='custom-message-holder'>
                          <div className='opening-holder'>
                            {selectedStudent.greeting} {selectedStudent.nick_name},
                          </div>
                          <div className='message-holder'>{textSplitter(getDefaultMessage())}</div>
                          <div className='closing-holder'>
                            {selectedStudent.sign_off}, {selectedStudent.name_as_sign_off}
                          </div>
                        </div>
                      </div>
                      <div className='buttons-holder'>
                        {dashInfo.btnList.map((btn, j) => {
                          return (
                            <div className='btn-holder' key={j}>
                              <div className='icon-holder'>
                                <img src={btn.btnIcon} alt='Button' className='btn-icon' />
                              </div>
                              <button className='btn-white c-purple' onClick={btn.handleOnClick} id={btn.id}>
                                {btn.btnMessage}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          ) : (
            <div className='spinner-holder'>
              <Spinner animation='border' role='status' aria-hidden='true' />
            </div>
          )}
          {/* <div className="cards-holder">
            {dashInfo.cardImgList.map((card, i) => {
              return (
                <div key={i} className="single-card-holder">
                  <img className="weekly-card" src={card.cardImg} alt={card.cardLabel} />
                  <div className="card-label t-h3">{card.cardLabel}</div>
                </div>
              )
            })}
          </div> */}
          {/* <div className="card-btn-holder">
            {dashInfo.btnList.map((btn, j) => {
              return (
                <div className="btn-holder" key={j}>
                  <div className="icon-holder">
                    <img src={btn.btnIcon} alt="Button" className="btn-icon" />
                  </div>
                  <button className="btn-white c-purple" onClick={btn.handleOnClick}>{btn.btnMessage}</button>
                </div>
              )
            })}
          </div> */}
        </div>
        <div className='quick-action-wrapper'>
          <div className='c-teal weekly-card-title'>Quick Actions</div>
          <div className='action-card-wrapper'>
            {actionInfo.map((action, i) => {
              return <QuickActionCard key={i} actionInfo={action} user={user} selectedStudent={selectedStudent} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
