import React, { useContext, Fragment } from 'react'

import { Context } from '../context/Context'

import Modal from 'react-bootstrap/Modal'
// import api from '../api/api';

const MonthlyNewsletterModal = ({ newsletter }) => {
  const { showNewsletterModal, handleCloseNewsletterModal } = useContext(Context)

  return (
    <Modal
      className="c2c-modal"
      show={showNewsletterModal}
      onHide={handleCloseNewsletterModal}
      centered
      backdrop="static"
    >
      <div className="btn-close-modal" onClick={handleCloseNewsletterModal}></div>
      <h2 className=" title t-h1 c-purple newsletter-title">{newsletter.title}</h2>
      <p className="newsletter-content">{newsletter.content}</p>
    </Modal>
  )
}

export default MonthlyNewsletterModal
