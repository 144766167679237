import React from 'react';

const TemplateUploader = ({title, name, inputRef, handleOnChange, src, handleClickChange, dataBtn}) => {
  return (
    <div className="template-uploader">
      <div className="form-group required">
        <h3 className="info-title t-h2 c-grayish-navy">{title}</h3>
        <input
          className="text-holder t-h1"
          type="file"
          name={name}
          accept="image/png"
          ref={inputRef}
          onChange={handleOnChange}
          hidden
        />
        <div className="image-wrapper">
          <img src={src} />
          <button
            data-image-btn={dataBtn}
            className="btn-image"
            onClick={handleClickChange}
          >
            {src ? "Change Photo" : "Add Photo"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TemplateUploader