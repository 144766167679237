import React from 'react';
import C2CModal from "../NewComponents/C2CModal";
import ThemePackTemplateForm from "./ThemePackTemplateForm"
import { Context } from "../../context/Context"
import api from '../../api/api'

const AddThemePackTemplateModal = () => {
  const {
    addThemePackTemplateModalState,
    handleCloseAddThemePackTemplateModal,
    setThemePackTemplates,
    setThemePackTemplateProcessState,
    tokenAdmin
  } = React.useContext(Context)

  const handleSave = (templateData) => {
    setThemePackTemplateProcessState(true)
    api.handleAddThemePackTemplate(tokenAdmin, templateData).then(() => {
      api.getAllThemePacks(tokenAdmin, setThemePackTemplates).then(() => {
        setThemePackTemplateProcessState(false)
        handleCloseAddThemePackTemplateModal()
      })
    })
  }

  return (
    <C2CModal
      show={addThemePackTemplateModalState}
      onHide={handleCloseAddThemePackTemplateModal}
      title={`Add New Theme Pack Templates`}
    >
      <ThemePackTemplateForm onSave={handleSave} onCancel={handleCloseAddThemePackTemplateModal} saveBtnTxt={"Add"} />
    </C2CModal>
  )
}

export default AddThemePackTemplateModal