import React, { useContext, useState } from 'react'

import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import { Context } from '../context/Context'
import Iframe from './Iframe'

const CardPreviewModal = ({ sentDate, cardPreviewUrl, handleOnload }) => {
  const { showCardPreviewModal, handleCloseCardPreviewModal } = useContext(
    Context,
  )
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const handleOnLoad = () => {
    setIframeLoaded(true)
  }

  const handleClose = () => {
    setIframeLoaded(false)
    handleCloseCardPreviewModal()
  }

  return (
    <Modal
      className="c2c-modal"
      show={showCardPreviewModal}
      onHide={handleClose}
      centered
      dialogClassName="card-preview-modal"
      backdrop="static"
    >
      <div className="btn-close-modal" onClick={handleClose}></div>
      <h2 className="title t-h1 c-grayish-navy">{sentDate} Card Preview</h2>
      {!iframeLoaded && (
        <div className="d-flex justify-content-center h-100 p-4">
          <Spinner animation="border" role="status" aria-hidden="true" />
        </div>
      )}
      <Iframe
        src={cardPreviewUrl}
        width="100%"
        height="100%"
        handleOnLoad={handleOnLoad}
      />
    </Modal>
  )
}

export default CardPreviewModal
