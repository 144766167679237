import React from 'react'
import TemplateUploader from './TemplateUploader'
import Spinner from 'react-bootstrap/Spinner'
import { validateWhiteSpace } from '../util'
import { Context } from "../../context/Context"

const ThemePackTemplateForm = ({onSave, onCancel, saveBtnTxt}) => {
  const {
    themePackTemplateProcessState,
    currentThemePackTemplate,
    setCurrentThemePackTemplate,
  } = React.useContext(Context)

  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    setData({...currentThemePackTemplate})
  }, [setCurrentThemePackTemplate])

  const coverRef1 = React.useRef(null);
  const messageRef1 = React.useRef(null);
  const coverRef2 = React.useRef(null);
  const messageRef2 = React.useRef(null);
  const coverRef3 = React.useRef(null);
  const messageRef3 = React.useRef(null);
  const coverRef4 = React.useRef(null);
  const messageRef4 = React.useRef(null);
  const [cover1Clicked, setCover1Clicked] = React.useState(false);
  const [msg1Clicked, setMsg1Clicked] = React.useState(false);
  const [cover2Clicked, setCover2Clicked] = React.useState(false);
  const [msg2Clicked, setMsg2Clicked] = React.useState(false);
  const [cover3Clicked, setCover3Clicked] = React.useState(false);
  const [msg3Clicked, setMsg3Clicked] = React.useState(false);
  const [cover4Clicked, setCover4Clicked] = React.useState(false);
  const [msg4Clicked, setMsg4Clicked] = React.useState(false);
  const cover1Btn = "cover1Btn"
  const msg1Btn = "msg1Btn"
  const cover2Btn = "cover2Btn"
  const msg2Btn = "msg2Btn"
  const cover3Btn = "cover3Btn"
  const msg3Btn = "msg3Btn"
  const cover4Btn = "cover4Btn"
  const msg4Btn = "msg4Btn"

  const handleOnChange = function (e) {
    if (e.target == undefined) return
    const inputType = e.target.type

    if (inputType === 'text') {
      const target = e.target.name
      const value = e.target.value
      setData({
        ...data,
        [target]: value,
      })
      return
    } else if (inputType === 'file') {
      const imageObject = e.target.files[0] || e.dataTransfer.files[0]
      const fileName = imageObject.name
      const fileType = imageObject.type
      const inputName = e.target.name

      if (fileType !== 'image/png') {
        alert('Image file type is not supported. Please choose PNG image.')
        return
      } else {
        const reader = new FileReader()
        reader.onload = (e) => {
          setData({
            ...data,
            [inputName]: e.target.result,
          })
        }
        reader.readAsDataURL(imageObject)
        return
      }
    }
  }

  const handleClickChange = (event) => {
    const id = event.target.dataset.imageBtn;

    if (id === cover1Btn) {
      coverRef1.current.click();
      setCover1Clicked(true);
    } else if (id === msg1Btn) {
      messageRef1.current.click();
      setMsg1Clicked(true);
    } else if (id === cover2Btn) {
      coverRef2.current.click();
      setCover2Clicked(true);
    } else if (id === msg2Btn) {
      messageRef2.current.click();
      setMsg2Clicked(true);
    } else if (id === cover3Btn) {
      coverRef3.current.click();
      setCover3Clicked(true);
    } else if (id === msg3Btn) {
      messageRef3.current.click();
      setMsg3Clicked(true);
    } else if (id === cover4Btn) {
      coverRef4.current.click();
      setCover4Clicked(true);
    } else if (id === msg4Btn) {
      messageRef4.current.click();
      setMsg4Clicked(true);
    } else {
      alert("No id found");
    }
  };

  const handleOnSave = () => {
    if (validateWhiteSpace(data.theme_name) === -1 || data.theme_name === '') {
      alert('Theme name is required')
    } else if (
      validateWhiteSpace(data.theme_code) === -1 ||
      data.theme_code === ''
    ) {
      alert('Stripe code is required')
    } else if (
      validateWhiteSpace(data.template_id1) === -1 ||
      data.template_id1 === ''
    ) {
      alert('First template Template ID is required')
    } else if (
      validateWhiteSpace(data.message_1) === -1 ||
      data.message_1 === ''
    ) {
      alert('First template Template Name is required')
    } else if (
      validateWhiteSpace(data.template_id2) === -1 ||
      data.template_id2 === ''
    ) {
      alert('Second template Template ID is required')
    } else if (
      validateWhiteSpace(data.message_2) === -1 ||
      data.message_2 === ''
    ) {
      alert('Second template Template Name is required')
    } else if (
      validateWhiteSpace(data.template_id3) === -1 ||
      data.template_id3 === ''
    ) {
      alert('Third template Template ID is required')
    } else if (
      validateWhiteSpace(data.message_3) === -1 ||
      data.message_3 === ''
    ) {
      alert('Third template Template Name is required')
    } else if (
      validateWhiteSpace(data.template_id4) === -1 ||
      data.template_id4 === ''
    ) {
      alert('First template Template ID is required')
    } else if (
      validateWhiteSpace(data.message_4) === -1 ||
      data.message_4 === ''
    ) {
      alert('First template Template Name is required')
    } else if (
      validateWhiteSpace(data.front_image) === -1 ||
      data.front_image === ''
    ) {
      alert('First template Front Card Cover image is required')
    } else if (
      validateWhiteSpace(data.inside_message) === -1 ||
      data.inside_message === ''
    ) {
      alert('First template Inside 1 Message image is required')
    } else if (
      validateWhiteSpace(data.front_image2) === -1 ||
      data.front_image2 === ''
    ) {
      alert('Second template Front Card Cover image is required')
    } else if (
      validateWhiteSpace(data.inside_message2) === -1 ||
      data.inside_message2 === ''
    ) {
      alert('Second template Inside 1 Message image is required')
    } else if (
      validateWhiteSpace(data.front_image3) === -1 ||
      data.front_image3 === ''
    ) {
      alert('Third template Front Card Cover image is required')
    } else if (
      validateWhiteSpace(data.inside_message3) === -1 ||
      data.inside_message3 === ''
    ) {
      alert('Third template Inside 1 Message image is required')
    } else if (
      validateWhiteSpace(data.front_image4) === -1 ||
      data.front_image4 === ''
    ) {
      alert('Fourth template Front Card Cover image is required')
    } else if (
      validateWhiteSpace(data.inside_message4) === -1 ||
      data.inside_message4 === ''
    ) {
      alert('Fourth template Inside 1 Message image is required')
    } else {
      setCurrentThemePackTemplate(data)
      onSave(data)
    }
  }

  return (
    <React.Fragment>
      <div className="themepack-template-holder">
        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Theme Pack Info</h3>
          </span>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="theme_name"
              placeholder="Theme Name"
              value={data?.theme_name || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Theme Name</div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="theme_code"
              placeholder="Stripe Code"
              value={data?.theme_code || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Stripe Code</div>
          </div>


        </div>

        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">First Template</h3>
          </span>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="template_id1"
              placeholder="Template ID"
              value={data?.template_id1 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template ID</div>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="message_1"
              placeholder="Template Name"
              value={data?.message_1 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template Name</div>
          </div>

          <div className="template-uploader-wrapper">
            <TemplateUploader
              title="Front Card Cover"
              name="front_image"
              inputRef={coverRef1}
              handleOnChange={handleOnChange}
              src={data?.front_image ?? null}
              handleClickChange={handleClickChange}
              dataBtn={cover1Btn}
            />
            <TemplateUploader
              title="Inside 1 Message"
              name="inside_message"
              inputRef={messageRef1}
              handleOnChange={handleOnChange}
              src={data?.inside_message ?? null}
              handleClickChange={handleClickChange}
              dataBtn={msg1Btn}
            />
          </div>
        </div>

        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Second Template</h3>
          </span>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="template_id2"
              placeholder="Template ID"
              value={data?.template_id2 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template ID</div>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="message_2"
              placeholder="Template Name"
              value={data?.message_2 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template Name</div>
          </div>
          <div className="template-uploader-wrapper">
            <TemplateUploader
              title="Front Card Cover"
              name="front_image2"
              inputRef={coverRef2}
              handleOnChange={handleOnChange}
              src={data?.front_image2 ?? null}
              handleClickChange={handleClickChange}
              dataBtn={cover2Btn}
            />
            <TemplateUploader
              title="Inside 1 Message"
              name="inside_message2"
              inputRef={messageRef2}
              handleOnChange={handleOnChange}
              src={data?.inside_message2 ?? null}
              handleClickChange={handleClickChange}
              dataBtn={msg2Btn}
            />
            </div>
        </div>

        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Third Template</h3>
          </span>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="template_id3"
              placeholder="Template ID"
              value={data?.template_id3 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template ID</div>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="message_3"
              placeholder="Template Name"
              value={data?.message_3 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template Name</div>
          </div>
          <div className="template-uploader-wrapper">
            <TemplateUploader
              title="Front Card Cover"
              name="front_image3"
              inputRef={coverRef3}
              handleOnChange={handleOnChange}
              src={data?.front_image3 ?? null}
              handleClickChange={handleClickChange}
              dataBtn={cover3Btn}
            />
            <TemplateUploader
              title="Inside 1 Message"
              name="inside_message3"
              inputRef={messageRef3}
              handleOnChange={handleOnChange}
              src={data?.inside_message3 ?? null}
              handleClickChange={handleClickChange}
              dataBtn={msg3Btn}
            />
            </div>
        </div>

        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Fourth Template</h3>
          </span>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="template_id4"
              placeholder="Template ID"
              value={data?.template_id4 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template ID</div>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="message_4"
              placeholder="Template Name"
              value={data?.message_4 || ""}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Template Name</div>
          </div>

          <div className="template-uploader-wrapper">
            <TemplateUploader
              title="Front Card Cover"
              name="front_image4"
              inputRef={coverRef4}
              handleOnChange={handleOnChange}
              src={data?.front_image4 ?? null}
              handleClickChange={handleClickChange}
              dataBtn={cover4Btn}
            />
            <TemplateUploader
              title="Inside 1 Message"
              name="inside_message4"
              inputRef={messageRef4}
              handleOnChange={handleOnChange}
              src={data?.inside_message4 ?? null}
              handleClickChange={handleClickChange}
              dataBtn={msg4Btn}
            />
          </div>
        </div>
      </div>

      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={onCancel}
          disabled={themePackTemplateProcessState}
        >
          <span>Cancel</span>
        </button>
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={handleOnSave}
          disabled={themePackTemplateProcessState}
        >
          {themePackTemplateProcessState && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          {themePackTemplateProcessState && <span>Please wait ...</span>}
          {!themePackTemplateProcessState && <span>{saveBtnTxt}</span>}
        </button>
      </div>
    </React.Fragment>
  )
}

export default ThemePackTemplateForm