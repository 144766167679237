import React, { useState, useContext, Fragment, useEffect } from 'react'

import { Context } from '../context/Context'

import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import api from '../api/api'
import { validateWhiteSpace } from './util'

const EditDefaultMessagesModal = () => {
  const {
    showEditDefaultMessagesModal,
    setShowEditDefaultMessagesModal,
    handleCloseEditDefaultMessagesModal,
    updateDefaultMessagesLoading,
    selectedStudent,
    setSelectedStudent,
    onChangeStudentData,
    setUpdateDefaultMessagesLoading,
    token,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
  } = useContext(Context)

  const [data, setData] = useState({
    id: '',
    default_message_1: '',
    default_message_2: '',
    default_message_3: '',
  })

  useEffect(() => {
    setData({
      id: selectedStudent.id,
      default_message_1: selectedStudent.default_message_1,
      default_message_2: selectedStudent.default_message_2 === null ? '' : selectedStudent.default_message_2,
      default_message_3: selectedStudent.default_message_3 === null ? '' : selectedStudent.default_message_3,
    })
  }, [showEditDefaultMessagesModal])

  const updateData = e => {
    const target = e.target.name
    let value = e.target.value

    const ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
    ];
    
    if (value.match(ranges.join('|'))) {
      value = value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
      if (!e.target.dataset.alerted) {
        alert("We cannot print emojis at this time");
        e.target.dataset.alerted = true;
      }
    } else {
      e.target.dataset.alerted = false;
    }
    
    setData({
      ...data,
      [target]: value,
    })
  }

  const editStudentMessages = () => {
    setUpdateDefaultMessagesLoading(true)
    api
      .handleUpdateStudent(token, data, setSelectedStudent, newRecipientData, setNewRecipientData, getSubscriptionType)
      .then(() => {
        setUpdateDefaultMessagesLoading(false)
        handleCloseEditDefaultMessagesModal()
      })
  }

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(data.default_message_1) === -1 || data.default_message_1 === '') {
      alert('Default message 1 is required')
    } else {
      editStudentMessages()
    }
  }

  const onKeyPress = event => {
    var lines = 9
    var newLines = event.target.value.split('\n').length
    if (event.which === 13 && newLines >= lines) {
      event.preventDefault()
    }

    if (event.key === 'Enter') {
      if (event.target.value.length <= 270) {
        event.target.value = event.target.value + '                             '
      } else {
        event.preventDefault()
      }
    }
  }

  return (
    <Modal
      className="c2c-modal default-message-modal"
      show={showEditDefaultMessagesModal}
      onHide={handleCloseEditDefaultMessagesModal}
      centered
      backdrop={'static'}>
      <div className="btn-close-modal" onClick={handleCloseEditDefaultMessagesModal}></div>
      <h2 className="title t-h1 c-grayish-navy"> Edit Default Messages </h2>
      <div className="messages-holder">
        <div className="form-group">
          <label className="info-title t-h1 c-grayish-navy">Message 1</label>
          <textarea
            className="message-box"
            name="default_message_1"
            placeholder="Default Message 1"
            value={data.default_message_1}
            onChange={updateData}
            maxLength="325"
            onKeyPress={onKeyPress}
          />
          <div className="text-count">{data.default_message_1 ? data.default_message_1.length : 0} / 325</div>
        </div>
        <div className="form-group">
          <label className="info-title t-h1 c-grayish-navy">Message 2</label>
          <textarea
            className="message-box"
            name="default_message_2"
            placeholder="Default Message 2"
            value={data.default_message_2}
            onChange={updateData}
            maxLength="325"
            onKeyPress={onKeyPress}
          />
          <div className="text-count">{data.default_message_2 ? data.default_message_2.length : 0} / 325</div>
        </div>
        <div className="form-group">
          <label className="info-title t-h1 c-grayish-navy">Message 3</label>
          <textarea
            className="message-box"
            name="default_message_3"
            placeholder="Default Message 3"
            value={data.default_message_3}
            onChange={updateData}
            maxLength="325"
            onKeyPress={onKeyPress}
          />
          <div className="text-count">{data.default_message_3 ? data.default_message_3.length : 0} / 325</div>
        </div>
      </div>
      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={updateDefaultMessagesLoading}>
          {updateDefaultMessagesLoading && (
            <Spinner className="mr-2" animation="border" role="status" aria-hidden="true" />
          )}
          {updateDefaultMessagesLoading && <span>Please wait</span>}
          {!updateDefaultMessagesLoading && <span>Save</span>}
        </button>
      </div>
    </Modal>
  )
}

export default EditDefaultMessagesModal
