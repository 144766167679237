import React, { useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import cookie from 'react-cookies';
import { useLocation } from 'react-router';
const TOKEN_KEY = 'jwt';
const ADMIN_KEY = 'jwt-admin';

export const logEvent = (name) => (event) => {
  console.log(`[${name}]`, event);
};

export const Result = ({ children }) => <div className='result'>{children}</div>;

export const ErrorResult = ({ children }) => <div className='error'>{children}</div>;

// Demo hook to dynamically change font size based on window size.
export const useDynamicFontSize = () => {
  const [fontSize, setFontSize] = useState(window.innerWidth < 450 ? '14px' : '18px');

  useEffect(() => {
    const onResize = () => {
      setFontSize(window.innerWidth < 450 ? '14px' : '18px');
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return fontSize;
};

export const isLogin = () => {
  if (cookie.load(TOKEN_KEY) !== undefined) {
    return true;
  }
  return false;
};

export const isAdmin = () => {
  if (cookie.load(ADMIN_KEY) !== undefined) {
    return true;
  }
  return false;
};

export const validateEmailAddress = (email) => {
  const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
  if (regex.test(email)) {
    return 1;
  } else {
    return -1;
  }
};

export const validateEmailExists = (data, email) => {
  const arr = data.students;
  const found = arr.some((el) => el.student_email === email);
  if (!found) {
    return 1;
  } else {
    return -1;
  }
};

export const validatePassword = (password) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]<>\/\\',-]{8,}$/;
  if (regex.test(password)) {
    return 1;
  } else {
    return -1;
  }
};

export const validateWhiteSpace = (data) => {
  const regex = /^\s+$/;
  if (regex.test(data)) {
    return -1;
  } else {
    return 1;
  }
};

export const validateUserInfo = (data, callback, extras) => {
  if (validateWhiteSpace(data.first_name) === -1 || data.first_name === '') {
    alert('First name is required');
  } else if (validateWhiteSpace(data.last_name) === -1 || data.last_name === '') {
    alert('Last name is required');
  } else if (validateWhiteSpace(data.email) === -1 || data.email === '') {
    alert('Email is required');
  } else if (validateEmailAddress(data.email) === -1) {
    alert('Invalid email address');
  } else if (extras.emailexists) {
    alert('Email already taken');
  } else if (!isValidPhoneNumber(data.cell_phone) && data.cell_phone !== '') {
    alert('Not a valid phone number');
  } else if (validatePassword(data.password) === -1) {
    alert('Password must be 8 characters or longer & include uppercase, lowercase, and number, special characters optional.');
  } else if (validateWhiteSpace(data.confirmPassword) === -1 || data.confirmPassword === '') {
    alert('Confirm password is required');
  } else if (data.password !== data.confirmPassword) {
    alert('Password does not match');
  } else if (validateWhiteSpace(data.street) === -1 || data.street === '') {
    alert('Street Address is required');
  } else if (validateWhiteSpace(data.city) === -1 || data.city === '') {
    alert('City is required');
  } else if (validateWhiteSpace(data.state) === -1 || data.state === '') {
    alert('State is required');
  } else if (validateWhiteSpace(data.zipcode) === -1 || data.zipcode === '') {
    alert('Zipcode is required');
  } else {
    callback();
  }
};

export const validateRecipientInfo = (data, callback, extras) => {
  if (validateWhiteSpace(data.student_first_name) === -1 || data.student_first_name === '') {
    alert('First name is required');
  } else if (validateWhiteSpace(data.student_last_name) === -1 || data.student_last_name === '') {
    alert('Last name is required');
  } else if ((validateWhiteSpace(data.schoolname) === -1 || data.schoolname === '') && data.subscription_type !== 'theme') {
    alert('School is required');
  } else if ((validateWhiteSpace(data.semester) === -1 || data.semester === '') && data.subscription_type !== 'theme') {
    alert(`First day of current ${data.subscription_type === 'semester' ? 'semester' : 'quarter'} is required`);
  } else if ((validateWhiteSpace(data.season) === -1 || data.season === '') && data.subscription_type === 'semester') {
    alert('Starting semester is required');
  } else if ((validateWhiteSpace(data.quarter) === -1 || data.quarter === '') && data.subscription_type === 'quarter') {
    alert('Starting quarter is required');
  } else if (
    validateEmailAddress(data.student_email) === -1 &&
    data.student_email !== '' &&
    data.subscription_type !== 'theme'
  ) {
    alert('Invalid email address');
  } else if (
    validateEmailExists(data, data.student_email) === -1 &&
    data.student_email !== '' &&
    data.subscription_type !== 'theme'
  ) {
    alert('Email already in use in one of your students');
  } else if (extras.studentEmailExists && data.student_email !== '' && data.subscription_type !== 'theme') {
    alert('Email already taken');
  } else if (
    (validateWhiteSpace(data.student_street) === -1 || data.student_street === '') &&
    data.subscription_type === 'theme'
  ) {
    alert('Address 1 is required');
  }
  // else if (
  //   (validateWhiteSpace(data.student_unit) === -1 ||
  //   data.student_unit === "") && (data.subscription_type === "theme")
  // ) {
  //   alert('Apt/Suite/Unit,Building is required');
  // }
  else if ((validateWhiteSpace(data.student_city) === -1 || data.student_city === '') && data.subscription_type === 'theme') {
    alert('City is required');
  } else if (
    (validateWhiteSpace(data.student_state) === -1 || data.student_state === '') &&
    data.subscription_type === 'theme'
  ) {
    alert('State is required');
  } else if (
    (validateWhiteSpace(data.student_zipcode) === -1 || data.student_zipcode === '') &&
    data.subscription_type === 'theme'
  ) {
    alert('Zipcode is required');
  } else if (validateWhiteSpace(data.plan_selected) === -1 || data.plan_selected === '') {
    alert('Subscription Plan is required');
  } else {
    callback();
  }
};

export const validateCustomMessage = (data, callback, extras) => {
  if (validateWhiteSpace(data.greeting) === -1 || data.greeting === '') {
    alert('Greeting is required');
  } else if (validateWhiteSpace(data.nick_name) === -1 || data.nick_name === '') {
    alert('Nickname is required');
  } else if (validateWhiteSpace(data.sign_off) === -1 || data.sign_off === '') {
    alert('Sign off is required');
  } else if (validateWhiteSpace(data.personal_sign_off) === -1 || data.personal_sign_off === '') {
    alert('Name as sign off is required');
  } else if (validateWhiteSpace(data.message_box1) === -1 || data.message_box1 === '') {
    alert('Message is required');
  } else {
    callback();
  }
};

export const validateSubscriptionDetails = (data, callback, extras) => {
  if (validateWhiteSpace(data.plan_selected) === -1 || data.plan_selected === '') {
    alert('Subscription Plan is required');
  } else {
    callback();
  }
};

export const validateCoupon = (data, callback, extras) => {
  if (validateWhiteSpace(data.coupon) === -1 || data.coupon === '') {
    alert('Please enter a coupon.');
  } else {
    callback();
  }
};

export const validateCollegeDates = (collegeDates) => {
  let isValidDates = false;

  if (collegeDates.length > 0) {
    isValidDates = true;
  }
  return isValidDates;
};

export const formatDate = (date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
};

export const isMonday = (date) => {
  const day = date.getDay();
  return day === 1;
};

export const isSignUpPage = () => {
  const location = window.location.hash;

  if (location === '#/sign-up') {
    return true;
  }

  return false;
};
