import React, { useContext, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Spinner from 'react-bootstrap/Spinner'

import { Context } from '../context/Context'
import api from '../api/api'

import UpdateTwilioMsgModal from '../components/UpdateTwilioMsgModal'

const AdminManageTwilio = () => {
  const {
    tokenAdmin,
    twilioMessages,
    setTwilioMessages,
    handleShowUpdateTwilioModal,
    handleCloseLoaderModal
  } = useContext(Context)

  const [twilioIsLoading, setTwilioIsLoading] = useState(true)

  const [twilioData, setTwilioData] = useState({})

  useEffect(() => {
    if (tokenAdmin) {
      api.handleGetTwilioMsgs(tokenAdmin, setTwilioMessages).then(() => {
        setTwilioIsLoading(false)
        handleCloseLoaderModal()
      })
    }
  }, [setTwilioMessages, tokenAdmin])

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      maxWidth: '100px',
    },
    {
      name: 'Message Content',
      selector: 'message_content',
      sortable: false,
    },
    {
      name: 'Message Code',
      selector: 'message_code',
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'Message Description',
      selector: 'message_desc',
      sortable: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
      },
    },
  }
  const handleOnRowClicked = (row, event) => {
    setTwilioData(row)

    handleShowUpdateTwilioModal()
  }

  return (
    <div className="dashboard-body">
      <div className="container dash-container">
        <div className="colleges-table-wrapper">
          <div className="section-heading-holder">
            <div className="c-teal section-title">Manage Twilio Messages</div>
          </div>
          <div className="table-holder">
            <DataTable
              columns={columns}
              data={twilioMessages}
              defaultSortField="id"
              pagination={false}
              customStyles={customStyles}
              highlightOnHover={true}
              pointerOnHover={false}
              dense={false}
              progressPending={twilioIsLoading}
              noHeader={true}
              selectableRowsHighlight={true}
              progressComponent={
                <Spinner animation="border" role="status" aria-hidden="true" />
              }
              // paginationPerPage={15}
              // paginationRowsPerPageOptions={[15, 20, 25, 30]}
              pointerOnHover={true}
              onRowClicked={handleOnRowClicked}
              responsive={true}
            />
          </div>
        </div>
      </div>
      <UpdateTwilioMsgModal twilioData={twilioData} twilioIsLoading={twilioIsLoading} setTwilioIsLoading={setTwilioIsLoading} />
    </div>
  )
}

export default AdminManageTwilio
