import React, { useContext, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import InfoBox from './InfoBox';

import api_base from '../api/api_base_url';
import api from '../api/api';
import { Context } from '../context/Context';

const AdminHome = () => {
  const { superAdminSummary, setSuperAdminSummary, handleCloseLoaderModal, tokenAdmin } = useContext(Context);
  useEffect(() => {
    if (tokenAdmin) {
      api.handleSuperAdminSummary(tokenAdmin, setSuperAdminSummary).then(() => handleCloseLoaderModal());
    }
  }, [setSuperAdminSummary, tokenAdmin]);

  // console.log(superAdminSummary)

  return (
    <div className='dashboard-body'>
      <div className='container dash-container'>
        <div className='info-box-wrapper box-three'>
          <InfoBox heading='Parents' subheading='Signed Up' number={superAdminSummary.number_of_parents} />
          <InfoBox heading='Students' subheading='Currrently Signed Up' number={superAdminSummary.number_of_students} />
          <InfoBox heading='Cards Out' subheading='This Week' number={superAdminSummary.number_of_cards_out} />
        </div>
        {superAdminSummary.student_each_plan != undefined ? (
          <>
            <div className='info-box-wrapper box-two'>
              {/* Weekly Gift Card */}
              <InfoBox
                heading='Students'
                subheading={superAdminSummary.student_each_plan[0].plan_selected}
                number={superAdminSummary.student_each_plan[0].total}
              />

              {/* Weekly Card + Weekly Gift Card */}
              <InfoBox
                heading='Students'
                subheading={superAdminSummary.student_each_plan[3].plan_selected}
                number={superAdminSummary.student_each_plan[3].total}
              />
            </div>
            <div className='info-box-wrapper box-two'>
              {/* Weekly Card + Monthly Gift */}
              <InfoBox
                heading='Students'
                subheading={superAdminSummary.student_each_plan[1].plan_selected}
                number={superAdminSummary.student_each_plan[1].total}
              />

              {/* Weekly Card + Monthly Gift + Weekly Gift Card */}
              <InfoBox
                heading='Students'
                subheading={superAdminSummary.student_each_plan[2].plan_selected}
                number={superAdminSummary.student_each_plan[2].total}
              />
            </div>
          </>
        ) : null}

        <div className='info-box-wrapper box-two'>
          <InfoBox heading='Colleges' subheading='Signed Up' number={superAdminSummary.different_college_signedup} />
          <InfoBox
            heading='People'
            subheading='Signed Up For Newsletter'
            number={superAdminSummary.number_of_newsletter}
          />
        </div>
        {/* <div className="info-box-wrapper box-one">
          <InfoBox
            heading="Active Students"
            subheading="Number of active students"
            number={superAdminSummary.number_of_active_students}
          />
          
        </div> */}
        {superAdminSummary.themepack_count && (
          <React.Fragment>
            <div className='info-box-wrapper box-two'>
              <InfoBox
                heading='Recipients'
                subheading={'Theme Pack Faith'}
                number={superAdminSummary.themepack_count['Theme Pack Faith'] ?? 0}
              />
              <InfoBox
                heading='Recipients'
                subheading={'Theme Pack Affirmation'}
                number={superAdminSummary.themepack_count['Theme Pack Affirmation'] ?? 0}
              />
            </div>
            <div className='info-box-wrapper box-two'>
              <InfoBox
                heading='Recipients'
                subheading={'Theme Pack Thinking of You'}
                number={superAdminSummary.themepack_count['Theme Pack Thinking of You'] ?? 0}
              />
              <InfoBox
                heading='Recipients'
                subheading={'Theme Pack Encouragement'}
                number={superAdminSummary.themepack_count['Theme Pack Encouragement'] ?? 0}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AdminHome;
