import React, { useRef, useState, useEffect } from 'react'

const AddAffiliateForm = ({ data, handleOnChange, title }) => {
  return (
    <>
      <h2 className="title t-h1 c-grayish-navy">{title}</h2>
      <div className="template-info-holder">
        <div className="form-group required">
          <span className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Affiliate Info</h3>
          </span>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="name"
              placeholder="Name"
              value={data?.name || ''}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Name of Affiliate</div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="code"
              placeholder="Code"
              value={data?.code || ''}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Affiliate Code</div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="email"
              name="email"
              placeholder="Email"
              value={data?.email || ''}
              onChange={handleOnChange}
            />
            <div className="input-subtext">Affiliate Email</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddAffiliateForm
