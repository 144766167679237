import React from 'react'

const ContactStepThree = ({ data, handleOnChange }) => {
  const onKeyPress = event => {
    var lines = 9
    var newLines = event.target.value.split('\n').length
    if (event.which === 13 && newLines >= lines) {
      event.preventDefault()
    }

    if (event.key === 'Enter') {
      if (event.target.value.length <= 270) {
        event.target.value = event.target.value + '                             '
      } else {
        event.preventDefault()
      }
    }
  }

  return (
    <div className="step-three">
      <h2 className="title t-h1 c-grayish-navy">
        {data.subscription_type === 'theme' ? '' : "Let's Encourage Your Student"}
      </h2>
      <div className="greeting-holder">
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">Greeting</h3>

          <div className="input-wrapper greeting-msg">
            <input
              className="text-holder t-h1"
              type="text"
              name="greeting"
              placeholder="Dear/Hey/Hi"
              value={data.greeting}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="nick_name"
              placeholder="Name/Nickname"
              value={data.nick_name}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>
        </div>
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">Signoff</h3>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="sign_off"
              placeholder="Love/From"
              value={data.sign_off}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper signoff-mssg ">
            <input
              className="text-holder t-h1"
              type="text"
              name="personal_sign_off"
              placeholder="Mom/Dad/Mentor"
              value={data.personal_sign_off}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>
        </div>
      </div>
      <div className="default-message-holder">
        {/* <div className="title-holder">
          <h3 className="info-title t-h1 c-grayish-navy">Default Message</h3>
          <span className="label c-grayish-navy">
            {data.subscription_type != 'theme'
              ? 'Your default messages will be used anytime you don’t personalize.'
              : "Message 1 will be used for tonight's card."}
          </span>
        </div> */}
        <div className="messages-holder">
          <div className="form-group required" style={{ width: '100%' }}>
            <label className="info-title t-h1 c-grayish-navy">Message</label>
            <textarea
              className="message-box"
              name="message_box1"
              placeholder="Message"
              value={data.message_box1}
              onChange={handleOnChange}
              maxLength="325"
              onKeyPress={onKeyPress}
            />
            <div className="text-count">{data.message_box1 ? data.message_box1.length : 0} / 325</div>
          </div>
          {/* <div className="form-group">
            <label className="info-title t-h1 c-grayish-navy">Message 2</label>
            <textarea
              className="message-box"
              name="message_box2"
              placeholder="Message"
              value={data.message_box2}
              onChange={handleOnChange}
              maxLength="325"
              onKeyPress={onKeyPress}
            />
            <div className="text-count">{data.message_box2 ? data.message_box2.length : 0} / 325</div>
          </div>
          <div className="form-group">
            <label className="info-title t-h1 c-grayish-navy">Message 3</label>
            <textarea
              className="message-box"
              name="message_box3"
              placeholder="Message"
              value={data.message_box3}
              onChange={handleOnChange}
              maxLength="325"
              onKeyPress={onKeyPress}
            />
            <div className="text-count">{data.message_box3 ? data.message_box3.length : 0} / 325</div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ContactStepThree
