import React, { useContext, useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import DataTable from 'react-data-table-component'
import Spinner from 'react-bootstrap/Spinner'

import { Context } from '../context/Context'
import api from '../api/api'

import UpdateCollegeModal from '../components/UpdateCollegeModal'
import AddCollegeModal from '../components/AddCollegeModal'

const AdminManageColleges = () => {
  const {
    schools,
    setSchools,
    handleShowUpdateCollegeModal,
    handleShowAddCollegeModal,
    handleCloseLoaderModal,
    currentCollegeCat,
    setCurrentCollegeCat,
    schoolSemDetails,
    setSchoolSemDetails,
    currentSemDetails,
    setCurrentSemDetails,
    tokenAdmin,
  } = useContext(Context)

  const [schoolIsLoading, setSchoolIsLoading] = useState(true)

  const [_collegeData, _setCollegeData] = useState({
    id: '',
    college_name: '',
    nickname: '',
    city: '',
    state: '',
    zipcode: '',
    website_link: '',
    mental_health_link: '',
    // fall_semester_start_date: '',
    // spring_semester_start_date: '',
    // next_fall_semester_start_date: '',
    // next_spring_semester_start_date: '',
    dates: []
  })

  useEffect(() => {
    api.handleGetSchools(setSchools).then(() => {
      return api.handleGetSemDetails(setSchoolSemDetails)
    }).then(() => {
      setSchoolIsLoading(false)
      handleCloseLoaderModal();
    })
  }, [setSchools])

  const getSchoolsByType = (type) => {
    return schools.reduce((result, school) => {
      if (type == school.qtr_or_sem) {
        result.push(school);
      }
      return result;
    }, []);
  }

  const getFilteredSchool = () => {
    if (currentCollegeCat === "college_semester") {
      return getSchoolsByType("S")
    } else {
      return getSchoolsByType("Q")
    }
  }

  const columns = [
    {
      name: 'Name',
      selector: 'college_name',
      sortable: true,
    },
    {
      name: 'Nickname',
      selector: 'nickname',
      sortable: true,
    },
    {
      name: 'City',
      selector: 'city',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
    },
    {
      name: 'Zipcode',
      selector: 'zipcode',
      sortable: true,
    },
    {
      name: 'Website Link',
      selector: 'website_link',
      sortable: false,
    },
    {
      name: 'Mental Health Link',
      selector: 'mental_health_link',
      sortable: false,
    },
    // {
    //   name: 'Fall Start Date',
    //   selector: 'fall_semester_start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Spring Start Date',
    //   selector: 'spring_semester_start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Next Fall Start Date',
    //   selector: 'next_fall_semester_start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Next Spring Start Date',
    //   selector: 'next_spring_semester_start_date',
    //   sortable: false,
    // },
  ]

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        ref={ref}
        {...rest}
      />
      <label className="custom-control-label" onClick={onClick} />
    </div>
  ))

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
      },
    },
  }

  const getSemDetailsByCollege = (id) => {
    return schoolSemDetails.reduce((result, semDetail) => {
      if (id == semDetail.coll_id) {
        result.push(semDetail);
      }
      return result;
    }, []);
  }

  const handleOnRowClicked = (row, event) => {
    _setCollegeData(row)
    setCurrentSemDetails(getSemDetailsByCollege(row.id))
    handleShowUpdateCollegeModal()
  }

  const onChangeCollegeCategory = (e) => {
    const value = e.target.value
    setSchoolIsLoading(true)
    setCurrentCollegeCat(value)
    api.handleGetSchools(setSchools).then(() => {
      return api.handleGetSemDetails(setSchoolSemDetails)
    }).then(() => {
      setSchoolIsLoading(false)
    })
  }

  return (
    <div className="dashboard-body">
      <div className="container dash-container">
        <div className="colleges-table-wrapper">
          <div className="section-heading-holder">
            <div className="c-teal section-title">Manage Colleges</div>
            <button
              className="action-btn"
              onClick={() => handleShowAddCollegeModal()}
            >
              Add College
            </button>
          </div>

          <div className="template-ctrlr">
            <h3 className="template-ctrlr__title">College System:</h3>
            <div className="template-ctrlr__options">
              <input
                type="radio"
                id="college_semester"
                name="active_college"
                value="college_semester"
                defaultChecked={true}
                onChange={onChangeCollegeCategory}
              />
              <label
                htmlFor="college_semester"
                className="text-holder check-holder t-h3 purple"
              >
                Semester
              </label>
              <input
                type="radio"
                id="college_quarter"
                name="active_college"
                value="college_quarter"
                defaultChecked={false}
                onChange={onChangeCollegeCategory}
              />
              <label
                htmlFor="college_quarter"
                className="text-holder check-holder t-h3 purple"
              >
                Quarter
              </label>
            </div>
          </div>

          <div className="table-holder">
            <DataTable
              columns={columns}
              data={getFilteredSchool()}
              defaultSortField="college_name"
              pagination={true}
              customStyles={customStyles}
              highlightOnHover={true}
              pointerOnHover={false}
              dense={false}
              progressPending={schoolIsLoading}
              noHeader={true}
              selectableRowsHighlight={true}
              progressComponent={
                <Spinner animation="border" role="status" aria-hidden="true" />
              }
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15, 20, 25, 30]}
              pointerOnHover={true}
              onRowClicked={handleOnRowClicked}
            />
          </div>
        </div>
      </div>
      <UpdateCollegeModal college={_collegeData} />
      <AddCollegeModal />
    </div>
  )
}

export default AdminManageColleges
