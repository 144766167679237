import React from "react";

import C2CModal from "./C2CModal";
import C2CWizard from "./C2CWizard";
import PaymentForm from "./PaymentForm";
import { Context } from "../../context/Context";
import Step from "../Step";

import { validateRecipientInfo, validateCustomMessage, validateSubscriptionDetails } from "../util";
import ContactStepTwo from "../ContactStepTwo";

const AddNewSubscriptionModal = () => {
  const {
    addSubscriptionModalState,
    hideAddSubscriptionModal,
    newRecipientData,
    handleOnChangeNewRecipientData,
  } = React.useContext(Context);


  // activeIndex must be equal to the stepNumber value of the first child of the wizard. see below
  const [activeIndex, setActiveIndex] = React.useState(3);

  const validators = [
    {
      stepNumber: 3,
      validator: validateRecipientInfo
    },
    {
      stepNumber: 4,
      validator: validateCustomMessage,
    },
    {
      stepNumber: 5,
      validator: validateSubscriptionDetails,
    },
  ];

  return (
    <C2CModal
      show={addSubscriptionModalState}
      onHide={() => {
        hideAddSubscriptionModal();
        setActiveIndex(3);
      }}
      logoSrc={require("../../assets/images/logo.png")}
    >
      <C2CWizard
        data={newRecipientData}
        showMiddleButton={false}
        validators={validators}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        <Step name={"Student Information"} stepNumber={3}> 
          <div>
            <ContactStepTwo data={newRecipientData} handleOnChange={handleOnChangeNewRecipientData}/>
          </div>
        </Step>
        <Step name={"Custom Message"} stepNumber={4}>
          <div>
            <CustomMessageForm
              data={newRecipientData}
              handleOnChange={handleOnChangeNewRecipientData}
            />
          </div>
        </Step>
        {/* <Step name={"Subscription Details"} stepNumber={4}>
          <div>
            <SubscriptionFrom
              data={newRecipientData}
              handleOnChange={handleOnChangeNewRecipientData}
            />
          </div>
        </Step> */}
        <Step name={"Payment"} stepNumber={5}>
          <div>
            <PaymentForm
              data={newRecipientData}
              handleOnChange={handleOnChangeNewRecipientData}
              setActiveIndex={setActiveIndex}
            />
          </div>
        </Step>
      </C2CWizard>

      {/* Other components here. */}
    </C2CModal>
  );
};

const CustomMessageForm = ({ data, handleOnChange }) => {
  const { currentSubscriptionType } = React.useContext(Context);

  const onKeyPress = (event) => {
    var lines = 9;
    var newLines = event.target.value.split("\n").length;
    if (event.which === 13 && newLines >= lines) {
      event.preventDefault();
    }

    if (event.key === "Enter") {
      if (event.target.value.length <= 270) {
        event.target.value =
          event.target.value + "                             ";
      } else {
        event.preventDefault();
      }
    }
  };

  return (
    <div className="step-three">
      <h2 className="title t-h1 c-grayish-navy">
        {data.subscription_type === "theme"
          ? ""
          : "Let's Encourage Your Student"}
      </h2>
      <div className="greeting-holder">
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">Greeting</h3>

          <div className="input-wrapper greeting-msg">
            <input
              className="text-holder t-h1"
              type="text"
              name="greeting"
              placeholder="Dear/Hey/Hi"
              value={data.greeting}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="nick_name"
              placeholder="Name/Nickname"
              value={data.nick_name}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>
        </div>
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">Signoff</h3>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="sign_off"
              placeholder="Love/From"
              value={data.sign_off}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper signoff-mssg ">
            <input
              className="text-holder t-h1"
              type="text"
              name="personal_sign_off"
              placeholder="Mom/Dad/Mentor"
              value={data.personal_sign_off}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>
        </div>
      </div>
      <div className="default-message-holder">
        <div className="title-holder">
          <h3 className="info-title t-h1 c-grayish-navy">Default Message</h3>
          <span className="label c-grayish-navy">
            {currentSubscriptionType != "theme"
              ? "Your default messages will be used anytime you don’t personalize."
              : "Message 1 will be used for tonight's card."}
          </span>
        </div>
        <div className="messages-holder">
          <div className="form-group required">
            <label className="info-title t-h1 c-grayish-navy">Message 1</label>
            <textarea
              className="message-box"
              name="message_box1"
              placeholder="Message"
              value={data.message_box1}
              onChange={handleOnChange}
              maxLength="265"
              onKeyPress={onKeyPress}
            />
            <div className="text-count">
              {data.message_box1 ? data.message_box1.length : 0} / 265
            </div>
          </div>
          <div className="form-group">
            <label className="info-title t-h1 c-grayish-navy">Message 2</label>
            <textarea
              className="message-box"
              name="message_box2"
              placeholder="Message"
              value={data.message_box2}
              onChange={handleOnChange}
              maxLength="265"
              onKeyPress={onKeyPress}
            />
            <div className="text-count">
              {data.message_box2 ? data.message_box2.length : 0} / 265
            </div>
          </div>
          <div className="form-group">
            <label className="info-title t-h1 c-grayish-navy">Message 3</label>
            <textarea
              className="message-box"
              name="message_box3"
              placeholder="Message"
              value={data.message_box3}
              onChange={handleOnChange}
              maxLength="265"
              onKeyPress={onKeyPress}
            />
            <div className="text-count">
              {data.message_box3 ? data.message_box3.length : 0} / 265
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionFrom = ({ data, handleOnChange }) => {
  const {
    defaultPlans,
    quarterDefaultPlans,
    themeDefaultPlans,
    currentSubscriptionType,
    currentProcess,
    themePackTemplates,
  } = React.useContext(Context);

  const getPlanOptions = (planList) => {
    return (
      planList &&
      planList.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.nickname}
          </option>
        );
      })
    );
  };

  const getPlanChoices = () => {
    const subscriptionType = currentSubscriptionType;
    let planOptions, planList;

    if (subscriptionType === "quarter") {
      planList = quarterDefaultPlans;
    } else if (subscriptionType === "theme") {
      // planList = themeDefaultPlans.reduce((result, plan) => {
      //   let passed = themePackTemplates.filter(template => template.theme_name === plan.id)
      //   if (passed.length) {
      //     result.push(plan);
      //   }
      //   return result;
      // }, []);

      planList = themeDefaultPlans;
    } else {
      planList = defaultPlans;
    }

    planOptions = getPlanOptions(planList);

    return planList ? (
      planOptions
    ) : (
      <option value="">No Plans Available</option>
    );
  };

  return (
    <div className="step-subscription">
      <div className="subscription-holder">
        <div className="form-group required">
          <h3 className="info-title t-h1 c-grayish-navy">
            Choose Current {data.subscription_type != "theme" ? "Student" : ""}{" "}
            Subscription
          </h3>
          <div className="dropdown-holder">
            <select
              name="plan_selected"
              value={data.plan_selected}
              onChange={handleOnChange}
            >
              <option defaultValue>Choose</option>
              {getPlanChoices()}
            </select>
            {
              <React.Fragment>
                {data.plan_text_price && (
                  <p className="text-price">{data.plan_text_price}</p>
                )}
                {data.plan_description && (
                  <p className="note c-grayish-navy">{data.plan_description}</p>
                )}
              </React.Fragment>
            }
          </div>
        </div>
      </div>
      {currentProcess != "add-theme" && (
        <div className="label c-grayish-navy label-another-student">{`Have more ${
          currentSubscriptionType != "theme" ? "students" : "recipients"
        }?`}</div>
      )}
    </div>
  );
};

export default AddNewSubscriptionModal;
