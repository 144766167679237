import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Context } from '../context/Context'

import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import api from '../api/api'
import AddAffiliateForm from './AddAffiliateForm'
import ConfirmModal from './ConfirmModal'

const AddAffiliateModal = ({
  isOpen = false,
  closeModal,
  setAffiliates,
  affiliateData,
  setAffiliateData,
  affiliates,
  affiliateOrig,
}) => {
  //const [affiliateCopy, setAffiliateCopy] = useState(null)
  const { tokenAdmin, handleCloseAddTemplateModal } = useContext(Context)

  const [showConfirm, setShowConfirm] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const handleOnChange = e => {
    const formName = e.target.name
    const value = e.target.value

    setAffiliateData(prevData => ({ ...prevData, [formName]: value }))
  }

  const addAffiliate = async () => {
    setIsSaving(true)
    try {
      const { data } = await api.addAffiliate(tokenAdmin, affiliateData)
      closeModal()
      setAffiliates(prev => [data, ...prev])
    } catch (e) {
      if (e?.response?.status === 422) {
        const responseData = e?.response?.data
        const firstKey = Object.keys(responseData?.errors)[0]
        const message = responseData?.errors[firstKey][0]
        alert(message)
      }
    } finally {
      setIsSaving(false)
    }
  }

  const handleDelete = async () => {
    const id = affiliateData?.id

    setIsSaving(true)

    try {
      const result = await api.deleteAffiliate(tokenAdmin, id)
      setAffiliates(prev => prev?.filter(afiiliate => afiiliate?.id !== result?.id))
      closeModal()
    } catch (e) {
      console.log(e)
    } finally {
      setIsSaving(false)
    }
  }
  const handleUpdate = async () => {
    const id = affiliateData?.id

    setIsSaving(true)

    let payload = {}

    if (affiliateOrig?.email !== affiliateData?.email) {
      payload['email'] = affiliateData?.email
    }
    if (affiliateOrig?.code !== affiliateData?.code) {
      payload['code'] = affiliateData?.code
    }
    if (affiliateOrig?.name !== affiliateData?.name) {
      payload['name'] = affiliateData?.name
    }

    try {
      const result = await api.updateAffiliate(tokenAdmin, id, payload)

      const affiliatesCopy = [...affiliates]
      const affiliateIndex = affiliatesCopy.findIndex(affiliate => affiliate?.id === result?.id)
      affiliatesCopy[affiliateIndex] = result
      setAffiliates(prev => [...affiliatesCopy])
      closeModal()
    } catch (e) {
      if (e?.response?.status === 422) {
        const responseData = e?.response?.data
        const firstKey = Object.keys(responseData?.errors)[0]
        const message = responseData?.errors[firstKey][0]
        alert(message)
      }
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Modal className="c2c-modal" show={isOpen} onHide={handleCloseAddTemplateModal} centered backdrop={'static'}>
      <div className="btn-close-modal" onClick={closeModal}></div>

      <AddAffiliateForm
        data={affiliateData}
        handleOnChange={handleOnChange}
        title={affiliateData?.id ? 'Edit Affiliate Data' : 'Add New Affiliate'}
      />

      <div className="btn-holder">
        <button className="btn-teal c-white card-h card-h-3 d-flex" onClick={() => closeModal()} disabled={isSaving}>
          <span>Cancel</span>
        </button>
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={async () => {
            affiliateData?.id ? await handleUpdate() : await addAffiliate()
          }}
          disabled={isSaving}>
          {isSaving && <Spinner className="mr-2" animation="border" role="status" aria-hidden="true" />}
          {isSaving && <span>Please wait ...</span>}
          {!isSaving && <span>{affiliateData?.id ? 'Save' : 'Add'}</span>}
        </button>
      </div>

      {affiliateData?.id && (
        <button className="action-btn btn-delete" onClick={() => setShowConfirm(true)}>
          <span className="far fa-trash-alt pr-2"></span>
          <span>Delete this affiliate</span>
        </button>
      )}

      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleDelete}
        onCancel={() => setShowConfirm(false)}
        title={'Are You Sure?'}
        content={'DO you want to delete this affiliate?'}
        onCancelText={'Cancel'}
        onConfirmText={'Delete'}
        isBusy={isSaving}
      />
    </Modal>
  )
}

export default AddAffiliateModal
