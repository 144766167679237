import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentMethodForm from './AddPaymentMethodForm';

import { STRIPE_KEY } from '../helpers/constants'

import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js';


import { Context } from '../context/Context';
import api from '../api/api';

const stripePromise = loadStripe(STRIPE_KEY);


const PaymentMethodModal = () => {
  const {
    user,
    setUser,
    showPaymentMethodModal,
    handleClosePaymentMethodModal,
    addPaymentMethodLoading,
    setAddPaymentMethodLoading,
    token
  } = useContext(Context);

  return (
    <Modal
      className="c2c-modal edit-student-modal"
      show={showPaymentMethodModal}
      onHide={handleClosePaymentMethodModal}
      centered
      backdrop={'static'}
    >
      <div className="btn-close-modal" onClick={handleClosePaymentMethodModal}></div>
      <div className="step-one">
      <h2 className="title t-h1 c-grayish-navy">Add Payment Method</h2>
      </div>
      <Elements stripe={stripePromise}>
        <AddPaymentMethodForm />
      </Elements>
    </Modal>
  )
}

export default PaymentMethodModal
