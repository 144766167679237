import React, { useContext } from 'react'
import Nav from 'react-bootstrap/Nav'
import { HashLink as NavLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom'

import { Context } from '../context/Context'

import Wizard from '../components/Wizard'
import Step from '../components/Step'
import StepOne from '../components/ContactStepOne'
import StepTwo from '../components/ContactStepTwo'
import StepThree from '../components/ContactStepThree'
import ContactStepSubscription from '../components/ContactStepSubscription'
import StepFour from '../components/ContactStepFour'
import api from '../api/api'
import LoaderModal from './LoaderModal'
import { MARKETING_SITE_URL } from '../helpers/constants'
import { isSignUpPage } from './util'

const useQuery = () => new URLSearchParams(useLocation().search)

function SignUp() {
  const {
    handleClose,
    defaultPlans,
    setEmailExists,
    setStudentEmailExists,
    data,
    handleOnChange,
    handleAddStudent,
    handlePhoneChange,
    handleOnChangeSchool,
    isAddingNewSchool,
    selectedSemesterDate,
    schools,
    setSchools,
    handleOnSubscriptionChange,
    handleDeleteSubscription,
    couponVerificationLoading,
    setCouponVerificationLoading,
    setPlans,
    setThemePackTemplates,
    setSchoolSemDetails,
    handleShowLoaderModal,
    handleCloseLoaderModal,
    setData,
    initSignUp,
  } = useContext(Context)

  const query = useQuery()

  React.useEffect(() => {
    handleShowLoaderModal()
    api
      .allPlans(setPlans)
      .then(() => {
        return api.handleGetSchools(setSchools)
      })
      .then(() => {
        return api.handleGetSemDetails(setSchoolSemDetails)
      })
      .then(() => {
        initSignUp()
        handleCloseLoaderModal()
        let singleParam = query.get('single')
        if (singleParam === 'true' || singleParam === '1') {
          setIsSingle(true)
          setData({
            ...data,
            subscription_type: 'theme',
          })
        } else {
          setIsSingle(false)
          setData({
            ...data,
            subscription_type: 'semester',
          })
        }
      })
  }, [setSchoolSemDetails])

  React.useEffect(() => {
    // console.log("DATAAAAAAA", data)
  }, [data])

  const [isSingle, setIsSingle] = React.useState(false)

  //console.log('ISSINGLE:::', isSingle);

  return (
    <div className="c2c-modal">
      <Nav.Link href={MARKETING_SITE_URL} className="btn-back" target="_blank">
        Home
      </Nav.Link>
      <Wizard handleAddStudent={handleAddStudent} isSingle={isSingle}>
        <Step name="Your Information" value="1">
          <div>
            <StepOne
              data={data}
              handleOnChange={handleOnChange}
              handlePhoneChange={handlePhoneChange}
              validateEmail={e => {
                handleOnChange(e)
                api.validateEmail({ email: e.target.value }, setEmailExists)
              }}
            />
          </div>
        </Step>
        <Step name={`${data.subscription_type !== 'theme' ? 'Student' : 'Recipient'} Information`} value="2">
          <div>
            <StepTwo
              data={data}
              handleOnChange={handleOnChange}
              handleOnChangeSchool={handleOnChangeSchool}
              isAddingNewSchool={isAddingNewSchool}
              selectedSemesterDate={selectedSemesterDate}
              schools={schools}
              validateEmail={e => {
                handleOnChange(e)
                api.studentValidateEmail({ email: e.target.value }, setStudentEmailExists)
              }}
              isSingle={isSingle}
            />
          </div>
        </Step>
        <Step name="Custom Message" value="3">
          <div>
            <StepThree data={data} handleOnChange={handleOnChange} />
          </div>
        </Step>
        {/* {isSingle && (
          <Step name='Subscription Details' value='4'>
            <div>
              <ContactStepSubscription
                data={data}
                plans={defaultPlans}
                select={data.plan_selected}
                handleOnChange={handleOnChange}
              />
            </div>
          </Step>
        )} */}
        <Step name="Payment" value="5">
          <div>
            <StepFour
              data={data}
              handleOnChange={handleOnChange}
              select={data.plan_selected}
              plans={defaultPlans}
              handleOnSubscriptionChange={handleOnSubscriptionChange}
              handleDeleteSubscription={handleDeleteSubscription}
              couponVerificationLoading={couponVerificationLoading}
              setCouponVerificationLoading={setCouponVerificationLoading}
            />
          </div>
        </Step>
      </Wizard>
      <LoaderModal />
    </div>
  )

  // return(
  //   <div className="c2c-modal">
  //     <div className="logo-holder">
  //       <img src={require('../assets/images/logo.png')} className="logo-img" alt="giftsandtalents" />
  //     </div>
  //     <StepWizard>
  //       <StepOne />
  //       {/* <div className="step-one">
  //         step 1
  //       </div> */}
  //       {/* <div className="step-two">
  //         step 2
  //       </div> */}
  //       {/* <p>Total Steps: {props.totalSteps}</p> */}
  //     </StepWizard>
  //   </div>
  // )
}

export default SignUp
