import React from 'react';
import { Context } from '../../context/Context';

const GiftCardOptions = ({
  data,
  handleOnChange,
  title = 'Choose a gift card for your student',
  planProperty = 'plan_selected',
}) => {
  const { giftCardOptions } = React.useContext(Context);

  React.useEffect(() => {
    // console.log("GIFTCARD DATA", data[planProperty])
  }, [data]);

  let isNotTheme = data.subscription_type !== 'theme';
  // let planHasGiftCard = data[planProperty] !== undefined ? data[planProperty].includes("Weekly Gift Card") : data.plan_selected.includes("Weekly Gift Card")
  let planHasGiftCard =
    data[planProperty] !== undefined
      ? data[planProperty].includes('Monthly Deluxe Gift')
      : data.plan_selected.includes('Monthly Deluxe Gift');

  return isNotTheme && planHasGiftCard ? (
    <div className='gift-card-holder'>
      <div className='form-group required'>
        <h3 className='info-title t-h1 c-grayish-navy'>{title}</h3>

        <div className='three-choices-holder quarter'>
          {giftCardOptions.map((option, idx) => {
            return (
              <div className='form-check form-check-inline' key={idx}>
                <input
                  className='form-check-input'
                  type='radio'
                  name='gift_card'
                  id={`gc-${option.id}`}
                  value={option.id}
                  onChange={handleOnChange}
                  // defaultChecked={data.gift_card ? data.gift_card.includes(option.id) : false} // for array type gift card
                  defaultChecked={data.gift_card === option.id}
                />
                <label className='form-check-label check-holder t-h3 purple' htmlFor={`gc-${option.id}`}>
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default GiftCardOptions;
