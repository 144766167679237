import React, { useState, useContext } from 'react'
import Nav from 'react-bootstrap/Nav'
import Spinner from 'react-bootstrap/Spinner'
import { HashLink as NavLink } from 'react-router-hash-link'

import { Context } from '../context/Context'
import api from '../api/api'

const AdminLanding = () => {
  const {
    adminLoginLoading,
    setAdminLoginLoading,
    tokenAdmin
  } = useContext(Context)

  const [creds, setCreds] = useState({
    email: null,
    password: null,
  })

  const auth = () => {
    setAdminLoginLoading(true)
    api.handleLoginAdmin(creds, setAdminLoginLoading)
  }

  const onChangeInput = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    })
  }

  sessionStorage.clear()
  return (
    <main className="landing">
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          name="email"
          placeholder="username"
          onChange={onChangeInput}
        />
        <input
          type="password"
          name="password"
          placeholder="password"
          onChange={onChangeInput}
        />
        <button
          className="btn-teal c-white d-flex justify-content-center"
          type="submit"
          onClick={auth}
          disabled={adminLoginLoading}
        >
          {adminLoginLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          )}
          Login
        </button>
        <div className="d-flex justify-content-center">
          <Nav.Link as={NavLink} smooth to="/" className="landing-back-btn">
            Go Home
          </Nav.Link>
        </div>
      </form>
    </main>
  )
}

export default AdminLanding
