import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import clsx from "clsx";

import { validateWhiteSpace } from "../util";
import { Context } from "../../context/Context";
import ConfirmModal from "../ConfirmModal";

/**
 *
 * TO FUTURE DEVS: Please use this component for creating another wizard forms.
 */
const C2CWizard = ({
  children,
  data,
  showMiddleButton,
  validators,
  activeIndex,
  setActiveIndex,
}) => {
  const {
    onAddNewStudent,
    showSignUpConfirmModal,
    handleShowSignUpConfirmModal,
    handleCloseSignUpConfirmModal,
    handleAddStudent,
    emailexists,
    studentEmailExists,
    currentProcess,
    newRecipientData,
    setNewRecipientData,
  } = React.useContext(Context);

  // const [activeIndex, setActiveIndex] = React.useState(children[0].props.stepNumber)
  const [startIndex, setStartIndex] = React.useState(activeIndex);
  const [confirmIsBusy, setConfirmIsBusy] = React.useState(false);
  const [confirmModalElements, setConfirmModalElements] = React.useState({
    title: "",
    content: "",
    onConfirm: () => {},
    onConfirmText: "",
    onCancel: () => {},
    onCancelText: "",
  });

  const steps = React.Children.map(children, (child, i) => {
    return React.cloneElement(child, {
      active: activeIndex === child.props.stepNumber,
    });
  });

  const extras = {
    emailexists: emailexists,
    studentEmailExists: studentEmailExists,
  };

  const onClickNext = () => {
    const currentValidator = getValidatorByStepNumber(activeIndex);
    if (activeIndex === 1 && currentValidator != undefined) {
      currentValidator.validator(
        data,
        () => {
          setActiveIndex(2);
        },
        extras
      );
    } else if (activeIndex === 2 && currentValidator != undefined) {
      currentValidator.validator(
        data,
        () => {
          setActiveIndex(3);
        },
        extras
      );
    } else if (activeIndex === 3 && currentValidator != undefined) {
      currentValidator.validator(
        data,
        () => {
          setActiveIndex(4);
        },
        extras
      );
    } else if (activeIndex === 4 && currentValidator != undefined) {
      currentValidator.validator(
        data,
        () => {
          const getPopupContent = () => {
            return currentProcess != "add-theme"
              ? "Please make sure you entered the info correctly. *Remember we need your student's email address if you selected an e-gift card package. If you selected a Theme pack, the first card will be sent tonight at 11pm PST."
              : "Please make sure you entered the info correctly. *Remember: The first card will be sent tonight at 11pm PST.";
          };

          setConfirmModalElements({
            title: "Proceed to checkout?",
            content: getPopupContent(),
            onConfirm: onProceedToCheckout,
            onConfirmText: "Proceed",
            onCancel: () => {
              handleCloseSignUpConfirmModal();
              currentProcess != "add-theme" && setActiveIndex(2);
            },
            onCancelText: currentProcess != "add-theme" ? "Review" : "Cancel",
          });

          handleShowSignUpConfirmModal();
        },
        extras
      );
    } else {
      setActiveIndex(maxIndex);
    }
  };

  const onClickBack = () => {
    if (activeIndex <= 1) {
      setActiveIndex(1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const onClickAddStudent = () => {
    if (
      validateWhiteSpace(data.plan_selected) === -1 ||
      data.plan_selected === ""
    ) {
      alert("Subscription Plan is required");
    } else {
      setConfirmModalElements({
        title: "Proceed adding new student?",
        content:
          "Please make sure you entered current student's info correctly. *Remember we need your student's email address if you selected an e-gift card package.",
        onConfirm: onAddNewStudent,
        onConfirmText: "Proceed",
        onCancel: () => {
          handleCloseSignUpConfirmModal();
          setActiveIndex(2);
        },
        onCancelText: "Review",
        additionalMessage:
          "By continuing, you acknowledge this is a recurring subscription that will continue until you cancel.",
      });
      handleShowSignUpConfirmModal();
    }
  };

  const maxIndex = children[children.length - 1].props.stepNumber;
  const controls = [
    {
      text: "Back",
      onClick: onClickBack,
      isVisible: activeIndex > startIndex && activeIndex !== maxIndex,
      classObject: {
        span: "back-button-holder",
        button: "btn-pagination",
      },
    },
    {
      text: "Add Another Student",
      onClick: onClickAddStudent,
      isVisible: activeIndex === maxIndex - 1 && showMiddleButton, // Subscription Details Step
      classObject: {
        span: "button-holder add-student-btn-holder",
        button: "btn-pagination add-student-btn",
      },
    },
    {
      text: "Next",
      onClick: onClickNext,
      isVisible: activeIndex !== maxIndex,
      classObject: {
        span: "next-button-holder",
        button: "btn-pagination",
      },
    },
  ];

  const onProceedToCheckout = () => {
    handleCloseSignUpConfirmModal();
    if (
      validateWhiteSpace(data.student_first_name) !== -1 ||
      data.student_first_name !== ""
    ) {
      handleAddStudent(newRecipientData, setNewRecipientData);
    }
    setActiveIndex(5);
  };

  const StudentTable = () => (
    <div className="all-students-holder">
      <label className="info-title t-h1 c-grayish-navy">
        Your {data.subscription_type != "theme" ? "Students" : "Recipients"}
      </label>
      <div className="subscriptions-table">
        <div className="table-header">
          <div className="header-name">
            {data.subscription_type != "theme" ? "Student" : "Recipient"} Name
          </div>
          <div className="header-subscription">Subscription Plan</div>
        </div>
        <div className="subscriptions">
          <div className="subscription">
            <div className="student-name">
              {data.student_first_name + " " + data.student_last_name}
            </div>
            <div className="student-subscription">{data.plan_selected}</div>
          </div>
          {data.students
            ? data.students.map((item, i) => {
                return (
                  <div className="subscription" key={i}>
                    <div className="student-name">
                      {item.student_first_name + " " + item.student_last_name}
                    </div>
                    <div className="student-subscription">
                      {item.plan_selected}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const getValidatorByStepNumber = (stepNumber) => {
    return validators.find((validator, idx) => {
      if (validator.stepNumber == stepNumber) {
        return true;
      }
    });
  };

  return (
    <React.Fragment>
      <div className="wizard">
        <div className="steps">{steps}</div>

        <div className="fields">
          {children &&
            children.map((child, i) => {
              return (
                <React.Fragment key={i}>
                  {React.cloneElement(child.props.children, {
                    className: clsx("content", {
                      active: activeIndex === child.props.stepNumber,
                    }),
                  })}
                </React.Fragment>
              );
            })}
        </div>

        <div className="controls">
          {controls &&
            controls.map((control, i) => {
              if (!control.isVisible) {
                return null;
              }

              return (
                <React.Fragment key={i}>
                  <span className={control.classObject.span}>
                    <button
                      className={control.classObject.button}
                      onClick={control.onClick}
                    >
                      {control.text}
                    </button>
                  </span>
                </React.Fragment>
              );
            })}
        </div>
      </div>

      <ConfirmModal
        show={showSignUpConfirmModal}
        onHide={handleCloseSignUpConfirmModal}
        onConfirm={confirmModalElements.onConfirm}
        onCancel={confirmModalElements.onCancel}
        title={confirmModalElements.title}
        content={confirmModalElements.content}
        onCancelText={confirmModalElements.onCancelText}
        onConfirmText={confirmModalElements.onConfirmText}
        additionalContent={<StudentTable />}
        isBusy={confirmIsBusy}
        additionalMessage={confirmModalElements.additionalMessage}
      />
    </React.Fragment>
  );
};

export default C2CWizard;
