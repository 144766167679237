import React, { useState, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { Context } from '../../context/Context';
import api_base from '../../api/api_base_url';
import api from '../../api/api';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Merri Weather',
      fontSize: '17px',
      fontWeight: 'Bold',
      color: '#525D7D',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#979dac',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const AddThemePackCheckoutForm = () => {
  const { 
    user, 
    data, 
    setInvoice,
    signupPayLoading, 
    setSignupPayLoading, 
    hideAddSubscriptionModal,
    token, 
    setStudents, 
    selectedStudent, 
    setSelectedStudent, 
    setSubscriptions, 
    setCurrentCard, 
    setLastWeekCard, 
    setAllCards,
    handleShowLoaderModal,
    handleCloseLoaderModal, 
    setStudentDataLoaded,
    newRecipientData,
    setNewRecipientData,
    defaultNewRecipientData,
    getSubscriptionType,
    handleShowPaymentSuccessModal
  } = useContext(Context);

  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [postal, setPostal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  let studentCtr = 0;

  const handleSubmit = event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setSignupPayLoading(true)

    createPaymentMethod()
  };

  const createPaymentMethod = async () => {
    
    if (!stripe || !elements || signupPayLoading) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: user.email,
        name: user.first_name + ' ' + user.last_name,
        phone: (user.cell_phone) ? user.cell_phone : "+335555555555",
        address: {
          postal_code: postal,
        },
      },
    });
    stripePaymentMethodHandler(result);
  }

  const stripePaymentMethodHandler = async (result)=> {
    if (result.error) {
      // Show error in payment form
      setErrorMessage(result.error.message);
      setPaymentMethod(null);
      setSignupPayLoading(false)
      alert(result.error.message)
    } else {
      const stripe_api_url = api_base + '/api/create-customer';
      const stripe_headers = {
        headers: { 'Content-Type': 'application/json' },
      };

      const customer_data = {
        email: result.paymentMethod.billing_details.email,
        phone: result.paymentMethod.billing_details.phone,
        name: result.paymentMethod.billing_details.name,
        payment_method: result.paymentMethod.id,
        customer_id: user.customer_id,
        students: newRecipientData.students,
        coupon: newRecipientData.coupon,
        coupon_validated: newRecipientData.couponValidated
      };

      console.log("REQUEST BODY[/create-customer]::", customer_data)

      await axios
        .post(stripe_api_url, customer_data, stripe_headers)
        .then(res => {
          if(res.data.status === 'success'){
            const student_api_url = api_base + '/api/signup';
            const student_headers = {
              headers: { 'Content-Type': 'application/json' },
            };

            const request_body = {
              customer_id: res.data.customer_id,
              parent_id: user.parent_id,
              students: res.data.students
            }
            
            console.log("REQUEST BODY[/signup]::", request_body)

            axios
              .post(student_api_url, request_body, student_headers)
              .then(res => {
                if (res.data.status === 'success') {
                    setSignupPayLoading(false)
                    hideAddSubscriptionModal()
                    handleShowLoaderModal()
                    setStudents([])
                    setSelectedStudent([])
                    setSubscriptions(null)
                    setCurrentCard(null)
                    setLastWeekCard(null)
                    setAllCards([])
                    setNewRecipientData(defaultNewRecipientData)
                    api.handleGetAllStudentsAfterAdd(
                      token,
                      setInvoice,
                      setStudents,
                      selectedStudent,
                      setSelectedStudent,
                      setSubscriptions,
                      setCurrentCard,
                      setLastWeekCard,
                      setAllCards,
                      handleCloseLoaderModal,
                      setStudentDataLoaded,
                      newRecipientData,
                      setNewRecipientData,
                      getSubscriptionType,
                    ).then(() => {
                      handleCloseLoaderModal(handleShowPaymentSuccessModal)
                    });
                } else {
                  alert("There's an error saving your data.")
                  setSignupPayLoading(false);
                }
              })
              .catch(e => {
                setSignupPayLoading(false)
                alert(`[Error ${e.response.status}] ${e.response.data.message}`)
              });
          }
        })
        .catch(e => {
          setSignupPayLoading(false)
          alert(`[Error ${e.response.status}] ${e.response.data.message}`)
        });

      setPaymentMethod(result.paymentMethod);
      setErrorMessage(null);
    }
  };

  return (
    <div className="card-wrapper">
      <form onSubmit={handleSubmit}>
        <div className="card-holder">
          <label htmlFor="name">
            <span>Name on Card</span>
            <input
              id="name"
              className="text-holder t-h1"
              name="card"
              required
              placeholder="Jenny Rosen"
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </label>
        </div>
        <div className="card-holder">
          <div className="info-holder">
            <label>Card Information</label>
            <CardNumberElement
              id="cardNumber"
              className="text-holder t-h1"
              options={ELEMENT_OPTIONS}
            />
            <CardExpiryElement
              id="expiry"
              className="text-holder t-h1"
              options={ELEMENT_OPTIONS}
            />
            <CardCvcElement
              id="cvc"
              className="text-holder t-h1"
              options={ELEMENT_OPTIONS}
            />
            <input
              id="postal"
              className="text-holder t-h1"
              required
              placeholder="Zipcode"
              value={postal}
              onChange={e => {
                setPostal(e.target.value);
              }}
            />
            {/* {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
          </div>
          <div className="button-holder">
            <button className="btn-submit d-flex" disabled={!stripe && signupPayLoading}>
              { signupPayLoading && <Spinner
                className="mr-2"
                animation="border"
                role="status"
                aria-hidden="true"
              /> }
              { signupPayLoading && <span>Please wait...</span> }
              { !signupPayLoading && <span>{'Add and Pay!'}</span> }
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddThemePackCheckoutForm;
