import React, { useContext, useState } from "react";

import { Context } from "../context/Context";

import EditUserInfoModal from "./EditUserInfoModal";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
import ConfirmModal from "./ConfirmModal";
import api from "../api/api";

import DashboardSubscription from "./DashboardSubscription";

const DashboardUserInfo = () => {
  const {
    token,
    user,
    customer,
    setCustomer,
    paymentMethods,
    setPaymentMethods,
    handleShowEditUserInfoModal,
    handleShowPaymentMethodModal,
    selectedStudent,
    handleShowPlanModal,
  } = useContext(Context);

  const [confirmIsBusy, setConfirmIsBusy] = useState(false);
  const [confirmModalElements, setConfirmModalElements] = useState({
    title: "",
    content: "",
    onConfirm: () => {},
    onConfirmText: "",
  });

  const [showConfirm, setShowConfirm] = useState(false);

  const removepaymentMethod = (id) => {
    setConfirmModalElements({
      title: "Are you sure?",
      content: "Do you really want to delete this payment method?",
      onConfirm: () => {
        setConfirmIsBusy(true);
        api.removePaymentMethod(token, id, user.customer_id).then(() => {
          api.getCustomer(token, user.customer_id, setCustomer, setPaymentMethods).then(() => {
            api.getPaymentMethods(token, user.customer_id, setPaymentMethods).then(() => {
              setConfirmIsBusy(false);
              setShowConfirm(false);
            });
          });
        });
      },
      onConfirmText: "Delete",
    });
    setShowConfirm(true);
  };

  const updateDefaultpaymentMethod = (id) => {
    setConfirmModalElements({
      title: "Are you sure?",
      content: "Do you really want to set this as your default payment method?",
      onConfirm: () => {
        setConfirmIsBusy(true);
        api
          .updateDefaultPaymentMethod(
            token,
            id,
            user.customer_id,
            setCustomer,
            setPaymentMethods,
            setShowConfirm,
            setConfirmIsBusy
          )
          .then(() => {
            api.getCustomer(token, user.customer_id, setCustomer, setPaymentMethods).then(() => {
              api.getPaymentMethods(token, user.customer_id, setPaymentMethods).then(() => {
                setConfirmIsBusy(false);
                setShowConfirm(false);
              });
            });
          });
      },
      onConfirmText: "Set as Default",
    });
    setShowConfirm(true);
  };

  return (
    <div className="dashboard-body">
      <div className="container dash-container">
        <div id={user.parent_id} className="user-info-wrapper">
          <div className="section-heading-holder">
            <div className="c-teal section-title">My Profile</div>
            <button className="action-btn" onClick={handleShowEditUserInfoModal}>
              Edit Info
            </button>
          </div>
          <div className="two-column-data">
            <div className="data-title">First Name</div>
            <div className="data-value">{user.first_name}</div>
          </div>
          <div className="two-column-data">
            <div className="data-title">Last Name</div>
            <div className="data-value">{user.last_name}</div>
          </div>
          <div className="two-column-data">
            <div className="data-title">Email Address/Login ID</div>
            <div className="data-value">{user.email}</div>
          </div>
          <div className="two-column-data">
            <div className="data-title">Cellphone Number</div>
            <div className="data-value">{user.cell_phone}</div>
          </div>
          <div className="two-column-data">
            <div className="data-title">Newsletter Subscription Status</div>
            <div className="data-value">{user.newsletter === "0" ? "Unsubscribed" : "Subscribed"}</div>
          </div>
          {/* <div className="two-column-data">
            <div className="data-title">Billing Address</div>
            <div className="data-value">{user.billing_address}</div>
          </div> */}
          <div className="two-column-data">
            <div className="data-title">Mailing Address</div>
            <div className="data-value">
              {user.street + ", "} {user.unit ? user.unit : ""} {user.city + ", "} {user.state} {user.zipcode}
            </div>
          </div>
        </div>

        {paymentMethods.length > 0 ? (
          <div id={user.customer_id} className="user-info-wrapper">
            <div className="section-heading-holder">
              <div className="c-teal section-title">My Payment Methods</div>
              {/* <button
                className="action-btn"
                onClick={handleShowPaymentMethodModal}
              >
                Add Payment Method
              </button> */}
            </div>
            {paymentMethods.length > 0 && (
              <div>
                {paymentMethods.map((pm, index) => {
                  return (
                    <div key={index} id={index} className="mb-4">
                      <div className="d-flex justify-content-between">
                        {customer.id !== undefined && (
                          <div>
                            {customer.invoice_settings.default_payment_method === pm.id ? (
                              <div className="c-teal">Default Payment Method</div>
                            ) : (
                              <button className="action-btn" onClick={() => updateDefaultpaymentMethod(pm.id)}>
                                Set as Default
                              </button>
                            )}
                          </div>
                        )}
                        {paymentMethods.length > 1 && (
                          <button className="action-btn" onClick={() => removepaymentMethod(pm.id)}>
                            Remove Payment Method
                          </button>
                        )}
                      </div>
                      <div className="two-column-data">
                        <div className="data-title">Cardholder Name</div>
                        <div className="data-value">{pm.billing_details.name}</div>
                      </div>
                      <div className="two-column-data">
                        <div className="data-title">Last 4</div>
                        <div className="data-value">{pm.card.last4}</div>
                      </div>
                      <div className="two-column-data">
                        <div className="data-title">Expire</div>
                        <div className="data-value">
                          {pm.card.exp_month + "/" + pm.card.exp_year.toString().substr(-2)}
                        </div>
                      </div>
                      {/* {<div className="two-column-data">
                      <div className="data-title">Expiry Year</div>
                      <div className="data-value">{pm.card.exp_year}</div></div>} */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div className="c-teal section-title">Loading Payment Methods...</div>
        )}

        <DashboardSubscription />

        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            gap: "12px",
          }}
        >
          <button
            className="btn-teal c-white"
            onClick={() => {
              window.open(process.env.REACT_APP_STRIPE_PORTAL_LINK, "_blank");
            }}
          >
            Update Payment
          </button>

          {selectedStudent.plan_selected !== "Cancelled" && selectedStudent.subscription_type !== "theme" && (
            <button className="btn-white c-purple" onClick={handleShowPlanModal}>
              Update Subscription
            </button>
          )}
        </div>
      </div>
      <EditUserInfoModal />
      <AddPaymentMethodModal />
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={confirmModalElements.onConfirm}
        onCancel={() => setShowConfirm(false)}
        title={confirmModalElements.title}
        content={confirmModalElements.content}
        onCancelText={"Cancel"}
        onConfirmText={confirmModalElements.onConfirmText}
        isBusy={confirmIsBusy}
        consent={confirmModalElements.consent}
      />
    </div>
  );
};

export default DashboardUserInfo;
