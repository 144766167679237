import Joyride, { EVENTS, STATUS, ACTIONS } from "react-joyride";
import React, { useContext } from "react";
import { Context } from "../../context/Context";

const Tutorial = () => {
  const {
    runTutorial,
    tutorialStepIndex,
    setRunTutorial,
    setTutorialStepIndex,
    tutorialSteps,
    setLeftSideBar,
    setDashTabActiveKey,
  } = useContext(Context);

  const showNavBar = (show) => {
    if (show) {
      if (window.innerWidth <= 991) {
        document.getElementById("basic-navbar-nav").classList.add("show");
      }
    } else {
      if (window.innerWidth <= 991) {
        document.getElementById("basic-navbar-nav").classList.remove("show");
      }
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, index, action, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    const stepEvents = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];

    if (finishedStatuses.includes(status)) {
      setRunTutorial(false);
      setTutorialStepIndex(0);
    } else if (stepEvents.includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (index === 0) {
        setDashTabActiveKey("first");
      } else if (index === 1) {
        if (action === ACTIONS.PREV) {
          setDashTabActiveKey("third");
        } else {
          setDashTabActiveKey("second");
        }
      } else if (index === 2) {
        if (action === ACTIONS.PREV) {
          showNavBar(false);
          setDashTabActiveKey("first");
        } else {
          showNavBar(true);
          setDashTabActiveKey("third");
        }
      } else if (
        (index === 3 && action === ACTIONS.PREV) ||
        (index === 4 && action === ACTIONS.NEXT)
      ) {
        if (index === 3) {
          setDashTabActiveKey("second");
        }
        showNavBar(false);
      } else if (index === 8) {
        if (action === ACTIONS.NEXT) {
          if (!(window.innerWidth <= 991)) {
            setRunTutorial(false);
            setLeftSideBar("opened");
            setTutorialStepIndex(nextStepIndex);
            setRunTutorial(true);
          }
        }
      } else if (index === 9) {
        if (action === ACTIONS.PREV) {
          setLeftSideBar("closed");
        }
      }

      setTutorialStepIndex(nextStepIndex);
    }
  };

  return (
    <div>
      <Joyride
        callback={handleJoyrideCallback}
        run={runTutorial}
        steps={tutorialSteps}
        stepIndex={tutorialStepIndex}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableOverlayClose
        locale={{
          last: "Done",
        }}
        styles={{
          options: {
            textColor: "#525D7D",
            primaryColor: "#6e4c96",
            border: "3px solid",
            borderColor: "#6e4c96",
            zIndex: 10000,
            beaconSize: 45,
          },
          tooltip: {
            borderRadius: "15px",
            fontFamily: "Merri Weather",
          },
          buttonNext: {
            borderRadius: "15px",
          },
        }}
      />
    </div>
  );
};

export default Tutorial;
