import React, { useState, useContext, Fragment, useEffect } from 'react'

import { Context } from '../context/Context'

import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import api from '../api/api'
import { validateWhiteSpace } from './util'

const EditMessageModal = () => {
  const {
    token,
    showEditMessageModal,
    handleCloseEditMessageModal,
    updateCustomMessageLoading,
    setUpdateCustomMessageLoading,
    selectedStudent,
    setSelectedStudent,
    onChangeStudentData,
    currentCard,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
    setCurrentCard,
  } = useContext(Context)

  const [data, setData] = useState({
    id: '',
    greeting: '',
    nick_name: '',
    sign_off: '',
    name_as_sign_off: '',
    custom_message: '',
  })

  useEffect(() => {
    let msg = ''
    if (currentCard) {
      // console.log(selectedStudent);
      if (currentCard.current_message === 'custom' || selectedStudent.custom_message) {
        msg = selectedStudent.custom_message
      } else if (currentCard.current_message === 'default_message_1') {
        msg = selectedStudent.default_message_1
      } else if (currentCard.current_message === 'default_message_2') {
        msg = selectedStudent.default_message_2
      } else if (currentCard.current_message === 'default_message_3') {
        msg = selectedStudent.default_message_3
      }
    }
    setData({
      id: selectedStudent.id,
      greeting: selectedStudent.greeting,
      nick_name: selectedStudent.nick_name,
      sign_off: selectedStudent.sign_off,
      name_as_sign_off: selectedStudent.name_as_sign_off,
      custom_message: msg,
    })
  }, [showEditMessageModal])

  const updateData = e => {
    const target = e.target.name
    let value = e.target.value

    const ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
    ];

    if (value.match(ranges.join('|'))) {
      value = value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
      if (!e.target.dataset.alerted) {
        alert("We cannot print emojis at this time");
        e.target.dataset.alerted = true;
      }
    } else {
      e.target.dataset.alerted = false;
    } 
    
    setData({
      ...data,
      [target]: value,
    })
  }

  const editCustomMessages = () => {
    // console.log(data)
    setUpdateCustomMessageLoading(true)
    api
      .handleUpdateStudent(token, data, setSelectedStudent, newRecipientData, setNewRecipientData, getSubscriptionType)
      .then(() => {
        api.getCurrentCard(token, selectedStudent.student_id, setCurrentCard)
        setUpdateCustomMessageLoading(false)
        handleCloseEditMessageModal()
      })
  }

  // temp form validation, need to transfer to more centralized form validation
  const handleOnSave = () => {
    if (validateWhiteSpace(data.greeting) === -1 || data.greeting === '') {
      alert('Greeting is required')
    } else if (validateWhiteSpace(data.nick_name) === -1 || data.nick_name === '') {
      alert('Nickname is required')
    } else if (validateWhiteSpace(data.sign_off) === -1 || data.sign_off === '') {
      alert('Sign off is required')
    } else if (validateWhiteSpace(data.name_as_sign_off) === -1 || data.name_as_sign_off === '') {
      alert('Name as sign off is required')
    }
    //  else if (
    //   validateWhiteSpace(data.custom_message) === -1 ||
    //   data.custom_message === ""
    // ) {
    //   alert('Message is required');
    // }
    else {
      editCustomMessages()
    }
  }

  const onKeyPress = event => {
    var lines = 9
    var newLines = event.target.value.split('\n').length
    if (event.which === 13 && newLines >= lines) {
      event.preventDefault()
    }
  }

  return (
    <Modal
      className="c2c-modal custom-message-modal"
      show={showEditMessageModal}
      onHide={handleCloseEditMessageModal}
      centered
      backdrop={'static'}>
      <div className="btn-close-modal" onClick={handleCloseEditMessageModal}></div>
      <h2 className="title t-h1 c-grayish-navy"> Personalize Your Cards </h2>
      <div className="greeting-signoff-holder">
        <div className="form-group">
          <div className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Greeting</h3>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="greeting"
              placeholder="Dear/Hey/Hi"
              value={data.greeting || ''}
              onChange={updateData}
            />
            <div className="input-subtext"></div>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="nick_name"
              placeholder="Name/Nickname"
              value={data.nick_name || ''}
              onChange={updateData}
            />
            <div className="input-subtext"></div>
          </div>
        </div>
      </div>
      <div className="custom-message-holder">
        <div className="form-group">
          <label className="info-title t-h1 c-grayish-navy">Your Message</label>
          <textarea
            className="message-box"
            name="custom_message"
            maxLength="325"
            value={data.custom_message || ''}
            onChange={updateData}
            onKeyPress={onKeyPress}
          />
        </div>
        <div className="text-count">{data.custom_message ? data.custom_message.length : 0} / 325</div>
      </div>
      <div className="greeting-signoff-holder">
        <div className="form-group">
          <div className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">Signoff</h3>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="sign_off"
              placeholder="Love/From"
              value={data.sign_off || ''}
              onChange={updateData}
            />
            <div className="input-subtext"></div>
          </div>
          <div className="input-wrapper">
            <input
              className="text-holder t-h1 signoff-mssg"
              type="text"
              name="name_as_sign_off"
              placeholder="Mom/Dad/Mentor"
              value={data.name_as_sign_off || ''}
              onChange={updateData}
            />
            <div className="input-subtext"></div>
          </div>
        </div>
      </div>
      <div className="btn-holder">
        <button
          className="btn-teal c-white card-h card-h-3 d-flex"
          onClick={() => handleOnSave()}
          disabled={updateCustomMessageLoading}>
          {updateCustomMessageLoading && (
            <Spinner className="mr-2" animation="border" role="status" aria-hidden="true" />
          )}
          {updateCustomMessageLoading && <span>Please wait</span>}
          {!updateCustomMessageLoading && <span>Save</span>}
        </button>
      </div>
    </Modal>
  )
}

export default EditMessageModal
