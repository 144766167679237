import React, { useState, useContext, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Context } from '../context/Context'
import Select from 'react-select'

import 'react-datepicker/dist/react-datepicker.css'
import { isLogin, isSignUpPage } from './util'

const ContactStepTwo = ({
  data,
  handleOnChange,
  handleOnChangeSchool,
  isAddingNewSchool,
  selectedSemesterDate,
  schools,
  validateEmail,
  isSingle,
}) => {
  const {
    studentEmailExists,
    schoolSemDetails,
    quarterDefaultPlans,
    themeDefaultPlans,
    defaultPlans,
    formErrors,
    setActiveIndex,
    handleCloseSignUpConfirmModal,
    handleShowSignUpConfirmModal,
    handleAddStudent,
    handleOnChangeAddNewSchool,
    showSchool,
    selectedOption,
  } = useContext(Context)
  const [planList, setPlanList] = useState([])

  const isOnlyMonday = date => {
    const day = date.getDay()
    return day === 1
  }

  const isThemePack = () => {
    return data.subscription_type === 'theme'
  }

  const getSchoolsByType = subscriptionType => {
    return schools.reduce((result, school) => {
      let semDetails = schoolSemDetails.filter(semDetail => semDetail.coll_id === school.id)
      if (subscriptionType === school.qtr_or_sem && semDetails.length) {
        result.push(school)
      }
      return result
    }, [])
  }

  const customStyles = {
    control: styles => ({
      ...styles,
      border: 'none',
      borderBottom: '2px solid #6e4c96',
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: '17px',
      color: '#525D7D',
      fontFamily: 'Merri Weather',
      fontWeight: 900,
    }),
    option: styles => ({
      ...styles,
      padding: '1px 0 1px 20px',
      fontSize: '17px',
      color: '#525D7D',
      fontFamily: 'Merri Weather !important',
    }),
    singleValue: styles => ({
      ...styles,
      fontFamily: 'Merri Weather !important',
      fontWeight: 900,
      fontSize: '17px',
      color: '#525D7D',
    }),
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
  }

  const groupBadgeStyles = {
    fontFamily: 'Merri Weather !important',
    color: '#525D7D',
    display: 'inline-block',
    fontSize: '17px',
    fontWeight: 900,
  }

  const getSchoolOptions = () => {
    let schoolTypeAbbrv = 'S'
    data.subscription_type === 'quarter' && (schoolTypeAbbrv = 'Q')

    let filteredSchools = getSchoolsByType(schoolTypeAbbrv)

    const options = []

    for (let i = 0; i < filteredSchools.length; i++) {
      options.push({
        value: filteredSchools[i].college_name,
        label: filteredSchools[i].college_name,
      })
    }

    const groupOptions = [
      {
        label: 'Available Schools',
        options: options,
      },
    ]

    const formatGroupLabel = data => (
      <div style={groupStyles}>
        <span style={groupBadgeStyles}>{data.label}</span>
      </div>
    )

    return (
      <>
        {showSchool ? (
          <Select
            value={'Add another school'}
            styles={customStyles}
            placeholder={'Add another school'}
            isDisabled={showSchool}
          />
        ) : (
          <Select
            // defaultValue={data.schoolname}
            value={selectedOption}
            options={groupOptions}
            formatGroupLabel={formatGroupLabel}
            onChange={handleOnChangeSchool}
            styles={customStyles}
            placeholder={'School Name'}
          />
        )}
      </>
    )
  }

  useEffect(() => {
    const subscriptionType = data.subscription_type

    if (subscriptionType === 'theme') {
      setPlanList(themeDefaultPlans)
    } else if (subscriptionType === 'quarter') {
      setPlanList(quarterDefaultPlans)
    } else {
      setPlanList(defaultPlans)
    }
  }, [data.subscription_type])

  const springMonths = [11, 0, 1, 2, 3] //December to April
  const fallMonths = [4, 5, 6, 7, 8, 9, 10] // May to November

  const getSeason = () => {
    const currentMonth = new Date().getMonth()

    if (springMonths.includes(currentMonth)) {
      return 'spring'
    }

    return 'fall'
  }

  return (
    <div className="step-two">
      <h2 className="title t-h1" style={{ color: '#6E4C96' }}>
        Tell Us About Your {isThemePack() ? 'Recipient' : 'Student'}
      </h2>
      <div className="student-details-holder">
        <div className="form-group required">
          <div className="title-holder">
            <h3 className="info-title t-h1 c-grayish-navy">{isThemePack() ? 'Details' : 'Student Details'}</h3>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="student_first_name"
              placeholder="First Name"
              value={data.student_first_name}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>

          <div className="input-wrapper">
            <input
              className="text-holder t-h1"
              type="text"
              name="student_last_name"
              placeholder="Last Name"
              value={data.student_last_name}
              onChange={handleOnChange}
            />
            <div className="input-subtext"></div>
          </div>

          <div style={{ width: '100%' }}>
            {!isThemePack() && (
              <div className="choices-holder-container">
                <div className="choices-holder">
                  <div className="three-choices-holder type" style={{ marginBottom: '0px' }}>
                    <span>Subscription Type</span>
                    <div style={{ marginTop: '10px' }} className="radio-group">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="subscription_type"
                          id="type-semester"
                          value="semester"
                          onChange={handleOnChange}
                          // defaultChecked={data.subscription_type === "semester"}
                          checked={data.subscription_type === 'semester'}
                        />
                        <label className="form-check-label check-holder t-h3 purple" htmlFor="type-semester">
                          Semester
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="subscription_type"
                          id="type-quarter"
                          value="quarter"
                          onChange={handleOnChange}
                          // defaultChecked={data.subscription_type === "quarter"}
                          checked={data.subscription_type === 'quarter'}
                        />
                        <label className="form-check-label check-holder t-h3 purple" htmlFor="type-quarter">
                          Quarter
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="choices-holder">
                  {data.subscription_type === 'semester' && (
                    <div className="two-choices-holder type">
                      <span>Starting Semester</span>
                      <div style={{ marginTop: '10px' }} className="radio-group">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="season"
                            id="season-fall"
                            value="fall"
                            onChange={handleOnChange}
                            // style={{ marginRight: '32px' }}
                            // defaultChecked={data.season === "fall"}
                            checked={data.season === 'fall'}
                          />
                          <label className="form-check-label check-holder t-h3 purple" htmlFor="season-fall">
                            Fall
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="season"
                            id="season-spring"
                            value="spring"
                            onChange={handleOnChange}
                            // defaultChecked={data.season === "spring"}
                            checked={data.season === 'spring'}
                          />
                          <label className="form-check-label check-holder t-h3 purple" htmlFor="season-spring">
                            Spring
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {data.subscription_type === 'quarter' && (
                    <div className="three-choices-holder type quarter">
                      <span>Starting Quarters</span>
                      <div style={{ marginTop: '10px' }} className="radio-group">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="quarter"
                            id="quarter-q1"
                            value="q1"
                            onChange={handleOnChange}
                            // defaultChecked={data.quarter === "q1"}
                            checked={data.quarter === 'q1'}
                          />
                          <label className="form-check-label check-holder t-h3 purple" htmlFor="quarter-q1">
                            Q1-Fall
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="quarter"
                            id="quarter-q2"
                            value="q2"
                            onChange={handleOnChange}
                            // defaultChecked={data.quarter === "q2"}
                            checked={data.quarter === 'q2'}
                          />
                          <label className="form-check-label check-holder t-h3 purple" htmlFor="quarter-q2">
                            Q2-Winter
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="quarter"
                            id="quarter-q3"
                            value="q3"
                            onChange={handleOnChange}
                            // defaultChecked={data.quarter === "q3"}
                            checked={data.quarter === 'q3'}
                          />
                          <label className="form-check-label check-holder t-h3 purple" htmlFor="quarter-q3">
                            Q3-Spring
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {data.subscription_type !== 'theme' && (
              <React.Fragment>
                <div className="subscription-container">
                  <div className="input-wrapper">{getSchoolOptions()}</div>

                  <div className="input-wrapper">
                    <select
                      name="plan_selected"
                      className="text-holder t-h1"
                      value={data.plan_selected}
                      //onChange={handleOnChange}
                    >
                      {planList &&
                        planList.map((item, i) => {
                          return (
                            <option key={i} value={item.id} defaultValue>
                              {`${item.metadata.plan_desc}`}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>

                <div className="deluxe-choices-container">
                  <div className="fourth-choices-holder type">
                    <span>Didn't find your school?</span>
                    <div style={{ marginTop: '10px' }}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="adding-school"
                          checked={showSchool}
                          onChange={handleOnChangeAddNewSchool}
                        />
                        <label className="form-check-label check-holder t-h3 purple" htmlFor="adding-school">
                          Add another school
                        </label>
                      </div>
                    </div>
                  </div>

                  {data.plan_description.toLowerCase().includes('care package') && (
                    <div className="fourth-choices-holder type">
                      <span className="c-teal">
                        <h4 className="deluxe-header c-teal">Deluxe Care Packages</h4>
                      </span>
                      <div style={{ marginTop: '9px' }}>
                        {data.subscription_type === 'semester' && (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="deluxe_package"
                              id="package-deluxe"
                              value={data.availedDeluxe}
                              onChange={handleOnChange}
                              // defaultChecked={data.subscription_type === "semester"}
                              // checked={data.subscription_type === 'semester'}
                            />
                            <label className="form-check-label check-holder t-h3 purple" htmlFor="package-deluxe">
                              Upgrade all packages to Deluxe $60/Semester
                            </label>
                          </div>
                        )}

                        {data.subscription_type === 'quarter' && (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="deluxe_package"
                              id="package-deluxe"
                              value={data.availedDeluxe}
                              onChange={handleOnChange}
                              // checked={data.subscription_type === 'quarter'}
                            />
                            <label className="form-check-label check-holder t-h3 purple" htmlFor="package-deluxe">
                              Upgrade all packages to Deluxe $40/Quarter
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {isAddingNewSchool && (
                  <div className="add-school-header label c-grayish-navy">
                    Enter school name and start of classes date below:
                  </div>
                )}
                {isAddingNewSchool && (
                  <div className="input-wrapper">
                    <input
                      className="text-holder t-h1"
                      type="text"
                      name="schoolname"
                      placeholder="School Name"
                      value={data.schoolname}
                      onChange={handleOnChange}
                    />
                    <div className="input-subtext"></div>
                  </div>
                )}
                {isAddingNewSchool && (
                  <div className="input-wrapper">
                    <div className="input-label-wrapper">
                      <DatePicker
                        className="text-holder t-h1"
                        name="semester"
                        onChange={handleOnChange}
                        selected={selectedSemesterDate}
                        filterDate={isOnlyMonday}
                        placeholderText={`First Day of Current ${
                          data.subscription_type === 'semester' ? 'Semester' : 'Quarter'
                        } `}
                      />
                      {/* <span>First Day of Current {data.subscription_type === 'semester' ? 'Semester' : 'Quarter'}</span> */}
                    </div>
                    <div className="input-subtext"></div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>

        {isThemePack() && (
          <React.Fragment>
            <div className="subscription-container theme-container">
              <div className="input-wrapper theme-container">
                <select
                  name="plan_selected"
                  className="text-holder t-h1"
                  value={data.plan_selected}
                  onChange={e => {
                    handleOnChange(e)
                  }}>
                  <option defaultValue>Choose Plan</option>
                  {planList &&
                    planList.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {`${item.nickname}`}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
          </React.Fragment>
        )}

        {/* {isThemePack() && <AddressHolder data={data} handleOnChange={handleOnChange} isThemePack={isThemePack} />} */}

        <div className="address-holder">
          <div className="form-group">
            <span className="title-holder">
              <h3 className="info-title t-h1 c-grayish-navy">
                {isThemePack() ? "Address" : "Student Address"}
              </h3>
              <span className="label c-grayish-navy">
                {isThemePack()
                  ? "Theme packs need address at sign-up"
                  : "Don't have their address yet? No problem. You can add it later."}
              </span>
            </span>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="student_street"
                placeholder="Address 1"
                value={data.student_street}
                onChange={handleOnChange}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="student_unit"
                placeholder="Address 2"
                value={data.student_unit}
                onChange={handleOnChange}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="student_city"
                placeholder="City"
                value={data.student_city}
                onChange={handleOnChange}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="text"
                name="student_state"
                placeholder="State"
                maxLength="2"
                value={data.student_state}
                onChange={handleOnChange}
              />
              <div className="input-subtext"></div>
            </div>

            <div className="input-wrapper">
              <input
                className="text-holder t-h1"
                type="number"
                name="student_zipcode"
                placeholder="Zipcode"
                value={data.student_zipcode}
                onChange={handleOnChange}
              />
              <div className="input-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      {/* {!isThemePack() && (
        <div className='email-holder'>
          <div className='form-group'>
            <div className='input-wrapper'>
              <input
                className={`text-holder t-h1 ${studentEmailExists ? 'has-error' : ''}`}
                type='text'
                name='student_email'
                placeholder='Student Email Address'
                value={data.student_email}
                onChange={validateEmail}
              ></input>
              <div className='input-subtext'></div>
            </div>
            <span className='label c-grayish-navy'>For Student E-Gift Card</span>
          </div>
        </div>
      )} */}
    </div>
  )
}

const AddressHolder = ({ handleOnChange, isThemePack, data }) => {
  return (
    <div className="address-holder">
      <div className="form-group">
        <span className="title-holder">
          <h3 className="info-title t-h1 c-grayish-navy">{isThemePack() ? 'Address' : 'Student Address'}</h3>
          <span className="label c-grayish-navy">
            {isThemePack()
              ? 'Theme packs need address at sign-up'
              : "Don't have their address yet? No problem. You can add it later."}
          </span>
        </span>

        <div className="input-wrapper">
          <input
            className="text-holder t-h1"
            type="text"
            name="student_street"
            placeholder="Address 1"
            value={data.student_street}
            onChange={handleOnChange}
          />
          <div className="input-subtext"></div>
        </div>

        <div className="input-wrapper">
          <input
            className="text-holder t-h1"
            type="text"
            name="student_unit"
            placeholder="Address 2"
            value={data.student_unit}
            onChange={handleOnChange}
          />
          <div className="input-subtext"></div>
        </div>

        <div className="input-wrapper">
          <input
            className="text-holder t-h1"
            type="text"
            name="student_city"
            placeholder="City"
            value={data.student_city}
            onChange={handleOnChange}
          />
          <div className="input-subtext"></div>
        </div>

        <div className="input-wrapper">
          <input
            className="text-holder t-h1"
            type="text"
            name="student_state"
            placeholder="State"
            maxLength="2"
            value={data.student_state}
            onChange={handleOnChange}
          />
          <div className="input-subtext"></div>
        </div>

        <div className="input-wrapper">
          <input
            className="text-holder t-h1"
            type="text"
            name="student_zipcode"
            placeholder="Zipcode"
            value={data.student_zipcode}
            onChange={handleOnChange}
          />
          <div className="input-subtext"></div>
        </div>
      </div>
    </div>
  )
}

export default ContactStepTwo
