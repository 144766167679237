import React from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { Context } from '../../context/Context';
import { formatDate } from '../util';
import Spinner from 'react-bootstrap/Spinner';
import { validateWhiteSpace } from '../util';

const CollegeDateForm = ({ onSave, saveBtnText, onCancel }) => {
  const { currentCollegeCat, collegeDateProcessState, currentCollegeDate, setCurrentCollegeDate } =
    React.useContext(Context);

  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedAcadYear, setSelectedAcadYear] = React.useState(null);

  const [semData, setSemData] = React.useState({
    sem_type_: '',
    date_start_: '',
    acad_year_: '',
  });

  React.useEffect(() => {
    setSemData({
      ...semData,
      sem_type_: currentCollegeDate ? currentCollegeDate.sem_type : '',
      date_start_: currentCollegeDate ? currentCollegeDate.date_start : '',
      acad_year_: currentCollegeDate ? currentCollegeDate.acad_year : '',
    });

    setSelectedStartDate(currentCollegeDate.date_start ? new Date(currentCollegeDate.date_start) : null);
    setSelectedAcadYear(currentCollegeDate.acad_year ? new Date(getFromYear(currentCollegeDate.acad_year)) : null);
  }, [currentCollegeDate]);

  const getFromYear = (acadYear) => {
    return acadYear.split('-')[0];
  };

  const isMonday = (date) => {
    const day = date.getDay();
    return day === 1;
  };

  const semTypes = {
    semester: [
      {
        id: 'F',
        label: 'Fall',
      },
      {
        id: 'S',
        label: 'Spring',
      },
    ],
    quarter: [
      {
        id: 'Q1',
        label: 'Quarter 1',
      },
      {
        id: 'Q2',
        label: 'Quarter 2',
      },
      {
        id: 'Q3',
        label: 'Quarter 3',
      },
    ],
  };

  const CustomCalendarContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ background: '#f0f0f0', padding: '8px', fontSize: '12px' }}>Select only the starting year.</div>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    );
  };

  const buildSchoolTypeOptions = (type) => {
    let typeList = semTypes.semester;

    for (const [key, value] of Object.entries(semTypes)) {
      if (value.some((element) => element.id === type)) {
        typeList = semTypes[key];
      }
    }

    return typeList.map((type, i) => {
      return (
        <option key={i} value={type.id}>
          {type.label}
        </option>
      );
    });
  };

  const SchoolTypeDropdown = ({ name, value, handleOnChange, classList }) => {
    return (
      <select className={classList} name={name} value={value} onChange={handleOnChange}>
        {buildSchoolTypeOptions(currentCollegeCat === 'college_semester' ? 'F' : 'Q1')}
      </select>
    );
  };

  const handleOnChangeSemData = (event, dateName) => {
    if (event === null) return;
    const target = event.target;

    if (target !== undefined) {
      setSemData({
        ...semData,
        [target.name]: target.value,
      });
    } else {
      // console.log(formatDate(event))
      if (dateName === 'date_start_') {
        setSemData({
          ...semData,
          date_start_: formatDate(event),
        });
        setSelectedStartDate(event);
      }

      if (dateName === 'acad_year_') {
        const fromDate = event;
        const fromYear = fromDate.getFullYear();
        const toDate = new Date(fromDate.setFullYear(fromDate.getFullYear() + 1));
        const toYear = toDate.getFullYear();
        setSemData({
          ...semData,
          acad_year_: `${fromYear}-${toYear}`,
        });
        setSelectedAcadYear(event);
      }
    }
  };

  const handleSubmit = () => {
    if (validateWhiteSpace(semData.sem_type_) === -1 || semData.sem_type_ === '') {
      alert('Type is required.');
    } else if (validateWhiteSpace(semData.date_start_) === -1 || !semData.date_start_) {
      alert('Date start is required.');
    } else if (validateWhiteSpace(semData.acad_year_) === -1 || !semData.acad_year_) {
      alert('Academic year is required.');
    } else {
      const newSemDate = {
        ...currentCollegeDate,
        sem_type: semData.sem_type_,
        date_start: semData.date_start_,
        acad_year: semData.acad_year_,
      };

      setCurrentCollegeDate(newSemDate);

      onSave(newSemDate);
    }
  };

  return (
    <React.Fragment>
      <div className='form-group sem-data-form'>
        <div className='input-wrapper'>
          <SchoolTypeDropdown
            name={'sem_type_'}
            value={semData.sem_type_}
            handleOnChange={handleOnChangeSemData}
            classList={'c2c-select text-holder t-h1'}
          />
          <div className='input-subtext'>Type</div>
        </div>
        <div className='input-wrapper'>
          <DatePicker
            className='text-holder t-h1'
            name='date_start_'
            onChange={(e) => handleOnChangeSemData(e, 'date_start_')}
            selected={selectedStartDate}
            filterDate={isMonday}
            placeholderText='Date Start'
          />
          <div className='input-subtext'>Date Start</div>
        </div>
        <div className='input-wrapper'>
          <DatePicker
            className='text-holder t-h1'
            name='acad_year_'
            value={semData.acad_year_}
            onChange={(e) => handleOnChangeSemData(e, 'acad_year_')}
            selected={selectedAcadYear}
            placeholderText='Academic Year'
            showYearPicker
            calendarContainer={CustomCalendarContainer}
          />
          <div className='input-subtext'>Academic Year</div>
        </div>
      </div>

      <div className='btn-holder'>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={onCancel}
          disabled={collegeDateProcessState}
        >
          <span>Cancel</span>
        </button>
        <button
          className='btn-teal c-white card-h card-h-3 d-flex'
          onClick={handleSubmit}
          disabled={collegeDateProcessState}
        >
          {collegeDateProcessState && <Spinner className='mr-2' animation='border' role='status' aria-hidden='true' />}
          {collegeDateProcessState && <span>Please wait ...</span>}
          {!collegeDateProcessState && <span>{saveBtnText}</span>}
        </button>
      </div>
    </React.Fragment>
  );
};

export default CollegeDateForm;
