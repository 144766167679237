import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { isLogin } from './util'
import Nav from "react-bootstrap/Nav";
import { HashLink as NavLink } from "react-router-hash-link";

const CardPlan = ({ cardInfo, success, handleShow, dayOfWeek }) => {
  return (
    <div className="card-content-holder">
      <div className="flipper-container">
        <div className="flipper">
          <div className="card-plan-holder front">
            <div className="card-head">
              {cardInfo.hasImages
                ? cardInfo.planImgs.map((icon, i) => {
                  return (
                    <img
                      src={icon.planImg}
                      key={i}
                      className="plan-icon"
                      alt=""
                    />
                  );
                })
                : ''}
              <div className="plan-title t-h2 c-purple line">
                {dayOfWeek ? `${cardInfo.planTitle} / ${dayOfWeek}` : `${cardInfo.planTitle}`}
              </div>
              <div className="plan-price t-h3 c-purple">
                {cardInfo.planPrice}
              </div>
              {cardInfo.hasBtn ? (
                isLogin() ? (
                  <Nav.Link
                    as={NavLink}
                    smooth
                    to="/dashboard"
                    className="btn-teal c-white t-uppercase"
                  >
                    Manage Subscription
                  </Nav.Link>
                ) : (
                  <button
                    onClick={handleShow}
                    className="btn-teal c-white t-uppercase"
                  >
                    {cardInfo.btnText}
                  </button>
                )
              ) : null}
            </div>
          </div>
          {cardInfo.doesFlip ? null : (
            <div className="card-plan-holder back">
              <div className="back-wrapper">
                {cardInfo.hasImages
                  ? cardInfo.planImgs.map((icon, i) => {
                    return (
                      <img
                        src={icon.planImg}
                        key={i}
                        className="plan-icon"
                        alt=""
                      />
                    );
                  })
                  : ''}
                <div className="plan-title t-h2 c-purple">
                  {cardInfo.planTitle}
                </div>
                <div className="c-purple t-h3 plan-desc">{cardInfo.planDesc}</div>
                {cardInfo.hasBtn ? (
                  isLogin() ? (
                    <Nav.Link
                      as={NavLink}
                      smooth
                      to="/dashboard"
                      className="btn-teal c-white t-uppercase"
                    >
                      Manage Subscription
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      eventKey="6"
                      as={NavLink}
                      smooth
                      to="/sign-up#signUp"
                      className="btn-teal c-white t-uppercase"
                    >
                      Get Started
                    </Nav.Link>
                    // <button
                    //   onClick={handleShow}
                    //   className="btn-teal c-white t-uppercase"
                    // >
                    //   {cardInfo.btnText}
                    // </button>
                  )
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className="upgrade-text less t-h3 c-teal line">
          {cardInfo.planDiscount}
        </div>
      </div>
    </div>
  );
};

export default CardPlan;
