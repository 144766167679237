import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

import { Context } from '../context/Context'

import { ReactComponent as IconHome } from '../assets/images/home.svg'
import { ReactComponent as IconCards } from '../assets/images/22-Mail.svg'
import { ReactComponent as IconStudentInfo } from '../assets/images/Group 701.svg'
import { ReactComponent as IconUsers } from '../assets/images/group.svg'
import { ReactComponent as IconMessage } from '../assets/images/twilio-message.svg'

const AdminLeftSidebar = () => {
  const { user, handleShowChangePasswordModal } = useContext(Context)

  const [btnToggle, openSideBar] = useState(false)

  const toggle = () => {
    openSideBar(!btnToggle)
  }

  const toggleOnClickItem = () => {
    if (window.innerWidth <= 767) {
      openSideBar(!btnToggle)
    }
  }

  return (
    <>
      <div
        className={
          btnToggle
            ? 'sidebar-wrapper admin-left-sidebar left'
            : 'sidebar-wrapper admin-left-sidebar sidebar-collapsed-wrapper left'
        }>
        <div className="sidebar-list">
          <div onClick={toggle} className={btnToggle ? 'sidebar-toggler opened' : 'sidebar-toggler closed'}></div>
          <div className="sidebar-title c-white">Welcome Admin {user.first_name}</div>
          <Link smooth to="/c2c-admin" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconHome />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Home</p>
            </div>
          </Link>
          <Link smooth to="/manage-colleges" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconStudentInfo />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Manage Colleges</p>
            </div>
          </Link>
          <Link smooth to="/manage-templates" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconCards />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Manage Templates</p>
            </div>
          </Link>
          <Link smooth to="/manage-subscribers" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconUsers />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Manage Subscribers</p>
            </div>
          </Link>
          <Link smooth to="/manage-twilio" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconMessage />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Manage Twilio</p>
            </div>
          </Link>
          <Link smooth to="/manage-affiliates" className="sidebar-item" onClick={toggleOnClickItem}>
            <div className="icon-holder">
              <span>
                <IconMessage />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Manage Affiliates</p>
            </div>
          </Link>
          {/* <div className="sidebar-item" onClick={(e) => e.preventDefault()}>
            <div className="icon-holder">
              <span>
                <IconChangePassword />
              </span>
            </div>
            <div className="label-holder">
              <p className="t-paragraph label">Change Password</p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default AdminLeftSidebar
