import React from 'react';

import C2CModal from "../NewComponents/C2CModal";
import { Context } from "../../context/Context";
import CollegeDateForm from "./CollegeDateForm";

const UpdateCollegeDateModal = ({handleUpdate}) => {
  const {
    updateCollegeDateModalState,
    handleCloseUpdateCollegeDateModal
  } = React.useContext(Context);

  return (
    <C2CModal
      show={updateCollegeDateModalState}
      onHide={handleCloseUpdateCollegeDateModal}
      title={`Update College Date`}
      customClass={"confirm-modal college-date-modal"}
    >
      <CollegeDateForm onSave={handleUpdate} saveBtnText={"Update"} onCancel={handleCloseUpdateCollegeDateModal} />
    </C2CModal>
  )
}

export default UpdateCollegeDateModal