import React, { useContext, useState } from 'react';

import { Context } from '../context/Context';
import api from '../api/api';

import EditStudentInfoModal from './EditStudentInfoModal';

const DashboardStudentInfo = () => {
  const { selectedStudent, handleShowEditStudentInfoModal, setSelectedStudent } = useContext(Context);

  // React.useEffect(() => {
  //   console.log("STUDENT INFO PAGE SELECTED STUDENT:::", selectedStudent)
  // }, [selectedStudent])

  return (
    <div className='dashboard-body'>
      <div className='container dash-container'>
        <div id={selectedStudent.student_id} className='student-info-wrapper'>
          <div className='section-heading-holder'>
            <div className='c-teal section-title'>
              {selectedStudent.first_name + ' ' + selectedStudent.last_name}'S Information
            </div>
            <button className='action-btn' onClick={handleShowEditStudentInfoModal}>
              Edit Info
            </button>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>First Name</div>
            <div className='data-value'>{selectedStudent.first_name}</div>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>Last Name</div>
            <div className='data-value'>{selectedStudent.last_name}</div>
          </div>
          {selectedStudent.subscription_type != 'theme' && (
            <React.Fragment>
              <div className='two-column-data'>
                <div className='data-title'>School Name</div>
                <div className='data-value'>{selectedStudent.school}</div>
              </div>
              <div className='two-column-data'>
                <div className='data-title'>
                  First Day of Current {selectedStudent.subscription_type == 'semester' ? 'Semester' : 'Quarter'}
                </div>
                <div className='data-value'>{selectedStudent.first_day_of_semester}</div>
              </div>
            </React.Fragment>
          )}
          {/* {selectedStudent.subscription_type !== 'theme' && (
            <div className='two-column-data'>
              <div className='data-title'>Email Address</div>
              <div className='data-value'>{selectedStudent.email}</div>
            </div>
          )} */}
          <div className='two-column-data'>
            <div className='data-title'>College Name</div>
            <div className='data-value'>{selectedStudent.company}</div>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>Address 1</div>
            <div className='data-value'>{selectedStudent.street}</div>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>Address 2</div>
            <div className='data-value'>{selectedStudent.unit}</div>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>City</div>
            <div className='data-value'>{selectedStudent.city}</div>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>State</div>
            <div className='data-value'>{selectedStudent.state}</div>
          </div>
          <div className='two-column-data'>
            <div className='data-title'>Zipcode</div>
            <div className='data-value'>{selectedStudent.zipcode}</div>
          </div>
        </div>
      </div>
      <EditStudentInfoModal />
    </div>
  );
};

export default DashboardStudentInfo;
