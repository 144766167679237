import React, { createContext, useState } from 'react'
import axios from 'axios'
import api from '../api/api'
import { isLogin } from '../components/util'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { useRef } from 'react'
import { useHistory } from "react-router-dom";

export const Context = createContext()

export const ContextProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [showPlanModal, setShowPlanModal] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showEditMessageModal, setShowEditMessageModal] = useState(false)
  const [showEditDefaultMessagesModal, setShowEditDefaultMessagesModal] = useState(false)
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false) // eto yon
  const [afterSignUp, setAfterSignUp] = useState(false)
  const [showCardPreviewModal, setShowCardPreviewModal] = useState(false)
  const [showLoaderModal, setShowLoaderModal] = useState(true)
  const [showEditStudentInfoModal, setShowEditStudentInfoModal] = useState(false)
  const [showSignUpConfirmModal, setShowSignUpConfirmModal] = useState(false)
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [showEditUserInfoModal, setShowEditUserInfoModal] = useState(false)
  const [showAddStudentModal, setShowAddStudentModal] = useState(false)
  const [showUpdateCollegeModal, setShowUpdateCollegeModal] = useState(false)
  const [showUpdateSubscriberModal, setShowUpdateSubscriberModal] = useState(false)
  const [showUpdateTwilioModal, setShowUpdateTwilioModal] = useState(false)
  const [showAddCollegeModal, setShowAddCollegeModal] = useState(false)
  const [showUpdateTemplateModal, setShowUpdateTemplateModal] = useState(false)
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false)

  const [plans, setPlans] = useState(null)
  const [emailexists, setEmailExists] = useState(false)
  const [studentEmailExists, setStudentEmailExists] = useState(false)

  const [updateAddressLoading, setUpdateAddressLoading] = useState(false)
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [updatePlanLoading, setUpdatePlanLoading] = useState(false)
  const [updateCustomMessageLoading, setUpdateCustomMessageLoading] = useState(false)
  const [updateDefaultMessagesLoading, setUpdateDefaultMessagesLoading] = useState(false)
  const [signupPayLoading, setSignupPayLoading] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [adminLoginLoading, setAdminLoginLoading] = useState(false)
  const [updateStudentInfoLoading, setUpdateStudentInfoLoading] = useState(false)
  const [addPaymentMethodLoading, setAddPaymentMethodLoading] = useState(false)
  const [updateUserInfoLoading, setUpdateUserInfoLoading] = useState(false)
  const [couponVerificationLoading, setCouponVerificationLoading] = useState(false)
  const [updateCollegeLoading, setUpdateCollegeLoading] = useState(false)
  const [updateSubscriberLoading, setUpdateSubscriberLoading] = useState(false)
  const [deleteCollegeLoading, setDeleteCollegeLoading] = useState(false)
  const [addCollegeLoading, setAddCollegeLoading] = useState(false)

  const [updateTemplateLoading, setUpdateTemplateLoading] = useState(false)
  const [addTemplateLoading, setAddTemplateLoading] = useState(false)

  const [themePackTemplateProcessState, setThemePackTemplateProcessState] = useState(false)

  const [userDataLoaded, setUserDataLoaded] = useState(false)
  const [studentDataLoaded, setStudentDataLoaded] = useState(false)

  const [schools, setSchools] = useState([])
  const [subscribers, setSubscribers] = useState([])
  const [twilioMessages, setTwilioMessages] = useState([])
  const [templates, setTemplates] = useState([])
  const [isAddingNewSchool, setIsAddingNewSchool] = useState(false)

  const [selectedSemesterDate, setSelectedSemesterDate] = useState(null)

  const [currentCard, setCurrentCard] = useState(null)

  const [lastWeekCard, setLastWeekCard] = useState(null)

  const [allCards, setAllCards] = useState([])

  const [activeIndex, _setActiveIndex] = useState(1)

  //this will be used to redirect to edit message when clicked on the payment success modal
  const yourMessageButtonRef = useRef()

  //deluxe package
  const [availedDeluxe, setAvailedDeluxe] = useState()

  const setActiveIndex = index => {
    window.scrollTo(0, 0)
    _setActiveIndex(index)
  }

  const defaultPlans = [
    {
      id: 'Weekly Card',
      nickname: 'Weekly Card',
      metadata: {
        text_price: '$99 / semester',
        plan_desc: '16 Weekly Cards $99/Semester',
      },
    },
    // eto yung reremove
    // {
    //   id: 'Weekly Card + Weekly Gift Card',
    //   nickname: 'Weekly Card + Weekly Gift Card',
    //   metadata: {
    //     text_price: '$199 / semester',
    //     plan_desc:
    //       'In addition to your weekly card, your student will receive 4 custom deluxe care packages. Each Deluxe box is upgraded  and contains stress relieving fidgets, healthy snacks and a few well-deserved treats.',
    //   },
    // },
    // {
    //   id: 'Weekly Card + Monthly Deluxe Gift',
    //   nickname: 'Weekly Card + Monthly Deluxe Gift',
    //   metadata: {
    //     text_price: '$399 / semester',
    //     plan_desc:
    //       'In addition to your weekly card, your student will receive 4 custom deluxe care packages. Each box contains stress-relieving fidgets, healthy snacks, and a few well-deserved treats.',
    //   },
    // },
    // {
    //   id: 'Weekly Card + Monthly Gift',
    //   nickname: 'Weekly Card + Monthly Gift',
    //   metadata: {
    //     previous_price: '$295',
    //     text_price: '$249 / semester',
    //     // plan_desc:
    //     //   'In addition to your weekly card, your student will receive 4 custom care packages at the beginning of each month. Each themed package will have useful items for their college journey. What’s in the box? It’s a surprise! Trust us, we got this covered.',
    //     plan_desc: '16 Weekly Cards & 3 Care Packages $249/Semester',
    //   },
    // },
    // eto yung reremove
    // {
    //   id: 'Weekly Card + Monthly Gift + Weekly Gift Card',
    //   nickname: 'Weekly Card + Monthly Gift + Weekly Gift Card',
    //   metadata: {
    //     text_price: '$334 / semester',
    //     // plan_desc:
    //     //   'In addition to your weekly card, your student will receive 16 weekly-$5 Cards via e-mail each semester. It’s an extra weekly treat on you! Plus, your student will receive 4 custom care packages at the beginning of each month. Each themed package will have useful items for their college journey. What’s in the box? It’s a surprise! Trust us, we got this covered.',
    //     plan_desc:
    //       'In addition to your weekly card, your student will receive 4 custom deluxe care packages. Each Deluxe box is upgraded  and contains stress relieving fidgets, healthy snacks and a few well-deserved treats,.',
    //   },
    // },
  ]

  const quarterDefaultPlans = [
    {
      id: 'Weekly Card',
      nickname: 'Weekly Card',
      metadata: {
        text_price: '$65 / quarter',
        plan_desc: '10 Weekly Cards $65/Quarter',
      },
    },
    // eto yung reremove
    // {
    //   id: 'Weekly Card + Weekly Gift Card',
    //   nickname: 'Weekly Card + Weekly Gift Card',
    //   metadata: {
    //     text_price: '$130 / quarter',
    //     plan_desc:
    //       'In addition to your weekly card, your student will receive 10 weekly-$5 Cards via e-mail each semester. It’s an extra weekly treat on you!',
    //   },
    // },
    // {
    //   id: 'Weekly Card + Monthly Deluxe Gift',
    //   nickname: 'Weekly Card + Monthly Deluxe Gift',
    //   metadata: {
    //     text_price: '$215 / quarter',
    //     plan_desc:
    //       'In addition to your weekly card, your student will receive 2 custom deluxe care packages each quarter. Each box contains stress-relieving fidgets, healthy snacks, and a few well-deserved treats.',
    //   },
    // },
    // {
    //   id: 'Weekly Card + Monthly Gift',
    //   nickname: 'Weekly Card + Monthly Gift',
    //   metadata: {
    //     text_price: '$140 / quarter',
    //     previous_price: '$163',
    //     plan_desc: '10 Weekly Cards & 2 Care Packages $140/Quarter',
    //   },
    // },
    // eto yung reremove
    // {
    //   id: 'Weekly Card + Monthly Gift + Weekly Gift Card',
    //   nickname: 'Weekly Card + Monthly Gift + Weekly Gift Card',
    //   metadata: {
    //     text_price: '$199 / quarter',
    //     plan_desc:
    //       'In addition to your weekly card, your student will receive 10 weekly-$5 Cards via e-mail each semester. It’s an extra weekly treat on you! Plus, your student will receive 2 custom care packages at the beginning of each month. Each themed package will have useful items for their college journey. What’s in the box? It’s a surprise! Trust us, we got this covered.',
    //   },
    // },
  ]

  const themeDefaultPlans = [
    {
      id: 'Theme Pack Faith',
      nickname: 'Theme Pack Faith',
      metadata: {
        text_price: '$25 / 4 cards',
        plan_desc:
          '4 cards sent in consecutive weeks with bible based quotes & messages to encourage, motivate, or support your loved one!',
      },
    },
    {
      id: 'Theme Pack Affirmation',
      nickname: 'Theme Pack Affirmation',
      metadata: {
        text_price: '$25 / 4 cards',
        plan_desc: '4 cards sent in consecutive weeks, great for someone needing to hear they are wonderful!',
      },
    },
    {
      id: 'Theme Pack Thinking of You',
      nickname: 'Theme Pack Thinking of You',
      metadata: {
        text_price: '$25 / 4 cards',
        plan_desc:
          '4 cards sent in consecutive weeks with general quotes & inspirational messages to stay connected to friends or family! ',
      },
    },
    {
      id: 'Theme Pack Encouragement',
      nickname: 'Theme Pack Encouragement',
      metadata: {
        text_price: '$25 / 4 cards',
        plan_desc:
          '4 cards sent in consecutive weeks with general quotes & uplifting messages for any occasion with covers created by a college student artist!',
      },
    },
  ]

  const springMonths = [11, 0, 1, 2, 3] //December to April
  const fallMonths = [4, 5, 6, 7, 8, 9, 10] // May to November
  const getSeason = () => {
    const currentMonth = new Date().getMonth()

    if (springMonths.includes(currentMonth)) {
      return 'spring'
    }

    return 'fall'
  }

  const emptyData = {
    parent_id: '',
    first_name: '',
    last_name: '',
    email: '',
    cell_phone: '',
    password: '',
    confirmPassword: '',
    newsletter: false,
    sms: false,
    street: '',
    unit: '',
    city: '',
    state: '',
    zipcode: '',
    student_first_name: '',
    student_last_name: '',
    schoolname: '',
    birthday: '',
    semester: '',
    season: getSeason(),
    student_email: '',
    level: '',
    student_street: '',
    student_unit: '',
    student_city: '',
    student_state: '',
    student_zipcode: '',
    subscription_id: '',
    greeting: '',
    nick_name: '',
    sign_off: '',
    personal_sign_off: '',
    message_box1: '',
    message_box2: '',
    message_box3: '',
    custom_message: '',
    plan_selected: 'Weekly Card',
    plan_text_price: '$99 / semester',
    plan_previous_price: '16 Weekly Cards $99/Semester',
    plan_description: '',
    coupon: '',
    couponValidated: false,
    //New properties for Quarter and Theme Packs system.
    subscription_type: 'semester',
    quarter: '',
    students: [],
    gift_card: '',
    availedDeluxe: false,
    initiallyAvailedDeluxe: false,
  }

  // const collegeDefaultData = {
  //   college_name: '',
  //   nickname: '',
  //   city: '',
  //   state: '',
  //   zipcode: '',
  //   website_link: '',
  //   mental_health_link: '',
  //   fall_semester_start_date: '',
  //   spring_semester_start_date: '',
  //   next_fall_semester_start_date: '',
  //   next_spring_semester_start_date: '',
  // }

  const collegeDefaultData = {
    college_name: '',
    nickname: '',
    city: '',
    state: '',
    zipcode: '',
    website_link: '',
    mental_health_link: '',
    qtr_or_sem: '',
    dates: [],
  }

  const emptyTemplateData = {
    id: '',
    template_id: '',
    template_name: '',
    message_1: '',
    order: templates.length,
    cover_image: '',
    message_image: '',
    cover_image_src: '',
    message_image_src: '',
    quarter_order: '',
  }

  const [collegeData, setCollegeData] = useState(collegeDefaultData)

  const [templateData, setTemplateData] = useState(emptyTemplateData)

  const [schoolDates, setSchoolDates] = useState({
    selectedFallStartDate: null,
    selectedSpringStartDate: null,
    selectedNextFallStartDate: null,
    selectedNextSpringStartDate: null,
  })

  const handleOnChangeTemplateData = e => {
    if (e.target == undefined) return
    const inputType = e.target.type

    if (inputType === 'text') {
      const target = e.target.name
      const value = e.target.value
      setTemplateData({
        ...templateData,
        [target]: value,
      })
      return
    } else if (inputType === 'file') {
      const imageObject = e.target.files[0] || e.dataTransfer.files[0]
      const fileName = imageObject.name
      const fileType = imageObject.type
      const inputName = e.target.name

      if (fileType !== 'image/png') {
        alert('Image file type is not supported. Please choose PNG image.')
        return
      } else {
        const reader = new FileReader()
        reader.onload = e => {
          setTemplateData({
            ...templateData,
            [inputName]: e.target.result,
            [`${inputName}_src`]: e.target.result,
          })
        }
        reader.readAsDataURL(imageObject)
        return
      }
    }
  }

  // const handleOnChangeCollege = (e, name) => {
  //   if (e === null) return
  //   let target = null
  //   let value = null

  //   if (e.target !== undefined) {
  //     target = e.target.name
  //     value = e.target.value
  //   }

  //   if (e.target === undefined) {
  //     let selectedDate = e
  //     let formatedDate =
  //       selectedDate.getMonth() +
  //       1 +
  //       '/' +
  //       selectedDate.getDate() +
  //       '/' +
  //       selectedDate.getFullYear()

  //     let dateKey = ''

  //     setCollegeData({
  //       ...collegeData,
  //       [name]: formatedDate,
  //     })

  //     if (name === 'fall_semester_start_date') {
  //       dateKey = 'selectedFallStartDate'
  //     } else if (name === 'spring_semester_start_date') {
  //       dateKey = 'selectedSpringStartDate'
  //     } else if (name === 'next_fall_semester_start_date') {
  //       dateKey = 'selectedNextFallStartDate'
  //     } else {
  //       dateKey = 'selectedNextSpringStartDate'
  //     }

  //     setSchoolDates({
  //       ...schoolDates,
  //       [dateKey]: e,
  //     })
  //   } else {
  //     setCollegeData({
  //       ...collegeData,
  //       [target]: value,

  //     })
  //   }
  // }

  const handleOnChangeCollege = (e, name) => {
    if (e === null) return
    let target = null
    let value = null

    if (e.target !== undefined) {
      target = e.target.name
      value = e.target.value
    }

    if (e.target === undefined) {
      let selectedDate = e
      let formatedDate = selectedDate.getMonth() + 1 + '/' + selectedDate.getDate() + '/' + selectedDate.getFullYear()

      let dateKey = ''

      setCollegeData({
        ...collegeData,
        [name]: formatedDate,
      })

      if (name === 'fall_semester_start_date') {
        dateKey = 'selectedFallStartDate'
      } else if (name === 'spring_semester_start_date') {
        dateKey = 'selectedSpringStartDate'
      } else if (name === 'next_fall_semester_start_date') {
        dateKey = 'selectedNextFallStartDate'
      } else {
        dateKey = 'selectedNextSpringStartDate'
      }

      setSchoolDates({
        ...schoolDates,
        [dateKey]: e,
      })
    } else {
      setCollegeData({
        ...collegeData,
        [target]: value,
        qtr_or_sem: currentCollegeCat === 'college_semester' ? 'S' : 'Q',
      })
    }
  }

  const [token, setToken] = useState(null)
  const [tokenAdmin, setTokenAdmin] = useState(null)

  const [user, setUser] = useState({
    billing_address: '',
    cell_phone: '',
    created_at: '',
    email: '',
    email_verified_at: '',
    first_name: '',
    has_active_subscription: '',
    id: '',
    last_name: '',
    mailing_address: '',
    parent_id: '',
    updated_at: '',
  })

  const [paymentMethods, setPaymentMethods] = useState([])
  const [invoice, setInvoice] = useState(null)
  const [upcomingInvoice, setUpcomingInvoice] = useState(null)
  const [customer, setCustomer] = useState([])

  const [success, setSuccess] = useState(false)
  const [data, setData] = useState(emptyData)

  const [showSchool, setShowSchool] = useState(false)

  const [formErrors, setFormErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    cell_phone: '',
    password: '',
    confirmPassword: '',
    street: '',
    unit: '',
    city: '',
    state: '',
    zipcode: '',
  })

  const handleShow = () => {
    _setActiveIndex(1)
    setData(emptyData)
    setIsAddingNewSchool(false)
    setCurrentProcess('signup')
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const resetSignUpForm = () => {
    _setActiveIndex(1)
    setData({ ...emptyData })
    setIsAddingNewSchool(false)
  }

  const handleShowPlanModal = () => setShowPlanModal(true)
  const handleClosePlanModal = () => setShowPlanModal(false)

  const handleShowAddressModal = () => setShowAddressModal(true)
  const handleCloseAddressModal = () => setShowAddressModal(false)

  const handleShowChangePasswordModal = () => setShowChangePasswordModal(true)
  const handleCloseChangePasswordModal = () => setShowChangePasswordModal(false)

  const handleShowEditMessageModal = () => setShowEditMessageModal(true)
  const handleCloseEditMessageModal = () => setShowEditMessageModal(false)

  const handleShowEditDefaultMessagesModal = () => setShowEditDefaultMessagesModal(true)
  const handleCloseEditDefaultMessagesModal = () => setShowEditDefaultMessagesModal(false)

  const handleShowNewsletterModal = () => setShowNewsletterModal(true)
  const handleCloseNewsletterModal = () => setShowNewsletterModal(false)

  const handleShowPaymentSuccessModal = () => {
    let modalTimeout

    modalTimeout = setTimeout(() => {
      setShowPaymentSuccessModal(true)
      setAfterSignUp(false)
      clearTimeout(modalTimeout)
    }, 2000)
  }

  const handleClosePaymentSuccessModal = () => {
    setShowPaymentSuccessModal(false)
    if (selectedStudent.plan_selected === 'Weekly Card' && students.length === 1) {
      handleRunTutorial()
    }
  }

  const showPersonalCardModal = () => {
    let modalTimeout

    modalTimeout = setTimeout(() => {
      setShowPaymentSuccessModal(true)
      clearTimeout(modalTimeout)
    }, 5000)
  }

  const handleShowCardPreviewModal = () => setShowCardPreviewModal(true)
  const handleCloseCardPreviewModal = () => setShowCardPreviewModal(false)

  const handleShowLoaderModal = () => setShowLoaderModal(true)
  const handleCloseLoaderModal = callback => {
    callback && typeof callback == 'function' && callback()
    setShowLoaderModal(false)
  }

  const handleShowEditStudentInfoModal = () => setShowEditStudentInfoModal(true)
  const handleCloseEditStudentInfoModal = () => setShowEditStudentInfoModal(false)

  const handleShowEditUserInfoModal = () => setShowEditUserInfoModal(true)
  const handleCloseEditUserInfoModal = () => setShowEditUserInfoModal(false)

  const handleShowPaymentMethodModal = () => setShowPaymentMethodModal(true)
  const handleClosePaymentMethodModal = () => setShowPaymentMethodModal(false)

  const handleShowSignUpConfirmModal = () => setShowSignUpConfirmModal(true)
  const handleCloseSignUpConfirmModal = () => setShowSignUpConfirmModal(false)

  const handleShowUpdateCollegeModal = () => {
    setCollegeCurrentProcess('update')
    setShowUpdateCollegeModal(true)
  }
  const handleCloseUpdateCollegeModal = () => {
    setCollegeData({
      ...collegeDefaultData,
      qtr_or_sem: currentCollegeCat === 'college_semester' ? 'S' : 'Q',
    })
    setShowUpdateCollegeModal(false)
  }

  const handleShowUpdateSubscriberModal = () => setShowUpdateSubscriberModal(true)
  const handleCloseUpdateSubscriberModal = () => {
    setCurrentCollegeDate(emptyCollegeDate)
    setCurrentCollegeCat('college_semester')
    setCurrentSemDetails([])
    setShowUpdateSubscriberModal(false)
  }

  const handleShowUpdateTwilioModal = () => setShowUpdateTwilioModal(true)
  const handleCloseUpdateTwilioModal = () => setShowUpdateTwilioModal(false)

  const handleShowUpdateTemplateModal = () => setShowUpdateTemplateModal(true)
  const handleCloseUpdateTemplateModal = () => setShowUpdateTemplateModal(false)

  const handleShowAddCollegeModal = () => {
    setCollegeData({
      ...collegeDefaultData,
      qtr_or_sem: currentCollegeCat === 'college_semester' ? 'S' : 'Q',
    })
    setSchoolDates({
      selectedFallStartDate: null,
      selectedSpringStartDate: null,
      selectedNextFallStartDate: null,
      selectedNextSpringStartDate: null,
    })
    setShowAddCollegeModal(true)
    setCurrentSemDetails([])
    setCollegeCurrentProcess('add')
  }
  const handleCloseAddCollegeModal = () => setShowAddCollegeModal(false)

  const handleShowAddTemplateModal = () => {
    setTemplateData(emptyTemplateData)
    setShowAddTemplateModal(true)
  }
  const handleCloseAddTemplateModal = () => setShowAddTemplateModal(false)

  const handleShowAddStudentModal = () => {
    _setActiveIndex(1)
    setData(emptyData)
    setCurrentProcess('add-student')
    setShowAddStudentModal(true)
  }
  const handleCloseAddStudentModal = () => setShowAddStudentModal(false)

  const [selectedOption, setSelectedOption] = useState(null)

  const handleOnChangeSchool = option => {
    // let value = e.target.value;
    setData({
      ...data,
      ['schoolname']: '',
      ['semester']: '',
    })
    if (option.value === 'default') {
      setIsAddingNewSchool(false)
      setSelectedOption(null)
    } else if (option.value === 'add-new-school') {
      setSelectedSemesterDate(null)
      setIsAddingNewSchool(true)
      setSelectedOption(null)
    } else {
      setSelectedOption(option)
      setData({
        ...data,
        ['schoolname']: option.value,
        ['semester']: '0',
      })
      setIsAddingNewSchool(false)
    }
  }

  const handleOnChangeAddNewSchool = e => {
    let value = e.target.checked
    setShowSchool(!showSchool)
    if (value) {
      handleOnChangeSchool({
        label: 'Add another school',
        value: 'add-new-school',
      })
    } else {
      setSelectedSemesterDate(null)
      setIsAddingNewSchool(false)
    }
  }

  const handleOnChange = e => {
    if (e === null) return
    let target = null
    let value = null

    if (e.target !== undefined) {
      target = e.target.name
      value = e.target.value
    }

    if (target === 'plan_selected') {
      const subscriptionType = data.subscription_type
      let planList

      if (subscriptionType === 'quarter') {
        planList = quarterDefaultPlans
      } else if (subscriptionType === 'theme') {
        planList = themeDefaultPlans
      } else {
        planList = defaultPlans
      }

      planList.map((item, i) => {
        value === item.id &&
          setData({
            ...data,
            plan_selected: value,
            plan_text_price: item.metadata.text_price,
            plan_previous_price: item.metadata.previous_price,
            plan_description: item.metadata.plan_desc,
            gift_card: !value.includes('Weekly Gift Card') ? '' : data.gift_card,
          })
      })
    } else if (target === 'newsletter') {
      setData({
        ...data,
        [target]: e.target.checked,
      })
    } else if (target === 'sms') {
      setData({
        ...data,
        [target]: e.target.checked,
      })
    } else if (e.target === undefined) {
      let selectedDate = e
      let formatedDate = selectedDate.getMonth() + 1 + '/' + selectedDate.getDate() + '/' + selectedDate.getFullYear()
      setSelectedSemesterDate(e)
      setData({
        ...data,
        ['semester']: formatedDate,
      })
    } else if (target === 'gift_card') {
      // let currentGiftCards = data.gift_card;
      // currentGiftCards.push(value);

      // if(e.target.checked){
      //   setData({
      //     ...data,
      //     gift_card: currentGiftCards,
      //   })
      // }else{
      //   setData({
      //     ...data,
      //     gift_card: data.gift_card.filter(e => e !== value),
      //   })
      // }

      setData({
        ...data,
        gift_card: value,
      })
    } else if (target === 'subscription_type') {
      const subscriptionType = value
      let planList

      if (subscriptionType === 'quarter') {
        planList = quarterDefaultPlans
      } else if (subscriptionType === 'theme') {
        planList = themeDefaultPlans
      } else {
        planList = defaultPlans
      }

      const plan = planList[0]

      setSelectedOption(null)
      setIsAddingNewSchool(false)
      setSelectedSemesterDate(null)
      setShowSchool(false)
      setData({
        ...data,
        schoolname: '',
        semester: '',
        quarter: '',
        season: value === 'semester' ? getSeason() : '',
        plan_text_price: plan.metadata.text_price,
        plan_previous_price: plan.metadata.previous_price,
        plan_description: plan.metadata.plan_desc,
        [target]: value,

        //set plan
      })
    } else if (target === 'deluxe_package') {
      setData({
        ...data,
        availedDeluxe: !data.availedDeluxe,
        initiallyAvailedDeluxe: !data.availedDeluxe,
      })
    } else {
      target = e.target.name
      value = e.target.value

      const ranges = [
        '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
      ];

      if (value.match(ranges.join('|'))) {
        value = value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
        if (!e.target.dataset.alerted) {
          alert("We cannot print emojis at this time");
          e.target.dataset.alerted = true;
        }
      } else {
        e.target.dataset.alerted = false;
      }

      setData({
        ...data,
        [target]: value,
      })
    }
  }

  const handleDeleteSubscription = (index, fromData = data, dataSetter = setData, defaultData = data) => {
    let copy = fromData.students

    // if(copy.length <=1) {
    //   alert("you must have atleast one student");
    // } else {
    //   copy.splice(index, 1);
    //   setData({
    //     ...data,
    //     students: copy
    //   });
    // }

    setIsAddingNewSchool(false)
    setSelectedSemesterDate(null)
    copy.splice(index, 1)
    dataSetter({
      ...defaultData,
      students: copy,
    })
  }

  const handleDeleteDeluxe = (index, fromData = data, dataSetter = setData, defaultData = data) => {
    let copy = fromData.students

    copy[index].availedDeluxe = false
    copy[index].initiallyAvailedDeluxe = false

    dataSetter({
      ...defaultData,
      students: copy,
    })
  }

  const handleOnSubscriptionChange = (e, index, fromData = data, dataSetter = setData) => {
    let target = e.target.name
    let value = e.target.value
    if (target === 'plan_selected') {
      let copy = fromData.students
      const subscriptionType = copy[index].subscription_type
      let planList

      if (subscriptionType === 'quarter') {
        planList = quarterDefaultPlans
      } else if (subscriptionType === 'theme') {
        planList = themeDefaultPlans
      } else {
        planList = defaultPlans
      }

      planList.map((item, i) => {
        if (value === item.id) {
          const isMonthlyGift = value.includes('Monthly Gift')

          const deluxeCost = subscriptionType === 'quarter' ? '$40' : '$75'

          if (!isMonthlyGift) {
            copy[index]['availedDeluxe'] = false
          } else {
            if (copy[index]['initiallyAvailedDeluxe']) {
              copy[index]['availedDeluxe'] = true
            }
          }

          copy[index][target] = value
          copy[index]['plan_text_price'] = item.metadata.text_price
          copy[index]['plan_previous_price'] = item.metadata.previous_price
          copy[index]['plan_description'] = item.metadata.plan_desc
          copy[index]['gift_card'] = !value.includes('Weekly Gift Card') ? '' : fromData.gift_card
        }
      })

      dataSetter({
        ...fromData,
        students: copy,
      })
    } else {
      let copy = fromData.students
      copy[index][target] = value

      if (target === 'gift_card') {
        copy[index][target] = value
      }

      dataSetter({
        ...fromData,
        students: copy,
      })
    }
  }

  const handleAddStudent = (fromData = data, dataSetter = setData) => {
    setIsAddingNewSchool(false)
    setSelectedSemesterDate(null)
    let student = {
      student_first_name: fromData.student_first_name,
      student_last_name: fromData.student_last_name,
      schoolname: fromData.schoolname,
      birthday: fromData.birthday,
      semester: fromData.semester,
      season: fromData.season,
      student_email: fromData.student_email,
      student_street: fromData.student_street,
      student_unit: fromData.student_unit,
      student_city: fromData.student_city,
      student_state: fromData.student_state,
      student_zipcode: fromData.student_zipcode,
      subscription_id: fromData.subscription_id,
      greeting: fromData.greeting,
      nick_name: fromData.nick_name,
      sign_off: fromData.sign_off,
      personal_sign_off: fromData.personal_sign_off,
      message_box1:
        fromData.message_box1 === ''
          ? `Believe in yourself and your abilities. You're capable of great things! Stay focused, work hard, and embrace every challenge as an opportunity to grow. Remember, success is a journey, not a destination.`
          : fromData.message_box1,
      message_box2: fromData.message_box2,
      message_box3: fromData.message_box3,
      custom_message: fromData.custom_message,
      plan_selected: fromData.plan_selected,
      plan_text_price: fromData.plan_text_price,
      plan_previous_price: fromData.plan_previous_price,
      plan_description: fromData.plan_description,
      subscription_type: fromData.subscription_type,
      quarter: fromData.quarter,
      gift_card: fromData.gift_card,
      availedDeluxe: fromData.availedDeluxe,
      initiallyAvailedDeluxe: fromData.initiallyAvailedDeluxe,
    }

    const newItemArray = fromData.students.concat(student)

    dataSetter({
      ...fromData,
      students: newItemArray,
      student_first_name: '',
      student_last_name: '',
      schoolname: '',
      birthday: '',
      semester: '',
      season: getSeason(),
      student_email: '',
      student_street: '',
      student_unit: '',
      student_city: '',
      student_state: '',
      student_zipcode: '',
      subscription_id: '',
      greeting: '',
      nick_name: '',
      sign_off: '',
      personal_sign_off: '',
      message_box1: '',
      message_box2: '',
      message_box3: '',
      custom_message: '',
      plan_selected: 'Weekly Card',
      plan_text_price: '$99 / semester',
      plan_previous_price: '16 Weekly Cards $99/Semester',
      plan_description: '',
      subscription_type: fromData.subscription_type,
      quarter: '',
      gift_card: '',
      availedDeluxe: false,
      initiallyAvailedDeluxe: false,
    })
  }

  const handlePhoneChange = value => {
    const isValid = isValidPhoneNumber(value)
    setData({
      ...data,
      cell_phone: value ? value : '',
      sms: value && isValid ? true : false,
    })
  }

  const handleParentID = value => {
    setData({
      ...data,
      parent_id: value,
    })
  }

  const handleSubscriptionId = (id, fromData = data, dataSetter = setData) => {
    dataSetter({
      ...fromData,
      subscription_id: id,
    })
  }

  const [students, setStudents] = useState([])
  const [selectedStudent, setSelectedStudent] = useState([])
  const [subscriptions, setSubscriptions] = useState(null)

  const onChangeStudentData = e => {
    const target = e.target.name
    const value = e.target.value

    if (target === 'plan_change') {
      setSelectedStudent({
        ...selectedStudent,
        plan_change: value,
      })
    } else if (target === 'gift_card') {
      setSelectedStudent({
        ...selectedStudent,
        gift_card: value,
      })
    } else {
      setSelectedStudent({
        ...selectedStudent,
        [target]: value,
      })
    }
  }

  const planList = [
    {
      hasImages: true,
      hasPrice: true,
      hasBtn: true,
      hasDiscount: true,
      planTitle: 'Weekly Card + Weekly Gift Card',
      planPrice: '$199 / Semester',
      btnText: 'Get Started',
      planDiscount: 'Less Than $2 Per Day',
      planDesc:
        'In addition to your weekly card, your student will receive 16 weekly-$5 Starbucks Cards via e-mail each semester. It’s an extra weekly treat on you!',
      planImgs: [
        {
          planImg: require('../assets/images/Choose Your Plan Card Icon@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Plus Sign@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Gift Card Icon@2x.png'),
        },
      ],
    },
    {
      hasImages: true,
      hasPrice: true,
      hasBtn: true,
      hasDiscount: true,
      planTitle: 'Weekly Card + Monthly Deluxe Gift',
      planPrice: '$399 / Semester',
      btnText: 'Get Started',
      planDiscount: 'Less Than $2 Per Day',
      planDesc:
        'In addition to your weekly card, your student will receive 4 custom deluxe care packages. Each box contains stress-relieving fidgets, healthy snacks, and a few well-deserved treats.',
      planImgs: [
        {
          planImg: require('../assets/images/Choose Your Plan Card Icon@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Plus Sign@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Gift Card Icon@2x.png'),
        },
      ],
    },
    {
      hasImages: true,
      hasPrice: true,
      hasBtn: true,
      hasDiscount: true,
      planTitle: 'Weekly Card + Monthly Gift',
      planPrice: '$249 / Semester',
      btnText: 'Get Started',
      planDiscount: 'Just Over $2 Per Day',
      // planDesc:
      //   'In addition to your weekly card, your student will receive 4 custom care packages at the beginning of each month. Each themed package will have useful items for their college journey. What’s in the box?  It’s a surprise! Trust us, we got this covered.',
      planDesc:
        'In addition to your weekly card, your student will receive 4 custom care packages. Each box contains stress relieving fidgets, healthy snacks and a few well-deserved treats',
      planImgs: [
        {
          planImg: require('../assets/images/Choose Your Plan Card Icon@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Plus Sign@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Present Icon@2x.png'),
        },
      ],
    },
    {
      hasImages: true,
      hasPrice: true,
      hasBtn: true,
      hasDiscount: true,
      planImg: require('../assets/images/Choose Your Plan Card Icon@2x.png'),
      planTitle: 'Weekly Card + Monthly Gift + Weekly Gift Card',
      btnText: 'Get Started',
      planPrice: '$334 / Semester',
      planDiscount: 'Less Than $3 Per Day',
      planDesc:
        'In addition to your weekly card, your student will receive 16 weekly-$5 Starbucks Cards via e-mail each semester. It’s an extra weekly treat on you! Plus, your student will receive 4 custom care packages at the beginning of each month. Each themed package will have useful items for their college journey. What’s in the box?  It’s a surprise! Trust us, we got this covered.',
      planImgs: [
        {
          planImg: require('../assets/images/Choose Your Plan Card Icon@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Plus Sign@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Present Icon@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Plus Sign@2x.png'),
        },
        {
          planImg: require('../assets/images/Choose Your Plan Gift Card Icon@2x.png'),
        },
      ],
    },
  ]

  const featureList = [
    {
      featIcon: require('../assets/images/How it Works Comp Icon@2x.png'),
      featTitle: 'Sign Up!',
      featCaption: 'Give us the who and where.',
    },
    {
      featIcon: require('../assets/images/How it works Cell phone Icon@2x.png'),
      featTitle: 'Personalize!',
      featCaption: 'Add your weekly personalized message.',
    },
    {
      featIcon: require('../assets/images/How it Works Envelope Icon@2x.png'),
      featTitle: 'We Send!',
      featCaption: "Sit back, relax, and we'll handle the rest.",
    },
  ]

  const gettingList = [
    {
      itemIcon: require('../assets/images/Benefits Grad Cap Icon@2x.png'),
      itemCaption:
        'Using inspirational quotes & motivational messages to support your young adults in college as they navigate what some say are their most difficult & stressful years.',
    },
    {
      itemIcon: require('../assets/images/Benefits Support Icon@2x.png'),
      itemCaption:
        'Helping parents, grandparents, and mentors easily connect with consistent support printed, mailed, and delivered weekly.',
    },
  ]

  const gettingImageList = [
    {
      itemImage: require('../assets/images/benefit-image-1.png'),
      itemText: 'Real cards for your students',
    },
    {
      itemImage: require('../assets/images/benefit-image-2.png'),
      itemText: 'Our cards look genuinely handwritten',
    },
    {
      itemImage: require('../assets/images/benefit-image-3.jpg'),
      itemText: 'Feelings of home',
    },
  ]

  const statsList = [
    {
      statItem: 'Suicide is the #2 leading cause of death among students.',
    },
    {
      statItem: 'Anxiety is the most common student mental health problem',
    },
    {
      statItem: '1 out of 3 students report feeling so depressed they have trouble functioning.',
    },
    {
      statItem: 'Mental health issues are associated with lower GPA’s and higher dropout rates.',
    },
    {
      statItem: 'Only 7% of parents report that their students suffer from mental health issues.',
    },
  ]

  const [superAdminSummary, setSuperAdminSummary] = useState({})
  const [superAdminWeeklyGiftCardReport, setSuperAdminWeeklyGiftCardReport] = useState({})

  const [tempStudentData, setTempStudentData] = useState({
    subscription_type: '',
    city: '',
    default_message_1: '',
    default_message_2: '',
    default_message_3: '',
    custom_message: '',
    email: '',
    fall_semester_day: '',
    fall_semester_month: '',
    first_day_of_semester: '',
    first_name: '',
    greeting: '',
    id: '',
    is_custom_school: '',
    last_name: '',
    name_as_sign_off: '',
    next_fall_semester_day: '',
    next_fall_semester_month: '',
    next_spring_semester_day: '',
    next_spring_semester_month: '',
    nick_name: '',
    parent_id: '',
    plan_selected: '',
    school: '',
    sign_off: '',
    spring_semester_day: '',
    spring_semester_month: '',
    state: '',
    street: '',
    student_id: '',
    subscription_id: '',
    subscription_status: '',
    unit: '',
    zipcode: '',
  })

  /**
   * Global state variables for Add subscription modal
   */
  const [addSubscriptionModalState, setAddSubscriptionModalState] = useState(false)
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState('semester')

  const defaultNewRecipientData = {
    student_first_name: selectedStudent.first_name ?? '',
    student_last_name: selectedStudent.last_name ?? '',
    schoolname: selectedStudent.school ?? '',
    birthday: '',
    semester: '',
    season: '',
    student_email: selectedStudent.email ?? '',
    student_street: selectedStudent.street ?? '',
    student_unit: selectedStudent.unit ?? '',
    student_city: selectedStudent.city ?? '',
    student_state: selectedStudent.state ?? '',
    student_zipcode: selectedStudent.zipcode ?? '',
    subscription_id: selectedStudent.subscription_id ?? '',
    greeting: '',
    nick_name: '',
    sign_off: '',
    personal_sign_off: '',
    message_box1: '',
    message_box2: '',
    message_box3: '',
    custom_message: '',
    plan_selected: '',
    plan_text_price: '',
    plan_previous_price: '',
    plan_description: '',
    coupon: '',
    couponValidated: false,
    subscription_type: 'theme',
    quarter: '',
    students: [],
    gift_card: '',
  }

  /**
   * This is a new data object for adding theme pack subscription to be passed on sign up request.
   */
  const [newRecipientData, setNewRecipientData] = useState(defaultNewRecipientData)

  const showAddSubscriptionModal = () => {
    setAddSubscriptionModalState(true)
  }
  const hideAddSubscriptionModal = () => {
    setCurrentSubscriptionType('semester')
    setNewRecipientData(defaultNewRecipientData)
    setAddSubscriptionModalState(false)
  }

  /**
   * On change handler for new recipient data
   */
  const handleOnChangeNewRecipientData = e => {
    if (e === null) return
    let target = null
    let value = null

    if (e.target !== undefined) {
      target = e.target.name
      value = e.target.value
    }

    if (target === 'plan_selected') {
      const subscriptionType = newRecipientData.subscription_type
      let planList

      if (subscriptionType === 'quarter') {
        planList = quarterDefaultPlans
      } else if (subscriptionType === 'theme') {
        planList = themeDefaultPlans
      } else {
        planList = defaultPlans
      }

      planList.map((item, i) => {
        value === item.id &&
          setNewRecipientData({
            ...newRecipientData,
            plan_selected: value,
            plan_text_price: item.metadata.text_price,
            plan_previous_price: item.metadata.previous_price,
            plan_description: item.metadata.plan_desc,
          })
      })
    } else {
      const ranges = [
        '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
      ];

      if (value.match(ranges.join('|'))) {
        value = value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
        if (!e.target.dataset.alerted) {
          alert("We cannot print emojis at this time");
          e.target.dataset.alerted = true;
        }
      } else {
        e.target.dataset.alerted = false;
      }
      setNewRecipientData({
        ...newRecipientData,
        [target]: value,
      })
    }
  }

  /**
   * Global state variables for new user data and Checkout form.
   * currentProcess can be [signup, add-theme, add-student]
   */
  const [currentProcess, setCurrentProcess] = useState('signup')

  /**
   * Use this to populate current user and selected student data to our `data` object
   */
  const populateCurrentData = () => {
    setData({
      ...data,
      cell_phone: user.cell_phone,
      city: user.city,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      newsletter: user.newsletter == 0 ? false : true,
      parent_id: user.parent_id,
      sms: user.sms == 0 ? false : true,
      state: user.state,
      street: user.street,
      unit: user.unit,
      zipcode: user.zipcode,

      student_first_name: selectedStudent.first_name,
      student_last_name: selectedStudent.last_name,
      schoolname: selectedStudent.school,
      student_email: selectedStudent.email,
      student_street: selectedStudent.state,
      student_unit: selectedStudent.unit,
      student_city: selectedStudent.city,
      student_state: selectedStudent.state,
      student_zipcode: selectedStudent.zipcode,
      subscription_id: selectedStudent.subscription_id,
      greeting: selectedStudent.greeting,
      nick_name: selectedStudent.nick_name,
      sign_off: selectedStudent.sign_off,
      personal_sign_off: selectedStudent.name_as_sign_off,
      message_box1: selectedStudent.default_message_1,
      message_box2: selectedStudent.default_message_2,
      message_box3: selectedStudent.default_message_3,
      custom_message: selectedStudent.custom_message,
      plan_selected: selectedStudent.plan_selected,

      subscription_type: 'theme',
      gift_card: selectedStudent.gift_card,
    })
  }

  const getSubscriptionType = studentData => {
    const planSelected = studentData.plan_selected
    const haveQuarter = studentData.q1_day != null || studentData.q2_day != null || studentData.q3_day != null
    const isCollegePlan = defaultPlans.some(collegePlan => collegePlan.id == planSelected)
    if (haveQuarter && isCollegePlan) {
      return 'quarter'
    } else if (!haveQuarter && isCollegePlan) {
      return 'semester'
    } else {
      return 'theme'
    }
  }

  const handleOnClickAddThemePack = () => {
    const currentSubscriptionType = selectedStudent.subscription_type
    const subscriptionIsActive = selectedStudent.subscription_status == 1 ? true : false
    const haveCompleteAddress =
      selectedStudent.street != null &&
      selectedStudent.city != null &&
      selectedStudent.state != null &&
      selectedStudent.zipcode != null
    if (currentSubscriptionType === 'theme' && subscriptionIsActive) {
      alert('A theme pack subscription for this recipient is active. Please wait until it expires to add a new one.')
    } else if (!haveCompleteAddress) {
      alert(
        `Current ${
          currentSubscriptionType != 'theme' ? 'student' : 'recipient'
        }'s address is incomplete. Please make sure you filled theme up before subscribing to a theme pack plan.`
      )
    } else {
      setNewRecipientData({
        ...newRecipientData,
        subscription_type: 'theme',
      })
      setCurrentSubscriptionType('theme')
      setCurrentProcess('add-theme')
      showAddSubscriptionModal()
    }
  }

  const [themePackTemplates, setThemePackTemplates] = useState([])
  const [currentTemplateCat, setCurrentTemplateCat] = useState('template_college')
  const [currentCollegeCat, setCurrentCollegeCat] = useState('college_semester')

  const [updateThemePackTemplateModalState, setUpdateThemePackTemplateModalState] = useState(false)
  const handleShowUpdateThemePackTemplateModal = () => setUpdateThemePackTemplateModalState(true)
  const handleCloseUpdateThemePackTemplateModal = () => setUpdateThemePackTemplateModalState(false)

  const [addThemePackTemplateModalState, setAddThemePackTemplateModalState] = useState(false)
  const handleShowAddThemePackTemplateModal = () => {
    setCurrentThemePackTemplate({
      id: '',
      template_id1: '',
      template_id2: '',
      template_id3: '',
      template_id4: '',
      message_1: '',
      message_2: '',
      message_3: '',
      message_4: '',
      theme_name: '',
      theme_code: '',
      front_image: '',
      front_image2: '',
      front_image3: '',
      front_image4: '',
      inside_message: '',
      inside_message2: '',
      inside_message3: '',
      inside_message4: '',
    })
    setAddThemePackTemplateModalState(true)
  }
  const handleCloseAddThemePackTemplateModal = () => setAddThemePackTemplateModalState(false)

  const [schoolSemDetails, setSchoolSemDetails] = useState([])
  const [customSchoolSemDetails, setCustomSchoolSemDetails] = useState([])
  const [currentSemDetails, setCurrentSemDetails] = useState([])

  const [addCollegeDateModalState, setAddCollegeDateModalState] = useState(false)
  const handleShowAddCollegeDateModal = () => {
    setCurrentCollegeDate(emptyCollegeDate)
    setAddCollegeDateModalState(true)
  }
  const handleCloseAddCollegeDateModal = () => {
    setCollegeDateProcessState(false)
    setAddCollegeDateModalState(false)
  }

  const [updateGiftCardModalState, setUpdateGiftCardModalState] = useState(false)
  const handleShowUpdateGiftCardModal = () => {
    setUpdateGiftCardModalState(true)
  }
  const handleCloseUpdateGiftCardModal = () => {
    setUpdateGiftCardModalState(false)
  }

  const [giftCardProcess, setGiftCardProcess] = useState(false)

  const [updateCollegeDateModalState, setUpdateCollegeDateModalState] = useState(false)
  const handleShowUpdateCollegeDateModal = () => setUpdateCollegeDateModalState(true)
  const handleCloseUpdateCollegeDateModal = () => {
    setCollegeDateProcessState(false)
    setUpdateCollegeDateModalState(false)
  }

  const [collegeDateProcessState, setCollegeDateProcessState] = useState(false)

  const [collegeCurrentProcess, setCollegeCurrentProcess] = useState('add')

  const emptyCollegeDate = {
    id: '',
    coll_id: '',
    acad_year: '',
    sem_type: currentCollegeCat === 'college_semester' ? 'F' : 'Q1',
    date_start: '',
  }

  const [currentCollegeDate, setCurrentCollegeDate] = useState(emptyCollegeDate)
  const [currentCollegeDateIndex, setCurrentCollegeDateIndex] = useState(0)
  const [customSchools, setCustomSchools] = useState([])
  const [customSchoolId, setCustomSchoolId] = useState(null)

  const [currentThemePackTemplate, setCurrentThemePackTemplate] = useState(null)

  const giftCardOptions = [
    {
      id: 'Starbucks',
      name: 'Starbucks',
    },
    {
      id: "Dunkin'",
      name: "Dunkin'",
    },
    {
      id: "Domino's",
      name: "Domino's",
    },
    {
      id: 'Subway',
      name: 'Subway',
    },
  ]

  const initSignUp = () => {
    _setActiveIndex(1)
    setData(emptyData)
    setIsAddingNewSchool(false)
    setCurrentProcess('signup')
  }

  const [runTutorial, setRunTutorial] = useState(false) 
  const [tutorialStepIndex, setTutorialStepIndex] = useState(0)
  const [leftSideBar, setLeftSideBar] = useState('closed')
  const [dashTabActiveKey, setDashTabActiveKey] = useState('third')
  const history = useHistory();
  const [tutorialSteps, setTutorialSteps] = useState([
    {
      title: <h5>Tutorial</h5>,
      content: 'Let\'s start your Cards2College journey with a quick tutorial.',
      placement: 'center',
      target: 'body',
      hideCloseButton: true
    },
    {
      title: <h5>Front Cover</h5>,
      content: 'Click here to view this week\'s cover.',
      target: '#front-cover',
      hideCloseButton: true
    },
    {
      title: <h5>Weekly Inspiration</h5>,
      content: 'Read the message that is included in this week\'s card.',
      target: '#weekly-inspiration',
      hideCloseButton: true
    },
    {
      title: <h5>Your Students</h5>,
      content: 'Toggle between your students or add a new student.',
      target: '#collapsible-nav-dropdown',
      placement: 'bottom',
      // spotlightClicks: true,
      hideCloseButton: true
    },
    {
      title: <h5>Shop Care Packages</h5>,
      content: 'Shop our care package store here.',
      target: '#care-packages',
      placement: 'bottom',
      // spotlightClicks: true,
      hideCloseButton: true
    },
    {
      title: <h5>Editing your message</h5>,
      content: 'Write your new message here each week.',
      target: '#edit-your-message-btn',
      // spotlightClicks: true,
      hideCloseButton: true
    },
    {
      title: <h5>Editing default messages</h5>,
      content: 'Add up to 3 extra message that will cycle in case you miss a week.',
      target: '#edit-default-messages-btn',
      // spotlightClicks: true,
      hideCloseButton: true
    },
    {
      title: <h5>Editing student address</h5>,
      content: 'Update your student\'s address here.',
      target: '#my-student',
      // spotlightClicks: true,
      hideCloseButton: true
    },
    {
      title: <h5>Previous cards</h5>,
      content: 'This area has the previews of cards sent.',
      target: '#last-week-card',
      hideCloseButton: true
    },
    {
      title: <h5>Account Menu</h5>,
      content: 'Click here to manage your account.',
      target: '#left-sidebar-toggle',
      spotlightClicks: true,
      hideCloseButton: true,
      placement: 'right'
    }
  ]);

  const handleRunTutorial = () => {
    history.push("/dashboard");
    document.getElementById("basic-navbar-nav").classList.remove("show");
    setTutorialStepIndex(0);
    setRunTutorial(true);
    setLeftSideBar("closed")
  }

  return (
    <Context.Provider
      value={{
        planList,
        featureList,
        gettingList,
        gettingImageList,
        statsList,
        show,
        handleShow,
        handleClose,
        plans,
        setPlans,
        defaultPlans,
        emailexists,
        setEmailExists,
        studentEmailExists,
        setStudentEmailExists,
        success,
        setSuccess,
        data,
        setData,
        user,
        setUser,
        customer,
        setCustomer,
        paymentMethods,
        setPaymentMethods,
        invoice,
        setInvoice,
        upcomingInvoice,
        setUpcomingInvoice,
        token,
        setToken,
        tokenAdmin,
        setTokenAdmin,
        students,
        setStudents,
        selectedStudent,
        setSelectedStudent,
        subscriptions,
        setSubscriptions,
        onChangeStudentData,
        handleOnChange,
        handleAddStudent,
        handlePhoneChange,
        handleSubscriptionId,
        handleParentID,
        formErrors,
        setFormErrors,
        showPlanModal,
        handleClosePlanModal,
        handleShowPlanModal,
        showAddressModal,
        handleCloseAddressModal,
        handleShowAddressModal,
        showChangePasswordModal,
        handleCloseChangePasswordModal,
        handleShowChangePasswordModal,
        userDataLoaded,
        setUserDataLoaded,
        studentDataLoaded,
        setStudentDataLoaded,
        updateAddressLoading,
        setUpdateAddressLoading,
        updatePasswordLoading,
        setUpdatePasswordLoading,
        updatePlanLoading,
        setUpdatePlanLoading,
        signupPayLoading,
        setSignupPayLoading,
        handleOnChangeSchool,
        selectedOption,
        setSelectedOption,
        handleOnChangeAddNewSchool,
        showSchool,
        isAddingNewSchool,
        selectedSemesterDate,
        schools,
        setSchools,
        subscribers,
        setSubscribers,
        twilioMessages,
        setTwilioMessages,
        templates,
        setTemplates,
        showEditMessageModal,
        handleShowEditMessageModal,
        handleCloseEditMessageModal,
        updateCustomMessageLoading,
        setUpdateCustomMessageLoading,
        showEditDefaultMessagesModal,
        handleShowEditDefaultMessagesModal,
        handleCloseEditDefaultMessagesModal,
        updateDefaultMessagesLoading,
        setUpdateDefaultMessagesLoading,
        currentCard,
        setCurrentCard,
        lastWeekCard,
        setLastWeekCard,
        allCards,
        setAllCards,
        showNewsletterModal,
        handleShowNewsletterModal,
        handleCloseNewsletterModal,
        showPaymentSuccessModal,
        handleShowPaymentSuccessModal,
        handleClosePaymentSuccessModal,
        showPersonalCardModal,
        showCardPreviewModal,
        handleCloseCardPreviewModal,
        handleShowCardPreviewModal,
        showLoaderModal,
        handleShowLoaderModal,
        handleCloseLoaderModal,
        loginLoading,
        setLoginLoading,
        showEditStudentInfoModal,
        handleShowEditStudentInfoModal,
        handleCloseEditStudentInfoModal,
        updateStudentInfoLoading,
        setUpdateStudentInfoLoading,
        showSignUpConfirmModal,
        handleShowSignUpConfirmModal,
        handleCloseSignUpConfirmModal,
        showEditUserInfoModal,
        handleShowEditUserInfoModal,
        handleCloseEditUserInfoModal,
        showPaymentMethodModal,
        handleShowPaymentMethodModal,
        handleClosePaymentMethodModal,
        addPaymentMethodLoading,
        setAddPaymentMethodLoading,
        updateUserInfoLoading,
        setUpdateUserInfoLoading,
        handleDeleteSubscription,
        handleOnSubscriptionChange,
        resetPasswordLoading,
        setResetPasswordLoading,
        activeIndex,
        setActiveIndex,
        couponVerificationLoading,
        setCouponVerificationLoading,
        showAddStudentModal,
        handleShowAddStudentModal,
        handleCloseAddStudentModal,
        superAdminSummary,
        setSuperAdminSummary,
        superAdminWeeklyGiftCardReport,
        setSuperAdminWeeklyGiftCardReport,
        adminLoginLoading,
        setAdminLoginLoading,
        showUpdateCollegeModal,
        handleShowUpdateCollegeModal,
        handleCloseUpdateCollegeModal,
        showUpdateSubscriberModal,
        handleShowUpdateSubscriberModal,
        handleCloseUpdateSubscriberModal,
        showUpdateTwilioModal,
        handleShowUpdateTwilioModal,
        handleCloseUpdateTwilioModal,
        deleteCollegeLoading,
        setDeleteCollegeLoading,
        updateCollegeLoading,
        setUpdateCollegeLoading,
        updateSubscriberLoading,
        setUpdateSubscriberLoading,
        schoolDates,
        collegeData,
        handleOnChangeCollege,
        addCollegeLoading,
        setAddCollegeLoading,
        handleShowAddCollegeModal,
        handleCloseAddCollegeModal,
        showAddCollegeModal,
        addTemplateLoading,
        setAddTemplateLoading,
        handleShowAddTemplateModal,
        handleCloseAddTemplateModal,
        showAddTemplateModal,
        showUpdateTemplateModal,
        handleShowUpdateTemplateModal,
        handleCloseUpdateTemplateModal,
        updateTemplateLoading,
        setUpdateTemplateLoading,
        templateData,
        handleOnChangeTemplateData,
        resetSignUpForm,
        quarterDefaultPlans,
        themeDefaultPlans,
        tempStudentData,
        setTempStudentData,
        addSubscriptionModalState,
        showAddSubscriptionModal,
        hideAddSubscriptionModal,
        currentSubscriptionType,
        setCurrentSubscriptionType,
        currentProcess,
        setCurrentProcess,
        populateCurrentData,
        newRecipientData,
        setNewRecipientData,
        handleOnChangeNewRecipientData,
        defaultNewRecipientData,
        getSubscriptionType,
        handleOnClickAddThemePack,
        themePackTemplates,
        setThemePackTemplates,
        currentTemplateCat,
        setCurrentTemplateCat,
        updateThemePackTemplateModalState,
        handleShowUpdateThemePackTemplateModal,
        handleCloseUpdateThemePackTemplateModal,
        addThemePackTemplateModalState,
        handleShowAddThemePackTemplateModal,
        handleCloseAddThemePackTemplateModal,
        currentCollegeCat,
        setCurrentCollegeCat,
        schoolSemDetails,
        setSchoolSemDetails,
        setCollegeData,
        currentSemDetails,
        setCurrentSemDetails,
        addCollegeDateModalState,
        handleShowAddCollegeDateModal,
        handleCloseAddCollegeDateModal,
        collegeDateProcessState,
        setCollegeDateProcessState,
        collegeCurrentProcess,
        setCollegeCurrentProcess,
        updateCollegeDateModalState,
        handleShowUpdateCollegeDateModal,
        handleCloseUpdateCollegeDateModal,
        currentCollegeDate,
        setCurrentCollegeDate,
        emptyCollegeDate,
        currentCollegeDateIndex,
        setCurrentCollegeDateIndex,
        customSchoolSemDetails,
        setCustomSchoolSemDetails,
        customSchools,
        setCustomSchools,
        customSchoolId,
        setCustomSchoolId,
        themePackTemplateProcessState,
        setThemePackTemplateProcessState,
        currentThemePackTemplate,
        setCurrentThemePackTemplate,
        giftCardOptions,
        updateGiftCardModalState,
        handleShowUpdateGiftCardModal,
        handleCloseUpdateGiftCardModal,
        giftCardProcess,
        setGiftCardProcess,
        initSignUp,
        afterSignUp,
        setAfterSignUp,
        yourMessageButtonRef,
        handleDeleteDeluxe,
        runTutorial,
        setRunTutorial,
        tutorialStepIndex,
        setTutorialStepIndex,
        tutorialSteps,
        setTutorialSteps,
        leftSideBar,
        setLeftSideBar,
        dashTabActiveKey, 
        setDashTabActiveKey,
        handleRunTutorial
      }}>
      {children}
    </Context.Provider>
  )
}
