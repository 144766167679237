import React, { useContext, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/Spinner";

import { Context } from "../context/Context";
import api from "../api/api";

import UpdateSubscriberModal from "../components/UpdateSubscriberModal";

const AdminManageSubscribers = () => {
  const {
    subscribers,
    setSubscribers,
    handleShowUpdateSubscriberModal,
    handleCloseLoaderModal,
    customSchoolSemDetails,
    setCustomSchoolSemDetails,
    customSchools,
    setCustomSchools,
    setCurrentSemDetails,
    setCurrentCollegeCat,
    setSchools,
    tokenAdmin,
  } = useContext(Context);

  const [subscriberIsLoading, setSubscriberIsLoading] = useState(true);

  const [subscriberData, setSubscriberData] = useState({
    id: "",
    parent_id: "",
    student_id: "",
    first_name: "",
    last_name: "",
    email: "",
    fall_semester_start_date: "",
    spring_semester_start_date: "",
    next_fall_semester_start_date: "",
    next_spring_semester_start_date: "",
    subscription_status: "",

    //Novermber 14 2022 updates
    street: "",
    unit: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });

  useEffect(() => {
    api
      .handleGetSubscribers(tokenAdmin, setSubscribers)
      .then(() => {
        return api.handleGetSchools(setSchools);
      })
      .then(() => {
        setSubscriberIsLoading(false);
        handleCloseLoaderModal();
      });
  }, [setCustomSchools]);

  const columns = [
    {
      name: "Subscriber ID",
      selector: "student_id",
      sortable: true,
    },
    {
      name: "Parent ID",
      selector: "parent_id",
      sortable: true,
    },
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
    },
    // {
    //   name: 'Fall Start Date',
    //   selector: 'fall_semester_start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Spring Start Date',
    //   selector: 'spring_semester_start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Next Fall Start Date',
    //   selector: 'next_fall_semester_start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Next Spring Start Date',
    //   selector: 'next_spring_semester_start_date',
    //   sortable: false,
    // },
    {
      name: "Account Status",
      selector: "subscription_status",
      sortable: true,
      cell: (row) => (
        <div>
          {" "}
          {row.subscription_status == 1 ? (
            <span className="badge badge-pill badge-success p-2">Active</span>
          ) : (
            <span className="badge badge-pill badge-danger p-2">Inactive</span>
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const getSemDetailsById = (idx) => {
    return customSchoolSemDetails.reduce((result, semDetail) => {
      if (idx == semDetail.coll_id) {
        result.push(semDetail);
      }
      return result;
    }, []);
  };

  const getCollegeCategory = (customSchool) => {
    const qtrOrSem = customSchool.qtr_or_sem;
    return qtrOrSem == "S" ? "college_semester" : "college_quarter";
  };

  const handleOnRowClicked = (row, event) => {
    setSubscriberData(row);
    const customSchool = customSchools.find(
      (school) => school.student_id == row.student_id
    );
    if (customSchool !== undefined) {
      setCurrentCollegeCat(getCollegeCategory(customSchool));
      setCurrentSemDetails(getSemDetailsById(customSchool.id));
    }
    handleShowUpdateSubscriberModal();
  };

  return (
    <div className="dashboard-body">
      <div className="container dash-container">
        <div className="colleges-table-wrapper">
          <div className="section-heading-holder">
            <div className="c-teal section-title">Manage Subscribers</div>
            {/* <button
              className="action-btn"
              onClick={() => handleShowAddCollegeModal()}
            >
              Add College
            </button> */}
          </div>
          <div className="table-holder">
            <DataTable
              columns={columns}
              data={subscribers}
              defaultSortField="college_name"
              pagination={true}
              customStyles={customStyles}
              highlightOnHover={true}
              pointerOnHover={false}
              dense={false}
              progressPending={subscriberIsLoading}
              noHeader={true}
              selectableRowsHighlight={true}
              progressComponent={
                <Spinner animation="border" role="status" aria-hidden="true" />
              }
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15, 20, 25, 30, 50]}
              pointerOnHover={true}
              onRowClicked={handleOnRowClicked}
            />
          </div>
        </div>
      </div>
      <UpdateSubscriberModal subscriber={subscriberData} />
    </div>
  );
};

export default AdminManageSubscribers;
